@charset "UTF-8";
/*!
 * Bootstrap v5.1.3 (https://getbootstrap.com/)
 * Copyright 2011-2021 The Bootstrap Authors
 * Copyright 2011-2021 Twitter, Inc.
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/main/LICENSE)
 */
:root {
  --bs-blue: #0d6efd;
  --bs-indigo: #6610f2;
  --bs-purple: #6f42c1;
  --bs-pink: #d63384;
  --bs-red: #dc3545;
  --bs-orange: #fd7e14;
  --bs-yellow: #ffc107;
  --bs-green: #198754;
  --bs-teal: #20c997;
  --bs-cyan: #0dcaf0;
  --bs-white: #fff;
  --bs-gray: #6c757d;
  --bs-gray-dark: #343a40;
  --bs-gray-100: #f8f9fa;
  --bs-gray-200: #e9ecef;
  --bs-gray-300: #dee2e6;
  --bs-gray-400: #ced4da;
  --bs-gray-500: #adb5bd;
  --bs-gray-600: #6c757d;
  --bs-gray-700: #495057;
  --bs-gray-800: #343a40;
  --bs-gray-900: #212529;
  --bs-primary: #0f6fec;
  --bs-secondary: #14191e;
  --bs-success: #0cbc87;
  --bs-info: #4f9ef8;
  --bs-warning: #f7c32e;
  --bs-danger: #d6293e;
  --bs-light: #fff;
  --bs-dark: #14191e;
  --bs-primary-rgb: #61A2F1;
  --bs-secondary-rgb: 20, 25, 30;
  --bs-success-rgb: 12, 188, 135;
  --bs-info-rgb: 79, 158, 248;
  --bs-warning-rgb: 247, 195, 46;
  --bs-danger-rgb: 214, 41, 62;
  --bs-light-rgb: 238, 240, 242;
  --bs-dark-rgb: 20, 25, 30;
  --bs-white-rgb: 255, 255, 255;
  --bs-black-rgb: 0, 0, 0;
  --bs-body-color-rgb: 103, 106, 121;
  --bs-body-bg-rgb: 239, 242, 246;
  --bs-font-sans-serif: system-ui, -apple-system, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", "Liberation Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji";
  --bs-font-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
  --bs-gradient: linear-gradient(180deg, rgba(255, 255, 255, 0.15), rgba(255, 255, 255, 0));
  --bs-body-font-family: Inter, sans-serif;
  --bs-body-font-size: 0.9375rem;
  --bs-body-font-weight: 400;
  --bs-body-line-height: 1.5;
  --bs-body-color: #676a79;
  --bs-body-bg: #fff;
}

.shadow {
  -webkit-box-shadow: 0px 0px 20px rgba(97, 210, 255, 0.15) !important;
          box-shadow: 0px 0px 20px rgba(93, 83, 84, 0.15) !important;
}
.shadow {
  background-image: radial-gradient(
    50% 50% at 50% 50%,
    rgba(27, 190, 240, 0.1) 0%,
    rgba(77, 109, 239, 0) 100%
  );
}

*,
*::before,
*::after {
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}

@media (prefers-reduced-motion: no-preference) {
  :root {
    scroll-behavior: smooth;
  }
}

body {
  margin: 0;
  font-family: var(--bs-body-font-family);
  font-size: var(--bs-body-font-size);
  font-weight: var(--bs-body-font-weight);
  line-height: var(--bs-body-line-height);
  color: var(--bs-body-color);
  text-align: var(--bs-body-text-align);
  background-color: "#fff"!important;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}

.display-linebreak {
  white-space: pre-line;
}

hr {
  margin: 1rem 0;
  color: #a1a1a8;
  background-color: currentColor;
  border: 0;
  opacity: 0.25;
}

hr:not([size]) {
  height: 1px;
}

h6, .h6, h5, .h5, h4, .h4, h3, .h3, h2, .h2, h1, .h1 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  font-family: "Inter", sans-serif;
  font-weight: 700;
  line-height: 1.25;
  color: #14191e;
}

h1, .h1 {
  font-size: calc(1.359375rem + 1.3125vw);
}
@media (min-width: 1200px) {
  h1, .h1 {
    font-size: 2.34375rem;
  }
}

h2, .h2 {
  font-size: calc(1.3125rem + 0.75vw);
}
@media (min-width: 1200px) {
  h2, .h2 {
    font-size: 1.875rem;
  }
}

h3, .h3 {
  font-size: calc(1.2890625rem + 0.46875vw);
}
@media (min-width: 1200px) {
  h3, .h3 {
    font-size: 1.640625rem;
  }
}

h4, .h4 {
  font-size: calc(1.265625rem + 0.1875vw);
}
@media (min-width: 1200px) {
  h4, .h4 {
    font-size: 1.40625rem;
  }
}

h5, .h5 {
  font-size: 1.171875rem;
}

h6, .h6 {
  font-size: 0.9375rem;
}

p {
  margin-top: 0;
  margin-bottom: 1rem;
}

abbr[title],
abbr[data-bs-original-title] {
  -webkit-text-decoration: underline dotted;
          text-decoration: underline dotted;
  cursor: help;
  -webkit-text-decoration-skip-ink: none;
          text-decoration-skip-ink: none;
}

address {
  margin-bottom: 1rem;
  font-style: normal;
  line-height: inherit;
}

ol,
ul {
  padding-left: 2rem;
}

ol,
ul,
dl {
  margin-top: 0;
  margin-bottom: 1rem;
}

ol ol,
ul ul,
ol ul,
ul ol {
  margin-bottom: 0;
}

dt {
  font-weight: 700;
}

dd {
  margin-bottom: 0.5rem;
  margin-left: 0;
}


b,
strong {
  font-weight: bolder;
}

small, .small {
  font-size: 0.875em;
}

mark, .mark {
  padding: 0.2em;
  background-color: #fcf8e3;
}

sub,
sup {
  position: relative;
  font-size: 0.75em;
  line-height: 0;
  vertical-align: baseline;
}

sub {
  bottom: -0.25em;
}

sup {
  top: -0.5em;
}

a {
  color: #0f6fec;
  text-decoration: none;
}
a:hover {
  color: #0c59bd;
}

a:not([href]):not([class]), a:not([href]):not([class]):hover {
  color: inherit;
  text-decoration: none;
}

.textBlog {
    text-indent: 40px;
    
}

pre,
code,
kbd,
samp {
  font-family: var(--bs-font-monospace);
  font-size: 1em;
  direction: ltr /* rtl:ignore */;
  unicode-bidi: bidi-override;
}

pre {
  display: block;
  margin-top: 0;
  margin-bottom: 1rem;
  overflow: auto;
  font-size: 0.875em;
}
pre code {
  font-size: inherit;
  color: inherit;
  word-break: normal;
}

code {
  font-size: 0.875em;
  color: #d63384;
  word-wrap: break-word;
}
a > code {
  color: inherit;
}

kbd {
  padding: 0.2rem 0.4rem;
  font-size: 0.875em;
  color: #fff;
  background-color: #212529;
  border-radius: 0.2rem;
}
kbd kbd {
  padding: 0;
  font-size: 1em;
  font-weight: 700;
}

figure {
  margin: 0 0 1rem;
}

img,
svg {
  vertical-align: middle;
}

table {
  caption-side: bottom;
  border-collapse: collapse;
}

caption {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  color: #6c757d;
  text-align: left;
}

th {
  text-align: inherit;
  text-align: -webkit-match-parent;
}

thead,
tbody,
tfoot,
tr,
td,
th {
  border-color: inherit;
  border-style: solid;
  border-width: 0;
}

label {
  display: inline-block;
}

button {
  border-radius: 0;
}

button:focus:not(:focus-visible) {
  outline: 0;
}

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
}

[role=button] {
  cursor: pointer;
}

select {
  word-wrap: normal;
}
select:disabled {
  opacity: 1;
}

[list]::-webkit-calendar-picker-indicator {
  display: none;
}

button,
[type=button],
[type=reset],
[type=submit] {
  -webkit-appearance: button;
}
button:not(:disabled),
[type=button]:not(:disabled),
[type=reset]:not(:disabled),
[type=submit]:not(:disabled) {
  cursor: pointer;
}

::-moz-focus-inner {
  padding: 0;
  border-style: none;
}

textarea {
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  float: left;
  width: 100%;
  padding: 0;
  margin-bottom: 0.5rem;
  font-size: calc(1.275rem + 0.3vw);
  line-height: inherit;
}
@media (min-width: 1200px) {
  legend {
    font-size: 1.5rem;
  }
}
legend + * {
  clear: left;
}

::-webkit-datetime-edit-fields-wrapper,
::-webkit-datetime-edit-text,
::-webkit-datetime-edit-minute,
::-webkit-datetime-edit-hour-field,
::-webkit-datetime-edit-day-field,
::-webkit-datetime-edit-month-field,
::-webkit-datetime-edit-year-field {
  padding: 0;
}

::-webkit-inner-spin-button {
  height: auto;
}

[type=search] {
  outline-offset: -2px;
  -webkit-appearance: textfield;
}

/* rtl:raw:
[type="tel"],
[type="url"],
[type="email"],
[type="number"] {
  direction: ltr;
}
*/
::-webkit-search-decoration {
  -webkit-appearance: none;
}

::-webkit-color-swatch-wrapper {
  padding: 0;
}

::-webkit-file-upload-button {
  font: inherit;
}

::file-selector-button {
  font: inherit;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

output {
  display: inline-block;
}

iframe {
  border: 0;
}

summary {
  display: list-item;
  cursor: pointer;
}

progress {
  vertical-align: baseline;
}

[hidden] {
  display: none !important;
}

.lead {
  font-size: 1.171875rem;
  font-weight: 400;
}

.display-1 {
  font-size: calc(1.625rem + 4.5vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-1 {
    font-size: 5rem;
  }
}

.display-2 {
  font-size: calc(1.575rem + 3.9vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-2 {
    font-size: 4.5rem;
  }
}

.display-3 {
  font-size: calc(1.525rem + 3.3vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-3 {
    font-size: 4rem;
  }
}

.display-4 {
  font-size: calc(1.475rem + 2.7vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-4 {
    font-size: 3.5rem;
  }
}

.display-5 {
  font-size: calc(1.425rem + 2.1vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-5 {
    font-size: 3rem;
  }
}

.display-6 {
  font-size: calc(1.375rem + 1.5vw);
  font-weight: 700;
  line-height: 1.25;
}
@media (min-width: 1200px) {
  .display-6 {
    font-size: 2.5rem;
  }
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
}

.list-inline {
  padding-left: 0;
  list-style: none;
}

.list-inline-item {
  display: inline-block;
}
.list-inline-item:not(:last-child) {
  margin-right: 0.5rem;
}

.initialism {
  font-size: 0.875em;
  text-transform: uppercase;
}

.blockquote {
  font-weight: bold;
  font-style: italic;
  font-size: 20px!important;
}

.blockquote-footer::before {

}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  max-width: 100%;
  height: auto;
}

.figure {
  display: inline-block;
}

.figure-img {
  margin-bottom: 0.5rem;
  line-height: 1;
}

.figure-caption {
  font-size: 0.875em;
  color: #6c757d;
}

.container,
.container-fluid,
.container-xxl,
.container-xl,
.container-lg,
.container-md,
.container-sm {
  width: 100%;
  padding-right: var(--bs-gutter-x, 0.75rem);
  padding-left: var(--bs-gutter-x, 0.75rem);
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container-sm, .container {
    max-width: 96%;
  }
}
@media (min-width: 768px) {
  .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 992px) {
  .container-lg, .container-md, .container-sm, .container {
    max-width: 100%;
  }
}
@media (min-width: 1200px) {
  .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1140px;
  }
}
@media (min-width: 1400px) {
  .container-xxl, .container-xl, .container-lg, .container-md, .container-sm, .container {
    max-width: 1200px;
  }
}
.row {
  --bs-gutter-x: 1.5rem;
  --bs-gutter-y: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  margin-top: calc(-1 * var(--bs-gutter-y));
  margin-right: calc(-0.5 * var(--bs-gutter-x));
  margin-left: calc(-0.5 * var(--bs-gutter-x));
}
.row > * {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 100%;
  max-width: 100%;
  padding-right: calc(var(--bs-gutter-x) * 0.5);
  padding-left: calc(var(--bs-gutter-x) * 0.5);
  margin-top: var(--bs-gutter-y);
}

.col {
  -webkit-box-flex: 1;
      -ms-flex: 1 0 0%;
          flex: 1 0 0%;
}

.row-cols-auto > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.row-cols-1 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.row-cols-2 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.row-cols-3 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.3333333333%;
}

.row-cols-4 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.row-cols-5 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 20%;
}

.row-cols-6 > * {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.6666666667%;
}

.col-auto {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: auto;
}

.col-1 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 8.33333333%;
}

.col-2 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 16.66666667%;
}

.col-3 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 25%;
}

.col-4 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 33.33333333%;
}

.col-5 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 41.66666667%;
}

.col-6 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 50%;
}

.col-7 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 58.33333333%;
}

.col-8 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 66.66666667%;
}

.col-9 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 75%;
}

.col-10 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 83.33333333%;
}

.col-11 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 91.66666667%;
}

.col-12 {
  -webkit-box-flex: 0;
      -ms-flex: 0 0 auto;
          flex: 0 0 auto;
  width: 100%;
}

.offset-1 {
  margin-left: 8.33333333%;
}

.offset-2 {
  margin-left: 16.66666667%;
}

.offset-3 {
  margin-left: 25%;
}

.offset-4 {
  margin-left: 33.33333333%;
}

.offset-5 {
  margin-left: 41.66666667%;
}

.offset-6 {
  margin-left: 50%;
}

.offset-7 {
  margin-left: 58.33333333%;
}

.offset-8 {
  margin-left: 66.66666667%;
}

.offset-9 {
  margin-left: 75%;
}

.offset-10 {
  margin-left: 83.33333333%;
}

.offset-11 {
  margin-left: 91.66666667%;
}

.g-0,
.gx-0 {
  --bs-gutter-x: 0;
}

.g-0,
.gy-0 {
  --bs-gutter-y: 0;
}

.g-1,
.gx-1 {
  --bs-gutter-x: 0.25rem;
}

.g-1,
.gy-1 {
  --bs-gutter-y: 0.25rem;
}

.g-2,
.gx-2 {
  --bs-gutter-x: 0.5rem;
}

.g-2,
.gy-2 {
  --bs-gutter-y: 0.5rem;
}

.g-3,
.gx-3 {
  --bs-gutter-x: 1rem;
}

.g-3,
.gy-3 {
  --bs-gutter-y: 1rem;
}

.g-4,
.gx-4 {
  --bs-gutter-x: 1.5rem;
}

.g-4,
.gy-4 {
  --bs-gutter-y: 1.5rem;
}

.g-5,
.gx-5 {
  --bs-gutter-x: 3rem;
}

.g-5,
.gy-5 {
  --bs-gutter-y: 3rem;
}

@media (min-width: 576px) {
  .col-sm {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-sm-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-sm-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-sm-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-sm-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-sm-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-sm-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-sm-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-sm-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-sm-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-sm-0 {
    margin-left: 0;
  }

  .offset-sm-1 {
    margin-left: 8.33333333%;
  }

  .offset-sm-2 {
    margin-left: 16.66666667%;
  }

  .offset-sm-3 {
    margin-left: 25%;
  }

  .offset-sm-4 {
    margin-left: 33.33333333%;
  }

  .offset-sm-5 {
    margin-left: 41.66666667%;
  }

  .offset-sm-6 {
    margin-left: 50%;
  }

  .offset-sm-7 {
    margin-left: 58.33333333%;
  }

  .offset-sm-8 {
    margin-left: 66.66666667%;
  }

  .offset-sm-9 {
    margin-left: 75%;
  }

  .offset-sm-10 {
    margin-left: 83.33333333%;
  }

  .offset-sm-11 {
    margin-left: 91.66666667%;
  }

  .g-sm-0,
.gx-sm-0 {
    --bs-gutter-x: 0;
  }

  .g-sm-0,
.gy-sm-0 {
    --bs-gutter-y: 0;
  }

  .g-sm-1,
.gx-sm-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-sm-1,
.gy-sm-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-sm-2,
.gx-sm-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-sm-2,
.gy-sm-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-sm-3,
.gx-sm-3 {
    --bs-gutter-x: 1rem;
  }

  .g-sm-3,
.gy-sm-3 {
    --bs-gutter-y: 1rem;
  }

  .g-sm-4,
.gx-sm-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-sm-4,
.gy-sm-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-sm-5,
.gx-sm-5 {
    --bs-gutter-x: 3rem;
  }

  .g-sm-5,
.gy-sm-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 768px) {
  .col-md {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-md-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-md-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-md-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-md-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-md-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-md-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-md-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-md-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-md-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-md-0 {
    margin-left: 0;
  }

  .offset-md-1 {
    margin-left: 8.33333333%;
  }

  .offset-md-2 {
    margin-left: 16.66666667%;
  }

  .offset-md-3 {
    margin-left: 25%;
  }

  .offset-md-4 {
    margin-left: 33.33333333%;
  }

  .offset-md-5 {
    margin-left: 41.66666667%;
  }

  .offset-md-6 {
    margin-left: 50%;
  }

  .offset-md-7 {
    margin-left: 58.33333333%;
  }

  .offset-md-8 {
    margin-left: 66.66666667%;
  }

  .offset-md-9 {
    margin-left: 75%;
  }

  .offset-md-10 {
    margin-left: 83.33333333%;
  }

  .offset-md-11 {
    margin-left: 91.66666667%;
  }

  .g-md-0,
.gx-md-0 {
    --bs-gutter-x: 0;
  }

  .g-md-0,
.gy-md-0 {
    --bs-gutter-y: 0;
  }

  .g-md-1,
.gx-md-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-md-1,
.gy-md-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-md-2,
.gx-md-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-md-2,
.gy-md-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-md-3,
.gx-md-3 {
    --bs-gutter-x: 1rem;
  }

  .g-md-3,
.gy-md-3 {
    --bs-gutter-y: 1rem;
  }

  .g-md-4,
.gx-md-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-md-4,
.gy-md-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-md-5,
.gx-md-5 {
    --bs-gutter-x: 3rem;
  }

  .g-md-5,
.gy-md-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 992px) {
  .col-lg {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-lg-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-lg-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-lg-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-lg-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-lg-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-lg-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-lg-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-lg-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-lg-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-lg-0 {
    margin-left: 0;
  }

  .offset-lg-1 {
    margin-left: 8.33333333%;
  }

  .offset-lg-2 {
    margin-left: 16.66666667%;
  }

  .offset-lg-3 {
    margin-left: 25%;
  }

  .offset-lg-4 {
    margin-left: 33.33333333%;
  }

  .offset-lg-5 {
    margin-left: 41.66666667%;
  }

  .offset-lg-6 {
    margin-left: 50%;
  }

  .offset-lg-7 {
    margin-left: 58.33333333%;
  }

  .offset-lg-8 {
    margin-left: 66.66666667%;
  }

  .offset-lg-9 {
    margin-left: 75%;
  }

  .offset-lg-10 {
    margin-left: 83.33333333%;
  }

  .offset-lg-11 {
    margin-left: 91.66666667%;
  }

  .g-lg-0,
.gx-lg-0 {
    --bs-gutter-x: 0;
  }

  .g-lg-0,
.gy-lg-0 {
    --bs-gutter-y: 0;
  }

  .g-lg-1,
.gx-lg-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-lg-1,
.gy-lg-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-lg-2,
.gx-lg-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-lg-2,
.gy-lg-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-lg-3,
.gx-lg-3 {
    --bs-gutter-x: 1rem;
  }

  .g-lg-3,
.gy-lg-3 {
    --bs-gutter-y: 1rem;
  }

  .g-lg-4,
.gx-lg-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-lg-4,
.gy-lg-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-lg-5,
.gx-lg-5 {
    --bs-gutter-x: 3rem;
  }

  .g-lg-5,
.gy-lg-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1200px) {
  .col-xl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-xl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-xl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-xl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-xl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-xl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-xl-0 {
    margin-left: 0;
  }

  .offset-xl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xl-3 {
    margin-left: 25%;
  }

  .offset-xl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xl-6 {
    margin-left: 50%;
  }

  .offset-xl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xl-9 {
    margin-left: 75%;
  }

  .offset-xl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xl-11 {
    margin-left: 91.66666667%;
  }

  .g-xl-0,
.gx-xl-0 {
    --bs-gutter-x: 0;
  }

  .g-xl-0,
.gy-xl-0 {
    --bs-gutter-y: 0;
  }

  .g-xl-1,
.gx-xl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xl-1,
.gy-xl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xl-2,
.gx-xl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xl-2,
.gy-xl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xl-3,
.gx-xl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xl-3,
.gy-xl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xl-4,
.gx-xl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xl-4,
.gy-xl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xl-5,
.gx-xl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xl-5,
.gy-xl-5 {
    --bs-gutter-y: 3rem;
  }
}
@media (min-width: 1400px) {
  .col-xxl {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
  }

  .row-cols-xxl-auto > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .row-cols-xxl-1 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .row-cols-xxl-2 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .row-cols-xxl-3 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.3333333333%;
  }

  .row-cols-xxl-4 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .row-cols-xxl-5 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 20%;
  }

  .row-cols-xxl-6 > * {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.6666666667%;
  }

  .col-xxl-auto {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: auto;
  }

  .col-xxl-1 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 8.33333333%;
  }

  .col-xxl-2 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 16.66666667%;
  }

  .col-xxl-3 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 25%;
  }

  .col-xxl-4 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 33.33333333%;
  }

  .col-xxl-5 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 41.66666667%;
  }

  .col-xxl-6 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 50%;
  }

  .col-xxl-7 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 58.33333333%;
  }

  .col-xxl-8 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 66.66666667%;
  }

  .col-xxl-9 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 75%;
  }

  .col-xxl-10 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 83.33333333%;
  }

  .col-xxl-11 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 91.66666667%;
  }

  .col-xxl-12 {
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;
    width: 100%;
  }

  .offset-xxl-0 {
    margin-left: 0;
  }

  .offset-xxl-1 {
    margin-left: 8.33333333%;
  }

  .offset-xxl-2 {
    margin-left: 16.66666667%;
  }

  .offset-xxl-3 {
    margin-left: 25%;
  }

  .offset-xxl-4 {
    margin-left: 33.33333333%;
  }

  .offset-xxl-5 {
    margin-left: 41.66666667%;
  }

  .offset-xxl-6 {
    margin-left: 50%;
  }

  .offset-xxl-7 {
    margin-left: 58.33333333%;
  }

  .offset-xxl-8 {
    margin-left: 66.66666667%;
  }

  .offset-xxl-9 {
    margin-left: 75%;
  }

  .offset-xxl-10 {
    margin-left: 83.33333333%;
  }

  .offset-xxl-11 {
    margin-left: 91.66666667%;
  }

  .g-xxl-0,
.gx-xxl-0 {
    --bs-gutter-x: 0;
  }

  .g-xxl-0,
.gy-xxl-0 {
    --bs-gutter-y: 0;
  }

  .g-xxl-1,
.gx-xxl-1 {
    --bs-gutter-x: 0.25rem;
  }

  .g-xxl-1,
.gy-xxl-1 {
    --bs-gutter-y: 0.25rem;
  }

  .g-xxl-2,
.gx-xxl-2 {
    --bs-gutter-x: 0.5rem;
  }

  .g-xxl-2,
.gy-xxl-2 {
    --bs-gutter-y: 0.5rem;
  }

  .g-xxl-3,
.gx-xxl-3 {
    --bs-gutter-x: 1rem;
  }

  .g-xxl-3,
.gy-xxl-3 {
    --bs-gutter-y: 1rem;
  }

  .g-xxl-4,
.gx-xxl-4 {
    --bs-gutter-x: 1.5rem;
  }

  .g-xxl-4,
.gy-xxl-4 {
    --bs-gutter-y: 1.5rem;
  }

  .g-xxl-5,
.gx-xxl-5 {
    --bs-gutter-x: 3rem;
  }

  .g-xxl-5,
.gy-xxl-5 {
    --bs-gutter-y: 3rem;
  }
}
.table {
  --bs-table-bg: transparent;
  --bs-table-accent-bg: transparent;
  --bs-table-striped-color: #212529;
  --bs-table-striped-bg: rgba(0, 0, 0, 0.05);
  --bs-table-active-color: #212529;
  --bs-table-active-bg: rgba(0, 0, 0, 0.1);
  --bs-table-hover-color: #212529;
  --bs-table-hover-bg: rgba(0, 0, 0, 0.075);
  width: 100%;
  margin-bottom: 1rem;
  color: #212529;
  vertical-align: top;
  border-color: #dee2e6;
}
.table > :not(caption) > * > * {
  padding: 0.5rem 0.5rem;
  background-color: var(--bs-table-bg);
  border-bottom-width: 1px;
  -webkit-box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
          box-shadow: inset 0 0 0 9999px var(--bs-table-accent-bg);
}
.table > tbody {
  vertical-align: inherit;
}
.table > thead {
  vertical-align: bottom;
}
.table > :not(:first-child) {
  border-top: 2px solid #a1a1a8;
}

.caption-top {
  caption-side: top;
}

.table-sm > :not(caption) > * > * {
  padding: 0.25rem 0.25rem;
}

.table-bordered > :not(caption) > * {
  border-width: 1px 0;
}
.table-bordered > :not(caption) > * > * {
  border-width: 0 1px;
}

.table-borderless > :not(caption) > * > * {
  border-bottom-width: 0;
}
.table-borderless > :not(:first-child) {
  border-top-width: 0;
}

.table-striped > tbody > tr:nth-of-type(odd) > * {
  --bs-table-accent-bg: var(--bs-table-striped-bg);
  color: var(--bs-table-striped-color);
}

.table-active {
  --bs-table-accent-bg: var(--bs-table-active-bg);
  color: var(--bs-table-active-color);
}

.table-hover > tbody > tr:hover > * {
  --bs-table-accent-bg: var(--bs-table-hover-bg);
  color: var(--bs-table-hover-color);
}

.table-primary {
  --bs-table-bg: #cfe2ff;
  --bs-table-striped-bg: #c5d7f2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bacbe6;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfd1ec;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bacbe6;
}

.table-secondary {
  --bs-table-bg: #e2e3e5;
  --bs-table-striped-bg: #d7d8da;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #cbccce;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #d1d2d4;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #cbccce;
}

.table-success {
  --bs-table-bg: #d1e7dd;
  --bs-table-striped-bg: #c7dbd2;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #bcd0c7;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #c1d6cc;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #bcd0c7;
}

.table-info {
  --bs-table-bg: #cff4fc;
  --bs-table-striped-bg: #c5e8ef;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #badce3;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #bfe2e9;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #badce3;
}

.table-warning {
  --bs-table-bg: #fff3cd;
  --bs-table-striped-bg: #f2e7c3;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #e6dbb9;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #ece1be;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #e6dbb9;
}

.table-danger {
  --bs-table-bg: #f8d7da;
  --bs-table-striped-bg: #eccccf;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfc2c4;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5c7ca;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfc2c4;
}

.table-light {
  --bs-table-bg: #f8f9fa;
  --bs-table-striped-bg: #ecedee;
  --bs-table-striped-color: #000;
  --bs-table-active-bg: #dfe0e1;
  --bs-table-active-color: #000;
  --bs-table-hover-bg: #e5e6e7;
  --bs-table-hover-color: #000;
  color: #000;
  border-color: #dfe0e1;
}

.table-dark {
  --bs-table-bg: #212529;
  --bs-table-striped-bg: #2c3034;
  --bs-table-striped-color: #fff;
  --bs-table-active-bg: #373b3e;
  --bs-table-active-color: #fff;
  --bs-table-hover-bg: #323539;
  --bs-table-hover-color: #fff;
  color: #fff;
  border-color: #373b3e;
}

.table-responsive {
  overflow-x: auto;
  -webkit-overflow-scrolling: touch;
}

@media (max-width: 575.98px) {
  .table-responsive-sm {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 767.98px) {
  .table-responsive-md {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 991.98px) {
  .table-responsive-lg {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1199.98px) {
  .table-responsive-xl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
@media (max-width: 1399.98px) {
  .table-responsive-xxl {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
.form-label {
  margin-bottom: 0.5rem;
}

.col-form-label {
  padding-top: calc(0.5rem + 1px);
  padding-bottom: calc(0.5rem + 1px);
  margin-bottom: 0;
  font-size: inherit;
  line-height: 1.5;
}

.col-form-label-lg {
  padding-top: calc(0.8rem + 1px);
  padding-bottom: calc(0.8rem + 1px);
  font-size: 1rem;
}

.col-form-label-sm {
  padding-top: calc(0.4rem + 1px);
  padding-bottom: calc(0.4rem + 1px);
  font-size: 0.8125rem;
}

.form-text {
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #6c757d;
}

.form-control {
  display: block;
  width: 100%;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #29292e;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #e1e4e6;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  border-radius: 0.4rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control[type=file] {
  overflow: hidden;
}
.form-control[type=file]:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control:focus {
  color: #29292e;
  background-color: #fff;
  border-color: #0f6fec;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.form-control::-webkit-date-and-time-value {
  height: 1.5em;
}
.form-control::-webkit-input-placeholder {
  color: #a1a1a8;
  opacity: 1;
}
.form-control::-moz-placeholder {
  color: #a1a1a8;
  opacity: 1;
}
.form-control:-ms-input-placeholder {
  color: #a1a1a8;
  opacity: 1;
}
.form-control::-ms-input-placeholder {
  color: #a1a1a8;
  opacity: 1;
}
.form-control::placeholder {
  color: #a1a1a8;
  opacity: 1;
}
.form-control:disabled, .form-control[readonly] {
  background-color: #eff2f6;
  border-color: #ced4da;
  opacity: 1;
}
.form-control::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.form-control::file-selector-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
  .form-control::file-selector-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}
.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
  background-color: #dde0e3;
}
.form-control::-webkit-file-upload-button {
  padding: 0.5rem 1rem;
  margin: -0.5rem -1rem;
  -webkit-margin-end: 1rem;
          margin-inline-end: 1rem;
  color: #212529;
  background-color: #e9ecef;
  pointer-events: none;
  border-color: inherit;
  border-style: solid;
  border-width: 0;
  border-inline-end-width: 1px;
  border-radius: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-control::-webkit-file-upload-button {
    -webkit-transition: none;
    transition: none;
  }
}
.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
  background-color: #dde0e3;
}

.form-control-plaintext {
  display: block;
  width: 100%;
  padding: 0.5rem 0;
  margin-bottom: 0;
  line-height: 1.5;
  color: #212529;
  background-color: transparent;
  border: solid transparent;
  border-width: 1px 0;
}
.form-control-plaintext.form-control-sm, .form-control-plaintext.form-control-lg {
  padding-right: 0;
  padding-left: 0;
}

.form-control-sm {
  min-height: calc(1.25em + 0.8rem + 2px);
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.4rem 0.8rem;
  margin: -0.4rem -0.8rem;
  -webkit-margin-end: 0.8rem;
          margin-inline-end: 0.8rem;
}
.form-control-sm::file-selector-button {
  padding: 0.4rem 0.8rem;
  margin: -0.4rem -0.8rem;
  -webkit-margin-end: 0.8rem;
          margin-inline-end: 0.8rem;
}
.form-control-sm::-webkit-file-upload-button {
  padding: 0.4rem 0.8rem;
  margin: -0.4rem -0.8rem;
  -webkit-margin-end: 0.8rem;
          margin-inline-end: 0.8rem;
}

.form-control-lg {
  min-height: calc(1.25em + 1.6rem + 2px);
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.2rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.8rem 1.5rem;
  margin: -0.8rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}
.form-control-lg::file-selector-button {
  padding: 0.8rem 1.5rem;
  margin: -0.8rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}
.form-control-lg::-webkit-file-upload-button {
  padding: 0.8rem 1.5rem;
  margin: -0.8rem -1.5rem;
  -webkit-margin-end: 1.5rem;
          margin-inline-end: 1.5rem;
}

textarea.form-control {
  min-height: calc(1.5em + 1rem + 2px);
}
textarea.form-control-sm {
  min-height: calc(1.25em + 0.8rem + 2px);
}
textarea.form-control-lg {
  min-height: calc(1.25em + 1.6rem + 2px);
}

.form-control-color {
  width: 3rem;
  height: auto;
  padding: 0.5rem;
}
.form-control-color:not(:disabled):not([readonly]) {
  cursor: pointer;
}
.form-control-color::-moz-color-swatch {
  height: 1.5em;
  border-radius: 0.4rem;
}
.form-control-color::-webkit-color-swatch {
  height: 1.5em;
  border-radius: 0.4rem;
}

.form-select {
  display: block;
  width: 100%;
  padding: 0.5rem 2.25rem 0.5rem 1rem;
  -moz-padding-start: calc(1rem - 3px);
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #29292e;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  background-size: 16px 12px;
  border: 1px solid #ced4da;
  border-radius: 0.4rem;
  -webkit-transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-select {
    -webkit-transition: none;
    transition: none;
  }
}
.form-select:focus {
  border-color: #0f6fec;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
}
.form-select[multiple], .form-select[size]:not([size="1"]) {
  padding-right: 1rem;
  background-image: none;
}
.form-select:disabled {
  background-color: #e9ecef;
  border-color: #ced4da;
}
.form-select:-moz-focusring {
  color: transparent;
  text-shadow: 0 0 0 #29292e;
}

.form-select-sm {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
  padding-left: 0.5rem;
  font-size: 0.875rem;
  border-radius: 0.2rem;
}

.form-select-lg {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  padding-left: 1rem;
  font-size: 1.25rem;
  border-radius: 0.3rem;
}

.form-check {
  display: block;
  min-height: 1.5rem;
  padding-left: 1.5em;
  margin-bottom: 0.125rem;
}
.form-check .form-check-input {
  float: left;
  margin-left: -1.5em;
}

.form-check-input {
  width: 1em;
  height: 1em;
  margin-top: 0.25em;
  vertical-align: top;
  background-color: #eff2f6;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: 1px solid rgba(0, 0, 0, 0.25);
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
  -webkit-print-color-adjust: exact;
          color-adjust: exact;
}
.form-check-input[type=checkbox] {
  border-radius: 0.25em;
}
.form-check-input[type=radio] {
  border-radius: 50%;
}
.form-check-input:active {
  -webkit-filter: brightness(90%);
          filter: brightness(90%);
}
.form-check-input:focus {
  border-color: #0f6fec;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
}
.form-check-input:checked {
  background-color: #0f6fec;
  border-color: #0f6fec;
}
.form-check-input:checked[type=checkbox] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
}
.form-check-input:checked[type=radio] {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='2' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input[type=checkbox]:indeterminate {
  background-color: #0f6fec;
  border-color: #0f6fec;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10h8'/%3e%3c/svg%3e");
}
.form-check-input:disabled {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.5;
}
.form-check-input[disabled] ~ .form-check-label, .form-check-input:disabled ~ .form-check-label {
  opacity: 0.5;
}

.form-switch {
  padding-left: 2.5em;
}
.form-switch .form-check-input {
  width: 2em;
  margin-left: -2.5em;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
  background-position: left center;
  border-radius: 2em;
  -webkit-transition: background-position 0.15s ease-in-out;
  transition: background-position 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-switch .form-check-input {
    -webkit-transition: none;
    transition: none;
  }
}
.form-switch .form-check-input:focus {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%230f6fec'/%3e%3c/svg%3e");
}
.form-switch .form-check-input:checked {
  background-position: right center;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
}
.btn-check[disabled] + .btn, .btn-check:disabled + .btn {
  pointer-events: none;
  -webkit-filter: none;
          filter: none;
  opacity: 0.65;
}

.form-range {
  width: 100%;
  height: 1.5rem;
  padding: 0;
  background-color: transparent;
  -webkit-appearance: none;
     -moz-appearance: none;
          appearance: none;
}
.form-range:focus {
  outline: 0;
}
.form-range:focus::-webkit-slider-thumb {
  -webkit-box-shadow: 0 0 0 1px #eff2f6, none;
          box-shadow: 0 0 0 1px #eff2f6, none;
}
.form-range:focus::-moz-range-thumb {
  box-shadow: 0 0 0 1px #eff2f6, none;
}
.form-range::-moz-focus-outer {
  border: 0;
}
.form-range::-webkit-slider-thumb {
  width: 1rem;
  height: 1rem;
  margin-top: -0.25rem;
  background-color: #0f6fec;
  border: 0;
  border-radius: 1rem;
  -webkit-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  -webkit-appearance: none;
          appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-webkit-slider-thumb {
    -webkit-transition: none;
    transition: none;
  }
}
.form-range::-webkit-slider-thumb:active {
  background-color: #b7d4f9;
}
.form-range::-webkit-slider-runnable-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range::-moz-range-thumb {
  width: 1rem;
  height: 1rem;
  background-color: #0f6fec;
  border: 0;
  border-radius: 1rem;
  -moz-transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  -moz-appearance: none;
       appearance: none;
}
@media (prefers-reduced-motion: reduce) {
  .form-range::-moz-range-thumb {
    -moz-transition: none;
    transition: none;
  }
}
.form-range::-moz-range-thumb:active {
  background-color: #b7d4f9;
}
.form-range::-moz-range-track {
  width: 100%;
  height: 0.5rem;
  color: transparent;
  cursor: pointer;
  background-color: #dee2e6;
  border-color: transparent;
  border-radius: 1rem;
}
.form-range:disabled {
  pointer-events: none;
}
.form-range:disabled::-webkit-slider-thumb {
  background-color: #a1a1a8;
}
.form-range:disabled::-moz-range-thumb {
  background-color: #a1a1a8;
}

.form-floating {
  position: relative;
}
.form-floating > .form-control,
.form-floating > .form-select {
  height: calc(3.5rem + 2px);
  line-height: 1.25;
}
.form-floating > label {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  padding: 1rem 0.75rem;
  pointer-events: none;
  border: 1px solid transparent;
  -webkit-transform-origin: 0 0;
          transform-origin: 0 0;
  -webkit-transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out;
  transition: opacity 0.1s ease-in-out, transform 0.1s ease-in-out, -webkit-transform 0.1s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .form-floating > label {
    -webkit-transition: none;
    transition: none;
  }
}
.form-floating > .form-control {
  padding: 1rem 0.75rem;
}
.form-floating > .form-control::-webkit-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-moz-placeholder {
  color: transparent;
}
.form-floating > .form-control:-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::-ms-input-placeholder {
  color: transparent;
}
.form-floating > .form-control::placeholder {
  color: transparent;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-ms-input-placeholder) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:focus, .form-floating > .form-control:not(:placeholder-shown) {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:-webkit-autofill {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-select {
  padding-top: 1.625rem;
  padding-bottom: 0.625rem;
}
.form-floating > .form-control:not(:-moz-placeholder-shown) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:not(:-ms-input-placeholder) ~ label {
  opacity: 0.65;
  transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:focus ~ label,
.form-floating > .form-control:not(:placeholder-shown) ~ label,
.form-floating > .form-select ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}
.form-floating > .form-control:-webkit-autofill ~ label {
  opacity: 0.65;
  -webkit-transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
          transform: scale(0.85) translateY(-0.5rem) translateX(0.15rem);
}

.input-group {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: stretch;
      -ms-flex-align: stretch;
          align-items: stretch;
  width: 100%;
}
.input-group > .form-control,
.input-group > .form-select {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  width: 1%;
  min-width: 0;
}
.input-group > .form-control:focus,
.input-group > .form-select:focus {
  z-index: 3;
}
.input-group .btn {
  position: relative;
  z-index: 2;
}
.input-group .btn:focus {
  z-index: 3;
}

.input-group-text {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  text-align: center;
  white-space: nowrap;
  background-color: #e9ecef;
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 0.4rem;
}

.input-group-lg > .form-control,
.input-group-lg > .form-select,
.input-group-lg > .input-group-text,
.input-group-lg > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.2rem;
}

.input-group-sm > .form-control,
.input-group-sm > .form-select,
.input-group-sm > .input-group-text,
.input-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}

.input-group-lg > .form-select,
.input-group-sm > .form-select {
  padding-right: 3.25rem;
}

.input-group:not(.has-validation) > :not(:last-child):not(.dropdown-toggle):not(.dropdown-menu),
.input-group:not(.has-validation) > .dropdown-toggle:nth-last-child(n+3) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group.has-validation > :nth-last-child(n+3):not(.dropdown-toggle):not(.dropdown-menu),
.input-group.has-validation > .dropdown-toggle:nth-last-child(n+4) {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.input-group > :not(:first-child):not(.dropdown-menu):not(.valid-tooltip):not(.valid-feedback):not(.invalid-tooltip):not(.invalid-feedback) {
  margin-left: -1px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.valid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #198754;
}

.valid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(25, 135, 84, 0.9);
  border-radius: 0.25rem;
}

.was-validated :valid ~ .valid-feedback,
.was-validated :valid ~ .valid-tooltip,
.is-valid ~ .valid-feedback,
.is-valid ~ .valid-tooltip {
  display: block;
}

.was-validated .form-control:valid, .form-control.is-valid {
  border-color: #198754;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:valid:focus, .form-control.is-valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}

.was-validated textarea.form-control:valid, textarea.form-control.is-valid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:valid, .form-select.is-valid {
  border-color: #198754;
}
.was-validated .form-select:valid:not([multiple]):not([size]), .was-validated .form-select:valid:not([multiple])[size="1"], .form-select.is-valid:not([multiple]):not([size]), .form-select.is-valid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='%23198754' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:valid:focus, .form-select.is-valid:focus {
  border-color: #198754;
  -webkit-box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}

.was-validated .form-check-input:valid, .form-check-input.is-valid {
  border-color: #198754;
}
.was-validated .form-check-input:valid:checked, .form-check-input.is-valid:checked {
  background-color: #198754;
}
.was-validated .form-check-input:valid:focus, .form-check-input.is-valid:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
          box-shadow: 0 0 0 1px rgba(25, 135, 84, 0.25);
}
.was-validated .form-check-input:valid ~ .form-check-label, .form-check-input.is-valid ~ .form-check-label {
  color: #198754;
}

.form-check-inline .form-check-input ~ .valid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:valid, .input-group .form-control.is-valid,
.was-validated .input-group .form-select:valid,
.input-group .form-select.is-valid {
  z-index: 1;
}
.was-validated .input-group .form-control:valid:focus, .input-group .form-control.is-valid:focus,
.was-validated .input-group .form-select:valid:focus,
.input-group .form-select.is-valid:focus {
  z-index: 3;
}

.invalid-feedback {
  display: none;
  width: 100%;
  margin-top: 0.25rem;
  font-size: 0.875em;
  color: #dc3545;
}

.invalid-tooltip {
  position: absolute;
  top: 100%;
  z-index: 5;
  display: none;
  max-width: 100%;
  padding: 0.25rem 0.5rem;
  margin-top: 0.1rem;
  font-size: 0.875rem;
  color: #fff;
  background-color: rgba(220, 53, 69, 0.9);
  border-radius: 0.25rem;
}

.was-validated :invalid ~ .invalid-feedback,
.was-validated :invalid ~ .invalid-tooltip,
.is-invalid ~ .invalid-feedback,
.is-invalid ~ .invalid-tooltip {
  display: block;
}

.was-validated .form-control:invalid, .form-control.is-invalid {
  border-color: #dc3545;
  padding-right: calc(1.5em + 0.75rem);
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.1875rem) center;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-control:invalid:focus, .form-control.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated textarea.form-control:invalid, textarea.form-control.is-invalid {
  padding-right: calc(1.5em + 0.75rem);
  background-position: top calc(0.375em + 0.1875rem) right calc(0.375em + 0.1875rem);
}

.was-validated .form-select:invalid, .form-select.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-select:invalid:not([multiple]):not([size]), .was-validated .form-select:invalid:not([multiple])[size="1"], .form-select.is-invalid:not([multiple]):not([size]), .form-select.is-invalid:not([multiple])[size="1"] {
  padding-right: 4.125rem;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e"), url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 12 12' width='12' height='12' fill='none' stroke='%23dc3545'%3e%3ccircle cx='6' cy='6' r='4.5'/%3e%3cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3e%3ccircle cx='6' cy='8.2' r='.6' fill='%23dc3545' stroke='none'/%3e%3c/svg%3e");
  background-position: right 0.75rem center, center right 2.25rem;
  background-size: 16px 12px, calc(0.75em + 0.375rem) calc(0.75em + 0.375rem);
}
.was-validated .form-select:invalid:focus, .form-select.is-invalid:focus {
  border-color: #dc3545;
  -webkit-box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}

.was-validated .form-check-input:invalid, .form-check-input.is-invalid {
  border-color: #dc3545;
}
.was-validated .form-check-input:invalid:checked, .form-check-input.is-invalid:checked {
  background-color: #dc3545;
}
.was-validated .form-check-input:invalid:focus, .form-check-input.is-invalid:focus {
  -webkit-box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
          box-shadow: 0 0 0 1px rgba(220, 53, 69, 0.25);
}
.was-validated .form-check-input:invalid ~ .form-check-label, .form-check-input.is-invalid ~ .form-check-label {
  color: #dc3545;
}

.form-check-inline .form-check-input ~ .invalid-feedback {
  margin-left: 0.5em;
}

.was-validated .input-group .form-control:invalid, .input-group .form-control.is-invalid,
.was-validated .input-group .form-select:invalid,
.input-group .form-select.is-invalid {
  z-index: 2;
}
.was-validated .input-group .form-control:invalid:focus, .input-group .form-control.is-invalid:focus,
.was-validated .input-group .form-select:invalid:focus,
.input-group .form-select.is-invalid:focus {
  z-index: 3;
}

.btn {
  display: inline-block;
  font-weight: 500;
  line-height: 1.5;
  color: #676a79;
  text-align: center;
  vertical-align: middle;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  background-color: transparent;
  border: 1px solid transparent;
  padding: 0.5rem 1rem;
  font-size: 0.9375rem;
  border-radius: 0.4rem;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .btn {
    -webkit-transition: none;
    transition: none;
  }
}
.btn:hover {
  color: #676a79;
}
.btn-check:focus + .btn, .btn:focus {
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}
.btn:disabled, .btn.disabled, fieldset:disabled .btn {
  pointer-events: none;
  opacity: 0.65;
}
.pattern {
background-color: #e2ecf9;
opacity: 1;
background: linear-gradient(135deg, #61A2F155 25%, transparent 25%) -14px 0/ 28px 28px, linear-gradient(225deg, #61A2F1 25%, transparent 25%) -14px 0/ 28px 28px, linear-gradient(315deg, #61A2F155 25%, transparent 25%) 0px 0/ 28px 28px, linear-gradient(45deg, #61A2F1 25%, #e2ecf9 25%) 0px 0/ 28px 28px;
}

.btn-primary {
  color: #fff;
  background-color: #61A2F1;
  border-color: #61A2F1;
  border-radius: 30px!important;
  font-weight: bold!important;
}
.btn-primary  a {
  font-weight: "bold"!important;
}
.btn-primary:hover {
  color: #fff;
  background-color: #0d5ec9;
  border-color: #0c59bd;
}
.btn-check:focus + .btn-primary, .btn-primary:focus {
  color: #fff;
  background-color: #0d5ec9;
  border-color: #0c59bd;
  -webkit-box-shadow: 0 0 0 0 rgba(51, 133, 239, 0.5);
          box-shadow: 0 0 0 0 rgba(51, 133, 239, 0.5);
}
.btn-check:checked + .btn-primary, .btn-check:active + .btn-primary, .btn-primary:active, .btn-primary.active, .show > .btn-primary.dropdown-toggle {
  color: #fff;
  background-color: #0c59bd;
  border-color: #0b53b1;
}
.btn-check:checked + .btn-primary:focus, .btn-check:active + .btn-primary:focus, .btn-primary:active:focus, .btn-primary.active:focus, .show > .btn-primary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(51, 133, 239, 0.5);
          box-shadow: 0 0 0 0 rgba(51, 133, 239, 0.5);
}
.btn-primary:disabled, .btn-primary.disabled {
  color: #fff;
  background-color: #0f6fec;
  border-color: #0f6fec;
}

.btn-secondary {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
  border-radius: 30px!important;
}
.btn-secondary:hover {
  color: #fff;
  background-color: #11151a;
  border-color: #101418;
}
.btn-check:focus + .btn-secondary, .btn-secondary:focus {
  color: #fff;
  background-color: #11151a;
  border-color: #101418;
  -webkit-box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
          box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
}
.btn-check:checked + .btn-secondary, .btn-check:active + .btn-secondary, .btn-secondary:active, .btn-secondary.active, .show > .btn-secondary.dropdown-toggle {
  color: #fff;
  background-color: #101418;
  border-color: #0f1317;
}
.btn-check:checked + .btn-secondary:focus, .btn-check:active + .btn-secondary:focus, .btn-secondary:active:focus, .btn-secondary.active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
          box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
}
.btn-secondary:disabled, .btn-secondary.disabled {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}

.btn-success {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87;
}
.btn-success:hover {
  color: #fff;
  background-color: #0aa073;
  border-color: #0a966c;
}
.btn-check:focus + .btn-success, .btn-success:focus {
  color: #fff;
  background-color: #0aa073;
  border-color: #0a966c;
  -webkit-box-shadow: 0 0 0 0 rgba(48, 198, 153, 0.5);
          box-shadow: 0 0 0 0 rgba(48, 198, 153, 0.5);
}
.btn-check:checked + .btn-success, .btn-check:active + .btn-success, .btn-success:active, .btn-success.active, .show > .btn-success.dropdown-toggle {
  color: #fff;
  background-color: #0a966c;
  border-color: #098d65;
}
.btn-check:checked + .btn-success:focus, .btn-check:active + .btn-success:focus, .btn-success:active:focus, .btn-success.active:focus, .show > .btn-success.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(48, 198, 153, 0.5);
          box-shadow: 0 0 0 0 rgba(48, 198, 153, 0.5);
}
.btn-success:disabled, .btn-success.disabled {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87;
}

.btn-info {
  color: #fff;
  background-color: #4f9ef8;
  border-color: #4f9ef8;
}
.btn-info:hover {
  color: #fff;
  background-color: #4386d3;
  border-color: #3f7ec6;
}
.btn-check:focus + .btn-info, .btn-info:focus {
  color: #fff;
  background-color: #4386d3;
  border-color: #3f7ec6;
  -webkit-box-shadow: 0 0 0 0 rgba(105, 173, 249, 0.5);
          box-shadow: 0 0 0 0 rgba(105, 173, 249, 0.5);
}
.btn-check:checked + .btn-info, .btn-check:active + .btn-info, .btn-info:active, .btn-info.active, .show > .btn-info.dropdown-toggle {
  color: #fff;
  background-color: #3f7ec6;
  border-color: #3b77ba;
}
.btn-check:checked + .btn-info:focus, .btn-check:active + .btn-info:focus, .btn-info:active:focus, .btn-info.active:focus, .show > .btn-info.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(105, 173, 249, 0.5);
          box-shadow: 0 0 0 0 rgba(105, 173, 249, 0.5);
}
.btn-info:disabled, .btn-info.disabled {
  color: #fff;
  background-color: #4f9ef8;
  border-color: #4f9ef8;
}

.btn-warning {
  color: #000;
  background-color: #f7c32e;
  border-color: #f7c32e;
}
.btn-warning:hover {
  color: #000;
  background-color: #f8cc4d;
  border-color: #f8c943;
}
.btn-check:focus + .btn-warning, .btn-warning:focus {
  color: #000;
  background-color: #f8cc4d;
  border-color: #f8c943;
  -webkit-box-shadow: 0 0 0 0 rgba(210, 166, 39, 0.5);
          box-shadow: 0 0 0 0 rgba(210, 166, 39, 0.5);
}
.btn-check:checked + .btn-warning, .btn-check:active + .btn-warning, .btn-warning:active, .btn-warning.active, .show > .btn-warning.dropdown-toggle {
  color: #000;
  background-color: #f9cf58;
  border-color: #f8c943;
}
.btn-check:checked + .btn-warning:focus, .btn-check:active + .btn-warning:focus, .btn-warning:active:focus, .btn-warning.active:focus, .show > .btn-warning.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(210, 166, 39, 0.5);
          box-shadow: 0 0 0 0 rgba(210, 166, 39, 0.5);
}
.btn-warning:disabled, .btn-warning.disabled {
  color: #000;
  background-color: #f7c32e;
  border-color: #f7c32e;
}

.btn-danger {
  color: #fff;
  background-color: #d6293e;
  border-color: #d6293e;
}
.btn-danger:hover {
  color: #fff;
  background-color: #b62335;
  border-color: #ab2132;
}
.btn-check:focus + .btn-danger, .btn-danger:focus {
  color: #fff;
  background-color: #b62335;
  border-color: #ab2132;
  -webkit-box-shadow: 0 0 0 0 rgba(220, 73, 91, 0.5);
          box-shadow: 0 0 0 0 rgba(220, 73, 91, 0.5);
}
.btn-check:checked + .btn-danger, .btn-check:active + .btn-danger, .btn-danger:active, .btn-danger.active, .show > .btn-danger.dropdown-toggle {
  color: #fff;
  background-color: #ab2132;
  border-color: #a11f2f;
}
.btn-check:checked + .btn-danger:focus, .btn-check:active + .btn-danger:focus, .btn-danger:active:focus, .btn-danger.active:focus, .show > .btn-danger.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(220, 73, 91, 0.5);
          box-shadow: 0 0 0 0 rgba(220, 73, 91, 0.5);
}
.btn-danger:disabled, .btn-danger.disabled {
  color: #fff;
  background-color: #d6293e;
  border-color: #d6293e;
}

.btn-light {
  color: #000;
  background-color: #eef0f2;
  border-color: #eef0f2;
}
.btn-light:hover {
  color: #000;
  background-color: #f1f2f4;
  border-color: #f0f2f3;
}
.btn-check:focus + .btn-light, .btn-light:focus {
  color: #000;
  background-color: #f1f2f4;
  border-color: #f0f2f3;
  -webkit-box-shadow: 0 0 0 0 rgba(202, 204, 206, 0.5);
          box-shadow: 0 0 0 0 rgba(202, 204, 206, 0.5);
}
.btn-check:checked + .btn-light, .btn-check:active + .btn-light, .btn-light:active, .btn-light.active, .show > .btn-light.dropdown-toggle {
  color: #000;
  background-color: #f1f3f5;
  border-color: #f0f2f3;
}
.btn-check:checked + .btn-light:focus, .btn-check:active + .btn-light:focus, .btn-light:active:focus, .btn-light.active:focus, .show > .btn-light.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(202, 204, 206, 0.5);
          box-shadow: 0 0 0 0 rgba(202, 204, 206, 0.5);
}
.btn-light:disabled, .btn-light.disabled {
  color: #000;
  background-color: #eef0f2;
  border-color: #eef0f2;
}

.btn-dark {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}
.btn-dark:hover {
  color: #fff;
  background-color: #11151a;
  border-color: #101418;
}
.btn-check:focus + .btn-dark, .btn-dark:focus {
  color: #fff;
  background-color: #11151a;
  border-color: #101418;
  -webkit-box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
          box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
}
.btn-check:checked + .btn-dark, .btn-check:active + .btn-dark, .btn-dark:active, .btn-dark.active, .show > .btn-dark.dropdown-toggle {
  color: #fff;
  background-color: #101418;
  border-color: #0f1317;
}
.btn-check:checked + .btn-dark:focus, .btn-check:active + .btn-dark:focus, .btn-dark:active:focus, .btn-dark.active:focus, .show > .btn-dark.dropdown-toggle:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
          box-shadow: 0 0 0 0 rgba(55, 60, 64, 0.5);
}
.btn-dark:disabled, .btn-dark.disabled {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}

.btn-outline-primary {
  color: var(--bs-primary);
  border-radius: 30px!important;
  border-color: var(--bs-primary);
}
.btn-outline-primary:hover {
  color: #fff;
  background-color: var(--bs-primary);
  border-color: var(--bs-primary);

}
.btn-check:focus + .btn-outline-primary, .btn-outline-primary:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.5);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.5);
}
.btn-check:checked + .btn-outline-primary, .btn-check:active + .btn-outline-primary, .btn-outline-primary:active, .btn-outline-primary.active, .btn-outline-primary.dropdown-toggle.show {
  color: #fff;
  background-color: #0f6fec;
  border-color: #0f6fec;
}
.btn-check:checked + .btn-outline-primary:focus, .btn-check:active + .btn-outline-primary:focus, .btn-outline-primary:active:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.5);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.5);
}
.btn-outline-primary:disabled, .btn-outline-primary.disabled {
  color: #0f6fec;
  background-color: transparent;
}

.btn-outline-secondary {
  color: #14191e;
  border-color: #14191e;
}
.btn-outline-secondary:hover {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}
.btn-check:focus + .btn-outline-secondary, .btn-outline-secondary:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
          box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
}
.btn-check:checked + .btn-outline-secondary, .btn-check:active + .btn-outline-secondary, .btn-outline-secondary:active, .btn-outline-secondary.active, .btn-outline-secondary.dropdown-toggle.show {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}
.btn-check:checked + .btn-outline-secondary:focus, .btn-check:active + .btn-outline-secondary:focus, .btn-outline-secondary:active:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
          box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
}
.btn-outline-secondary:disabled, .btn-outline-secondary.disabled {
  color: #14191e;
  background-color: transparent;
}

.btn-outline-success {
  color: #0cbc87;
  border-color: #0cbc87;
}
.btn-outline-success:hover {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87;
}
.btn-check:focus + .btn-outline-success, .btn-outline-success:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(12, 188, 135, 0.5);
          box-shadow: 0 0 0 0 rgba(12, 188, 135, 0.5);
}
.btn-check:checked + .btn-outline-success, .btn-check:active + .btn-outline-success, .btn-outline-success:active, .btn-outline-success.active, .btn-outline-success.dropdown-toggle.show {
  color: #fff;
  background-color: #0cbc87;
  border-color: #0cbc87;
}
.btn-check:checked + .btn-outline-success:focus, .btn-check:active + .btn-outline-success:focus, .btn-outline-success:active:focus, .btn-outline-success.active:focus, .btn-outline-success.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(12, 188, 135, 0.5);
          box-shadow: 0 0 0 0 rgba(12, 188, 135, 0.5);
}
.btn-outline-success:disabled, .btn-outline-success.disabled {
  color: #0cbc87;
  background-color: transparent;
}

.btn-outline-info {
  color: #4f9ef8;
  border-color: #4f9ef8;
}
.btn-outline-info:hover {
  color: #fff;
  background-color: #4f9ef8;
  border-color: #4f9ef8;
}
.btn-check:focus + .btn-outline-info, .btn-outline-info:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(79, 158, 248, 0.5);
          box-shadow: 0 0 0 0 rgba(79, 158, 248, 0.5);
}
.btn-check:checked + .btn-outline-info, .btn-check:active + .btn-outline-info, .btn-outline-info:active, .btn-outline-info.active, .btn-outline-info.dropdown-toggle.show {
  color: #fff;
  background-color: #4f9ef8;
  border-color: #4f9ef8;
}
.btn-check:checked + .btn-outline-info:focus, .btn-check:active + .btn-outline-info:focus, .btn-outline-info:active:focus, .btn-outline-info.active:focus, .btn-outline-info.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(79, 158, 248, 0.5);
          box-shadow: 0 0 0 0 rgba(79, 158, 248, 0.5);
}
.btn-outline-info:disabled, .btn-outline-info.disabled {
  color: #4f9ef8;
  background-color: transparent;
}

.btn-outline-warning {
  color: #f7c32e;
  border-color: #f7c32e;
}
.btn-outline-warning:hover {
  color: #000;
  background-color: #f7c32e;
  border-color: #f7c32e;
}
.btn-check:focus + .btn-outline-warning, .btn-outline-warning:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(247, 195, 46, 0.5);
          box-shadow: 0 0 0 0 rgba(247, 195, 46, 0.5);
}
.btn-check:checked + .btn-outline-warning, .btn-check:active + .btn-outline-warning, .btn-outline-warning:active, .btn-outline-warning.active, .btn-outline-warning.dropdown-toggle.show {
  color: #000;
  background-color: #f7c32e;
  border-color: #f7c32e;
}
.btn-check:checked + .btn-outline-warning:focus, .btn-check:active + .btn-outline-warning:focus, .btn-outline-warning:active:focus, .btn-outline-warning.active:focus, .btn-outline-warning.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(247, 195, 46, 0.5);
          box-shadow: 0 0 0 0 rgba(247, 195, 46, 0.5);
}
.btn-outline-warning:disabled, .btn-outline-warning.disabled {
  color: #f7c32e;
  background-color: transparent;
}

.btn-outline-danger {
  color: #d6293e;
  border-color: #d6293e;
}
.btn-outline-danger:hover {
  color: #fff;
  background-color: #d6293e;
  border-color: #d6293e;
}
.btn-check:focus + .btn-outline-danger, .btn-outline-danger:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(214, 41, 62, 0.5);
          box-shadow: 0 0 0 0 rgba(214, 41, 62, 0.5);
}
.btn-check:checked + .btn-outline-danger, .btn-check:active + .btn-outline-danger, .btn-outline-danger:active, .btn-outline-danger.active, .btn-outline-danger.dropdown-toggle.show {
  color: #fff;
  background-color: #d6293e;
  border-color: #d6293e;
}
.btn-check:checked + .btn-outline-danger:focus, .btn-check:active + .btn-outline-danger:focus, .btn-outline-danger:active:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(214, 41, 62, 0.5);
          box-shadow: 0 0 0 0 rgba(214, 41, 62, 0.5);
}
.btn-outline-danger:disabled, .btn-outline-danger.disabled {
  color: #d6293e;
  background-color: transparent;
}

.btn-outline-light {
  color: #eef0f2;
  border-color: #eef0f2;
}
.btn-outline-light:hover {
  color: #000;
  background-color: #eef0f2;
  border-color: #eef0f2;
}
.btn-check:focus + .btn-outline-light, .btn-outline-light:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(238, 240, 242, 0.5);
          box-shadow: 0 0 0 0 rgba(238, 240, 242, 0.5);
}
.btn-check:checked + .btn-outline-light, .btn-check:active + .btn-outline-light, .btn-outline-light:active, .btn-outline-light.active, .btn-outline-light.dropdown-toggle.show {
  color: #000;
  background-color: #eef0f2;
  border-color: #eef0f2;
}
.btn-check:checked + .btn-outline-light:focus, .btn-check:active + .btn-outline-light:focus, .btn-outline-light:active:focus, .btn-outline-light.active:focus, .btn-outline-light.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(238, 240, 242, 0.5);
          box-shadow: 0 0 0 0 rgba(238, 240, 242, 0.5);
}
.btn-outline-light:disabled, .btn-outline-light.disabled {
  color: #eef0f2;
  background-color: transparent;
}

.btn-outline-dark {
  color: #14191e;
  border-color: #14191e;
}
.btn-outline-dark:hover {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}
.btn-check:focus + .btn-outline-dark, .btn-outline-dark:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
          box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
}
.btn-check:checked + .btn-outline-dark, .btn-check:active + .btn-outline-dark, .btn-outline-dark:active, .btn-outline-dark.active, .btn-outline-dark.dropdown-toggle.show {
  color: #fff;
  background-color: #14191e;
  border-color: #14191e;
}
.btn-check:checked + .btn-outline-dark:focus, .btn-check:active + .btn-outline-dark:focus, .btn-outline-dark:active:focus, .btn-outline-dark.active:focus, .btn-outline-dark.dropdown-toggle.show:focus {
  -webkit-box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
          box-shadow: 0 0 0 0 rgba(20, 25, 30, 0.5);
}
.btn-outline-dark:disabled, .btn-outline-dark.disabled {
  color: #14191e;
  background-color: transparent;
}

.btn-link {
  font-weight: 500;
  color: #0f6fec;
  text-decoration: none;
}
.btn-link:hover {
  color: #0c59bd;
}
.btn-link:disabled, .btn-link.disabled {
  color: #e1e4e6;
}

.btn-lg, .btn-group-lg > .btn {
  padding: 0.8rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.2rem;
}

.btn-sm, .btn-group-sm > .btn {
  padding: 0.4rem 0.8rem;
  font-size: 0.8125rem;
  border-radius: 0.2rem;
}

.fade {
  -webkit-transition: opacity 0.15s linear;
  transition: opacity 0.15s linear;
}
@media (prefers-reduced-motion: reduce) {
  .fade {
    -webkit-transition: none;
    transition: none;
  }
}
.fade:not(.show) {
  opacity: 0;
}

.collapse:not(.show) {
  display: none;
}

.collapsing {
  height: 0;
  overflow: hidden;
  -webkit-transition: height 0.35s ease;
  transition: height 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing {
    -webkit-transition: none;
    transition: none;
  }
}
.collapsing.collapse-horizontal {
  width: 0;
  height: auto;
  -webkit-transition: width 0.35s ease;
  transition: width 0.35s ease;
}
@media (prefers-reduced-motion: reduce) {
  .collapsing.collapse-horizontal {
    -webkit-transition: none;
    transition: none;
  }
}

.dropup,
.dropend,
.dropdown,
.dropstart {
  position: relative;
}

.dropdown-toggle {
  white-space: nowrap;
}
.dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropdown-menu {
  position: absolute;
  z-index: 1000;
  display: none;
  min-width: 15rem;
  padding: 1rem 0;
  margin: 0;
  font-size: 0.9375rem;
  color: #676a79;
  text-align: left;
  list-style: none;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.06);
  border-radius: 0.4rem;
}
.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: 0;
  margin-top: 0;
}

.dropdown-menu-start {
  --bs-position: start;
}
.dropdown-menu-start[data-bs-popper] {
  right: auto;
  left: 0;
}

.dropdown-menu-end {
  --bs-position: end;
}
.dropdown-menu-end[data-bs-popper] {
  right: 0;
  left: auto;
}

@media (min-width: 576px) {
  .dropdown-menu-sm-start {
    --bs-position: start;
  }
  .dropdown-menu-sm-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-sm-end {
    --bs-position: end;
  }
  .dropdown-menu-sm-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 768px) {
  .dropdown-menu-md-start {
    --bs-position: start;
  }
  .dropdown-menu-md-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-md-end {
    --bs-position: end;
  }
  .dropdown-menu-md-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 992px) {
  .dropdown-menu-lg-start {
    --bs-position: start;
  }
  .dropdown-menu-lg-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-lg-end {
    --bs-position: end;
  }
  .dropdown-menu-lg-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1200px) {
  .dropdown-menu-xl-start {
    --bs-position: start;
  }
  .dropdown-menu-xl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xl-end {
    --bs-position: end;
  }
  .dropdown-menu-xl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
@media (min-width: 1400px) {
  .dropdown-menu-xxl-start {
    --bs-position: start;
  }
  .dropdown-menu-xxl-start[data-bs-popper] {
    right: auto;
    left: 0;
  }

  .dropdown-menu-xxl-end {
    --bs-position: end;
  }
  .dropdown-menu-xxl-end[data-bs-popper] {
    right: 0;
    left: auto;
  }
}
.dropup .dropdown-menu[data-bs-popper] {
  top: auto;
  bottom: 100%;
  margin-top: 0;
  margin-bottom: 0;
}
.dropup .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0;
  border-right: 0.3em solid transparent;
  border-bottom: 0.3em solid;
  border-left: 0.3em solid transparent;
}
.dropup .dropdown-toggle:empty::after {
  margin-left: 0;
}

.dropend .dropdown-menu[data-bs-popper] {
  top: 0;
  right: auto;
  left: 100%;
  margin-top: 0;
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0;
  border-bottom: 0.3em solid transparent;
  border-left: 0.3em solid;
}
.dropend .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropend .dropdown-toggle::after {
  vertical-align: 0;
}

.dropstart .dropdown-menu[data-bs-popper] {
  top: 0;
  right: 100%;
  left: auto;
  margin-top: 0;
  margin-right: 0;
}
.dropstart .dropdown-toggle::after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: "";
}
.dropstart .dropdown-toggle::after {
  display: none;
}
.dropstart .dropdown-toggle::before {
  display: inline-block;
  margin-right: 0.255em;
  vertical-align: 0.255em;
  content: "";
  border-top: 0.3em solid transparent;
  border-right: 0.3em solid;
  border-bottom: 0.3em solid transparent;
}
.dropstart .dropdown-toggle:empty::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle::before {
  vertical-align: 0;
}

.dropdown-divider {
  height: 0;
  margin: 0.5rem 0;
  overflow: hidden;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  display: block;
  width: 100%;
  padding: 0.4rem 1.2rem;
  clear: both;
  font-weight: 500;
  color: #676a79;
  text-align: inherit;
  white-space: nowrap;
  background-color: transparent;
  border: 0;
}
.dropdown-item:hover, .dropdown-item:focus {
  color: #0f6fec;
  background-color: transparent;
}
.dropdown-item.active, .dropdown-item:active {
  color: #0f6fec;
  text-decoration: none;
  background-color: transparent;
}
.dropdown-item.disabled, .dropdown-item:disabled {
  color: #676a79;
  pointer-events: none;
  background-color: transparent;
}

.dropdown-menu.show {
  display: block;
}

.dropdown-header {
  display: block;
  padding: 1rem 1.2rem;
  margin-bottom: 0;
  font-size: 0.8203125rem;
  color: #14191e;
  white-space: nowrap;
}

.dropdown-item-text {
  display: block;
  padding: 0.4rem 1.2rem;
  color: #676a79;
}

.dropdown-menu-dark {
  color: #eef0f2;
  background-color: #14191e;
  border-color: rgba(0, 0, 0, 0.06);
}
.dropdown-menu-dark .dropdown-item {
  color: #eef0f2;
}
.dropdown-menu-dark .dropdown-item:hover, .dropdown-menu-dark .dropdown-item:focus {
  color: #fff;
  background-color: transparent;
}
.dropdown-menu-dark .dropdown-item.active, .dropdown-menu-dark .dropdown-item:active {
  color: #0f6fec;
  background-color: transparent;
}
.dropdown-menu-dark .dropdown-item.disabled, .dropdown-menu-dark .dropdown-item:disabled {
  color: #a1a1a8;
}
.dropdown-menu-dark .dropdown-divider {
  border-color: rgba(255, 255, 255, 0.1);
}
.dropdown-menu-dark .dropdown-item-text {
  color: #eef0f2;
}
.dropdown-menu-dark .dropdown-header {
  color: #a1a1a8;
}

.btn-group,
.btn-group-vertical {
  position: relative;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  vertical-align: middle;
}
.btn-group > .btn,
.btn-group-vertical > .btn {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
}
.btn-group > .btn-check:checked + .btn,
.btn-group > .btn-check:focus + .btn,
.btn-group > .btn:hover,
.btn-group > .btn:focus,
.btn-group > .btn:active,
.btn-group > .btn.active,
.btn-group-vertical > .btn-check:checked + .btn,
.btn-group-vertical > .btn-check:focus + .btn,
.btn-group-vertical > .btn:hover,
.btn-group-vertical > .btn:focus,
.btn-group-vertical > .btn:active,
.btn-group-vertical > .btn.active {
  z-index: 1;
}

.btn-toolbar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.btn-toolbar .input-group {
  width: auto;
}

.btn-group > .btn:not(:first-child),
.btn-group > .btn-group:not(:first-child) {
  margin-left: -1px;
}
.btn-group > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group > .btn-group:not(:last-child) > .btn {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
}
.btn-group > .btn:nth-child(n+3),
.btn-group > :not(.btn-check) + .btn,
.btn-group > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.dropdown-toggle-split {
  padding-right: 0.75rem;
  padding-left: 0.75rem;
}
.dropdown-toggle-split::after, .dropup .dropdown-toggle-split::after, .dropend .dropdown-toggle-split::after {
  margin-left: 0;
}
.dropstart .dropdown-toggle-split::before {
  margin-right: 0;
}

.btn-sm + .dropdown-toggle-split, .btn-group-sm > .btn + .dropdown-toggle-split {
  padding-right: 0.6rem;
  padding-left: 0.6rem;
}

.btn-lg + .dropdown-toggle-split, .btn-group-lg > .btn + .dropdown-toggle-split {
  padding-right: 1.125rem;
  padding-left: 1.125rem;
}

.btn-group-vertical {
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: start;
      -ms-flex-align: start;
          align-items: flex-start;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.btn-group-vertical > .btn,
.btn-group-vertical > .btn-group {
  width: 100%;
}
.btn-group-vertical > .btn:not(:first-child),
.btn-group-vertical > .btn-group:not(:first-child) {
  margin-top: -1px;
}
.btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle),
.btn-group-vertical > .btn-group:not(:last-child) > .btn {
  border-bottom-right-radius: 0;
  border-bottom-left-radius: 0;
}
.btn-group-vertical > .btn ~ .btn,
.btn-group-vertical > .btn-group:not(:first-child) > .btn {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}

.nav-link {
  display: block;
  padding: 0.25rem 0.75rem;
  color: #000;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .nav-link {
    -webkit-transition: none;
    transition: none;
  }
}
.nav-link:hover, .nav-link:focus {
  color: #0c59bd;
}
.nav-link.disabled {
  color: #a1a1a8 !important;
  pointer-events: none;
  cursor: default;
}

.nav-tabs {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.nav-tabs .nav-link {
  margin-bottom: -1px;
  background: none;
  border: 1px solid transparent;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
.nav-tabs .nav-link:hover, .nav-tabs .nav-link:focus {
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1);
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  color: #a1a1a8 !important;
  background-color: transparent;
  border-color: transparent;
}
.nav-tabs .nav-link.active,
.nav-tabs .nav-item.show .nav-link {
  color: #29292e;
  background-color: #eff2f6;
  border-color: rgba(0, 0, 0, 0.1) rgba(0, 0, 0, 0.1) #eff2f6;
}
.nav-tabs .dropdown-menu {
  margin-top: -1px;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.4rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  color: #fff;
  background-color: #0d6efd;
}

.nav-fill > .nav-link,
.nav-fill .nav-item {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  text-align: center;
}

.nav-justified > .nav-link,
.nav-justified .nav-item {
  -ms-flex-preferred-size: 0;
      flex-basis: 0;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  text-align: center;
}

.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}

.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}

.navbar {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding-top: 0;
  padding-right: 0;
  padding-bottom: 0;
  padding-left: 0;
}
.navbar > .container,
.navbar > .container-fluid,
.navbar > .container-sm,
.navbar > .container-md,
.navbar > .container-lg,
.navbar > .container-xl,
.navbar > .container-xxl {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: inherit;
      flex-wrap: inherit;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
  margin-right: 1rem;
  font-size: 1.25rem;
  white-space: nowrap;
}
.navbar-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  list-style: none;
}
.navbar-nav .nav-link {
  padding-right: 0;
  padding-left: 0;
}
.navbar-nav .dropdown-menu {
  position: static;
}

.navbar-text {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.navbar-collapse {
  -ms-flex-preferred-size: 100%;
      flex-basis: 100%;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.navbar-toggler {
  padding: 0.25rem 0;
  font-size: 1.25rem;
  line-height: 1;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  -webkit-transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: -webkit-box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out;
  transition: box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .navbar-toggler {
    -webkit-transition: none;
    transition: none;
  }
}
.navbar-toggler:hover {
  text-decoration: none;
}
.navbar-toggler:focus {
  text-decoration: none;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0;
          box-shadow: 0 0 0 0;
}

.navbar-toggler-icon {
  display: inline-block;
  width: 1.5em;
  height: 1.5em;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-nav-scroll {
  max-height: var(--bs-scroll-height, 75vh);
  overflow-y: auto;
}

@media (min-width: 576px) {
  .navbar-expand-sm {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-sm .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-sm .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-sm .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-sm .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
  .navbar-expand-sm .offcanvas-header {
    display: none;
  }
  .navbar-expand-sm .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-sm .offcanvas-top,
.navbar-expand-sm .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-sm .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-md .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-md .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-md .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-md .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-md .navbar-toggler {
    display: none;
  }
  .navbar-expand-md .offcanvas-header {
    display: none;
  }
  .navbar-expand-md .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-md .offcanvas-top,
.navbar-expand-md .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-md .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-lg .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-lg .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-lg .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-lg .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-lg .navbar-toggler {
    display: none;
  }
  .navbar-expand-lg .offcanvas-header {
    display: none;
  }
  .navbar-expand-lg .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-lg .offcanvas-top,
.navbar-expand-lg .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-lg .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xl .offcanvas-top,
.navbar-expand-xl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl {
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
  }
  .navbar-expand-xxl .navbar-nav {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: absolute;
  }
  .navbar-expand-xxl .navbar-nav .nav-link {
    padding-right: 1rem;
    padding-left: 1rem;
  }
  .navbar-expand-xxl .navbar-nav-scroll {
    overflow: visible;
  }
  .navbar-expand-xxl .navbar-collapse {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
    -ms-flex-preferred-size: auto;
        flex-basis: auto;
  }
  .navbar-expand-xxl .navbar-toggler {
    display: none;
  }
  .navbar-expand-xxl .offcanvas-header {
    display: none;
  }
  .navbar-expand-xxl .offcanvas {
    position: inherit;
    bottom: 0;
    z-index: 1000;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    visibility: visible !important;
    background-color: transparent;
    border-right: 0;
    border-left: 0;
    -webkit-transition: none;
    transition: none;
    -webkit-transform: none;
            transform: none;
  }
  .navbar-expand-xxl .offcanvas-top,
.navbar-expand-xxl .offcanvas-bottom {
    height: auto;
    border-top: 0;
    border-bottom: 0;
  }
  .navbar-expand-xxl .offcanvas-body {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    padding: 0;
    overflow-y: visible;
  }
}
.navbar-expand {
  -ms-flex-wrap: nowrap;
      flex-wrap: nowrap;
  -webkit-box-pack: start;
      -ms-flex-pack: start;
          justify-content: flex-start;
}
.navbar-expand .navbar-nav {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: absolute;
}
.navbar-expand .navbar-nav .nav-link {
  padding-right: 1rem;
  padding-left: 1rem;
}
.navbar-expand .navbar-nav-scroll {
  overflow: visible;
}
.navbar-expand .navbar-collapse {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
  -ms-flex-preferred-size: auto;
      flex-basis: auto;
}
.navbar-expand .navbar-toggler {
  display: none;
}
.navbar-expand .offcanvas-header {
  display: none;
}
.navbar-expand .offcanvas {
  position: inherit;
  bottom: 0;
  z-index: 1000;
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  visibility: visible !important;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  -webkit-transition: none;
  transition: none;
  -webkit-transform: none;
          transform: none;
}
.navbar-expand .offcanvas-top,
.navbar-expand .offcanvas-bottom {
  height: auto;
  border-top: 0;
  border-bottom: 0;
}
.navbar-expand .offcanvas-body {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 0;
      -ms-flex-positive: 0;
          flex-grow: 0;
  padding: 0;
  overflow-y: visible;
}

.navbar-light .navbar-brand {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
  color: rgba(0, 0, 0, 0.9);
}
.navbar-light .navbar-nav .nav-link {
  margin:10px;
  color: #000;
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #0f6fec;
}
.navbar-light .navbar-nav .nav-link.disabled {
  color: rgba(0, 0, 0, 0.3);
}
.navbar-light .navbar-nav .show > .nav-link,
.navbar-light .navbar-nav .nav-link.active {
  color: #0f6fec;
}
.navbar-light .navbar-toggler {
  color: #676a79;
  border-color: rgba(0, 0, 0, 0.1);
}
.navbar-light .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 30 30'%3e%3cpath stroke='rgba%280, 0, 0, 0.55%29' stroke-linecap='round' stroke-miterlimit='10' stroke-width='2' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-light .navbar-text {
  color: #676a79;
}
.navbar-light .navbar-text a,
.navbar-light .navbar-text a:hover,
.navbar-light .navbar-text a:focus {
  color: #0f6fec;
}

.navbar-dark .navbar-brand {
  color: #fff;
}
.navbar-dark .navbar-brand:hover, .navbar-dark .navbar-brand:focus {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link {
  color: #fff;
}
.navbar-dark .navbar-nav .nav-link:hover, .navbar-dark .navbar-nav .nav-link:focus {
  color: #0f6fec !important;
}
.navbar-dark .navbar-nav .nav-link.disabled {
  color: rgba(255, 255, 255, 0.25);
}
.navbar-dark .navbar-nav .show > .nav-link,
.navbar-dark .navbar-nav .nav-link.active {
  color: #0f6fec !important;
}
.navbar-dark .navbar-toggler {
  color: #fff;
  border-color: rgba(255, 255, 255, 0.1);
}
.navbar-dark .navbar-toggler-icon {
  background-image: url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='%23ffffff' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e");
}
.navbar-dark .navbar-text {
  color: #fff;
}
.navbar-dark .navbar-text a,
.navbar-dark .navbar-text a:hover,
.navbar-dark .navbar-text a:focus {
  color: #0f6fec !important;
}

.card {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  min-width: 0;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: border-box;
  border: 1px solid rgba(0, 0, 0, 0.09);
  border-radius: 0.4rem;
}
.card > hr {
  margin-right: 0;
  margin-left: 0;
}
.card > .list-group {
  border-top: inherit;
  border-bottom: inherit;
}
.card > .list-group:first-child {
  border-top-width: 0;
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px);
}
.card > .list-group:last-child {
  border-bottom-width: 0;
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px);
}
.card > .card-header + .list-group,
.card > .list-group + .card-footer {
  border-top: 0;
}

.card-body {
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1.25rem 1.25rem;
}

.card-title {
  margin-bottom: 0.5rem;
}

.card-subtitle {
  margin-top: -0.25rem;
  margin-bottom: 0;
}

.card-text:last-child {
  margin-bottom: 0;
}

.card-link + .card-link {
  margin-left: 1.25rem;
}

.card-header {
  padding: 1.25rem 1.25rem;
  margin-bottom: 0;
  background-color: #fff;
  border-bottom: 1px solid rgba(0, 0, 0, 0.09);
}
.card-header:first-child {
  border-radius: calc(0.4rem - 1px) calc(0.4rem - 1px) 0 0;
}

.card-footer {
  padding: 1.25rem 1.25rem;
  background-color: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.09);
}
.card-footer:last-child {
  border-radius: 0 0 calc(0.4rem - 1px) calc(0.4rem - 1px);
}

.card-header-tabs {
  margin-right: -0.625rem;
  margin-bottom: -1.25rem;
  margin-left: -0.625rem;
  border-bottom: 0;
}
.card-header-tabs .nav-link.active {
  background-color: #fff;
  border-bottom-color: #fff;
}

.card-header-pills {
  margin-right: -0.625rem;
  margin-left: -0.625rem;
}

.card-img-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  padding: 1.25rem;
  border-radius: calc(0.4rem - 1px);
}

.card-img,
.card-img-top,
.card-img-bottom {
  width: 100%;
}

.card-img,
.card-img-top {
  border-top-left-radius: calc(0.4rem - 1px);
  border-top-right-radius: calc(0.4rem - 1px);
}

.card-img,
.card-img-bottom {
  border-bottom-right-radius: calc(0.4rem - 1px);
  border-bottom-left-radius: calc(0.4rem - 1px);
}

.card-group > .card {
  margin-bottom: 0.75rem;
}
@media (min-width: 576px) {
  .card-group {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
  }
  .card-group > .card {
    -webkit-box-flex: 1;
        -ms-flex: 1 0 0%;
            flex: 1 0 0%;
    margin-bottom: 0;
  }
  .card-group > .card + .card {
    margin-left: 0;
    border-left: 0;
  }
  .card-group > .card:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-top,
.card-group > .card:not(:last-child) .card-header {
    border-top-right-radius: 0;
  }
  .card-group > .card:not(:last-child) .card-img-bottom,
.card-group > .card:not(:last-child) .card-footer {
    border-bottom-right-radius: 0;
  }
  .card-group > .card:not(:first-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-top,
.card-group > .card:not(:first-child) .card-header {
    border-top-left-radius: 0;
  }
  .card-group > .card:not(:first-child) .card-img-bottom,
.card-group > .card:not(:first-child) .card-footer {
    border-bottom-left-radius: 0;
  }
}

.accordion-button {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 100%;
  padding: 1rem 1.25rem;
  font-size: 0.9375rem;
  color: #212529;
  text-align: left;
  background-color: #fff;
  border: 0;
  border-radius: 0;
  overflow-anchor: none;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:not(.collapsed) {
  color: #0e64d4;
  background-color: #e7f1fd;
  -webkit-box-shadow: inset 0 -1px 0 rgba(103, 106, 121, 0.125);
          box-shadow: inset 0 -1px 0 rgba(103, 106, 121, 0.125);
}
.accordion-button:not(.collapsed)::after {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230e64d4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  -webkit-transform: rotate(-180deg);
          transform: rotate(-180deg);
}
.accordion-button::after {
  -ms-flex-negative: 0;
      flex-shrink: 0;
  width: 1.25rem;
  height: 1.25rem;
  margin-left: auto;
  content: "";
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-size: 1.25rem;
  -webkit-transition: -webkit-transform 0.2s ease-in-out;
  transition: -webkit-transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out;
  transition: transform 0.2s ease-in-out, -webkit-transform 0.2s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .accordion-button::after {
    -webkit-transition: none;
    transition: none;
  }
}
.accordion-button:hover {
  z-index: 2;
}
.accordion-button:focus {
  z-index: 3;
  border-color: none;
  outline: 0;
  -webkit-box-shadow: none;
          box-shadow: none;
}

.accordion-header {
  margin-bottom: 0;
}

.accordion-item {
  background-color: #fff;
  border: 1px solid rgba(103, 106, 121, 0.125);
}
.accordion-item:first-of-type {
  border-top-left-radius: 0.25rem;
  border-top-right-radius: 0.25rem;
}
.accordion-item:first-of-type .accordion-button {
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.accordion-item:not(:first-of-type) {
  border-top: 0;
}
.accordion-item:last-of-type {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}
.accordion-item:last-of-type .accordion-button.collapsed {
  border-bottom-right-radius: calc(0.25rem - 1px);
  border-bottom-left-radius: calc(0.25rem - 1px);
}
.accordion-item:last-of-type .accordion-collapse {
  border-bottom-right-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.accordion-body {
  padding: 1rem 1.25rem;
}

.accordion-flush .accordion-collapse {
  border-width: 0;
}
.accordion-flush .accordion-item {
  border-right: 0;
  border-left: 0;
  border-radius: 0;
}
.accordion-flush .accordion-item:first-child {
  border-top: 0;
}
.accordion-flush .accordion-item:last-child {
  border-bottom: 0;
}
.accordion-flush .accordion-item .accordion-button {
  border-radius: 0;
}

.breadcrumb {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  padding: 0.5rem 0;
  margin-bottom: 1rem;
  list-style: none;
  background-color: transparent;
}

.breadcrumb-item + .breadcrumb-item {
  padding-left: 0.8rem;
}
.breadcrumb-item + .breadcrumb-item::before {
  float: left;
  padding-right: 0.8rem;
  color: #a1a1a8;
  content: var(--bs-breadcrumb-divider, "/") /* rtl: var(--bs-breadcrumb-divider, "/") */;
}
.breadcrumb-item.active {
  color: #a1a1a8;
}

.pagination {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  padding-left: 0;
  list-style: none;
}

.page-link {
  position: relative;
  display: block;
  color: #676a79;
  background-color: #fff;
  border: 1px solid #eef0f2;
  -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, -webkit-box-shadow 0.15s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .page-link {
    -webkit-transition: none;
    transition: none;
  }
}
.page-link:hover {
  z-index: 2;
  color: #fff;
  background-color: #0f6fec;
  border-color: #0f6fec;
}
.page-link:focus {
  z-index: 3;
  color: #0c59bd;
  background-color: #eff2f6;
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
}

.page-item:not(:first-child) .page-link {
  margin-left: -1px;
}
.page-item.active .page-link {
  z-index: 3;
  color: #fff;
  background-color: #0f6fec;
  border-color: #0f6fec;
}
.page-item.disabled .page-link {
  color: #e1e4e6;
  pointer-events: none;
  background-color: #fff;
  border-color: #eef0f2;
}

.page-link {
  padding: 0.4rem 0.8rem;
}

.page-item:first-child .page-link {
  border-top-left-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
.page-item:last-child .page-link {
  border-top-right-radius: 0.4rem;
  border-bottom-right-radius: 0.4rem;
}

.pagination-lg .page-link {
  padding: 0.75rem 1.5rem;
  font-size: 1.171875rem;
}
.pagination-lg .page-item:first-child .page-link {
  border-top-left-radius: 0.3rem;
  border-bottom-left-radius: 0.3rem;
}
.pagination-lg .page-item:last-child .page-link {
  border-top-right-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.pagination-sm .page-link {
  padding: 0.25rem 0.5rem;
  font-size: 0.8203125rem;
}
.pagination-sm .page-item:first-child .page-link {
  border-top-left-radius: 0.2rem;
  border-bottom-left-radius: 0.2rem;
}
.pagination-sm .page-item:last-child .page-link {
  border-top-right-radius: 0.2rem;
  border-bottom-right-radius: 0.2rem;
}

.badge {
  display: inline-block;
  padding: 0.35em 0.65em;
  font-size: 0.85em;
  font-weight: 400;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
}
.badge:empty {
  display: none;
}

.btn .badge {
  position: relative;
  top: -1px;
}

.alert {
  position: relative;
  padding: 1rem 1rem;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
}

.alert-heading {
  color: inherit;
}

.alert-link {
  font-weight: 700;
}

.alert-dismissible {
  padding-right: 3rem;
}
.alert-dismissible .btn-close {
  position: absolute;
  top: 0;
  right: 0;
  z-index: 2;
  padding: 1.25rem 1rem;
}

.alert-primary {
  color: #09438e;
  background-color: #cfe2fb;
  border-color: #b7d4f9;
}
.alert-primary .alert-link {
  color: #073672;
}

.alert-secondary {
  color: #0c0f12;
  background-color: #d0d1d2;
  border-color: #b9babc;
}
.alert-secondary .alert-link {
  color: #0a0c0e;
}

.alert-success {
  color: #077151;
  background-color: #cef2e7;
  border-color: #b6ebdb;
}
.alert-success .alert-link {
  color: #065a41;
}

.alert-info {
  color: #2f5f95;
  background-color: #dcecfe;
  border-color: #cae2fd;
}
.alert-info .alert-link {
  color: #264c77;
}

.alert-warning {
  color: #94751c;
  background-color: #fdf3d5;
  border-color: #fdedc0;
}
.alert-warning .alert-link {
  color: #765e16;
}

.alert-danger {
  color: #801925;
  background-color: #f7d4d8;
  border-color: #f3bfc5;
}
.alert-danger .alert-link {
  color: #66141e;
}

.alert-light {
  color: #8f9091;
  background-color: #fcfcfc;
  border-color: #fafbfb;
}
.alert-light .alert-link {
  color: #727374;
}

.alert-dark {
  color: #0c0f12;
  background-color: #d0d1d2;
  border-color: #b9babc;
}
.alert-dark .alert-link {
  color: #0a0c0e;
}

@-webkit-keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}

@keyframes progress-bar-stripes {
  0% {
    background-position-x: 1rem;
  }
}
.progress {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  height: 1rem;
  overflow: hidden;
  font-size: 0.75rem;
  background-color: rgba(0, 0, 0, 0.1);
  border-radius: 0.25rem;
}

.progress-bar {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  overflow: hidden;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  background-color: #0d6efd;
  -webkit-transition: width 0.6s ease;
  transition: width 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar {
    -webkit-transition: none;
    transition: none;
  }
}

.progress-bar-striped {
  background-image: linear-gradient(45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
  background-size: 1rem 1rem;
}

.progress-bar-animated {
  -webkit-animation: 1s linear infinite progress-bar-stripes;
          animation: 1s linear infinite progress-bar-stripes;
}
@media (prefers-reduced-motion: reduce) {
  .progress-bar-animated {
    -webkit-animation: none;
            animation: none;
  }
}

.list-group {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.4rem;
}

.list-group-numbered {
  list-style-type: none;
  counter-reset: section;
}
.list-group-numbered > li::before {
  content: counters(section, ".") ". ";
  counter-increment: section;
}

.list-group-item-action {
  width: 100%;
  color: #495057;
  text-align: inherit;
}
.list-group-item-action:hover, .list-group-item-action:focus {
  z-index: 1;
  color: #495057;
  text-decoration: none;
  background-color: #eff2f6;
}
.list-group-item-action:active {
  color: #212529;
  background-color: #e9ecef;
}

.list-group-item {
  position: relative;
  display: block;
  padding: 0.5rem 1rem;
  color: #676a79;
  background-color: transparent;
  border: 1px solid rgba(0, 0, 0, 0.125);
}
.list-group-item:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit;
}
.list-group-item:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit;
}
.list-group-item.disabled, .list-group-item:disabled {
  color: #6c757d;
  pointer-events: none;
  background-color: #fff;
}
.list-group-item.active {
  z-index: 2;
  color: #fff;
  background-color: #0f6fec;
  border-color: #0f6fec;
}
.list-group-item + .list-group-item {
  border-top-width: 0;
}
.list-group-item + .list-group-item.active {
  margin-top: -1px;
  border-top-width: 1px;
}

.list-group-horizontal {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
}
.list-group-horizontal > .list-group-item:first-child {
  border-bottom-left-radius: 0.4rem;
  border-top-right-radius: 0;
}
.list-group-horizontal > .list-group-item:last-child {
  border-top-right-radius: 0.4rem;
  border-bottom-left-radius: 0;
}
.list-group-horizontal > .list-group-item.active {
  margin-top: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item {
  border-top-width: 1px;
  border-left-width: 0;
}
.list-group-horizontal > .list-group-item + .list-group-item.active {
  margin-left: -1px;
  border-left-width: 1px;
}

@media (min-width: 576px) {
  .list-group-horizontal-sm {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-sm > .list-group-item:first-child {
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-sm > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 768px) {
  .list-group-horizontal-md {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-md > .list-group-item:first-child {
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-md > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-md > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 992px) {
  .list-group-horizontal-lg {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-lg > .list-group-item:first-child {
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-lg > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1200px) {
  .list-group-horizontal-xl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xl > .list-group-item:first-child {
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
@media (min-width: 1400px) {
  .list-group-horizontal-xxl {
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-direction: row;
            flex-direction: row;
  }
  .list-group-horizontal-xxl > .list-group-item:first-child {
    border-bottom-left-radius: 0.4rem;
    border-top-right-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item:last-child {
    border-top-right-radius: 0.4rem;
    border-bottom-left-radius: 0;
  }
  .list-group-horizontal-xxl > .list-group-item.active {
    margin-top: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item {
    border-top-width: 1px;
    border-left-width: 0;
  }
  .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px;
  }
}
.list-group-flush {
  border-radius: 0;
}
.list-group-flush > .list-group-item {
  border-width: 0 0 1px;
}
.list-group-flush > .list-group-item:last-child {
  border-bottom-width: 0;
}

.list-group-item-primary {
  color: #09438e;
  background-color: #cfe2fb;
}
.list-group-item-primary.list-group-item-action:hover, .list-group-item-primary.list-group-item-action:focus {
  color: #09438e;
  background-color: #bacbe2;
}
.list-group-item-primary.list-group-item-action.active {
  color: #fff;
  background-color: #09438e;
  border-color: #09438e;
}

.list-group-item-secondary {
  color: #0c0f12;
  background-color: #d0d1d2;
}
.list-group-item-secondary.list-group-item-action:hover, .list-group-item-secondary.list-group-item-action:focus {
  color: #0c0f12;
  background-color: #bbbcbd;
}
.list-group-item-secondary.list-group-item-action.active {
  color: #fff;
  background-color: #0c0f12;
  border-color: #0c0f12;
}

.list-group-item-success {
  color: #077151;
  background-color: #cef2e7;
}
.list-group-item-success.list-group-item-action:hover, .list-group-item-success.list-group-item-action:focus {
  color: #077151;
  background-color: #b9dad0;
}
.list-group-item-success.list-group-item-action.active {
  color: #fff;
  background-color: #077151;
  border-color: #077151;
}

.list-group-item-info {
  color: #2f5f95;
  background-color: #dcecfe;
}
.list-group-item-info.list-group-item-action:hover, .list-group-item-info.list-group-item-action:focus {
  color: #2f5f95;
  background-color: #c6d4e5;
}
.list-group-item-info.list-group-item-action.active {
  color: #fff;
  background-color: #2f5f95;
  border-color: #2f5f95;
}

.list-group-item-warning {
  color: #94751c;
  background-color: #fdf3d5;
}
.list-group-item-warning.list-group-item-action:hover, .list-group-item-warning.list-group-item-action:focus {
  color: #94751c;
  background-color: #e4dbc0;
}
.list-group-item-warning.list-group-item-action.active {
  color: #fff;
  background-color: #94751c;
  border-color: #94751c;
}

.list-group-item-danger {
  color: #801925;
  background-color: #f7d4d8;
}
.list-group-item-danger.list-group-item-action:hover, .list-group-item-danger.list-group-item-action:focus {
  color: #801925;
  background-color: #debfc2;
}
.list-group-item-danger.list-group-item-action.active {
  color: #fff;
  background-color: #801925;
  border-color: #801925;
}

.list-group-item-light {
  color: #8f9091;
  background-color: #fcfcfc;
}
.list-group-item-light.list-group-item-action:hover, .list-group-item-light.list-group-item-action:focus {
  color: #8f9091;
  background-color: #e3e3e3;
}
.list-group-item-light.list-group-item-action.active {
  color: #fff;
  background-color: #8f9091;
  border-color: #8f9091;
}

.list-group-item-dark {
  color: #0c0f12;
  background-color: #d0d1d2;
}
.list-group-item-dark.list-group-item-action:hover, .list-group-item-dark.list-group-item-action:focus {
  color: #0c0f12;
  background-color: #bbbcbd;
}
.list-group-item-dark.list-group-item-action.active {
  color: #fff;
  background-color: #0c0f12;
  border-color: #0c0f12;
}

.btn-close {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  width: 1em;
  height: 1em;
  padding: 0.25em 0.25em;
  color: #000;
  background: transparent url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e") center/1em auto no-repeat;
  border: 0;
  border-radius: 0.4rem;
  opacity: 0.5;
}
.btn-close:hover {
  color: #000;
  text-decoration: none;
  opacity: 0.75;
}
.btn-close:focus {
  outline: 0;
  -webkit-box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
          box-shadow: 0 0 0 0 rgba(15, 111, 236, 0.25);
  opacity: 1;
}
.btn-close:disabled, .btn-close.disabled {
  pointer-events: none;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
  opacity: 0.25;
}

.btn-close-white {
  -webkit-filter: invert(1) grayscale(100%) brightness(200%);
          filter: invert(1) grayscale(100%) brightness(200%);
}

.toast {
  width: 350px;
  max-width: 100%;
  font-size: 0.875rem;
  pointer-events: auto;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
          box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.15);
  border-radius: 0.25rem;
}
.toast.showing {
  opacity: 0;
}
.toast:not(.show) {
  display: none;
}

.toast-container {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  max-width: 100%;
  pointer-events: none;
}
.toast-container > :not(:last-child) {
  margin-bottom: 0.75rem;
}

.toast-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  padding: 0.5rem 0.75rem;
  color: #6c757d;
  background-color: rgba(255, 255, 255, 0.85);
  background-clip: padding-box;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05);
  border-top-left-radius: calc(0.25rem - 1px);
  border-top-right-radius: calc(0.25rem - 1px);
}
.toast-header .btn-close {
  margin-right: -0.375rem;
  margin-left: 0.75rem;
}

.toast-body {
  padding: 0.75rem;
  word-wrap: break-word;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1055;
  display: none;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;
}

.modal-dialog {
  position: relative;
  width: auto;
  margin: 0.5rem;
  pointer-events: none;
}
.modal.fade .modal-dialog {
  -webkit-transition: -webkit-transform 0.3s ease-out;
  transition: -webkit-transform 0.3s ease-out;
  transition: transform 0.3s ease-out;
  transition: transform 0.3s ease-out, -webkit-transform 0.3s ease-out;
  -webkit-transform: translate(0, -50px);
          transform: translate(0, -50px);
}
@media (prefers-reduced-motion: reduce) {
  .modal.fade .modal-dialog {
    -webkit-transition: none;
    transition: none;
  }
}
.modal.show .modal-dialog {
  -webkit-transform: none;
          transform: none;
}
.modal.modal-static .modal-dialog {
  -webkit-transform: scale(1.02);
          transform: scale(1.02);
}

.modal-dialog-scrollable {
  height: calc(100% - 1rem);
}
.modal-dialog-scrollable .modal-content {
  max-height: 100%;
  overflow: hidden;
}
.modal-dialog-scrollable .modal-body {
  overflow-y: auto;
}

.modal-dialog-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  min-height: calc(100% - 1rem);
}

.modal-content {
  position: relative;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 0 solid rgba(0, 0, 0, 0.2);
  border-radius: 0.4rem;
  outline: 0;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.modal-backdrop.fade {
  opacity: 0;
}
.modal-backdrop.show {
  opacity: 0.5;
}

.modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid #dee2e6;
  border-top-left-radius: 0.4rem;
  border-top-right-radius: 0.4rem;
}
.modal-header .btn-close {
  padding: 0.5rem 0.5rem;
  margin: -0.5rem -0.5rem -0.5rem auto;
}

.modal-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.modal-body {
  position: relative;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  padding: 1rem;
}

.modal-footer {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid #dee2e6;
  border-bottom-right-radius: 0.4rem;
  border-bottom-left-radius: 0.4rem;
}
.modal-footer > * {
  margin: 0.25rem;
}

@media (min-width: 576px) {
  .modal-dialog {
    max-width: 500px;
    margin: 1.75rem auto;
  }

  .modal-dialog-scrollable {
    height: calc(100% - 3.5rem);
  }

  .modal-dialog-centered {
    min-height: calc(100% - 3.5rem);
  }

  .modal-sm {
    max-width: 300px;
  }
}
@media (min-width: 992px) {
  .modal-lg,
.modal-xl {
    max-width: 800px;
  }
}
@media (min-width: 1200px) {
  .modal-xl {
    max-width: 1140px;
  }
}
.modal-fullscreen {
  width: 100vw;
  max-width: none;
  height: 100%;
  margin: 0;
}
.modal-fullscreen .modal-content {
  height: 100%;
  border: 0;
  border-radius: 0;
}
.modal-fullscreen .modal-header {
  border-radius: 0;
}
.modal-fullscreen .modal-body {
  overflow-y: auto;
}
.modal-fullscreen .modal-footer {
  border-radius: 0;
}

@media (max-width: 575.98px) {
  .modal-fullscreen-sm-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-sm-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-sm-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-sm-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 767.98px) {
  .modal-fullscreen-md-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-md-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-md-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-md-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 991.98px) {
  .modal-fullscreen-lg-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-lg-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-lg-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-lg-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1199.98px) {
  .modal-fullscreen-xl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xl-down .modal-footer {
    border-radius: 0;
  }
}
@media (max-width: 1399.98px) {
  .modal-fullscreen-xxl-down {
    width: 100vw;
    max-width: none;
    height: 100%;
    margin: 0;
  }
  .modal-fullscreen-xxl-down .modal-content {
    height: 100%;
    border: 0;
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-header {
    border-radius: 0;
  }
  .modal-fullscreen-xxl-down .modal-body {
    overflow-y: auto;
  }
  .modal-fullscreen-xxl-down .modal-footer {
    border-radius: 0;
  }
}
.tooltip {
  position: absolute;
  z-index: 1080;
  display: block;
  margin: 0;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.6875rem;
  word-wrap: break-word;
  opacity: 0;
}
.tooltip.show {
  opacity: 0.9;
}
.tooltip .tooltip-arrow {
  position: absolute;
  display: block;
  width: 0.8rem;
  height: 0.4rem;
}
.tooltip .tooltip-arrow::before {
  position: absolute;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-tooltip-top, .bs-tooltip-auto[data-popper-placement^=top] {
  padding: 0.4rem 0;
}
.bs-tooltip-top .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow {
  bottom: 0;
}
.bs-tooltip-top .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=top] .tooltip-arrow::before {
  top: -1px;
  border-width: 0.4rem 0.4rem 0;
  border-top-color: #000;
}

.bs-tooltip-end, .bs-tooltip-auto[data-popper-placement^=right] {
  padding: 0 0.4rem;
}
.bs-tooltip-end .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow {
  left: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-end .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=right] .tooltip-arrow::before {
  right: -1px;
  border-width: 0.4rem 0.4rem 0.4rem 0;
  border-right-color: #000;
}

.bs-tooltip-bottom, .bs-tooltip-auto[data-popper-placement^=bottom] {
  padding: 0.4rem 0;
}
.bs-tooltip-bottom .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow {
  top: 0;
}
.bs-tooltip-bottom .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=bottom] .tooltip-arrow::before {
  bottom: -1px;
  border-width: 0 0.4rem 0.4rem;
  border-bottom-color: #000;
}

.bs-tooltip-start, .bs-tooltip-auto[data-popper-placement^=left] {
  padding: 0 0.4rem;
}
.bs-tooltip-start .tooltip-arrow, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow {
  right: 0;
  width: 0.4rem;
  height: 0.8rem;
}
.bs-tooltip-start .tooltip-arrow::before, .bs-tooltip-auto[data-popper-placement^=left] .tooltip-arrow::before {
  left: -1px;
  border-width: 0.4rem 0 0.4rem 0.4rem;
  border-left-color: #000;
}

.tooltip-inner {
  max-width: 200px;
  padding: 0.25rem 0.5rem;
  color: #fff;
  text-align: center;
  background-color: #000;
  border-radius: 0.25rem;
}

.popover {
  position: absolute;
  top: 0;
  left: 0 /* rtl:ignore */;
  z-index: 1070;
  display: block;
  max-width: 276px;
  font-family: "Inter", sans-serif;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5;
  text-align: left;
  text-align: start;
  text-decoration: none;
  text-shadow: none;
  text-transform: none;
  letter-spacing: normal;
  word-break: normal;
  word-spacing: normal;
  white-space: normal;
  line-break: auto;
  font-size: 0.875rem;
  word-wrap: break-word;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  border-radius: 0.3rem;
}
.popover .popover-arrow {
  position: absolute;
  display: block;
  width: 1rem;
  height: 0.5rem;
}
.popover .popover-arrow::before, .popover .popover-arrow::after {
  position: absolute;
  display: block;
  content: "";
  border-color: transparent;
  border-style: solid;
}

.bs-popover-top > .popover-arrow, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow {
  bottom: calc(-0.5rem - 1px);
}
.bs-popover-top > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::before {
  bottom: 0;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-top > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=top] > .popover-arrow::after {
  bottom: 1px;
  border-width: 0.5rem 0.5rem 0;
  border-top-color: #fff;
}

.bs-popover-end > .popover-arrow, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow {
  left: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-end > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::before {
  left: 0;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-end > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=right] > .popover-arrow::after {
  left: 1px;
  border-width: 0.5rem 0.5rem 0.5rem 0;
  border-right-color: #fff;
}

.bs-popover-bottom > .popover-arrow, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow {
  top: calc(-0.5rem - 1px);
}
.bs-popover-bottom > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::before {
  top: 0;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-bottom > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=bottom] > .popover-arrow::after {
  top: 1px;
  border-width: 0 0.5rem 0.5rem 0.5rem;
  border-bottom-color: #fff;
}
.bs-popover-bottom .popover-header::before, .bs-popover-auto[data-popper-placement^=bottom] .popover-header::before {
  position: absolute;
  top: 0;
  left: 50%;
  display: block;
  width: 1rem;
  margin-left: -0.5rem;
  content: "";
  border-bottom: 1px solid #f0f0f0;
}

.bs-popover-start > .popover-arrow, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow {
  right: calc(-0.5rem - 1px);
  width: 0.5rem;
  height: 1rem;
}
.bs-popover-start > .popover-arrow::before, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::before {
  right: 0;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: rgba(0, 0, 0, 0.25);
}
.bs-popover-start > .popover-arrow::after, .bs-popover-auto[data-popper-placement^=left] > .popover-arrow::after {
  right: 1px;
  border-width: 0.5rem 0 0.5rem 0.5rem;
  border-left-color: #fff;
}

.popover-header {
  padding: 0.5rem 1rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  color: #14191e;
  background-color: #f0f0f0;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
}
.popover-header:empty {
  display: none;
}

.popover-body {
  padding: 1rem 1rem;
  color: #212529;
}

.carousel {
  position: relative;
}

.carousel.pointer-event {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
}

.carousel-inner {
  position: relative;
  width: 100%;
  overflow: hidden;
}
.carousel-inner::after {
  display: block;
  clear: both;
  content: "";
}

.carousel-item {
  position: relative;
  display: none;
  float: left;
  width: 100%;
  margin-right: -100%;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  -webkit-transition: -webkit-transform 0.6s ease-in-out;
  transition: -webkit-transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out;
  transition: transform 0.6s ease-in-out, -webkit-transform 0.6s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-item {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-item.active,
.carousel-item-next,
.carousel-item-prev {
  display: block;
}

/* rtl:begin:ignore */
.carousel-item-next:not(.carousel-item-start),
.active.carousel-item-end {
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.carousel-item-prev:not(.carousel-item-end),
.active.carousel-item-start {
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

/* rtl:end:ignore */
.carousel-fade .carousel-item {
  opacity: 0;
  -webkit-transition-property: opacity;
  transition-property: opacity;
  -webkit-transform: none;
          transform: none;
}
.carousel-fade .carousel-item.active,
.carousel-fade .carousel-item-next.carousel-item-start,
.carousel-fade .carousel-item-prev.carousel-item-end {
  z-index: 1;
  opacity: 1;
}
.carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
  z-index: 0;
  opacity: 0;
  -webkit-transition: opacity 0s 0.6s;
  transition: opacity 0s 0.6s;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-fade .active.carousel-item-start,
.carousel-fade .active.carousel-item-end {
    -webkit-transition: none;
    transition: none;
  }
}

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 15%;
  padding: 0;
  color: #fff;
  text-align: center;
  background: none;
  border: 0;
  opacity: 0.5;
  -webkit-transition: opacity 0.15s ease;
  transition: opacity 0.15s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-control-prev,
.carousel-control-next {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-control-prev:hover, .carousel-control-prev:focus,
.carousel-control-next:hover,
.carousel-control-next:focus {
  color: #fff;
  text-decoration: none;
  outline: 0;
  opacity: 0.9;
}

.carousel-control-prev {
  left: 0;
}

.carousel-control-next {
  right: 0;
}

.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: 100% 100%;
}

/* rtl:options: {
  "autoRename": true,
  "stringMap":[ {
    "name"    : "prev-next",
    "search"  : "prev",
    "replace" : "next"
  } ]
} */
.carousel-control-prev-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M11.354 1.646a.5.5 0 0 1 0 .708L5.707 8l5.647 5.646a.5.5 0 0 1-.708.708l-6-6a.5.5 0 0 1 0-.708l6-6a.5.5 0 0 1 .708 0z'/%3e%3c/svg%3e");
}

.carousel-control-next-icon {
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M4.646 1.646a.5.5 0 0 1 .708 0l6 6a.5.5 0 0 1 0 .708l-6 6a.5.5 0 0 1-.708-.708L10.293 8 4.646 2.354a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.carousel-indicators {
  position: absolute;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 2;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  padding: 0;
  margin-right: 15%;
  margin-bottom: 1rem;
  margin-left: 15%;
  list-style: none;
}
.carousel-indicators [data-bs-target] {
  -webkit-box-sizing: content-box;
          box-sizing: content-box;
  -webkit-box-flex: 0;
      -ms-flex: 0 1 auto;
          flex: 0 1 auto;
  width: 30px;
  height: 3px;
  padding: 0;
  margin-right: 3px;
  margin-left: 3px;
  text-indent: -999px;
  cursor: pointer;
  background-color: #fff;
  background-clip: padding-box;
  border: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  opacity: 0.5;
  -webkit-transition: opacity 0.6s ease;
  transition: opacity 0.6s ease;
}
@media (prefers-reduced-motion: reduce) {
  .carousel-indicators [data-bs-target] {
    -webkit-transition: none;
    transition: none;
  }
}
.carousel-indicators .active {
  opacity: 1;
}

.carousel-caption {
  position: absolute;
  right: 15%;
  bottom: 1.25rem;
  left: 15%;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  color: #fff;
  text-align: center;
}

.carousel-dark .carousel-control-prev-icon,
.carousel-dark .carousel-control-next-icon {
  -webkit-filter: invert(1) grayscale(100);
          filter: invert(1) grayscale(100);
}
.carousel-dark .carousel-indicators [data-bs-target] {
  background-color: #000;
}
.carousel-dark .carousel-caption {
  color: #000;
}

@-webkit-keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
            transform: rotate(360deg) /* rtl:ignore */;
  }
}

@keyframes spinner-border {
  to {
    -webkit-transform: rotate(360deg) /* rtl:ignore */;
            transform: rotate(360deg) /* rtl:ignore */;
  }
}
.spinner-border {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  border: 0.25em solid currentColor;
  border-right-color: transparent;
  border-radius: 50%;
  -webkit-animation: 0.75s linear infinite spinner-border;
          animation: 0.75s linear infinite spinner-border;
}

.spinner-border-sm {
  width: 1rem;
  height: 1rem;
  border-width: 0.2em;
}

@-webkit-keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}

@keyframes spinner-grow {
  0% {
    -webkit-transform: scale(0);
            transform: scale(0);
  }
  50% {
    opacity: 1;
    -webkit-transform: none;
            transform: none;
  }
}
.spinner-grow {
  display: inline-block;
  width: 2rem;
  height: 2rem;
  vertical-align: -0.125em;
  background-color: currentColor;
  border-radius: 50%;
  opacity: 0;
  -webkit-animation: 0.75s linear infinite spinner-grow;
          animation: 0.75s linear infinite spinner-grow;
}

.spinner-grow-sm {
  width: 1rem;
  height: 1rem;
}

@media (prefers-reduced-motion: reduce) {
  .spinner-border,
.spinner-grow {
    -webkit-animation-duration: 1.5s;
            animation-duration: 1.5s;
  }
}
.offcanvas {
  position: fixed;
  bottom: 0;
  z-index: 1045;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  max-width: 100%;
  visibility: hidden;
  background-color: #fff;
  background-clip: padding-box;
  outline: 0;
  -webkit-transition: -webkit-transform 0.3s ease-in-out;
  transition: -webkit-transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out;
  transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
}
@media (prefers-reduced-motion: reduce) {
  .offcanvas {
    -webkit-transition: none;
    transition: none;
  }
}

.offcanvas-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
}
.offcanvas-backdrop.fade {
  opacity: 0;
}
.offcanvas-backdrop.show {
  opacity: 0.5;
}

.offcanvas-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  padding: 1rem 1.5rem;
}
.offcanvas-header .btn-close {
  padding: 0.5rem 0.75rem;
  margin-top: -0.5rem;
  margin-right: -0.75rem;
  margin-bottom: -0.5rem;
}

.offcanvas-title {
  margin-bottom: 0;
  line-height: 1.5;
}

.offcanvas-body {
  -webkit-box-flex: 1;
      -ms-flex-positive: 1;
          flex-grow: 1;
  padding: 1rem 1.5rem;
  overflow-y: auto;
}

.offcanvas-start {
  top: 0;
  left: 0;
  width: 400px;
  border-right: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateX(-100%);
          transform: translateX(-100%);
}

.offcanvas-end {
  top: 0;
  right: 0;
  width: 400px;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateX(100%);
          transform: translateX(100%);
}

.offcanvas-top {
  top: 0;
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(-100%);
          transform: translateY(-100%);
}

.offcanvas-bottom {
  right: 0;
  left: 0;
  height: 30vh;
  max-height: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  -webkit-transform: translateY(100%);
          transform: translateY(100%);
}

.offcanvas.show {
  -webkit-transform: none;
          transform: none;
}

.placeholder {
  display: inline-block;
  min-height: 1em;
  vertical-align: middle;
  cursor: wait;
  background-color: currentColor;
  opacity: 0.5;
}
.placeholder.btn::before {
  display: inline-block;
  content: "";
}

.placeholder-xs {
  min-height: 0.6em;
}

.placeholder-sm {
  min-height: 0.8em;
}

.placeholder-lg {
  min-height: 1.2em;
}

.placeholder-glow .placeholder {
  -webkit-animation: placeholder-glow 2s ease-in-out infinite;
          animation: placeholder-glow 2s ease-in-out infinite;
}

@-webkit-keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}

@keyframes placeholder-glow {
  50% {
    opacity: 0.2;
  }
}
.placeholder-wave {
  -webkit-mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
          mask-image: linear-gradient(130deg, #000 55%, rgba(0, 0, 0, 0.8) 75%, #000 95%);
  -webkit-mask-size: 200% 100%;
          mask-size: 200% 100%;
  -webkit-animation: placeholder-wave 2s linear infinite;
          animation: placeholder-wave 2s linear infinite;
}

@-webkit-keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}

@keyframes placeholder-wave {
  100% {
    -webkit-mask-position: -200% 0%;
            mask-position: -200% 0%;
  }
}
.clearfix::after {
  display: block;
  clear: both;
  content: "";
}

.link-primary {
  color: #0f6fec;
}
.link-primary:hover, .link-primary:focus {
  color: #0c59bd;
}

.link-secondary {
  color: #14191e;
}
.link-secondary:hover, .link-secondary:focus {
  color: #101418;
}

.link-success {
  color: #0cbc87;
}
.link-success:hover, .link-success:focus {
  color: #0a966c;
}

.link-info {
  color: #4f9ef8;
}
.link-info:hover, .link-info:focus {
  color: #3f7ec6;
}

.link-warning {
  color: #f7c32e;
}
.link-warning:hover, .link-warning:focus {
  color: #f9cf58;
}

.link-danger {
  color: #d6293e;
}
.link-danger:hover, .link-danger:focus {
  color: #ab2132;
}

.link-light {
  color: #eef0f2;
}
.link-light:hover, .link-light:focus {
  color: #f1f3f5;
}

.link-dark {
  color: #14191e;
}
.link-dark:hover, .link-dark:focus {
  color: #101418;
}

.ratio {
  position: relative;
  width: 100%;
}
.ratio::before {
  display: block;
  padding-top: var(--bs-aspect-ratio);
  content: "";
}
.ratio > * {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ratio-1x1 {
  --bs-aspect-ratio: 100%;
}

.ratio-4x3 {
  --bs-aspect-ratio: 75%;
}

.ratio-16x9 {
  --bs-aspect-ratio: 56.25%;
}

.ratio-21x9 {
  --bs-aspect-ratio: 42.8571428571%;
}

.fixed-top {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 900;
}

.bpw-layout {
  z-index: 1000!important;
  background-color: #fff;
}
.fixed-bottom {
  position: fixed;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1030;
}

.sticky-top {
  position: sticky;
  top: 0;
  z-index: 1020;
}

@media (min-width: 576px) {
  .sticky-sm-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 768px) {
  .sticky-md-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 992px) {
  .sticky-lg-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1200px) {
  .sticky-xl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
@media (min-width: 1400px) {
  .sticky-xxl-top {
    position: sticky;
    top: 0;
    z-index: 1020;
  }
}
.hstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.vstack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1 1 auto;
          flex: 1 1 auto;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -ms-flex-item-align: stretch;
      align-self: stretch;
}

.visually-hidden,
.visually-hidden-focusable:not(:focus):not(:focus-within) {
  position: absolute !important;
  width: 1px !important;
  height: 1px !important;
  padding: 0 !important;
  margin: -1px !important;
  overflow: hidden !important;
  clip: rect(0, 0, 0, 0) !important;
  white-space: nowrap !important;
  border: 0 !important;
}

.stretched-link::after {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1;
  content: "";
}

.text-truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.vr {
  display: inline-block;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  width: 1px;
  min-height: 1em;
  background-color: currentColor;
  opacity: 0.25;
}

.align-baseline {
  vertical-align: baseline !important;
}

.align-top {
  vertical-align: top !important;
}

.align-middle {
  vertical-align: middle !important;
}

.align-bottom {
  vertical-align: bottom !important;
}

.align-text-bottom {
  vertical-align: text-bottom !important;
}

.align-text-top {
  vertical-align: text-top !important;
}

.float-start {
  float: left !important;
}

.float-end {
  float: right !important;
}

.float-none {
  float: none !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-25 {
  opacity: 0.25 !important;
}

.opacity-50 {
  opacity: 0.5 !important;
}

.opacity-75 {
  opacity: 0.75 !important;
}

.opacity-100 {
  opacity: 1 !important;
}

.overflow-auto {
  overflow: auto !important;
}

.overflow-hidden {
  overflow: hidden !important;
}

.overflow-visible {
  overflow: visible !important;
}

.overflow-scroll {
  overflow: scroll !important;
}

.d-inline {
  display: inline !important;
}

.d-inline-block {
  display: inline-block !important;
}

.d-block {
  display: block !important;
}

.d-grid {
  display: grid !important;
}

.d-table {
  display: table !important;
}

.d-table-row {
  display: table-row !important;
}

.d-table-cell {
  display: table-cell !important;
}

.d-flex {
  display: -webkit-box !important;
  display: -ms-flexbox !important;
  display: flex !important;
}

.d-inline-flex {
  display: -webkit-inline-box !important;
  display: -ms-inline-flexbox !important;
  display: inline-flex !important;
}

.d-none {
  display: none !important;
}


.shadow-sm {
  -webkit-box-shadow: 0 0.125rem 0.25rem rgba(83, 88, 93, 0.15) !important;
          box-shadow: 0 0.125rem 0.25rem rgba(83, 88, 93, 0.15) !important;
}

.shadow-lg {
  -webkit-box-shadow: 0 1rem 3rem rgba(83, 88, 93, 0.15) !important;
          box-shadow: 0 1rem 3rem rgba(83, 88, 93, 0.15) !important;
}

.shadow-none {
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}

.position-static {
  position: static !important;
}

.position-relative {
  position: relative !important;
}

.position-absolute {
  position: absolute !important;
}

.position-fixed {
  position: fixed !important;
}

.position-sticky {
  position: sticky !important;
}

.top-0 {
  top: 0 !important;
}

.top-50 {
  top: 50% !important;
}

.top-100 {
  top: 100% !important;
}

.bottom-0 {
  bottom: 0 !important;
}

.bottom-50 {
  bottom: 50% !important;
}

.bottom-100 {
  bottom: 100% !important;
}

.start-0 {
  left: 0 !important;
}

.start-50 {
  left: 50% !important;
}

.start-100 {
  left: 100% !important;
}

.end-0 {
  right: 0 !important;
}

.end-50 {
  right: 50% !important;
}

.end-100 {
  right: 100% !important;
}

.translate-middle {
  -webkit-transform: translate(-50%, -50%) !important;
          transform: translate(-50%, -50%) !important;
}

.translate-middle-x {
  -webkit-transform: translateX(-50%) !important;
          transform: translateX(-50%) !important;
}

.translate-middle-y {
  -webkit-transform: translateY(-50%) !important;
          transform: translateY(-50%) !important;
}

.border {
  border: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-0 {
  border: 0 !important;
}

.border-top {
  border-top: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-top-0 {
  border-top: 0 !important;
}

.border-end {
  border-right: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-end-0 {
  border-right: 0 !important;
}

.border-bottom {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-bottom-0 {
  border-bottom: 0 !important;
}

.border-start {
  border-left: 1px solid rgba(0, 0, 0, 0.1) !important;
}

.border-start-0 {
  border-left: 0 !important;
}

.border-primary {
  border-color: #0f6fec !important;
}

.border-secondary {
  border-color: #14191e !important;
}

.border-success {
  border-color: #0cbc87 !important;
}

.border-info {
  border-color: #4f9ef8 !important;
}

.border-warning {
  border-color: #f7c32e !important;
}

.border-danger {
  border-color: #d6293e !important;
}

.border-light {
  border-color: #eef0f2 !important;
}

.border-dark {
  border-color: #14191e !important;
}

.border-white {
  border-color: #fff !important;
}

.border-1 {
  border-width: 1px !important;
}

.border-2 {
  border-width: 2px !important;
}

.border-3 {
  border-width: 3px !important;
}

.border-4 {
  border-width: 4px !important;
}

.border-5 {
  border-width: 5px !important;
}

.w-25 {
  width: 25% !important;
}

.w-50 {
  width: 50% !important;
}

.w-75 {
  width: 75% !important;
}

.w-100 {
  width: 100% !important;
}

.w-auto {
  width: auto !important;
}

.mw-100 {
  max-width: 100% !important;
}

.chatCard {
  min-width: 27vh;
}
.chatCard:hover {
    background-color: #e8f8ff;
}
.chatCardActive {
  min-width: 27vh;  
  background-color: #e8f8ff;
  border: 1px solid #e8f8ff;
}

.vw-100 {
  width: 100vw !important;
}

.min-vw-100 {
  min-width: 100vw !important;
}

.h-25 {
  height: 25% !important;
}

.h-50 {
  height: 50% !important;
}

.h-75 {
  height: 75% !important;
}

.h-100 {
  height: 100% !important;
}

.h-auto {
  height: auto !important;
}
.h-400 {
  height: 400px!important;
}
.chatList  {
  overflow:hidden; overflow-y:scroll;
  height: 70vh!important;

}
.chatBox {
 overflow-x:scroll;
  margin-bottom: 0!important;
  height: 70vh!important;
}

.mh-100 {
  max-height: 100% !important;
}

.vh-100 {
  height: 100vh !important;
}

.min-vh-100 {
  min-height: 100vh !important;
}

.flex-fill {
  -webkit-box-flex: 1 !important;
      -ms-flex: 1 1 auto !important;
          flex: 1 1 auto !important;
}

.flex-row {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: row !important;
          flex-direction: row !important;
}

.flex-column {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: normal !important;
      -ms-flex-direction: column !important;
          flex-direction: column !important;
}

.flex-row-reverse {
  -webkit-box-orient: horizontal !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: row-reverse !important;
          flex-direction: row-reverse !important;
}

.flex-column-reverse {
  -webkit-box-orient: vertical !important;
  -webkit-box-direction: reverse !important;
      -ms-flex-direction: column-reverse !important;
          flex-direction: column-reverse !important;
}

.flex-grow-0 {
  -webkit-box-flex: 0 !important;
      -ms-flex-positive: 0 !important;
          flex-grow: 0 !important;
}

.flex-grow-1 {
  -webkit-box-flex: 1 !important;
      -ms-flex-positive: 1 !important;
          flex-grow: 1 !important;
}

.flex-shrink-0 {
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.flex-shrink-1 {
  -ms-flex-negative: 1 !important;
      flex-shrink: 1 !important;
}

.flex-wrap {
  -ms-flex-wrap: wrap !important;
      flex-wrap: wrap !important;
}

.flex-nowrap {
  -ms-flex-wrap: nowrap !important;
      flex-wrap: nowrap !important;
}

.flex-wrap-reverse {
  -ms-flex-wrap: wrap-reverse !important;
      flex-wrap: wrap-reverse !important;
}

.gap-0 {
  gap: 0 !important;
}

.gap-1 {
  gap: 0.25rem !important;
}

.gap-2 {
  gap: 0.5rem !important;
}

.gap-3 {
  gap: 1rem !important;
}

.gap-4 {
  gap: 1.5rem !important;
}

.gap-5 {
  gap: 3rem !important;
}

.justify-content-start {
  -webkit-box-pack: start !important;
      -ms-flex-pack: start !important;
          justify-content: flex-start !important;
}

.justify-content-end {
  -webkit-box-pack: end !important;
      -ms-flex-pack: end !important;
          justify-content: flex-end !important;
}

.justify-content-center {
  -webkit-box-pack: center !important;
      -ms-flex-pack: center !important;
          justify-content: center !important;
}

.justify-content-between {
  -webkit-box-pack: justify !important;
      -ms-flex-pack: justify !important;
          justify-content: space-between !important;
}

.justify-content-around {
  -ms-flex-pack: distribute !important;
      justify-content: space-around !important;
}

.justify-content-evenly {
  -webkit-box-pack: space-evenly !important;
      -ms-flex-pack: space-evenly !important;
          justify-content: space-evenly !important;
}

.align-items-start {
  -webkit-box-align: start !important;
      -ms-flex-align: start !important;
          align-items: flex-start !important;
}

.align-items-end {
  -webkit-box-align: end !important;
      -ms-flex-align: end !important;
          align-items: flex-end !important;
}

.align-items-center {
  -webkit-box-align: center !important;
      -ms-flex-align: center !important;
          align-items: center !important;
}

.align-items-baseline {
  -webkit-box-align: baseline !important;
      -ms-flex-align: baseline !important;
          align-items: baseline !important;
}

.align-items-stretch {
  -webkit-box-align: stretch !important;
      -ms-flex-align: stretch !important;
          align-items: stretch !important;
}

.align-content-start {
  -ms-flex-line-pack: start !important;
      align-content: flex-start !important;
}

.align-content-end {
  -ms-flex-line-pack: end !important;
      align-content: flex-end !important;
}

.align-content-center {
  -ms-flex-line-pack: center !important;
      align-content: center !important;
}

.align-content-between {
  -ms-flex-line-pack: justify !important;
      align-content: space-between !important;
}

.align-content-around {
  -ms-flex-line-pack: distribute !important;
      align-content: space-around !important;
}

.align-content-stretch {
  -ms-flex-line-pack: stretch !important;
      align-content: stretch !important;
}

.align-self-auto {
  -ms-flex-item-align: auto !important;
      align-self: auto !important;
}

.align-self-start {
  -ms-flex-item-align: start !important;
      align-self: flex-start !important;
}

.align-self-end {
  -ms-flex-item-align: end !important;
      align-self: flex-end !important;
}

.align-self-center {
  -ms-flex-item-align: center !important;
      align-self: center !important;
}

.align-self-baseline {
  -ms-flex-item-align: baseline !important;
      align-self: baseline !important;
}

.align-self-stretch {
  -ms-flex-item-align: stretch !important;
      align-self: stretch !important;
}

.order-first {
  -webkit-box-ordinal-group: 0 !important;
      -ms-flex-order: -1 !important;
          order: -1 !important;
}

.order-0 {
  -webkit-box-ordinal-group: 1 !important;
      -ms-flex-order: 0 !important;
          order: 0 !important;
}

.order-1 {
  -webkit-box-ordinal-group: 2 !important;
      -ms-flex-order: 1 !important;
          order: 1 !important;
}

.order-2 {
  -webkit-box-ordinal-group: 3 !important;
      -ms-flex-order: 2 !important;
          order: 2 !important;
}

.order-3 {
  -webkit-box-ordinal-group: 4 !important;
      -ms-flex-order: 3 !important;
          order: 3 !important;
}

.order-4 {
  -webkit-box-ordinal-group: 5 !important;
      -ms-flex-order: 4 !important;
          order: 4 !important;
}

.order-5 {
  -webkit-box-ordinal-group: 6 !important;
      -ms-flex-order: 5 !important;
          order: 5 !important;
}

.order-last {
  -webkit-box-ordinal-group: 7 !important;
      -ms-flex-order: 6 !important;
          order: 6 !important;
}

.m-0 {
  margin: 0 !important;
}

.m-1 {
  margin: 0.25rem !important;
}

.m-2 {
  margin: 0.5rem !important;
}

.m-3 {
  margin: 1rem !important;
}

.m-4 {
  margin: 1.5rem !important;
}

.m-5 {
  margin: 3rem !important;
}

.m-auto {
  margin: auto !important;
}

.mx-0 {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

.mx-1 {
  margin-right: 0.25rem !important;
  margin-left: 0.25rem !important;
}

.mx-2 {
  margin-right: 0.5rem !important;
  margin-left: 0.5rem !important;
}

.mx-3 {
  margin-right: 1rem !important;
  margin-left: 1rem !important;
}

.mx-4 {
  margin-right: 1.5rem !important;
  margin-left: 1.5rem !important;
}

.mx-5 {
  margin-right: 3rem !important;
  margin-left: 3rem !important;
}

.mx-auto {
  margin-right: auto !important;
  margin-left: auto !important;
}

.my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.my-1 {
  margin-top: 0.25rem !important;
  margin-bottom: 0.25rem !important;
}

.my-2 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-3 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-4 {
  margin-top: 1.5rem !important;
  margin-bottom: 1.5rem !important;
}

.my-5 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-auto {
  margin-top: auto !important;
  margin-bottom: auto !important;
}

.mt-0 {
  margin-top: 0 !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mt-auto {
  margin-top: auto !important;
}

.me-0 {
  margin-right: 0 !important;
}

.me-1 {
  margin-right: 0.25rem !important;
}

.me-2 {
  margin-right: 0.5rem !important;
}

.me-3 {
  margin-right: 1rem !important;
}

.me-4 {
  margin-right: 1.5rem !important;
}

.me-5 {
  margin-right: 3rem !important;
}

.me-auto {
  margin-right: auto !important;
}

.mb-0 {
  margin-bottom: 0 !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mb-auto {
  margin-bottom: auto !important;
}

.ms-0 {
  margin-left: 0 !important;
}

.ms-1 {
  margin-left: 0.25rem !important;
}

.ms-2 {
  margin-left: 0.5rem !important;
}

.ms-3 {
  margin-left: 1rem !important;
}

.ms-4 {
  margin-left: 1.5rem !important;
}

.ms-5 {
  margin-left: 3rem !important;
}

.ms-auto {
  margin-left: auto !important;
}

.m-n1 {
  margin: -0.25rem !important;
}

.m-n2 {
  margin: -0.5rem !important;
}

.m-n3 {
  margin: -1rem !important;
}

.m-n4 {
  margin: -1.5rem !important;
}

.m-n5 {
  margin: -3rem !important;
}

.mx-n1 {
  margin-right: -0.25rem !important;
  margin-left: -0.25rem !important;
}

.mx-n2 {
  margin-right: -0.5rem !important;
  margin-left: -0.5rem !important;
}

.mx-n3 {
  margin-right: -1rem !important;
  margin-left: -1rem !important;
}

.mx-n4 {
  margin-right: -1.5rem !important;
  margin-left: -1.5rem !important;
}

.mx-n5 {
  margin-right: -3rem !important;
  margin-left: -3rem !important;
}

.my-n1 {
  margin-top: -0.25rem !important;
  margin-bottom: -0.25rem !important;
}

.my-n2 {
  margin-top: -0.5rem !important;
  margin-bottom: -0.5rem !important;
}

.my-n3 {
  margin-top: -1rem !important;
  margin-bottom: -1rem !important;
}

.my-n4 {
  margin-top: -1.5rem !important;
  margin-bottom: -1.5rem !important;
}

.my-n5 {
  margin-top: -3rem !important;
  margin-bottom: -3rem !important;
}

.mt-n1 {
  margin-top: -0.25rem !important;
}

.mt-n2 {
  margin-top: -0.5rem !important;
}

.mt-n3 {
  margin-top: -1rem !important;
}

.mt-n4 {
  margin-top: -1.5rem !important;
}

.mt-n5 {
  margin-top: -3rem !important;
}

.me-n1 {
  margin-right: -0.25rem !important;
}

.me-n2 {
  margin-right: -0.5rem !important;
}

.me-n3 {
  margin-right: -1rem !important;
}

.me-n4 {
  margin-right: -1.5rem !important;
}

.me-n5 {
  margin-right: -3rem !important;
}

.mb-n1 {
  margin-bottom: -0.25rem !important;
}

.mb-n2 {
  margin-bottom: -0.5rem !important;
}

.mb-n3 {
  margin-bottom: -1rem !important;
}

.mb-n4 {
  margin-bottom: -1.5rem !important;
}

.mb-n5 {
  margin-bottom: -3rem !important;
}

.ms-n1 {
  margin-left: -0.25rem !important;
}

.ms-n2 {
  margin-left: -0.5rem !important;
}

.ms-n3 {
  margin-left: -1rem !important;
}

.ms-n4 {
  margin-left: -1.5rem !important;
}

.ms-n5 {
  margin-left: -3rem !important;
}

.p-0 {
  padding: 0 !important;
}

.p-1 {
  padding: 0.25rem !important;
}

.p-2 {
  padding: 0.5rem !important;
}

.p-3 {
  padding: 1rem !important;
}

.p-4 {
  padding: 1.5rem !important;
}

.p-5 {
  padding: 3rem !important;
}

.px-0 {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

.px-1 {
  padding-right: 0.25rem !important;
  padding-left: 0.25rem !important;
}

.px-2 {
  padding-right: 0.5rem !important;
  padding-left: 0.5rem !important;
}

.px-3 {
  padding-right: 1rem !important;
  padding-left: 1rem !important;
}

.px-4 {
  padding-right: 1.5rem !important;
  padding-left: 1.5rem !important;
}

.px-5 {
  padding-right: 3rem !important;
  padding-left: 3rem !important;
}

.py-0 {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.py-1 {
  padding-top: 0.25rem !important;
  padding-bottom: 0.25rem !important;
}

.py-2 {
  padding-top: 0.5rem !important;
  padding-bottom: 0.5rem !important;
}

.py-3 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-4 {
  padding-top: 1.5rem !important;
  padding-bottom: 1.5rem !important;
}

.py-5 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.pt-0 {
  padding-top: 0 !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pe-0 {
  padding-right: 0 !important;
}

.pe-1 {
  padding-right: 0.25rem !important;
}

.pe-2 {
  padding-right: 0.5rem !important;
}

.pe-3 {
  padding-right: 1rem !important;
}

.pe-4 {
  padding-right: 1.5rem !important;
}

.pe-5 {
  padding-right: 3rem !important;
}

.pb-0 {
  padding-bottom: 0 !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ps-0 {
  padding-left: 0 !important;
}

.ps-1 {
  padding-left: 0.25rem !important;
}

.ps-2 {
  padding-left: 0.5rem !important;
}

.ps-3 {
  padding-left: 1rem !important;
}

.ps-4 {
  padding-left: 1.5rem !important;
}

.ps-5 {
  padding-left: 3rem !important;
}

.font-monospace {
  font-family: var(--bs-font-monospace) !important;
}

.fs-1 {
  font-size: calc(1.359375rem + 1.3125vw) !important;
}

.fs-2 {
  font-size: calc(1.3125rem + 0.75vw) !important;
}

.fs-3 {
  font-size: calc(1.2890625rem + 0.46875vw) !important;
}

.fs-4 {
  font-size: calc(1.265625rem + 0.1875vw) !important;
}

.fs-5 {
  font-size: 1.171875rem !important;
}

.fs-6 {
  font-size: 0.9375rem !important;
}

.fst-italic {
  font-style: italic !important;
}

.fst-normal {
  font-style: normal !important;
}

.fw-light {
  font-weight: 400 !important;
}

.fw-lighter {
  font-weight: lighter !important;
}

.fw-normal {
  font-weight: 500 !important;
}

.fw-bold {
  font-weight: 700 !important;
}

.fw-bolder {
  font-weight: bolder !important;
}

.lh-1 {
  line-height: 1 !important;
}

.lh-sm {
  line-height: 1.25 !important;
}

.lh-base {
  line-height: 1.5 !important;
}

.lh-lg {
  line-height: 2 !important;
}

.text-start {
  text-align: left !important;
}

.text-end {
  text-align: right !important;
}

.text-center {
  text-align: center !important;
}

.text-decoration-none {
  text-decoration: none !important;
}

.text-decoration-underline {
  text-decoration: underline !important;
}

.text-decoration-line-through {
  text-decoration: line-through !important;
}

.text-lowercase {
  text-transform: lowercase !important;
}

.text-uppercase {
  text-transform: uppercase !important;
}

.text-capitalize {
  text-transform: capitalize !important;
}

.text-wrap {
  white-space: normal !important;
}

.text-nowrap {
  white-space: nowrap !important;
}

/* rtl:begin:remove */
.text-break {
  word-wrap: break-word !important;
  word-break: break-word !important;
}

/* rtl:end:remove */
.text-primary {
  --bs-text-opacity: 1;
  color: var(--bs-primary-rgb) !important;
}

.text-secondary {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-secondary-rgb), var(--bs-text-opacity)) !important;
}

.text-success {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-success-rgb), var(--bs-text-opacity)) !important;
}

.text-info {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-info-rgb), var(--bs-text-opacity)) !important;
}

.text-warning {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-warning-rgb), var(--bs-text-opacity)) !important;
}

.text-danger {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-danger-rgb), var(--bs-text-opacity)) !important;
}

.text-light {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-light-rgb), var(--bs-text-opacity)) !important;
}

.text-dark {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-rgb), var(--bs-text-opacity)) !important;
}

.text-black {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-black-rgb), var(--bs-text-opacity)) !important;
}

.text-white {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-white-rgb), var(--bs-text-opacity)) !important;
}

.text-body {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-body-color-rgb), var(--bs-text-opacity)) !important;
}

.text-muted {
  --bs-text-opacity: 1;
  color: #e1e4e6 !important;
}

.text-black-50 {
  --bs-text-opacity: 1;
  color: rgba(0, 0, 0, 0.5) !important;
}

.text-white-50 {
  --bs-text-opacity: 1;
  color: rgba(255, 255, 255, 0.5) !important;
}

.text-reset {
  --bs-text-opacity: 1;
  color: inherit !important;
}

.text-opacity-25 {
  --bs-text-opacity: 0.25;
}

.text-opacity-50 {
  --bs-text-opacity: 0.5;
}

.text-opacity-75 {
  --bs-text-opacity: 0.75;
}

.text-opacity-100 {
  --bs-text-opacity: 1;
}

.bg-primary {
  --bs-bg-opacity: 1;
  background-color: var(--bs-primary-rgb)!important;
}

.bg-secondary {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-secondary-rgb), var(--bs-bg-opacity)) !important;
}

.bg-success {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-success-rgb), var(--bs-bg-opacity)) !important;
}

.bg-info {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-info-rgb), var(--bs-bg-opacity)) !important;
}

.bg-warning {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-warning-rgb), var(--bs-bg-opacity)) !important;
}

.bg-danger {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-danger-rgb), var(--bs-bg-opacity)) !important;
}

.bg-light {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-light-rgb), var(--bs-bg-opacity)) !important;
}

.bg-dark {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-dark-rgb), var(--bs-bg-opacity)) !important;
}

.bg-black {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-black-rgb), var(--bs-bg-opacity)) !important;
}

.bg-white {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-white-rgb), var(--bs-bg-opacity)) !important;
}

.bg-body {
  --bs-bg-opacity: 1;
  background-color: rgba(var(--bs-body-bg-rgb), var(--bs-bg-opacity)) !important;
}

.bg-transparent {
  --bs-bg-opacity: 1;
  background-color: transparent !important;
}

.bg-opacity-10 {
  --bs-bg-opacity: 0.1;
}

.bg-opacity-25 {
  --bs-bg-opacity: 0.25;
}

.bg-opacity-50 {
  --bs-bg-opacity: 0.5;
}

.bg-opacity-75 {
  --bs-bg-opacity: 0.75;
}

.bg-opacity-100 {
  --bs-bg-opacity: 1;
}

.bg-gradient {
  background-image: var(--bs-gradient) !important;
}

.user-select-all {
  -webkit-user-select: all !important;
     -moz-user-select: all !important;
          user-select: all !important;
}

.user-select-auto {
  -webkit-user-select: auto !important;
     -moz-user-select: auto !important;
      -ms-user-select: auto !important;
          user-select: auto !important;
}

.user-select-none {
  -webkit-user-select: none !important;
     -moz-user-select: none !important;
      -ms-user-select: none !important;
          user-select: none !important;
}

.pe-none {
  pointer-events: none !important;
}

.pe-auto {
  pointer-events: auto !important;
}

.rounded {
  border-radius: 0.4rem !important;
}
.roundImg {
  border-radius-right: 20px!important;
  border-top-left-radius: 20px;
  border-bottom-left-radius: 20px;

}
.italic {
  font-style: italic;
}

.rounded-0 {
  border-radius: 0 !important;
}

.rounded-1 {
  border-radius: 0.2rem !important;
}

.rounded-2 {
  border-radius: 0.4rem !important;
}

.rounded-3 {
  border-radius: 0.7rem !important;
}

.rounded-circle {
  border-radius: 50% !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}

.rounded-top {
  border-top-left-radius: 0.4rem !important;
  border-top-right-radius: 0.4rem !important;
}

.rounded-end {
  border-top-right-radius: 0.4rem !important;
  border-bottom-right-radius: 0.4rem !important;
}

.rounded-bottom {
  border-bottom-right-radius: 0.4rem !important;
  border-bottom-left-radius: 0.4rem !important;
}

.rounded-start {
  border-bottom-left-radius: 0.4rem !important;
  border-top-left-radius: 0.4rem !important;
}

.visible {
  visibility: visible !important;
}

.invisible {
  visibility: hidden !important;
}

@media (min-width: 576px) {
  .float-sm-start {
    float: left !important;
  }

  .float-sm-end {
    float: right !important;
  }

  .float-sm-none {
    float: none !important;
  }

  .d-sm-inline {
    display: inline !important;
  }

  .d-sm-inline-block {
    display: inline-block !important;
  }

  .d-sm-block {
    display: block !important;
  }

  .d-sm-grid {
    display: grid !important;
  }

  .d-sm-table {
    display: table !important;
  }

  .d-sm-table-row {
    display: table-row !important;
  }

  .d-sm-table-cell {
    display: table-cell !important;
  }

  .d-sm-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-sm-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-sm-none {
    display: none !important;
  }

  .flex-sm-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-sm-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-sm-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-sm-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-sm-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-sm-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-sm-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-sm-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-sm-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .flex-sm-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-sm-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-sm-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .gap-sm-0 {
    gap: 0 !important;
  }

  .gap-sm-1 {
    gap: 0.25rem !important;
  }

  .gap-sm-2 {
    gap: 0.5rem !important;
  }

  .gap-sm-3 {
    gap: 1rem !important;
  }

  .gap-sm-4 {
    gap: 1.5rem !important;
  }

  .gap-sm-5 {
    gap: 3rem !important;
  }

  .justify-content-sm-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-sm-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-sm-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-sm-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-sm-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .justify-content-sm-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }

  .align-items-sm-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-sm-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-sm-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-sm-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-sm-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-sm-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-sm-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-sm-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-sm-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-sm-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-sm-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-sm-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-sm-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-sm-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-sm-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-sm-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-sm-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }

  .order-sm-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }

  .order-sm-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }

  .order-sm-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }

  .order-sm-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }

  .order-sm-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }

  .order-sm-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }

  .order-sm-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }

  .order-sm-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }

  .m-sm-0 {
    margin: 0 !important;
  }

  .m-sm-1 {
    margin: 0.25rem !important;
  }

  .m-sm-2 {
    margin: 0.5rem !important;
  }

  .m-sm-3 {
    margin: 1rem !important;
  }

  .m-sm-4 {
    margin: 1.5rem !important;
  }

  .m-sm-5 {
    margin: 3rem !important;
  }

  .m-sm-auto {
    margin: auto !important;
  }

  .mx-sm-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-sm-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-sm-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-sm-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-sm-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-sm-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-sm-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-sm-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-sm-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-sm-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-sm-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-sm-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-sm-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-sm-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-sm-0 {
    margin-top: 0 !important;
  }

  .mt-sm-1 {
    margin-top: 0.25rem !important;
  }

  .mt-sm-2 {
    margin-top: 0.5rem !important;
  }

  .mt-sm-3 {
    margin-top: 1rem !important;
  }

  .mt-sm-4 {
    margin-top: 1.5rem !important;
  }

  .mt-sm-5 {
    margin-top: 3rem !important;
  }

  .mt-sm-auto {
    margin-top: auto !important;
  }

  .me-sm-0 {
    margin-right: 0 !important;
  }

  .me-sm-1 {
    margin-right: 0.25rem !important;
  }

  .me-sm-2 {
    margin-right: 0.5rem !important;
  }

  .me-sm-3 {
    margin-right: 1rem !important;
  }

  .me-sm-4 {
    margin-right: 1.5rem !important;
  }

  .me-sm-5 {
    margin-right: 3rem !important;
  }

  .me-sm-auto {
    margin-right: auto !important;
  }

  .mb-sm-0 {
    margin-bottom: 0 !important;
  }

  .mb-sm-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-sm-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-sm-3 {
    margin-bottom: 1rem !important;
  }

  .mb-sm-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-sm-5 {
    margin-bottom: 3rem !important;
  }

  .mb-sm-auto {
    margin-bottom: auto !important;
  }

  .ms-sm-0 {
    margin-left: 0 !important;
  }

  .ms-sm-1 {
    margin-left: 0.25rem !important;
  }

  .ms-sm-2 {
    margin-left: 0.5rem !important;
  }

  .ms-sm-3 {
    margin-left: 1rem !important;
  }

  .ms-sm-4 {
    margin-left: 1.5rem !important;
  }

  .ms-sm-5 {
    margin-left: 3rem !important;
  }

  .ms-sm-auto {
    margin-left: auto !important;
  }

  .m-sm-n1 {
    margin: -0.25rem !important;
  }

  .m-sm-n2 {
    margin: -0.5rem !important;
  }

  .m-sm-n3 {
    margin: -1rem !important;
  }

  .m-sm-n4 {
    margin: -1.5rem !important;
  }

  .m-sm-n5 {
    margin: -3rem !important;
  }

  .mx-sm-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-sm-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-sm-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-sm-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-sm-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-sm-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-sm-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-sm-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-sm-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-sm-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-sm-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-sm-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-sm-n3 {
    margin-top: -1rem !important;
  }

  .mt-sm-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-sm-n5 {
    margin-top: -3rem !important;
  }

  .me-sm-n1 {
    margin-right: -0.25rem !important;
  }

  .me-sm-n2 {
    margin-right: -0.5rem !important;
  }

  .me-sm-n3 {
    margin-right: -1rem !important;
  }

  .me-sm-n4 {
    margin-right: -1.5rem !important;
  }

  .me-sm-n5 {
    margin-right: -3rem !important;
  }

  .mb-sm-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-sm-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-sm-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-sm-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-sm-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-sm-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-sm-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-sm-n3 {
    margin-left: -1rem !important;
  }

  .ms-sm-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-sm-n5 {
    margin-left: -3rem !important;
  }

  .p-sm-0 {
    padding: 0 !important;
  }

  .p-sm-1 {
    padding: 0.25rem !important;
  }

  .p-sm-2 {
    padding: 0.5rem !important;
  }

  .p-sm-3 {
    padding: 1rem !important;
  }

  .p-sm-4 {
    padding: 1.5rem !important;
  }

  .p-sm-5 {
    padding: 3rem !important;
  }

  .px-sm-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-sm-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-sm-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-sm-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-sm-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-sm-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-sm-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-sm-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-sm-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-sm-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-sm-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-sm-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-sm-0 {
    padding-top: 0 !important;
  }

  .pt-sm-1 {
    padding-top: 0.25rem !important;
  }

  .pt-sm-2 {
    padding-top: 0.5rem !important;
  }

  .pt-sm-3 {
    padding-top: 1rem !important;
  }

  .pt-sm-4 {
    padding-top: 1.5rem !important;
  }

  .pt-sm-5 {
    padding-top: 3rem !important;
  }

  .pe-sm-0 {
    padding-right: 0 !important;
  }

  .pe-sm-1 {
    padding-right: 0.25rem !important;
  }

  .pe-sm-2 {
    padding-right: 0.5rem !important;
  }

  .pe-sm-3 {
    padding-right: 1rem !important;
  }

  .pe-sm-4 {
    padding-right: 1.5rem !important;
  }

  .pe-sm-5 {
    padding-right: 3rem !important;
  }

  .pb-sm-0 {
    padding-bottom: 0 !important;
  }

  .pb-sm-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-sm-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-sm-3 {
    padding-bottom: 1rem !important;
  }

  .pb-sm-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-sm-5 {
    padding-bottom: 3rem !important;
  }

  .ps-sm-0 {
    padding-left: 0 !important;
  }

  .ps-sm-1 {
    padding-left: 0.25rem !important;
  }

  .ps-sm-2 {
    padding-left: 0.5rem !important;
  }

  .ps-sm-3 {
    padding-left: 1rem !important;
  }

  .ps-sm-4 {
    padding-left: 1.5rem !important;
  }

  .ps-sm-5 {
    padding-left: 3rem !important;
  }

  .text-sm-start {
    text-align: left !important;
  }

  .text-sm-end {
    text-align: right !important;
  }

  .text-sm-center {
    text-align: center !important;
  }
}
@media (min-width: 768px) {
  .float-md-start {
    float: left !important;
  }

  .float-md-end {
    float: right !important;
  }

  .float-md-none {
    float: none !important;
  }

  .d-md-inline {
    display: inline !important;
  }

  .d-md-inline-block {
    display: inline-block !important;
  }

  .d-md-block {
    display: block !important;
  }

  .d-md-grid {
    display: grid !important;
  }

  .d-md-table {
    display: table !important;
  }

  .d-md-table-row {
    display: table-row !important;
  }

  .d-md-table-cell {
    display: table-cell !important;
  }

  .d-md-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-md-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-md-none {
    display: none !important;
  }

  .flex-md-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-md-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-md-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-md-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-md-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-md-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-md-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-md-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-md-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .flex-md-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-md-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-md-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .gap-md-0 {
    gap: 0 !important;
  }

  .gap-md-1 {
    gap: 0.25rem !important;
  }

  .gap-md-2 {
    gap: 0.5rem !important;
  }

  .gap-md-3 {
    gap: 1rem !important;
  }

  .gap-md-4 {
    gap: 1.5rem !important;
  }

  .gap-md-5 {
    gap: 3rem !important;
  }

  .justify-content-md-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-md-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-md-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-md-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-md-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .justify-content-md-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }

  .align-items-md-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-md-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-md-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-md-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-md-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-md-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-md-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-md-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-md-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-md-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-md-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-md-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-md-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-md-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-md-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-md-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-md-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }

  .order-md-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }

  .order-md-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }

  .order-md-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }

  .order-md-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }

  .order-md-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }

  .order-md-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }

  .order-md-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }

  .order-md-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }

  .m-md-0 {
    margin: 0 !important;
  }

  .m-md-1 {
    margin: 0.25rem !important;
  }

  .m-md-2 {
    margin: 0.5rem !important;
  }

  .m-md-3 {
    margin: 1rem !important;
  }

  .m-md-4 {
    margin: 1.5rem !important;
  }

  .m-md-5 {
    margin: 3rem !important;
  }

  .m-md-auto {
    margin: auto !important;
  }

  .mx-md-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-md-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-md-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-md-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-md-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-md-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-md-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-md-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-md-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-md-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-md-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-md-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-md-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-md-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-md-0 {
    margin-top: 0 !important;
  }

  .mt-md-1 {
    margin-top: 0.25rem !important;
  }

  .mt-md-2 {
    margin-top: 0.5rem !important;
  }

  .mt-md-3 {
    margin-top: 1rem !important;
  }

  .mt-md-4 {
    margin-top: 1.5rem !important;
  }

  .mt-md-5 {
    margin-top: 3rem !important;
  }

  .mt-md-auto {
    margin-top: auto !important;
  }

  .me-md-0 {
    margin-right: 0 !important;
  }

  .me-md-1 {
    margin-right: 0.25rem !important;
  }

  .me-md-2 {
    margin-right: 0.5rem !important;
  }

  .me-md-3 {
    margin-right: 1rem !important;
  }

  .me-md-4 {
    margin-right: 1.5rem !important;
  }

  .me-md-5 {
    margin-right: 3rem !important;
  }

  .me-md-auto {
    margin-right: auto !important;
  }

  .mb-md-0 {
    margin-bottom: 0 !important;
  }

  .mb-md-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-md-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-md-3 {
    margin-bottom: 1rem !important;
  }

  .mb-md-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-md-5 {
    margin-bottom: 3rem !important;
  }

  .mb-md-auto {
    margin-bottom: auto !important;
  }

  .ms-md-0 {
    margin-left: 0 !important;
  }

  .ms-md-1 {
    margin-left: 0.25rem !important;
  }

  .ms-md-2 {
    margin-left: 0.5rem !important;
  }

  .ms-md-3 {
    margin-left: 1rem !important;
  }

  .ms-md-4 {
    margin-left: 1.5rem !important;
  }

  .ms-md-5 {
    margin-left: 3rem !important;
  }

  .ms-md-auto {
    margin-left: auto !important;
  }

  .m-md-n1 {
    margin: -0.25rem !important;
  }

  .m-md-n2 {
    margin: -0.5rem !important;
  }

  .m-md-n3 {
    margin: -1rem !important;
  }

  .m-md-n4 {
    margin: -1.5rem !important;
  }

  .m-md-n5 {
    margin: -3rem !important;
  }

  .mx-md-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-md-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-md-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-md-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-md-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-md-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-md-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-md-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-md-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-md-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-md-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-md-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-md-n3 {
    margin-top: -1rem !important;
  }

  .mt-md-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-md-n5 {
    margin-top: -3rem !important;
  }

  .me-md-n1 {
    margin-right: -0.25rem !important;
  }

  .me-md-n2 {
    margin-right: -0.5rem !important;
  }

  .me-md-n3 {
    margin-right: -1rem !important;
  }

  .me-md-n4 {
    margin-right: -1.5rem !important;
  }

  .me-md-n5 {
    margin-right: -3rem !important;
  }

  .mb-md-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-md-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-md-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-md-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-md-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-md-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-md-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-md-n3 {
    margin-left: -1rem !important;
  }

  .ms-md-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-md-n5 {
    margin-left: -3rem !important;
  }

  .p-md-0 {
    padding: 0 !important;
  }

  .p-md-1 {
    padding: 0.25rem !important;
  }

  .p-md-2 {
    padding: 0.5rem !important;
  }

  .p-md-3 {
    padding: 1rem !important;
  }

  .p-md-4 {
    padding: 1.5rem !important;
  }

  .p-md-5 {
    padding: 3rem !important;
  }

  .px-md-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-md-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-md-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-md-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-md-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-md-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-md-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-md-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-md-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-md-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-md-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-md-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-md-0 {
    padding-top: 0 !important;
  }

  .pt-md-1 {
    padding-top: 0.25rem !important;
  }

  .pt-md-2 {
    padding-top: 0.5rem !important;
  }

  .pt-md-3 {
    padding-top: 1rem !important;
  }

  .pt-md-4 {
    padding-top: 1.5rem !important;
  }

  .pt-md-5 {
    padding-top: 3rem !important;
  }

  .pe-md-0 {
    padding-right: 0 !important;
  }

  .pe-md-1 {
    padding-right: 0.25rem !important;
  }

  .pe-md-2 {
    padding-right: 0.5rem !important;
  }

  .pe-md-3 {
    padding-right: 1rem !important;
  }

  .pe-md-4 {
    padding-right: 1.5rem !important;
  }

  .pe-md-5 {
    padding-right: 3rem !important;
  }

  .pb-md-0 {
    padding-bottom: 0 !important;
  }

  .pb-md-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-md-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-md-3 {
    padding-bottom: 1rem !important;
  }

  .pb-md-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-md-5 {
    padding-bottom: 3rem !important;
  }

  .ps-md-0 {
    padding-left: 0 !important;
  }

  .ps-md-1 {
    padding-left: 0.25rem !important;
  }

  .ps-md-2 {
    padding-left: 0.5rem !important;
  }

  .ps-md-3 {
    padding-left: 1rem !important;
  }

  .ps-md-4 {
    padding-left: 1.5rem !important;
  }

  .ps-md-5 {
    padding-left: 3rem !important;
  }

  .text-md-start {
    text-align: left !important;
  }

  .text-md-end {
    text-align: right !important;
  }

  .text-md-center {
    text-align: center !important;
  }
}
@media (min-width: 992px) {
  .float-lg-start {
    float: left !important;
  }

  .float-lg-end {
    float: right !important;
  }

  .float-lg-none {
    float: none !important;
  }

  .d-lg-inline {
    display: inline !important;
  }

  .d-lg-inline-block {
    display: inline-block !important;
  }

  .d-lg-block {
    display: block !important;
  }

  .d-lg-grid {
    display: grid !important;
  }

  .d-lg-table {
    display: table !important;
  }

  .d-lg-table-row {
    display: table-row !important;
  }

  .d-lg-table-cell {
    display: table-cell !important;
  }

  .d-lg-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-lg-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-lg-none {
    display: none !important;
  }

  .flex-lg-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-lg-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-lg-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-lg-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-lg-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-lg-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-lg-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-lg-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-lg-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .flex-lg-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-lg-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-lg-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .gap-lg-0 {
    gap: 0 !important;
  }

  .gap-lg-1 {
    gap: 0.25rem !important;
  }

  .gap-lg-2 {
    gap: 0.5rem !important;
  }

  .gap-lg-3 {
    gap: 1rem !important;
  }

  .gap-lg-4 {
    gap: 1.5rem !important;
  }

  .gap-lg-5 {
    gap: 3rem !important;
  }

  .justify-content-lg-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-lg-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-lg-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-lg-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-lg-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .justify-content-lg-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }

  .align-items-lg-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-lg-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-lg-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-lg-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-lg-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-lg-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-lg-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-lg-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-lg-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-lg-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-lg-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-lg-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-lg-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-lg-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-lg-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-lg-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-lg-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }

  .order-lg-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }

  .order-lg-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }

  .order-lg-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }

  .order-lg-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }

  .order-lg-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }

  .order-lg-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }

  .order-lg-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }

  .order-lg-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }

  .m-lg-0 {
    margin: 0 !important;
  }

  .m-lg-1 {
    margin: 0.25rem !important;
  }

  .m-lg-2 {
    margin: 0.5rem !important;
  }

  .m-lg-3 {
    margin: 1rem !important;
  }

  .m-lg-4 {
    margin: 1.5rem !important;
  }

  .m-lg-5 {
    margin: 3rem !important;
  }

  .m-lg-auto {
    margin: auto !important;
  }

  .mx-lg-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-lg-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-lg-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-lg-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-lg-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-lg-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-lg-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-lg-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-lg-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-lg-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-lg-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-lg-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-lg-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-lg-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-lg-0 {
    margin-top: 0 !important;
  }

  .mt-lg-1 {
    margin-top: 0.25rem !important;
  }

  .mt-lg-2 {
    margin-top: 0.5rem !important;
  }

  .mt-lg-3 {
    margin-top: 1rem !important;
  }

  .mt-lg-4 {
    margin-top: 1.5rem !important;
  }

  .mt-lg-5 {
    margin-top: 3rem !important;
  }

  .mt-lg-auto {
    margin-top: auto !important;
  }

  .me-lg-0 {
    margin-right: 0 !important;
  }

  .me-lg-1 {
    margin-right: 0.25rem !important;
  }

  .me-lg-2 {
    margin-right: 0.5rem !important;
  }

  .me-lg-3 {
    margin-right: 1rem !important;
  }

  .me-lg-4 {
    margin-right: 1.5rem !important;
  }

  .me-lg-5 {
    margin-right: 3rem !important;
  }

  .me-lg-auto {
    margin-right: auto !important;
  }

  .mb-lg-0 {
    margin-bottom: 0 !important;
  }

  .mb-lg-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-lg-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-lg-3 {
    margin-bottom: 1rem !important;
  }

  .mb-lg-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-lg-5 {
    margin-bottom: 3rem !important;
  }

  .mb-lg-auto {
    margin-bottom: auto !important;
  }

  .ms-lg-0 {
    margin-left: 0 !important;
  }

  .ms-lg-1 {
    margin-left: 0.25rem !important;
  }

  .ms-lg-2 {
    margin-left: 0.5rem !important;
  }

  .ms-lg-3 {
    margin-left: 1rem !important;
  }

  .ms-lg-4 {
    margin-left: 1.5rem !important;
  }

  .ms-lg-5 {
    margin-left: 3rem !important;
  }

  .ms-lg-auto {
    margin-left: auto !important;
  }

  .m-lg-n1 {
    margin: -0.25rem !important;
  }

  .m-lg-n2 {
    margin: -0.5rem !important;
  }

  .m-lg-n3 {
    margin: -1rem !important;
  }

  .m-lg-n4 {
    margin: -1.5rem !important;
  }

  .m-lg-n5 {
    margin: -3rem !important;
  }

  .mx-lg-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-lg-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-lg-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-lg-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-lg-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-lg-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-lg-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-lg-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-lg-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-lg-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-lg-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-lg-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-lg-n3 {
    margin-top: -1rem !important;
  }

  .mt-lg-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-lg-n5 {
    margin-top: -3rem !important;
  }

  .me-lg-n1 {
    margin-right: -0.25rem !important;
  }

  .me-lg-n2 {
    margin-right: -0.5rem !important;
  }

  .me-lg-n3 {
    margin-right: -1rem !important;
  }

  .me-lg-n4 {
    margin-right: -1.5rem !important;
  }

  .me-lg-n5 {
    margin-right: -3rem !important;
  }

  .mb-lg-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-lg-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-lg-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-lg-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-lg-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-lg-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-lg-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-lg-n3 {
    margin-left: -1rem !important;
  }

  .ms-lg-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-lg-n5 {
    margin-left: -3rem !important;
  }

  .p-lg-0 {
    padding: 0 !important;
  }

  .p-lg-1 {
    padding: 0.25rem !important;
  }

  .p-lg-2 {
    padding: 0.5rem !important;
  }

  .p-lg-3 {
    padding: 1rem !important;
  }

  .p-lg-4 {
    padding: 1.5rem !important;
  }

  .p-lg-5 {
    padding: 3rem !important;
  }

  .px-lg-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-lg-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-lg-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-lg-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-lg-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-lg-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-lg-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-lg-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-lg-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-lg-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-lg-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-lg-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-lg-0 {
    padding-top: 0 !important;
  }

  .pt-lg-1 {
    padding-top: 0.25rem !important;
  }

  .pt-lg-2 {
    padding-top: 0.5rem !important;
  }

  .pt-lg-3 {
    padding-top: 1rem !important;
  }

  .pt-lg-4 {
    padding-top: 1.5rem !important;
  }

  .pt-lg-5 {
    padding-top: 3rem !important;
  }

  .pe-lg-0 {
    padding-right: 0 !important;
  }

  .pe-lg-1 {
    padding-right: 0.25rem !important;
  }

  .pe-lg-2 {
    padding-right: 0.5rem !important;
  }

  .pe-lg-3 {
    padding-right: 1rem !important;
  }

  .pe-lg-4 {
    padding-right: 1.5rem !important;
  }

  .pe-lg-5 {
    padding-right: 3rem !important;
  }

  .pb-lg-0 {
    padding-bottom: 0 !important;
  }

  .pb-lg-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-lg-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-lg-3 {
    padding-bottom: 1rem !important;
  }

  .pb-lg-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-lg-5 {
    padding-bottom: 3rem !important;
  }

  .ps-lg-0 {
    padding-left: 0 !important;
  }

  .ps-lg-1 {
    padding-left: 0.25rem !important;
  }

  .ps-lg-2 {
    padding-left: 0.5rem !important;
  }

  .ps-lg-3 {
    padding-left: 1rem !important;
  }

  .ps-lg-4 {
    padding-left: 1.5rem !important;
  }

  .ps-lg-5 {
    padding-left: 3rem !important;
  }

  .text-lg-start {
    text-align: left !important;
  }

  .text-lg-end {
    text-align: right !important;
  }

  .text-lg-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .float-xl-start {
    float: left !important;
  }

  .float-xl-end {
    float: right !important;
  }

  .float-xl-none {
    float: none !important;
  }

  .d-xl-inline {
    display: inline !important;
  }

  .d-xl-inline-block {
    display: inline-block !important;
  }

  .d-xl-block {
    display: block !important;
  }

  .d-xl-grid {
    display: grid !important;
  }

  .d-xl-table {
    display: table !important;
  }

  .d-xl-table-row {
    display: table-row !important;
  }

  .d-xl-table-cell {
    display: table-cell !important;
  }

  .d-xl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xl-none {
    display: none !important;
  }

  .flex-xl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-xl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-xl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-xl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-xl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-xl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-xl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-xl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-xl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .flex-xl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-xl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-xl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .gap-xl-0 {
    gap: 0 !important;
  }

  .gap-xl-1 {
    gap: 0.25rem !important;
  }

  .gap-xl-2 {
    gap: 0.5rem !important;
  }

  .gap-xl-3 {
    gap: 1rem !important;
  }

  .gap-xl-4 {
    gap: 1.5rem !important;
  }

  .gap-xl-5 {
    gap: 3rem !important;
  }

  .justify-content-xl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-xl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-xl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-xl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-xl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .justify-content-xl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }

  .align-items-xl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-xl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-xl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-xl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-xl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-xl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-xl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-xl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-xl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-xl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-xl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-xl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-xl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-xl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-xl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-xl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-xl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }

  .order-xl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }

  .order-xl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }

  .order-xl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }

  .order-xl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }

  .order-xl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }

  .order-xl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }

  .order-xl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }

  .order-xl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }

  .m-xl-0 {
    margin: 0 !important;
  }

  .m-xl-1 {
    margin: 0.25rem !important;
  }

  .m-xl-2 {
    margin: 0.5rem !important;
  }

  .m-xl-3 {
    margin: 1rem !important;
  }

  .m-xl-4 {
    margin: 1.5rem !important;
  }

  .m-xl-5 {
    margin: 3rem !important;
  }

  .m-xl-auto {
    margin: auto !important;
  }

  .mx-xl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xl-0 {
    margin-top: 0 !important;
  }

  .mt-xl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xl-3 {
    margin-top: 1rem !important;
  }

  .mt-xl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xl-5 {
    margin-top: 3rem !important;
  }

  .mt-xl-auto {
    margin-top: auto !important;
  }

  .me-xl-0 {
    margin-right: 0 !important;
  }

  .me-xl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xl-3 {
    margin-right: 1rem !important;
  }

  .me-xl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xl-5 {
    margin-right: 3rem !important;
  }

  .me-xl-auto {
    margin-right: auto !important;
  }

  .mb-xl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xl-auto {
    margin-bottom: auto !important;
  }

  .ms-xl-0 {
    margin-left: 0 !important;
  }

  .ms-xl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xl-3 {
    margin-left: 1rem !important;
  }

  .ms-xl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xl-5 {
    margin-left: 3rem !important;
  }

  .ms-xl-auto {
    margin-left: auto !important;
  }

  .m-xl-n1 {
    margin: -0.25rem !important;
  }

  .m-xl-n2 {
    margin: -0.5rem !important;
  }

  .m-xl-n3 {
    margin: -1rem !important;
  }

  .m-xl-n4 {
    margin: -1.5rem !important;
  }

  .m-xl-n5 {
    margin: -3rem !important;
  }

  .mx-xl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xl-n5 {
    margin-top: -3rem !important;
  }

  .me-xl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xl-n3 {
    margin-right: -1rem !important;
  }

  .me-xl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xl-n5 {
    margin-left: -3rem !important;
  }

  .p-xl-0 {
    padding: 0 !important;
  }

  .p-xl-1 {
    padding: 0.25rem !important;
  }

  .p-xl-2 {
    padding: 0.5rem !important;
  }

  .p-xl-3 {
    padding: 1rem !important;
  }

  .p-xl-4 {
    padding: 1.5rem !important;
  }

  .p-xl-5 {
    padding: 3rem !important;
  }

  .px-xl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xl-0 {
    padding-top: 0 !important;
  }

  .pt-xl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xl-3 {
    padding-top: 1rem !important;
  }

  .pt-xl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xl-5 {
    padding-top: 3rem !important;
  }

  .pe-xl-0 {
    padding-right: 0 !important;
  }

  .pe-xl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xl-3 {
    padding-right: 1rem !important;
  }

  .pe-xl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xl-5 {
    padding-right: 3rem !important;
  }

  .pb-xl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xl-0 {
    padding-left: 0 !important;
  }

  .ps-xl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xl-3 {
    padding-left: 1rem !important;
  }

  .ps-xl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xl-5 {
    padding-left: 3rem !important;
  }

  .text-xl-start {
    text-align: left !important;
  }

  .text-xl-end {
    text-align: right !important;
  }

  .text-xl-center {
    text-align: center !important;
  }
}
@media (min-width: 1400px) {
  .float-xxl-start {
    float: left !important;
  }

  .float-xxl-end {
    float: right !important;
  }

  .float-xxl-none {
    float: none !important;
  }

  .d-xxl-inline {
    display: inline !important;
  }

  .d-xxl-inline-block {
    display: inline-block !important;
  }

  .d-xxl-block {
    display: block !important;
  }

  .d-xxl-grid {
    display: grid !important;
  }

  .d-xxl-table {
    display: table !important;
  }

  .d-xxl-table-row {
    display: table-row !important;
  }

  .d-xxl-table-cell {
    display: table-cell !important;
  }

  .d-xxl-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-xxl-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-xxl-none {
    display: none !important;
  }

  .flex-xxl-fill {
    -webkit-box-flex: 1 !important;
        -ms-flex: 1 1 auto !important;
            flex: 1 1 auto !important;
  }

  .flex-xxl-row {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: row !important;
            flex-direction: row !important;
  }

  .flex-xxl-column {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: normal !important;
        -ms-flex-direction: column !important;
            flex-direction: column !important;
  }

  .flex-xxl-row-reverse {
    -webkit-box-orient: horizontal !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: row-reverse !important;
            flex-direction: row-reverse !important;
  }

  .flex-xxl-column-reverse {
    -webkit-box-orient: vertical !important;
    -webkit-box-direction: reverse !important;
        -ms-flex-direction: column-reverse !important;
            flex-direction: column-reverse !important;
  }

  .flex-xxl-grow-0 {
    -webkit-box-flex: 0 !important;
        -ms-flex-positive: 0 !important;
            flex-grow: 0 !important;
  }

  .flex-xxl-grow-1 {
    -webkit-box-flex: 1 !important;
        -ms-flex-positive: 1 !important;
            flex-grow: 1 !important;
  }

  .flex-xxl-shrink-0 {
    -ms-flex-negative: 0 !important;
        flex-shrink: 0 !important;
  }

  .flex-xxl-shrink-1 {
    -ms-flex-negative: 1 !important;
        flex-shrink: 1 !important;
  }

  .flex-xxl-wrap {
    -ms-flex-wrap: wrap !important;
        flex-wrap: wrap !important;
  }

  .flex-xxl-nowrap {
    -ms-flex-wrap: nowrap !important;
        flex-wrap: nowrap !important;
  }

  .flex-xxl-wrap-reverse {
    -ms-flex-wrap: wrap-reverse !important;
        flex-wrap: wrap-reverse !important;
  }

  .gap-xxl-0 {
    gap: 0 !important;
  }

  .gap-xxl-1 {
    gap: 0.25rem !important;
  }

  .gap-xxl-2 {
    gap: 0.5rem !important;
  }

  .gap-xxl-3 {
    gap: 1rem !important;
  }

  .gap-xxl-4 {
    gap: 1.5rem !important;
  }

  .gap-xxl-5 {
    gap: 3rem !important;
  }

  .justify-content-xxl-start {
    -webkit-box-pack: start !important;
        -ms-flex-pack: start !important;
            justify-content: flex-start !important;
  }

  .justify-content-xxl-end {
    -webkit-box-pack: end !important;
        -ms-flex-pack: end !important;
            justify-content: flex-end !important;
  }

  .justify-content-xxl-center {
    -webkit-box-pack: center !important;
        -ms-flex-pack: center !important;
            justify-content: center !important;
  }

  .justify-content-xxl-between {
    -webkit-box-pack: justify !important;
        -ms-flex-pack: justify !important;
            justify-content: space-between !important;
  }

  .justify-content-xxl-around {
    -ms-flex-pack: distribute !important;
        justify-content: space-around !important;
  }

  .justify-content-xxl-evenly {
    -webkit-box-pack: space-evenly !important;
        -ms-flex-pack: space-evenly !important;
            justify-content: space-evenly !important;
  }

  .align-items-xxl-start {
    -webkit-box-align: start !important;
        -ms-flex-align: start !important;
            align-items: flex-start !important;
  }

  .align-items-xxl-end {
    -webkit-box-align: end !important;
        -ms-flex-align: end !important;
            align-items: flex-end !important;
  }

  .align-items-xxl-center {
    -webkit-box-align: center !important;
        -ms-flex-align: center !important;
            align-items: center !important;
  }

  .align-items-xxl-baseline {
    -webkit-box-align: baseline !important;
        -ms-flex-align: baseline !important;
            align-items: baseline !important;
  }

  .align-items-xxl-stretch {
    -webkit-box-align: stretch !important;
        -ms-flex-align: stretch !important;
            align-items: stretch !important;
  }

  .align-content-xxl-start {
    -ms-flex-line-pack: start !important;
        align-content: flex-start !important;
  }

  .align-content-xxl-end {
    -ms-flex-line-pack: end !important;
        align-content: flex-end !important;
  }

  .align-content-xxl-center {
    -ms-flex-line-pack: center !important;
        align-content: center !important;
  }

  .align-content-xxl-between {
    -ms-flex-line-pack: justify !important;
        align-content: space-between !important;
  }

  .align-content-xxl-around {
    -ms-flex-line-pack: distribute !important;
        align-content: space-around !important;
  }

  .align-content-xxl-stretch {
    -ms-flex-line-pack: stretch !important;
        align-content: stretch !important;
  }

  .align-self-xxl-auto {
    -ms-flex-item-align: auto !important;
        align-self: auto !important;
  }

  .align-self-xxl-start {
    -ms-flex-item-align: start !important;
        align-self: flex-start !important;
  }

  .align-self-xxl-end {
    -ms-flex-item-align: end !important;
        align-self: flex-end !important;
  }

  .align-self-xxl-center {
    -ms-flex-item-align: center !important;
        align-self: center !important;
  }

  .align-self-xxl-baseline {
    -ms-flex-item-align: baseline !important;
        align-self: baseline !important;
  }

  .align-self-xxl-stretch {
    -ms-flex-item-align: stretch !important;
        align-self: stretch !important;
  }

  .order-xxl-first {
    -webkit-box-ordinal-group: 0 !important;
        -ms-flex-order: -1 !important;
            order: -1 !important;
  }

  .order-xxl-0 {
    -webkit-box-ordinal-group: 1 !important;
        -ms-flex-order: 0 !important;
            order: 0 !important;
  }

  .order-xxl-1 {
    -webkit-box-ordinal-group: 2 !important;
        -ms-flex-order: 1 !important;
            order: 1 !important;
  }

  .order-xxl-2 {
    -webkit-box-ordinal-group: 3 !important;
        -ms-flex-order: 2 !important;
            order: 2 !important;
  }

  .order-xxl-3 {
    -webkit-box-ordinal-group: 4 !important;
        -ms-flex-order: 3 !important;
            order: 3 !important;
  }

  .order-xxl-4 {
    -webkit-box-ordinal-group: 5 !important;
        -ms-flex-order: 4 !important;
            order: 4 !important;
  }

  .order-xxl-5 {
    -webkit-box-ordinal-group: 6 !important;
        -ms-flex-order: 5 !important;
            order: 5 !important;
  }

  .order-xxl-last {
    -webkit-box-ordinal-group: 7 !important;
        -ms-flex-order: 6 !important;
            order: 6 !important;
  }

  .m-xxl-0 {
    margin: 0 !important;
  }

  .m-xxl-1 {
    margin: 0.25rem !important;
  }

  .m-xxl-2 {
    margin: 0.5rem !important;
  }

  .m-xxl-3 {
    margin: 1rem !important;
  }

  .m-xxl-4 {
    margin: 1.5rem !important;
  }

  .m-xxl-5 {
    margin: 3rem !important;
  }

  .m-xxl-auto {
    margin: auto !important;
  }

  .mx-xxl-0 {
    margin-right: 0 !important;
    margin-left: 0 !important;
  }

  .mx-xxl-1 {
    margin-right: 0.25rem !important;
    margin-left: 0.25rem !important;
  }

  .mx-xxl-2 {
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }

  .mx-xxl-3 {
    margin-right: 1rem !important;
    margin-left: 1rem !important;
  }

  .mx-xxl-4 {
    margin-right: 1.5rem !important;
    margin-left: 1.5rem !important;
  }

  .mx-xxl-5 {
    margin-right: 3rem !important;
    margin-left: 3rem !important;
  }

  .mx-xxl-auto {
    margin-right: auto !important;
    margin-left: auto !important;
  }

  .my-xxl-0 {
    margin-top: 0 !important;
    margin-bottom: 0 !important;
  }

  .my-xxl-1 {
    margin-top: 0.25rem !important;
    margin-bottom: 0.25rem !important;
  }

  .my-xxl-2 {
    margin-top: 0.5rem !important;
    margin-bottom: 0.5rem !important;
  }

  .my-xxl-3 {
    margin-top: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .my-xxl-4 {
    margin-top: 1.5rem !important;
    margin-bottom: 1.5rem !important;
  }

  .my-xxl-5 {
    margin-top: 3rem !important;
    margin-bottom: 3rem !important;
  }

  .my-xxl-auto {
    margin-top: auto !important;
    margin-bottom: auto !important;
  }

  .mt-xxl-0 {
    margin-top: 0 !important;
  }

  .mt-xxl-1 {
    margin-top: 0.25rem !important;
  }

  .mt-xxl-2 {
    margin-top: 0.5rem !important;
  }

  .mt-xxl-3 {
    margin-top: 1rem !important;
  }

  .mt-xxl-4 {
    margin-top: 1.5rem !important;
  }

  .mt-xxl-5 {
    margin-top: 3rem !important;
  }

  .mt-xxl-auto {
    margin-top: auto !important;
  }

  .me-xxl-0 {
    margin-right: 0 !important;
  }

  .me-xxl-1 {
    margin-right: 0.25rem !important;
  }

  .me-xxl-2 {
    margin-right: 0.5rem !important;
  }

  .me-xxl-3 {
    margin-right: 1rem !important;
  }

  .me-xxl-4 {
    margin-right: 1.5rem !important;
  }

  .me-xxl-5 {
    margin-right: 3rem !important;
  }

  .me-xxl-auto {
    margin-right: auto !important;
  }

  .mb-xxl-0 {
    margin-bottom: 0 !important;
  }

  .mb-xxl-1 {
    margin-bottom: 0.25rem !important;
  }

  .mb-xxl-2 {
    margin-bottom: 0.5rem !important;
  }

  .mb-xxl-3 {
    margin-bottom: 1rem !important;
  }

  .mb-xxl-4 {
    margin-bottom: 1.5rem !important;
  }

  .mb-xxl-5 {
    margin-bottom: 3rem !important;
  }

  .mb-xxl-auto {
    margin-bottom: auto !important;
  }

  .ms-xxl-0 {
    margin-left: 0 !important;
  }

  .ms-xxl-1 {
    margin-left: 0.25rem !important;
  }

  .ms-xxl-2 {
    margin-left: 0.5rem !important;
  }

  .ms-xxl-3 {
    margin-left: 1rem !important;
  }

  .ms-xxl-4 {
    margin-left: 1.5rem !important;
  }

  .ms-xxl-5 {
    margin-left: 3rem !important;
  }

  .ms-xxl-auto {
    margin-left: auto !important;
  }

  .m-xxl-n1 {
    margin: -0.25rem !important;
  }

  .m-xxl-n2 {
    margin: -0.5rem !important;
  }

  .m-xxl-n3 {
    margin: -1rem !important;
  }

  .m-xxl-n4 {
    margin: -1.5rem !important;
  }

  .m-xxl-n5 {
    margin: -3rem !important;
  }

  .mx-xxl-n1 {
    margin-right: -0.25rem !important;
    margin-left: -0.25rem !important;
  }

  .mx-xxl-n2 {
    margin-right: -0.5rem !important;
    margin-left: -0.5rem !important;
  }

  .mx-xxl-n3 {
    margin-right: -1rem !important;
    margin-left: -1rem !important;
  }

  .mx-xxl-n4 {
    margin-right: -1.5rem !important;
    margin-left: -1.5rem !important;
  }

  .mx-xxl-n5 {
    margin-right: -3rem !important;
    margin-left: -3rem !important;
  }

  .my-xxl-n1 {
    margin-top: -0.25rem !important;
    margin-bottom: -0.25rem !important;
  }

  .my-xxl-n2 {
    margin-top: -0.5rem !important;
    margin-bottom: -0.5rem !important;
  }

  .my-xxl-n3 {
    margin-top: -1rem !important;
    margin-bottom: -1rem !important;
  }

  .my-xxl-n4 {
    margin-top: -1.5rem !important;
    margin-bottom: -1.5rem !important;
  }

  .my-xxl-n5 {
    margin-top: -3rem !important;
    margin-bottom: -3rem !important;
  }

  .mt-xxl-n1 {
    margin-top: -0.25rem !important;
  }

  .mt-xxl-n2 {
    margin-top: -0.5rem !important;
  }

  .mt-xxl-n3 {
    margin-top: -1rem !important;
  }

  .mt-xxl-n4 {
    margin-top: -1.5rem !important;
  }

  .mt-xxl-n5 {
    margin-top: -3rem !important;
  }

  .me-xxl-n1 {
    margin-right: -0.25rem !important;
  }

  .me-xxl-n2 {
    margin-right: -0.5rem !important;
  }

  .me-xxl-n3 {
    margin-right: -1rem !important;
  }

  .me-xxl-n4 {
    margin-right: -1.5rem !important;
  }

  .me-xxl-n5 {
    margin-right: -3rem !important;
  }

  .mb-xxl-n1 {
    margin-bottom: -0.25rem !important;
  }

  .mb-xxl-n2 {
    margin-bottom: -0.5rem !important;
  }

  .mb-xxl-n3 {
    margin-bottom: -1rem !important;
  }

  .mb-xxl-n4 {
    margin-bottom: -1.5rem !important;
  }

  .mb-xxl-n5 {
    margin-bottom: -3rem !important;
  }

  .ms-xxl-n1 {
    margin-left: -0.25rem !important;
  }

  .ms-xxl-n2 {
    margin-left: -0.5rem !important;
  }

  .ms-xxl-n3 {
    margin-left: -1rem !important;
  }

  .ms-xxl-n4 {
    margin-left: -1.5rem !important;
  }

  .ms-xxl-n5 {
    margin-left: -3rem !important;
  }

  .p-xxl-0 {
    padding: 0 !important;
  }

  .p-xxl-1 {
    padding: 0.25rem !important;
  }

  .p-xxl-2 {
    padding: 0.5rem !important;
  }

  .p-xxl-3 {
    padding: 1rem !important;
  }

  .p-xxl-4 {
    padding: 1.5rem !important;
  }

  .p-xxl-5 {
    padding: 3rem !important;
  }

  .px-xxl-0 {
    padding-right: 0 !important;
    padding-left: 0 !important;
  }

  .px-xxl-1 {
    padding-right: 0.25rem !important;
    padding-left: 0.25rem !important;
  }

  .px-xxl-2 {
    padding-right: 0.5rem !important;
    padding-left: 0.5rem !important;
  }

  .px-xxl-3 {
    padding-right: 1rem !important;
    padding-left: 1rem !important;
  }

  .px-xxl-4 {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important;
  }

  .px-xxl-5 {
    padding-right: 3rem !important;
    padding-left: 3rem !important;
  }

  .py-xxl-0 {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }

  .py-xxl-1 {
    padding-top: 0.25rem !important;
    padding-bottom: 0.25rem !important;
  }

  .py-xxl-2 {
    padding-top: 0.5rem !important;
    padding-bottom: 0.5rem !important;
  }

  .py-xxl-3 {
    padding-top: 1rem !important;
    padding-bottom: 1rem !important;
  }

  .py-xxl-4 {
    padding-top: 1.5rem !important;
    padding-bottom: 1.5rem !important;
  }

  .py-xxl-5 {
    padding-top: 3rem !important;
    padding-bottom: 3rem !important;
  }

  .pt-xxl-0 {
    padding-top: 0 !important;
  }

  .pt-xxl-1 {
    padding-top: 0.25rem !important;
  }

  .pt-xxl-2 {
    padding-top: 0.5rem !important;
  }

  .pt-xxl-3 {
    padding-top: 1rem !important;
  }

  .pt-xxl-4 {
    padding-top: 1.5rem !important;
  }

  .pt-xxl-5 {
    padding-top: 3rem !important;
  }

  .pe-xxl-0 {
    padding-right: 0 !important;
  }

  .pe-xxl-1 {
    padding-right: 0.25rem !important;
  }

  .pe-xxl-2 {
    padding-right: 0.5rem !important;
  }

  .pe-xxl-3 {
    padding-right: 1rem !important;
  }

  .pe-xxl-4 {
    padding-right: 1.5rem !important;
  }

  .pe-xxl-5 {
    padding-right: 3rem !important;
  }

  .pb-xxl-0 {
    padding-bottom: 0 !important;
  }

  .pb-xxl-1 {
    padding-bottom: 0.25rem !important;
  }

  .pb-xxl-2 {
    padding-bottom: 0.5rem !important;
  }

  .pb-xxl-3 {
    padding-bottom: 1rem !important;
  }

  .pb-xxl-4 {
    padding-bottom: 1.5rem !important;
  }

  .pb-xxl-5 {
    padding-bottom: 3rem !important;
  }

  .ps-xxl-0 {
    padding-left: 0 !important;
  }

  .ps-xxl-1 {
    padding-left: 0.25rem !important;
  }

  .ps-xxl-2 {
    padding-left: 0.5rem !important;
  }

  .ps-xxl-3 {
    padding-left: 1rem !important;
  }

  .ps-xxl-4 {
    padding-left: 1.5rem !important;
  }

  .ps-xxl-5 {
    padding-left: 3rem !important;
  }

  .text-xxl-start {
    text-align: left !important;
  }

  .text-xxl-end {
    text-align: right !important;
  }

  .text-xxl-center {
    text-align: center !important;
  }
}
@media (min-width: 1200px) {
  .fs-1 {
    font-size: 2.34375rem !important;
  }

  .fs-2 {
    font-size: 1.875rem !important;
  }

  .fs-3 {
    font-size: 1.640625rem !important;
  }

  .fs-4 {
    font-size: 1.40625rem !important;
  }
}
@media print {
  .d-print-inline {
    display: inline !important;
  }

  .d-print-inline-block {
    display: inline-block !important;
  }

  .d-print-block {
    display: block !important;
  }

  .d-print-grid {
    display: grid !important;
  }

  .d-print-table {
    display: table !important;
  }

  .d-print-table-row {
    display: table-row !important;
  }

  .d-print-table-cell {
    display: table-cell !important;
  }

  .d-print-flex {
    display: -webkit-box !important;
    display: -ms-flexbox !important;
    display: flex !important;
  }

  .d-print-inline-flex {
    display: -webkit-inline-box !important;
    display: -ms-inline-flexbox !important;
    display: inline-flex !important;
  }

  .d-print-none {
    display: none !important;
  }
}
.w-20px {
  width: 20px !important;
}

.w-30px {
  width: 30px !important;
}

.w-40px {
  width: 40px !important;
}

.w-50px {
  width: 50px !important;
}

.w-60px {
  width: 60px !important;
}

.w-70px {
  width: 70px !important;
}

.w-80px {
  width: 80px !important;
}

.w-90px {
  width: 90px !important;
}

.w-100px {
  width: 100px !important;
}

.w-150px {
  width: 150px !important;
}

.w-200px {
  width: 200px !important;
}

.w-300px {
  width: 300px !important;
}

.w-400px {
  width: 400px !important;
}

.h-20px {
  height: 20px !important;
}

.h-30px {
  height: 30px !important;
}

.h-40px {
  height: 40px !important;
}

.h-50px {
  height: 50px !important;
}

.h-60px {
  height: 60px !important;
}

.h-70px {
  height: 70px !important;
}

.h-80px {
  height: 80px !important;
}

.h-90px {
  height: 90px !important;
}

.h-100px {
  height: 100px !important;
}

.h-150px {
  height: 150px !important;
}

.h-200px {
  height: 200px !important;
}

.h-300px {
  height: 300px !important;
}

.h-400px {
  height: 400px !important;
}

.fill-primary {
  fill: #0f6fec !important;
}

.fill-secondary {
  fill: #14191e !important;
}

.fill-success {
  fill: #0cbc87 !important;
}

.fill-info {
  fill: #4f9ef8 !important;
}

.fill-warning {
  fill: #f7c32e !important;
}

.fill-danger {
  fill: #d6293e !important;
}

.fill-light {
  fill: #eef0f2 !important;
}

.fill-dark {
  fill: #14191e !important;
}

.fill-white {
  fill: #fff !important;
}

.z-index-0 {
  z-index: 0 !important;
}

.z-index-1 {
  z-index: 1 !important;
}

.z-index-9 {
  z-index: 9 !important;
}

.z-index-99 {
  z-index: 99 !important;
}

.z-index-n9 {
  z-index: -9 !important;
}

.opacity-0 {
  opacity: 0 !important;
}

.opacity-1 {
  opacity: 0.1 !important;
}

.opacity-2 {
  opacity: 0.2 !important;
}

.opacity-3 {
  opacity: 0.3 !important;
}

.opacity-4 {
  opacity: 0.4 !important;
}

.opacity-5 {
  opacity: 0.5 !important;
}

.opacity-6 {
  opacity: 0.6 !important;
}

.opacity-7 {
  opacity: 0.7 !important;
}

.opacity-8 {
  opacity: 0.8 !important;
}

.opacity-9 {
  opacity: 0.9 !important;
}

.blur-1 {
  -webkit-filter: blur(0.2rem) !important;
          filter: blur(0.2rem) !important;
}

.blur-2 {
  -webkit-filter: blur(0.4rem) !important;
          filter: blur(0.4rem) !important;
}

.blur-3 {
  -webkit-filter: blur(0.6rem) !important;
          filter: blur(0.6rem) !important;
}

.blur-4 {
  -webkit-filter: blur(0.8rem) !important;
          filter: blur(0.8rem) !important;
}

.blur-5 {
  -webkit-filter: blur(1rem) !important;
          filter: blur(1rem) !important;
}

.blur-6 {
  -webkit-filter: blur(1.2rem) !important;
          filter: blur(1.2rem) !important;
}

.blur-7 {
  -webkit-filter: blur(2rem) !important;
          filter: blur(2rem) !important;
}

.blur-8 {
  -webkit-filter: blur(5rem) !important;
          filter: blur(5rem) !important;
}

.blur-9 {
  -webkit-filter: blur(9rem) !important;
          filter: blur(9rem) !important;
}

.border-dashed {
  border-style: dashed !important;
}

.rounded-start-top-0 {
  border-top-left-radius: 0 !important;
}

.accordion.accordion-icon .accordion-item {
  -webkit-box-shadow: none;
          box-shadow: none;
  border-radius: 0.25rem;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion.accordion-icon .accordion-header {
  position: relative;
}
.accordion.accordion-icon .accordion-header .accordion-button {
  background: transparent;
  font-size: inherit;
  -webkit-transition: none;
  transition: none;
  -webkit-box-shadow: none !important;
          box-shadow: none !important;
}
.accordion.accordion-icon .accordion-header .accordion-button[aria-expanded=true] {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}
.accordion.accordion-icon .accordion-header .accordion-button:after {
  content: "";
  background: #14191e !important;
  width: 10px;
  height: 2px;
  display: block;
  position: absolute;
  top: 47%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 9;
}
.accordion.accordion-icon .accordion-header .accordion-button:before {
  content: "";
  background: #14191e !important;
  width: 10px;
  height: 2px;
  display: block;
  position: absolute;
  top: 47%;
  right: 20px;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
  z-index: 9;
}
.accordion.accordion-icon .accordion-header .accordion-button[aria-expanded=false]:after {
  -webkit-transform: rotateZ(90deg);
          transform: rotateZ(90deg);
}
.accordion.accordion-icon .accordion-header .accordion-button[aria-expanded=true]:before {
  -webkit-transform: rotateZ(0deg);
          transform: rotateZ(0deg);
}
.accordion.accordion-icon .accordion-collapse {
  border: none;
}
.accordion.accordion-icon .accordion-body {
  padding: 1rem;
}

.breadcrumb-item {
  font-size: 0.8125rem;
}
.breadcrumb-item a {
  color: #676a79;
}
.breadcrumb-item a:hover {
  color: #0f6fec;
}

.breadcrumb.breadcrumb-dots .breadcrumb-item + .breadcrumb-item::before {
  content: "•";
  font-size: 22px;
  line-height: 0;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.btn-link {
  padding: 0;
}

.btn {
  outline: 0;
}

.input-group .btn {
  margin-bottom: 0;
}

.btn-xs {
  padding: 0.4rem 0.6rem;
  font-size: 0.6rem;
  line-height: 1.2;
  border-radius: 0.2rem;
}

.btn-light.active, .btn-light:active, .btn-light:focus, .btn-light:hover {
  background: #eef0f2;
}

.btn-white {
  background: #fff;
  color: #14191e;
}
.btn-white.active, .btn-white:active, .btn-white:focus, .btn-white:hover {
  background: #eff2f6;
  color: #14191e;
}

.btn-outline-light {
  background: transparent;
  border-color: #e1e4e6;
  color: #676a79;
}

.btn-outline-light:hover,
.btn-outline-light:focus,
.btn-outline-light:active,
.btn-check:checked + .btn-outline-light {
  background: #e1e4e6;
  border-color: #e1e4e6;
  color: #14191e;
}

.btn-primary-soft {
  color: #0f6fec;
  background-color: rgba(15, 111, 236, 0.1);

}
.btn-primary-soft:hover {
  color: #fff;
  background-color: #0f6fec;
}

.btn-secondary-soft {
  color: #14191e;
  background-color: rgba(20, 25, 30, 0.1);
}
.btn-secondary-soft:hover {
  color: #fff;
  background-color: #14191e;
}

.btn-success-soft {
  color: #0cbc87;
  background-color: rgba(12, 188, 135, 0.1);
}
.btn-success-soft:hover {
  color: #fff;
  background-color: #0cbc87;
}

.btn-info-soft {
  color: #4f9ef8;
  background-color: rgba(79, 158, 248, 0.1);
}
.btn-info-soft:hover {
  color: #fff;
  background-color: #4f9ef8;
}

.btn-warning-soft {
  color: #f7c32e;
  background-color: rgba(247, 195, 46, 0.1);
}
.btn-warning-soft:hover {
  color: #000;
  background-color: #f7c32e;
}

.btn-danger-soft {
  color: #d6293e;
  background-color: rgba(214, 41, 62, 0.1);
}
.btn-danger-soft:hover {
  color: #fff;
  background-color: #d6293e;
}

.btn-light-soft {
  color: #eef0f2;
  background-color: rgba(238, 240, 242, 0.1);
}
.btn-light-soft:hover {
  color: #000;
  background-color: #eef0f2;
}

.btn-dark-soft {
  color: #14191e;
  background-color: rgba(20, 25, 30, 0.1);
}
.btn-dark-soft:hover {
  color: #fff;
  background-color: #14191e;
}

.btn-primary-soft-hover:hover:hover {
  color: #0f6fec !important;
  background-color: rgba(15, 111, 236, 0.1);
}

.btn-secondary-soft-hover:hover:hover {
  color: #14191e !important;
  background-color: rgba(20, 25, 30, 0.1);
}

.btn-success-soft-hover:hover:hover {
  color: #0cbc87 !important;
  background-color: rgba(12, 188, 135, 0.1);
}

.btn-info-soft-hover:hover:hover {
  color: #4f9ef8 !important;
  background-color: rgba(79, 158, 248, 0.1);
}

.btn-warning-soft-hover:hover:hover {
  color: #f7c32e !important;
  background-color: rgba(247, 195, 46, 0.1);
}

.btn-danger-soft-hover:hover:hover {
  color: #d6293e !important;
  background-color: rgba(214, 41, 62, 0.1);
}

.btn-light-soft-hover:hover:hover {
  color: #eef0f2 !important;
  background-color: rgba(238, 240, 242, 0.1);
}

.btn-dark-soft-hover:hover:hover {
  color: #14191e !important;
  background-color: rgba(20, 25, 30, 0.1);
}

.btn-dashed {
  background: -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(0, 0, 0, 0.1)), color-stop(50%, transparent)), -webkit-gradient(linear, left bottom, left top, color-stop(50%, rgba(0, 0, 0, 0.1)), color-stop(50%, transparent)), -webkit-gradient(linear, left top, right top, color-stop(50%, rgba(0, 0, 0, 0.1)), color-stop(50%, transparent)), -webkit-gradient(linear, left bottom, left top, color-stop(50%, rgba(0, 0, 0, 0.1)), color-stop(50%, transparent));
  background: linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%), linear-gradient(90deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%), linear-gradient(0deg, rgba(0, 0, 0, 0.1) 50%, transparent 50%);
  background-repeat: repeat-x, repeat-y, repeat-x, repeat-y;
  background-size: 10px 2px, 2px 10px, 10px 2px, 2px 10px;
  background-position: left top, right top, left bottom, left top;
  padding: 0.5rem, 1rem;
}
.btn-dashed:hover {
  -webkit-animation: border-dance 4s infinite linear;
          animation: border-dance 4s infinite linear;
}

@-webkit-keyframes border-dance {
  0% {
    background-position: left top, right top, right bottom, left bottom;
  }
  100% {
    background-position: right top, right bottom, left bottom, left top;
  }
}

@keyframes border-dance {
  0% {
    background-position: left top, right top, right bottom, left bottom;
  }
  100% {
    background-position: right top, right bottom, left bottom, left top;
  }
}
.btn-loader {
  position: relative;
  text-align: center;
}
.btn-loader .load-icon {
  display: none;
  margin: 0 auto;
  position: absolute;
  left: 0;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}
.btn-loader .load-text {
  opacity: 1;
  visibility: visible;
}

.btn-loader.active .load-text {
  opacity: 0;
  visibility: hidden;
}
.btn-loader.active .load-icon {
  display: block;
}

.spinner-dots {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.spinner-dot {
  background-color: #a1a1a8;
  display: inline-block;
  height: 4px;
  margin: 2px;
  border-radius: 50%;
  width: 4px;
}

@-webkit-keyframes dot {
  0% {
    background-color: #a1a1a8;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    background-color: #676a79;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  100% {
    background-color: #a1a1a8;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}

@keyframes dot {
  0% {
    background-color: #a1a1a8;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
  50% {
    background-color: #676a79;
    -webkit-transform: scale(1.3);
            transform: scale(1.3);
  }
  100% {
    background-color: #a1a1a8;
    -webkit-transform: scale(1);
            transform: scale(1);
  }
}
.btn-link-loader.active .spinner-dots .spinner-dot {
  -webkit-animation: dot ease-in-out 1s infinite;
          animation: dot ease-in-out 1s infinite;
}
.btn-link-loader.active .spinner-dots .spinner-dot:nth-of-type(2) {
  -webkit-animation-delay: 0.2s;
          animation-delay: 0.2s;
}
.btn-link-loader.active .spinner-dots .spinner-dot:nth-of-type(3) {
  -webkit-animation-delay: 0.3s;
          animation-delay: 0.3s;
}

.card.overflow-hidden {
  will-change: transform;
}

.card .card-image {
  position: relative;
}
.card .card-image .card-element-hover {
  visibility: hidden;
  margin-top: 20px;
  opacity: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  z-index: 9;
}
.card:hover .card-image .card-element-hover {
  visibility: visible;
  margin-top: 0px;
  opacity: 1;
}

.card.card-overlay-bottom {
  overflow: hidden;
}
.card.card-overlay-bottom:before {
  content: "";
  position: absolute;
  height: 50%;
  width: 100%;
  bottom: 0;
  left: 0;
  right: 0;
  background-image: -webkit-gradient(linear, left top, left bottom, from(transparent), to(black));
  background-image: linear-gradient(180deg, transparent, black);
  z-index: 1;
}
.card .card-img-overlay {
  z-index: 2;
}

.dropdown-menu {
  -webkit-box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.1), 0px 0px 30px rgba(83, 88, 93, 0.2);
          box-shadow: 0px 20px 30px rgba(83, 88, 93, 0.1), 0px 0px 30px rgba(83, 88, 93, 0.2);
}

.dropdown-item.active {
  color: #0f6fec;
}

.dropdown-toggle:after {
  content: "\f078";
  font-family: "Font Awesome 5 Free";
  font-size: 0.5rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropup .dropdown-toggle:after {
  content: "\f077";
  font-family: "Font Awesome 5 Free";
  font-size: 0.7rem;
  margin-left: 0.35rem;
  font-weight: 900;
  vertical-align: middle;
  border: none;
}

.dropdown-menu .dropdown-toggle:after {
  position: absolute;
  right: 25px;
  top: 10px;
}
.dropdown-menu .dropdown-toggle:before {
  position: absolute;
  right: 25px;
  top: 10px;
}

.dropdown-menu-size-md {
  min-width: 22rem;
}

@media (max-width: 575.98px) {
  .dropdown-menu-size-md {
    min-width: 16rem;
  }
}
.list-group-borderless .list-group-item {
  border: none;
  padding: 0.425rem 0;
}
.list-group-borderless a.list-group-item:hover {
  background-color: transparent;
  color: #0f6fec;
}

.nav .nav-item .nav-link.active {
  color: #000;
}

.nav-pills .nav-link {
  cursor: pointer;
  margin-bottom: 15px;
  padding: 0.5rem 1rem;
}

.nav-pills.nav-pills-light .nav-link:hover {
  background-color: #e4e6e8;
}
.nav-pills.nav-pills-light .nav-link.active {
  background-color: #e4e6e8;
}

.nav-pills-soft .nav-link.active,
.nav-pills-soft .show > .nav-link {
  background-color: rgba(15, 111, 236, 0.1);
}

.nav.nav-bottom-line {
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}
.nav.nav-bottom-line .nav-link {
  border: transparent;
  font-weight: 600;
  padding: 0.75rem 0.975rem;
}
.nav.nav-bottom-line .nav-item:first-child .nav-link {
  padding-left: 0;
}
.nav.nav-bottom-line .nav-link {
  background: transparent;
  border-bottom: 3px solid transparent;
}
.nav.nav-bottom-line .nav-link.active {
  background: transparent;
  color: #0f6fec;
  border-bottom: 3px solid #0f6fec;
}
.nav.nav-bottom-line .nav-item {
  margin-bottom: -2px;
}

.nav.flex-column:not(.nav-pills) .nav-link {
  padding-left: 0;
  padding-top: 0.3rem;
  padding-bottom: 0.3rem;
}

.tab-content {
  padding: 25px 0;
  margin-bottom: 20px;
}

.nav-tabs .nav-link {
  padding: 0.5rem 1rem;
}
.nav-tabs-white {
  border-radius: 0.4rem;
}
.nav-tabs-white .nav-item .nav-link {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
  border-radius: 0.4rem;
  margin-right: 4px;
  font-weight: 600;
}
.nav-tabs-white .nav-item:last-child .nav-link {
  margin-right: 0;
}
.nav-tabs-white .nav-item .nav-link.active, .nav-tabs-white .nav-item .nav-link:hover {
  background: #fff;
  color: #000;
}

.nav.nav-divider .nav-item + .nav-item:before {
  content: "•";
  color: inherit;
  padding-left: 0.6rem;
  padding-right: 0.6rem;
  opacity: 0.8;
}
.nav.nav-divider .nav-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.nav.nav-divider .nav-link {
  padding: 0;
}

.nav-link-secondary .nav-item .nav-link {
  color: #14191e;
}
.nav-link-secondary .nav-item .nav-link:hover, .nav-link-secondary .nav-item .nav-link.active {
  color: #0f6fec;
}

.nav-stack {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -ms-flex-item-align: stretch;
      align-self: stretch;
  gap: 0.7rem;
}
.nav-stack .nav-link {
  padding-left: 0;
  padding-right: 0;
}

.nav .nav-item .nav-link .nav-icon {
  background: #eef0f2;
  height: 2.1rem;
  width: 2.1rem;
  font-size: 1.1em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border-radius: 50%;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.nav .nav-item .nav-link:hover .nav-icon {
  background: #0f6fec;
  color: #fff;
}

@media (min-width: 992px) {
  .nav-sidenav {
    width: 5rem;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
    position: fixed;
    left: 0;
    top: 0;
    z-index: 9;
    margin-top: 56px;
  }
  .nav-sidenav .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    padding-left: 0;
  }
  .nav-sidenav .nav-text {
    opacity: 0;
    visibility: hidden;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }

  .sidebar-start-enabled .nav-sidenav {
    width: 270px;
  }
  .sidebar-start-enabled .nav-sidenav .nav-text {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    visibility: visible;
    padding-left: 10px;
  }
}
@media (max-width: 991.98px) {
  .nav-sidenav {
    width: 270px;
    left: 0;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 9;
    margin-top: 56px;
    -webkit-transform: translateX(-100%);
            transform: translateX(-100%);
  }
  .nav-sidenav .nav-text {
    padding-left: 10px;
  }

  .sidebar-start-enabled .nav-sidenav {
    -webkit-transform: none;
            transform: none;
  }
}
@media (min-width: 992px) {
  .sidebar-end {
    width: 5rem;
    -webkit-transition: width 0.3s;
    transition: width 0.3s;
    position: fixed;
    right: 0;
    top: 0;
    margin-top: 56px;
    z-index: 9;
  }
  .sidebar-end .sidebar-end-alignment {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .sidebar-end .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
  }
  .sidebar-end .contact-name, .sidebar-end .contact-search, .sidebar-end .contact-title, .sidebar-end .contact-status {
    opacity: 0;
    display: none;
    visibility: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
    white-space: nowrap;
  }

  .sidebar-end-enabled .sidebar-end {
    width: 270px;
  }
  .sidebar-end-enabled .sidebar-end .sidebar-end-alignment {
    -webkit-box-align: inherit;
        -ms-flex-align: inherit;
            align-items: inherit;
  }
  .sidebar-end-enabled .sidebar-end .contact-name, .sidebar-end-enabled .sidebar-end .contact-search, .sidebar-end-enabled .sidebar-end .contact-title, .sidebar-end-enabled .sidebar-end .contact-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    opacity: 1;
    visibility: visible;
    -webkit-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;
  }
}
@media (max-width: 991.98px) {
  .sidebar-end {
    width: 270px;
    right: 0;
    -webkit-transition: -webkit-transform 0.3s ease-in-out;
    transition: -webkit-transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out;
    transition: transform 0.3s ease-in-out, -webkit-transform 0.3s ease-in-out;
    position: fixed;
    top: 0;
    z-index: 9;
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
  }

  .sidebar-end-enabled .sidebar-end {
    -webkit-transform: none;
            transform: none;
  }
}
header {
  height: 56px;
}

.navbar-nav .nav-link {
  font-size: 0.9375rem;
  font-weight: 600;
  text-transform: capitalize;
  line-height: 56px;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar-nav .nav-link .nav-icon {
  margin-right: 0.3rem;
}
.navbar-nav .nav-item:last-child .nav-link {
  padding-right: 0;
}

.offcanvas .navbar-nav .nav-link {
  line-height: inherit;
  padding: 0.25rem 0.75rem;
}

.navbar-toggler {
  margin-bottom: 0;
  border: 0;
  background-color: #eef0f2;
}

.navbar-transparent {
  background: transparent;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

.navbar-dark .navbar-top span:not(.dropdown-item-text) {
  color: #fff;
}
.navbar-dark .navbar-top a:not(.dropdown-item) {
  color: #fff;
}
.navbar-dark .navbar-top a:not(.dropdown-item):hover {
  color: #0f6fec;
}

.navbar-dark .nav .nav-link {
  color: #fff;
}
.navbar-dark .nav .nav-link:hover {
  color: #0f6fec;
}

@media (max-width: 991.98px) {
  .navbar-dark .navbar-nav .nav-link {
    color: #676a79;
  }
}
.navbar-brand {
  padding-top: 10px;
  padding-bottom: 10px;
}
.navbar-brand .navbar-brand-item {
  height: 36px;
  display: block;
  width: auto;
}

@media (max-width: 991.98px) {
  .navbar-brand {
    padding-top: 10px;
    padding-bottom: 10px;
  }
  .navbar-brand .navbar-brand-item {
    height: 36px;
  }
}
.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-toggle:after {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  top: 10px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none !important;
  margin: 0;
}

@media (hover: hover) {
  .navbar .dropdown-menu .dropdown-submenu.dropend:hover > .dropdown-toggle:after {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
  }
}
.navbar .dropdown-menu .dropdown-submenu.dropend .dropdown-opened.dropdown-toggle:after {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

.navbar .dropdown-menu .dropdown-submenu.dropstart .dropdown-toggle:before {
  content: "\f00d";
  font-family: "Font Awesome 5 Free";
  font-size: 10px;
  top: 8px;
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: none !important;
  margin: 0;
  font-weight: 900;
}

.navbar .dropdown-menu .dropdown-submenu.dropstart:hover > .dropdown-toggle:before {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
}

@media (min-width: 576px) {
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .navbar-expand-sm .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-sm .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-sm .dropdown-submenu {
    position: relative;
  }
}
@media (max-width: 575.98px) {
  .navbar-expand-sm .navbar-collapse {
    -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
            box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 25px;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-sm .navbar-collapse .navbar-nav > .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-expand-sm .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-sm .navbar-nav .nav-item > .dropdown-menu {
    background: rgba(103, 106, 121, 0.04);
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-sm .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(103, 106, 121, 0.06);
    padding: 10px 0px !important;
  }
}
@media (min-width: 768px) {
  .navbar-expand-md .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .navbar-expand-md .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-md .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-md .dropdown-submenu {
    position: relative;
  }
}
@media (max-width: 767.98px) {
  .navbar-expand-md .navbar-collapse {
    -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
            box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 25px;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-md .navbar-collapse .navbar-nav > .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-expand-md .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-md .navbar-nav .nav-item > .dropdown-menu {
    background: rgba(103, 106, 121, 0.04);
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-md .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(103, 106, 121, 0.06);
    padding: 10px 0px !important;
  }
}
@media (min-width: 992px) {
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .navbar-expand-lg .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-lg .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-lg .dropdown-submenu {
    position: relative;
  }
}
@media (max-width: 991.98px) {
  .navbar-expand-lg .navbar-collapse {
    -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
            box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 25px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-lg .navbar-collapse .navbar-nav > .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-expand-lg .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu {
    background: rgba(103, 106, 121, 0.04);
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-lg .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(103, 106, 121, 0.06);
    padding: 10px 0px !important;
  }
}
@media (min-width: 1200px) {
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .navbar-expand-xl .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-xl .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-xl .dropdown-submenu {
    position: relative;
  }
}
@media (max-width: 1199.98px) {
  .navbar-expand-xl .navbar-collapse {
    -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
            box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 25px;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-xl .navbar-collapse .navbar-nav > .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-expand-xl .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-xl .navbar-nav .nav-item > .dropdown-menu {
    background: rgba(103, 106, 121, 0.04);
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-xl .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(103, 106, 121, 0.06);
    padding: 10px 0px !important;
  }
}
@media (min-width: 1400px) {
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    top: 120%;
    visibility: hidden;
    opacity: 0;
    display: block;
    -webkit-transition: all 0.1s ease-in-out;
    transition: all 0.1s ease-in-out;
  }
  .navbar-expand-xxl .navbar-nav .dropdown:hover > .dropdown-menu {
    top: 100%;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
    top: 0;
    visibility: visible;
    opacity: 1;
    -webkit-transition: all 0.3s ease-in-out;
    transition: all 0.3s ease-in-out;
  }
  .navbar-expand-xxl .dropdown-submenu {
    position: relative;
  }
}
@media (max-width: 1399.98px) {
  .navbar-expand-xxl .navbar-collapse {
    -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
            box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
    position: absolute;
    left: 0;
    right: 0;
    top: 100%;
    background: #fff;
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav .dropdown-toggle::after {
    position: absolute;
    right: 25px;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav .dropdown-menu {
    -webkit-box-shadow: none;
            box-shadow: none;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav .dropdown-menu:before {
    content: none;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
    padding-left: 20px;
    padding-bottom: 0px;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav > .nav-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 5px 30px;
    position: relative;
  }
  .navbar-expand-xxl .navbar-collapse .navbar-nav > .nav-item .nav-link {
    line-height: 40px;
  }
  .navbar-expand-xxl .navbar-nav .dropdown-menu {
    position: static;
    float: none;
  }
  .navbar-expand-xxl .navbar-nav .nav-item > .dropdown-menu {
    background: rgba(103, 106, 121, 0.04);
    padding: 20px 0px;
    margin: 10px 0;
  }
  .navbar-expand-xxl .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
    background: rgba(103, 106, 121, 0.06);
    padding: 10px 0px !important;
  }
}
.navbar-expand .navbar-nav .dropdown-menu {
  top: 120%;
  visibility: hidden;
  opacity: 0;
  display: block;
  -webkit-transition: all 0.1s ease-in-out;
  transition: all 0.1s ease-in-out;
}
.navbar-expand .navbar-nav .dropdown:hover > .dropdown-menu {
  top: 100%;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-expand .navbar-nav .dropdown-submenu:hover > .dropdown-menu {
  top: 0;
  visibility: visible;
  opacity: 1;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.navbar-expand .dropdown-submenu {
  position: relative;
}
.navbar-expand .navbar-collapse {
  -webkit-box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
          box-shadow: 0px 10px 30px rgba(83, 88, 93, 0.2);
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background: #fff;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.navbar-expand .navbar-collapse .navbar-nav .dropdown-toggle::after {
  position: absolute;
  right: 25px;
}
.navbar-expand .navbar-collapse .navbar-nav .dropdown-menu {
  -webkit-box-shadow: none;
          box-shadow: none;
}
.navbar-expand .navbar-collapse .navbar-nav .dropdown-menu:before {
  content: none;
}
.navbar-expand .navbar-collapse .navbar-nav .dropdown-submenu .dropdown-menu {
  padding-left: 20px;
  padding-bottom: 0px;
}
.navbar-expand .navbar-collapse .navbar-nav > .nav-item {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  padding: 5px 30px;
  position: relative;
}
.navbar-expand .navbar-collapse .navbar-nav > .nav-item .nav-link {
  line-height: 40px;
}
.navbar-expand .navbar-nav .dropdown-menu {
  position: static;
  float: none;
}
.navbar-expand .navbar-nav .nav-item > .dropdown-menu {
  background: rgba(103, 106, 121, 0.04);
  padding: 20px 0px;
  margin: 10px 0;
}
.navbar-expand .navbar-nav .nav-item > .dropdown-menu .dropdown-submenu .dropdown-menu {
  background: rgba(103, 106, 121, 0.06);
  padding: 10px 0px !important;
}

.navbar.navbar-icon .navbar-nav .nav-item {
  position: relative;
  padding-top: 0;
  padding-bottom: 0;
}
.navbar.navbar-icon .navbar-nav .nav-item .nav-link {
  font-size: 1.3rem;
  color: #14191e;
  padding: 0 1.2rem;
  margin-right: 0.5rem;
  margin-left: 0.5rem;
  position: relative;
}
.navbar.navbar-icon .navbar-nav .nav-item .nav-link:hover {
  color: #0f6fec;
  -webkit-box-shadow: inset 0px 4px 0px #0f6fec;
          box-shadow: inset 0px 4px 0px #0f6fec;
}
.navbar.navbar-icon .navbar-nav .nav-item .nav-link.active {
  color: #0f6fec;
  -webkit-box-shadow: inset 0px 4px 0px #0f6fec;
          box-shadow: inset 0px 4px 0px #0f6fec;
}
.navbar.navbar-icon .navbar-nav .nav-item .nav-link.active:before {
  position: absolute;
  top: 100%;
  width: 0;
  height: 0;
  content: "";
  border-style: solid;
  border-width: 10px 10px 0 10px;
  border-color: #fff transparent transparent transparent;
}
.navbar.navbar-icon .navbar-nav .nav-item .nav-link:last-child {
  padding-right: 1.2rem;
}
.navbar.navbar-icon .navbar-nav .nav-item .notif-badge {
  width: 6px;
  height: 6px;
  background: #d6293e;
  border-radius: 50%;
  position: absolute;
  top: inherit;
  bottom: 10px;
  right: 0;
  left: 0;
  margin: 0 auto;
  z-index: 1;
}
@media (min-width: 992px) {
  .navbar.navbar-icon .navbar-nav .nav-item .nav-link .nav-text {
    display: none;
  }
}
@media (max-width: 991.98px) {
  .navbar.navbar-icon .navbar-collapse {
    width: 100%;
    margin: 0 auto;
    text-align: center;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item {
    padding: 0 10px;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item .nav-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 1rem;
    padding: 0.2rem 1.2rem;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item .nav-link::before {
    display: none;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item .nav-link .nav-text {
    margin-left: 0.4rem;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item .nav-link .badge-notif.badge-notif-bottom {
    position: static;
    margin: inherit;
  }
  .navbar.navbar-icon .navbar-collapse .navbar-nav .nav-item .notif-badge {
    bottom: inherit;
    right: inherit;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
  }
}

@media (min-width: 992px) {
  .navbar-vertical {
    bottom: 0;
    display: block;
    max-width: 300px;
    position: fixed;
    z-index: 9;
    height: 100vh;
    top: 56px;
    width: 100%;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
  }
  .navbar-vertical .offcanvas-start {
    top: 1.5rem;
    left: inherit;
    position: relative;
    width: auto;
    border: none !important;
    -webkit-transform: none;
            transform: none;
    height: calc(100% - 6rem);
    visibility: visible !important;
  }

  .navbar-vertical + .page-content {
    margin-left: calc(300px + 1.5rem);
  }

  .page-content {
    position: relative;
    height: 100%;
    display: block;
    padding: 0 0.7rem 0 0;
  }
}
header.fixed-top + main {
  padding-top: calc(1.5rem + 56px);
  margin-bottom: 1.5rem;
}

.navbar-toggler .navbar-toggler-animation {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background-image: none;
  position: relative;
  height: 18px;
  width: 20px;
  display: inline-block;
  vertical-align: middle;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100%;
}

.navbar-toggler .navbar-toggler-animation span {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  -webkit-transform: rotateZ(0deg);
  transform: rotateZ(0deg);
  height: 2px;
  background: #676a79;
  display: block;
  position: absolute;
  width: 100%;
  border-radius: 30px;
}

.navbar-toggler .navbar-toggler-animation span:nth-child(1) {
  top: 10%;
}

.navbar-toggler .navbar-toggler-animation span:nth-child(2) {
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
}

.navbar-toggler .navbar-toggler-animation span:nth-child(3) {
  bottom: 10%;
}

.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(1) {
  -webkit-transform: rotateZ(45deg) translateY(-50%);
          transform: rotateZ(45deg) translateY(-50%);
  top: 47%;
  -webkit-transform-origin: 47% 0;
          transform-origin: 47% 0;
  width: 100%;
}

.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(2) {
  visibility: hidden;
  -webkit-transition: all 0s ease-in-out;
  transition: all 0s ease-in-out;
}

.navbar-toggler[data-bs-toggle=collapse][aria-expanded=true] .navbar-toggler-animation span:nth-child(3) {
  -webkit-transform: rotateZ(-45deg) translateY(-50%);
          transform: rotateZ(-45deg) translateY(-50%);
  bottom: 42%;
  -webkit-transform-origin: 50% 0;
          transform-origin: 50% 0;
  width: 100%;
}

.pagination .page-item {
  margin-bottom: 5px;
  float: left;
}

.pagination-light .page-item {
  margin: 2px;
}
.pagination-light .page-link {
  border-color: #eef0f2;
  background-color: #eef0f2;
  border-radius: 0.2rem;
}
.pagination-light .page-link:hover {
  background-color: #0f6fec;
  border-color: #0f6fec;
}

body {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  overflow-x: hidden;
  z-index: 0;
  position: relative;
}

main {
  background-color: #fff;
}

header {
  position: relative;
  z-index: 1020;
}

a {
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}

*:focus {
  outline: none !important;
}

h1 > a, h2 > a, h3 > a, h4 > a, h5 > a, h6 > a, .h1 > a, .h2 > a, .h3 > a, .h4 > a, .h5 > a, .h6 > a {
  color: #14191e;
}
h1 > a:hover, h2 > a:hover, h3 > a:hover, h4 > a:hover, h5 > a:hover, h6 > a:hover, .h1 > a:hover, .h2 > a:hover, .h3 > a:hover, .h4 > a:hover, .h5 > a:hover, .h6 > a:hover {
  color: #0f6fec;
}

img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 767.98px) {
  section {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}
small,
.small {
  font-weight: 400;
}

.smaller {
  font-size: 60%;
  font-weight: 500;
}

.dropcap {
  font-size: 4em;
  font-weight: bold;
  display: block;
  float: left;
  margin: 0.04em 0.2em 0 0;
  color: #14191e;
  line-height: 1;
}

.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}


a.badge:hover {
  color: #fff;
}

.badge-unread {
  background: #eef0f2;
}
.badge-unread:hover {
  background: #eef0f2;
}
.badge-unread:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #0f6fec;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 4px;
}

.badge-notif {
  width: 8px;
  height: 8px;
  background: #d6293e;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -3px;
  z-index: 1;
}
.badge-notif.badge-notif-bottom {
  width: 6px;
  height: 6px;
  top: inherit;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.offcanvas-sm {
  width: 270px;
}

@media (max-width: 767.98px) {
  .offcanvas-start {
    width: 260px;
  }
}
.toast-chat {
  position: fixed;
  right: calc(400px + 1rem);
  bottom: 0;
  z-index: 11;
}

@media (max-width: 575.98px) {
  .toast {
    width: 288px;
  }
}
.bg-mode {
  background: #fff;
}

.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.fw-icon {
  text-align: center;
  width: 1.25em;
  display: inline-block;
}

.icon-xs {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.6em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-sm {
  height: 2rem;
  width: 2rem;
  font-size: 0.7em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-md {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.9em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-lg {
  height: 3rem;
  width: 3rem;
  font-size: 1em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-xl {
  height: 4rem;
  width: 4rem;
  font-size: 1.6em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-xxl {
  height: 5.125rem;
  width: 5.125rem;
  font-size: 2em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.plyr__control--overlaid,
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #0f6fec;
}

.plyr--full-ui input[type=range] {
  color: #0f6fec;
}

/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/
.password-strength-meter {
  background-color: #e9ecef !important;
}

.glightbox-clean .gslide-description {
  background: #fff;
}

.plyr__controls-none .plyr__controls {
  display: none;
}
.plyr__controls-none .plyr--video {
  border-radius: 20px;
}

.nav-search .dropdown-menu {
  top: 120%;
  visibility: hidden;
  opacity: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 300px;
  left: auto;
  right: 0;
}

.nav-search .dropdown-menu.show {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575.98px) {
  .nav-search .dropdown-menu {
    width: 100%;
  }
}
/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/
.preloader {
  background-color: #fff;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.preloader .preloader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.comment-wrap .comment-item {
  position: relative;
}
.comment-wrap .comment-item .avatar {
  position: relative;
  z-index: 1;
}
.comment-wrap .comment-item:last-child .comment-line-inner {
  background: #eef0f2;
  height: 20px;
}
.comment-wrap .comment-item-nested {
  padding-left: 2.75rem;
}
.comment-wrap > .comment-item .comment-line-inner {
  position: absolute;
  left: 16px;
  top: 0;
  width: 2px;
  background-color: #eef0f2;
  height: 100%;
}

.comment-item-nested .comment-item {
  position: relative;
}
.comment-item-nested .comment-item .comment-line-inner {
  position: absolute;
  left: -28px;
  top: 0;
  width: 2px;
  background-color: #eef0f2;
  height: 100%;
}
.comment-item-nested .comment-item .comment-line-inner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 20px;
  height: 2px;
  width: 26px;
  background: #eef0f2;
}

@media (min-width: 992px) {
  .rounded-end-lg-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rounded-start-lg-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .border-end-lg-0 {
    border-right: 0;
  }
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flip-horizontal {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  display: inline-block;
}

.timeline {
  width: 100%;
}
.timeline .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
}
.timeline .timeline-item:last-child {
  margin-bottom: 0;
}
.timeline .timeline-item .timeline-icon {
  margin-right: 0.8rem;
  position: relative;
  position: relative;
}
.timeline .timeline-item .timeline-content {
  width: 100%;
}
.timeline .timeline-item:before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 1rem);
  background-color: rgba(0, 0, 0, 0.1);
  top: 2.5rem;
  left: 1.5rem;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
}
.timeline .timeline-item:last-child:before {
  height: calc(100% - 2.5rem);
}

@media (min-width: 992px) {
  .card-chat {
    height: calc(100vh - 5.1125rem - 1.5rem);
  }

  .card-chat-list {
    height: calc(100vh - 10.112rem - 1.5rem);
  }

  .chat-conversation-content {
    height: calc(100% - 5.25rem);
  }

  .chat-tab-list {
    height: calc(100% - 0.75rem);
  }
}
.typing .dot {
  -webkit-animation: TypingAnimation 1.8s infinite ease-in-out;
          animation: TypingAnimation 1.8s infinite ease-in-out;
  background-color: rgba(20, 25, 30, 0.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

@-webkit-keyframes TypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 25, 30, 0.7);
  }
  28% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    background-color: rgba(20, 25, 30, 0.4);
  }
  44% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 25, 30, 0.2);
  }
}

@keyframes TypingAnimation {
  0% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 25, 30, 0.7);
  }
  28% {
    -webkit-transform: translateY(-7px);
            transform: translateY(-7px);
    background-color: rgba(20, 25, 30, 0.4);
  }
  44% {
    -webkit-transform: translateY(0px);
            transform: translateY(0px);
    background-color: rgba(20, 25, 30, 0.2);
  }
}
.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  line-height: 1.5;
}

.fakepasswordicon {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.fakepasswordicon.fa-eye:before {
  content: "\f06e" !important;
}

.ticket-border {
  border-left: 2px dashed #14191e;
  padding-left: 50px;
  position: relative;
}
.ticket-border:before {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  content: "";
  top: -50px;
  left: -25px;
  position: absolute;
}
.ticket-border:after {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #fff;
  content: "";
  bottom: -50px;
  left: -25px;
  position: absolute;
}

@media (max-width: 575.98px) {
  .ticket-border {
    padding-left: 0;
    border: 0;
  }
  .ticket-border:after, .ticket-border:before {
    display: none;
  }
}
.plyr--audio .plyr__controls {
  background: transparent;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #0f6fec;
}

[data-autoresize] {
  resize: none;
}

[class*=status-]:before {
  content: "";
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  border: 2px solid #fff;
  height: 12px;
  width: 12px;
  right: -2px;
  top: -2px;
}

.status-online:before {
  background-color: #0cbc87;
}

.status-away:before {
  background-color: #f7c32e;
}

.status-offline:before {
  background-color: #d6293e;
}

.avatar.avatar-lg[class*=status-]:before {
  right: 2px;
  top: 2px;
}

.avatar.avatar-xl[class*=status-]:before {
  right: 4px;
  top: 4px;
}

.avatar.avatar-xxl[class*=status-]:before {
  right: 12px;
  top: 12px;
}

.avatar.avatar-xxxl[class*=status-]:before {
  right: 18px;
  top: 18px;
}

.avatar {
  height: 3rem;
  width: 3rem;
  position: relative;
  display: inline-block;
  -ms-flex-negative: 0 !important;
      flex-shrink: 0 !important;
}

.avatar-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
}

.avatar-group {
  padding: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.avatar-group > li {
  position: relative;
}
.avatar-group > li:not(:last-child) {
  margin-right: -0.8rem;
}


.avatar-group-two {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0;
}
.avatar-group-two > li {
  margin-top: 0.8rem;
  position: relative;
}
.avatar-group-two > li:not(:last-child) {
  margin-right: -1.2rem;
  margin-top: 0;
}

.avatar-group-three {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0;
  padding-right: 1.2rem;
  position: relative;
}
.avatar-group-three > li {
  position: relative;
}
.avatar-group-three > li:not(:last-child) {
  margin-right: -1.2rem;
}
.avatar-group-three > li:last-child {
  position: absolute;
  bottom: -30px;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.avatar-group-four {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto auto;
  gap: 0px 0px;
}
.avatar-group-four li {
  margin: 0;
}
.avatar-group-four li:not(:last-child) {
  margin: 0;
}

.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-xs {
  height: 2.1875rem;
  width: 2.1875rem;
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-lg {
  height: 5rem!important;
  width: 5rem!important;
  border-radius: 50px!important;
  padding: 4px;
}
.roundCard {

}

.avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}

.avatar-xxl {
  height: 5.125rem;
  width: 5.125rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxxl {
  height: 8rem;
  width: 8rem;
}
@media (min-width: 768px) {
  .avatar-xxxl {
    width: 11rem;
    height: 11rem;
  }
}


.avatar-uploader {
  position: relative;
}
.avatar-uploader .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}
.avatar-uploader .avatar-edit input {
  display: none;
}
.avatar-uploader .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #eff2f6;
  border: 1px solid transparent;
  -webkit-box-shadow: 0px 0px 20px rgba(93, 83, 84, 0.15);
          box-shadow: 0px 0px 20px rgba(93, 83, 84, 0.15);
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.avatar-uploader .avatar-edit input + label:after {
  content: "\f303";
  font-family: "Font Awesome 5 Free";
  color: #14191e;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 900;
  -webkit-transform: translate(0, -56%);
          transform: translate(0, -56%);
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.avatar-uploader .avatar-edit input + label:hover {
  background: #0f6fec;
  border-color: #0f6fec;
}
.avatar-uploader .avatar-edit input + label:hover:after {
  color: #fff;
}
.avatar-uploader img#avatar-preview {
  -o-object-fit: cover;
     object-fit: cover;
}

.tns-outer {
  position: relative;
}

.tns-item {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tiny-slider .item {
  position: relative;
}

.tns-controls [data-controls] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 0.4rem;
  display: block;
  z-index: 9;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(15, 111, 236, 0.3);
  color: #fff;
  border: none;
  padding: 0;
  margin: 0 5px;
}
.tns-controls [data-controls]:hover {
  background-color: #0f6fec;
  color: #fff;
}
.tns-controls [data-controls] i {
  line-height: inherit;
}

.tns-controls [data-controls=prev] {
  left: 0;
}

.tns-controls [data-controls=next] {
  right: 0;
}

/* rtl:raw:
.tiny-slider .tns-controls [data-controls]{
  transform: scaleX(-1) translateY(-50%);
}
.tns-visually-hidden {
  right: -10000em !important;
  left: 0 !important;
}
*/
.arrow-dark [data-controls] {
  background: #14191e;
  color: #fff;
}

.arrow-white [data-controls] {
  background-color: #eff2f6;
  color: #676a79;
}

.arrow-round [data-controls] {
  border-radius: 50%;
}

.arrow-hover {
  overflow: hidden;
}
.arrow-hover [data-controls=prev] {
  left: -60px;
}
.arrow-hover [data-controls=next] {
  right: -60px;
}
.arrow-hover:hover [data-controls=prev] {
  left: 0px;
}
.arrow-hover:hover [data-controls=next] {
  right: 0px;
}

.tns-nav {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10;
  width: auto;
  text-align: center;
  white-space: nowrap;
}
.tns-nav [data-nav] {
  position: relative;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 2px #a1a1a8;
          box-shadow: inset 0 0 0 2px #a1a1a8;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
}
.tns-nav .tns-nav-active {
  background-color: #fff;
}

.dots-inside .tns-nav {
  top: auto;
  padding-bottom: 20px;
}

.dots-white .tns-nav [data-nav] {
  -webkit-box-shadow: inset 0 0 0 2px #fff;
          box-shadow: inset 0 0 0 2px #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dots-white .tns-nav [data-nav]:after {
  background-color: #fff;
  -webkit-box-shadow: 0 0 1px #fff;
          box-shadow: 0 0 1px #fff;
}

.dots-dark .tns-nav [data-nav] {
  -webkit-box-shadow: inset 0 0 0 2px #14191e;
          box-shadow: inset 0 0 0 2px #14191e;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dots-dark .tns-nav [data-nav]:after {
  background-color: #14191e;
  -webkit-box-shadow: 0 0 1px #14191e;
          box-shadow: 0 0 1px #14191e;
}

.modeswitch-wrap {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}
.modeswitch-wrap .modeswitch-item {
  width: 42px;
  height: 12px;
  background: #676a79;
  border-radius: 60px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin: 0 16px;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.modeswitch-wrap .modeswitch-item .modeswitch-icon {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-transform: translateX(-14%);
          transform: translateX(-14%);
  height: 26px;
  width: 26px;
  background: #d8831b;
  border: 2px solid #fff;
  border-radius: 50%;
  -webkit-box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
          box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.4);
  font-size: 14px;
  color: #fff;
  -webkit-transition: all 1s;
  transition: all 1s;
}
.modeswitch-wrap .modeswitch-item .modeswitch-icon:before {
  content: "\f185";
  font-family: "Font Awesome 5 Free";
  font-weight: 900;
  border: none;
  height: 26px;
  width: 26px;
  line-height: 26px;
  text-align: center;
  display: block;
}

html[data-theme=dark] .modeswitch-item {
  background: #e1e4e6;
}
html[data-theme=dark] .modeswitch-item .modeswitch-icon {
  -webkit-transform: translateX(18px) rotate(1turn);
          transform: translateX(18px) rotate(1turn);
  background: #14191e;
}
html[data-theme=dark] .modeswitch-item .modeswitch-icon:before {
  content: "\f186";
}

.navbar-dark .modeswitch-item {
  background: #a1a1a8;
}

.dark-mode-item {
  display: none !important;
}

html[data-theme=dark] .dark-mode-item {
  display: block !important;
}
html[data-theme=dark] .light-mode-item {
  display: none !important;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 360px;
}

.gdesc-inner {
  width: 100%;
}

.glightbox-container .gslide {
  -webkit-user-select: inherit;
     -moz-user-select: inherit;
      -ms-user-select: inherit;
          user-select: inherit;
}

.glightbox-clean .gslide-desc {
  font-family: "Inter", sans-serif;
}

.glightbox-mobile .glightbox-container .gslide-desc .bg-light {
  background: #14191e !important;
}
.glightbox-mobile .glightbox-container .gslide-desc .form-control {
  border-color: rgba(0, 0, 0, 0.1);
}
.glightbox-mobile .glightbox-container .gslide-desc .text-secondary {
  color: #fff !important;
}
.glightbox-mobile .glightbox-container .gslide-desc .dropdown-menu {
  background: #14191e !important;
}
.glightbox-mobile .glightbox-container .gslide-desc a {
  font-weight: normal;
}
.glightbox-mobile .glightbox-container .gslide-desc .nav.nav-divider .nav-item {
  color: #fff !important;
}

.choices .choices__inner {
  background-color: #fff;
  color: #29292e;
  border: 1px solid #e1e4e6;
  line-height: 1.5;
  border-radius: 0.4rem;
  min-height: auto;
  padding: 0.5rem 1rem !important;
  font-size: 0.9375rem;
}
.choices .choices__list--multiple .choices__item {
  background-color: #eef0f2;
  border: 1px solid #eef0f2;
  border-radius: 0.4rem;
  color: #29292e;
}
.choices .choices__list--dropdown {
  background: #fff;
  border: 1px solid #e1e4e6;
}
.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #eef0f2;
}
.choices .choices__list--dropdown .choices__item {
  color: #14191e;
}
.choices .choices__input {
  background: transparent;
  padding: 0;
  color: #29292e;
}

.choices__list--single {
  padding: 0;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  border-left: 1px solid #14191e;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.choices[data-type*=select-one]:after {
  content: "";
  height: 10px;
  width: 10px;
  border: none;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 12px;
}

.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
}

.choices[data-type*=select-one] .choices__input {
  background: #fff;
  border-color: #fff;
}

.choices.is-focused .choices__inner {
  border-color: #0f6fec;
}

.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable, .choices[data-type*=select-multiple] .choices__list > .choices__list > .choices__item--selectable {
  padding-right: 0px;
}

.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable::after, .choices[data-type*=select-multiple] .choices__list > .choices__list > .choices__item--selectable::after {
  display: none;
}

.dropzone {
  min-height: auto;
  border-radius: 0.4rem;
  position: relative;
  z-index: 0;
  border: 2px dashed #eef0f2;
}
.dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.dropzone-custom {
  position: relative;
}
.dropzone-custom .dz-cover-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.dropzone-custom .dz-cover-preview .dz-cover-preview-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0 !important;
  overflow: hidden;
}
.dropzone-custom .dz-preview {
  min-height: auto;
  margin: 0;
  width: 100%;
}
.dropzone-custom .dz-preview.dz-cover-preview .dz-cover-preview-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.375rem;
}
.dropzone-custom.dropzone-cover .dz-message {
  padding: 6rem 2rem;
}
.dropzone-custom.dropzone-cover.dz-file-processing .dz-message {
  display: none;
}
.dropzone-custom.dropzone-cover.dz-file-processing.dz-file-complete .dz-message {
  display: block;
}
.dropzone-custom.dropzone-cover.dz-max-files-reached .dz-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
}
.dropzone-custom.dropzone-cover .dz-preview.dz-cover-preview .dz-cover-preview-img {
  width: 100%;
  height: 100%;
}
.dropzone-custom .dz-message {
  position: relative;
  padding: 2rem;
  margin: 0;
}
.dropzone-custom.dropzone .dz-preview {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.dropzone-custom.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone-custom.dropzone-cover.dz-max-files-reached:focus .dz-message, .dropzone.dropzone-cover.dz-max-files-reached:hover .dz-message {
  opacity: 1;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(20, 25, 30, 0.15);
}

.flatpickr-calendar {
  background: #fff;
  border-color: #fff;
  -webkit-box-shadow: 0px 0px 20px rgba(93, 83, 84, 0.15);
          box-shadow: 0px 0px 20px rgba(93, 83, 84, 0.15);
}

.flatpickr-current-month .flatpickr-monthDropdown-months, .flatpickr-current-month .flatpickr-monthDropdown-months .flatpickr-monthDropdown-month {
  background: #fff;
  border-color: rgba(0, 0, 0, 0.1);
}

.flatpickr-calendar.arrowBottom:before, .flatpickr-calendar.arrowBottom:after {
  border-top-color: #fff;
}

.flatpickr-calendar.arrowTop:before, .flatpickr-calendar.arrowTop:after, .flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #fff;
}

.flatpickr-current-month .numInputWrapper span.arrowDown:after {
  border-top-color: #14191e;
}

.flatpickr-current-month .numInputWrapper span.arrowUp:after {
  border-bottom-color: #14191e;
}

.flatpickr-months {
  position: relative;
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  border-radius: 0.4rem;
  margin: 3px;
  width: 30px;
  height: 30px;
  line-height: 30px;
  text-align: center;
  padding: 0;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.flatpickr-months .flatpickr-prev-month svg, .flatpickr-months .flatpickr-next-month svg {
  height: 12px;
  width: 12px;
}
.flatpickr-months .flatpickr-prev-month:hover, .flatpickr-months .flatpickr-next-month:hover {
  background: rgba(15, 111, 236, 0.1);
}
.flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
  fill: #0f6fec;
}

.flatpickr-day, span.flatpickr-weekday, .flatpickr-months .flatpickr-month {
  color: #14191e;
}

.flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
  color: rgba(20, 25, 30, 0.3);
}

.flatpickr-months .flatpickr-prev-month, .flatpickr-months .flatpickr-next-month {
  fill: #14191e;
}

.flatpickr-day.today, .flatpickr-day.today:hover, .flatpickr-day.today:focus {
  background: rgba(15, 111, 236, 0.1);
  color: #0f6fec;
  border-color: rgba(15, 111, 236, 0.1);
}

.flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
  background: #0f6fec;
  color: #fff;
  border-color: #0f6fec;
}

.flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
  background: #eef0f2;
  border-color: #eef0f2;
  color: #14191e;
}

.flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange {
  background: rgba(15, 111, 236, 0.1);
  color: #0f6fec;
  border-color: rgba(15, 111, 236, 0.1);
}

.flatpickr-day.inRange {
  -webkit-box-shadow: -5px 0 0 #eef0f2, 5px 0 0 #eef0f2;
          box-shadow: -5px 0 0 #eef0f2, 5px 0 0 #eef0f2;
}

.flatpickr-day.selected.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.startRange.startRange + .endRange:not(:nth-child(7n+1)), .flatpickr-day.endRange.startRange + .endRange:not(:nth-child(7n+1)) {
  -webkit-box-shadow: -10px 0 0 #0f6fec;
          box-shadow: -10px 0 0 #0f6fec;
}

.flatpickr-input:disabled, .flatpickr-input[readonly] {
  background-color: #fff;
}

.flatpickr-time input:hover, .flatpickr-time .flatpickr-am-pm:hover, .flatpickr-time input:focus, .flatpickr-time .flatpickr-am-pm:focus {
  background: #eef0f2;
}

.flatpickr-calendar.hasTime .flatpickr-time {
  border-top-color: rgba(0, 0, 0, 0.1);
}

.flatpickr-current-month {
  font-size: 120%;
  padding: 9.48px 0 0 0;
  border-radius: 0.4rem;
}

@media (max-width: 575.98px) {
  .flatpickr-calendar, .flatpickr-days, .dayContainer {
    width: 100%;
    min-width: 100%;
    max-width: 100%;
  }
}
/*User CSS*/
/*# sourceMappingURL=style.css.map */
/* // app ⁄ */


*:focus {
  outline: none !important;
}


img {
  max-width: 100%;
  height: auto;
}

video {
  background-size: cover;
  display: table-cell;
  vertical-align: middle;
  width: 100%;
}

section {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

@media (max-width: 767.98px) {
  section {
    padding-top: 4rem;
    padding-bottom: 3rem;
  }
}
small,
.small {
  font-weight: 400;
}

.smaller {
  font-size: 60%;
  font-weight: 500;
}

.dropcap {
  font-size: 4em;
  font-weight: bold;
  display: block;
  float: left;
  margin: 0.04em 0.2em 0 0;
  color: #fff;
  line-height: 1;
}

.bg-overlay {
  display: block;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
}



.badge-unread {
  background: #202227;
}
.badge-unread:hover {
  background: #202227;
}
.badge-unread:before {
  content: "";
  width: 7px;
  height: 7px;
  background: #61A2F1;
  border-radius: 50%;
  position: absolute;
  top: 40px;
  left: 4px;
}

.badge-notif {
  width: 8px;
  height: 8px;
  background: #d6293e;
  border-radius: 50%;
  position: absolute;
  top: 0;
  right: -3px;
  z-index: 1;
}
.badge-notif.badge-notif-bottom {
  width: 6px;
  height: 6px;
  top: inherit;
  bottom: 5px;
  left: 0;
  right: 0;
  margin: 0 auto;
}

.offcanvas-sm {
  width: 270px;
}

@media (max-width: 767.98px) {
  .offcanvas-start {
    width: 260px;
  }
}
.toast-chat {
  position: fixed;
  right: calc(400px + 1rem);
  bottom: 0;
  z-index: 11;
}

@media (max-width: 575.98px) {
  .toast {
    width: 288px;
  }
}
.bg-mode {
  background: #0f0f10;
}

.grayscale {
  -webkit-filter: grayscale(100%);
          filter: grayscale(100%);
  -webkit-transition: all 0.6s ease;
  transition: all 0.6s ease;
}
.grayscale:hover {
  filter: none;
  -webkit-filter: grayscale(0%);
}

.fw-icon {
  text-align: center;
  width: 1.25em;
  display: inline-block;
}

.icon-xs {
  height: 1.5rem;
  width: 1.5rem;
  font-size: 0.6em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-sm {
  height: 2rem;
  width: 2rem;
  font-size: 0.7em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-md {
  height: 2.5rem;
  width: 2.5rem;
  font-size: 0.9em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-lg {
  height: 3rem;
  width: 3rem;
  font-size: 1em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-xl {
  height: 4rem;
  width: 4rem;
  font-size: 1.6em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.icon-xxl {
  height: 5.125rem;
  width: 5.125rem;
  font-size: 2em;
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.plyr__control--overlaid,
.plyr--video .plyr__control.plyr__tab-focus,
.plyr--video .plyr__control:hover,
.plyr--video .plyr__control[aria-expanded=true] {
  background: #61A2F1;
}

.plyr--full-ui input[type=range] {
  color: #61A2F1;
}

/* rtl:raw:
.glightbox-clean .gclose, .glightbox-modern .gclose{
  right: auto !important;
  left: 20px;
}
*/
.password-strength-meter {
  background-color: #30343c !important;
}

.glightbox-clean .gslide-description {
  background: #0f0f10;
}

.plyr__controls-none .plyr__controls {
  display: none;
}
.plyr__controls-none .plyr--video {
  border-radius: 20px;
}

.nav-search .dropdown-menu {
  top: 120%;
  visibility: hidden;
  opacity: 0;
  display: block;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  width: 300px;
  left: auto;
  right: 0;
}

.nav-search .dropdown-menu.show {
  top: 100%;
  visibility: visible;
  opacity: 1;
}

@media (max-width: 575.98px) {
  .nav-search .dropdown-menu {
    width: 100%;
  }
}
/* rtl:raw:
  .rtl-flip {
    transform: scaleX(-1);
  }
  .rtl-flip.bi:before{
    transform: inherit;
  }
*/
.preloader {
  background-color: #0f0f10;
  position: fixed;
  z-index: 1190;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.preloader .preloader-item {
  position: absolute;
  top: 50%;
  left: 50%;
  text-align: center;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
}

.comment-wrap .comment-item {
  position: relative;
}
.comment-wrap .comment-item .avatar {
  position: relative;
  z-index: 1;
}
.comment-wrap .comment-item:last-child .comment-line-inner {
  background: #202227;
  height: 20px;
}
.comment-wrap .comment-item-nested {
  padding-left: 2.75rem;
}
.comment-wrap > .comment-item .comment-line-inner {
  position: absolute;
  left: 16px;
  top: 0;
  width: 2px;
  background-color: #202227;
  height: 100%;
}

.comment-item-nested .comment-item {
  position: relative;
}
.comment-item-nested .comment-item .comment-line-inner {
  position: absolute;
  left: -28px;
  top: 0;
  width: 2px;
  background-color: #202227;
  height: 100%;
}
.comment-item-nested .comment-item .comment-line-inner:before {
  content: "";
  position: absolute;
  left: 0;
  top: 20px;
  height: 2px;
  width: 26px;
  background: #202227;
}

@media (min-width: 992px) {
  .rounded-end-lg-0 {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
  }

  .rounded-start-lg-0 {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }

  .border-end-lg-0 {
    border-right: 0;
  }
}
.rounded-top-0 {
  border-top-left-radius: 0 !important;
  border-top-right-radius: 0 !important;
}

.rounded-bottom-0 {
  border-bottom-left-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
}

.flip-horizontal {
  -webkit-transform: scaleX(-1);
          transform: scaleX(-1);
  display: inline-block;
}

.timeline {
  width: 100%;
}
.timeline .timeline-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  margin-bottom: 1.5rem;
  position: relative;
}
.timeline .timeline-item:last-child {
  margin-bottom: 0;
}
.timeline .timeline-item .timeline-icon {
  margin-right: 0.8rem;
  position: relative;
  position: relative;
}
.timeline .timeline-item .timeline-content {
  width: 100%;
}
.timeline .timeline-item:before {
  content: "";
  position: absolute;
  width: 2px;
  height: calc(100% - 1rem);
  background-color: rgba(255, 255, 255, 0.1);
  top: 2.5rem;
  left: 1.5rem;
  -webkit-transform: translatex(-50%);
          transform: translatex(-50%);
}
.timeline .timeline-item:last-child:before {
  height: calc(100% - 2.5rem);
}

@media (min-width: 992px) {
  .card-chat {
    height: calc(91vh - 5.1125rem - 1.5rem);
    padding-bottom: 0px;
    background-color: transparent!important;
  }

  .card-chat-list {
    height: calc(100vh - 10.112rem - 1.5rem);
  }

  .chat-conversation-content {
    height: calc(100% - 5.25rem);
    
  }

}


::-webkit-scrollbar {
  width: 0;  /* Remove scrollbar space */
  background: transparent;  /* Optional: just make scrollbar invisible */
}
/* Optional: show position indicator in red */
::-webkit-scrollbar-thumb {
  background: transparent;
}


.chat {

}
.vh-90 { 
  height: 87vh!important  ;
}
.typing .dot {
  -webkit-animation: TypingAnimation 1.8s infinite ease-in-out;
          animation: TypingAnimation 1.8s infinite ease-in-out;
  background-color: rgba(197, 198, 204, 0.7);
  border-radius: 50%;
  height: 7px;
  margin-right: 4px;
  vertical-align: middle;
  width: 7px;
  display: inline-block;
}
.typing .dot:nth-child(1) {
  -webkit-animation-delay: 200ms;
          animation-delay: 200ms;
}
.typing .dot:nth-child(2) {
  -webkit-animation-delay: 300ms;
          animation-delay: 300ms;
}
.typing .dot:nth-child(3) {
  -webkit-animation-delay: 400ms;
          animation-delay: 400ms;
}
.typing .dot:last-child {
  margin-right: 0;
}

.bi::before,
[class^=bi-]::before,
[class*=" bi-"]::before {
  line-height: 1.5;
}

.fakepasswordicon {
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
}

.fakepasswordicon.fa-eye:before {
  content: "\f06e" !important;
}

.ticket-border {
  border-left: 2px dashed #c5c6cc;
  padding-left: 50px;
  position: relative;
}
.ticket-border:before {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #0f0f10;
  content: "";
  top: -50px;
  left: -25px;
  position: absolute;
}
.ticket-border:after {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background: #0f0f10;
  content: "";
  bottom: -50px;
  left: -25px;
  position: absolute;
}

@media (max-width: 575.98px) {
  .ticket-border {
    padding-left: 0;
    border: 0;
  }
  .ticket-border:after, .ticket-border:before {
    display: none;
  }
}
.plyr--audio .plyr__controls {
  background: transparent;
}

.plyr--audio .plyr__control.plyr__tab-focus, .plyr--audio .plyr__control:hover, .plyr--audio .plyr__control[aria-expanded=true] {
  background: #61A2F1;
}

[data-autoresize] {
  resize: none;
}

[class*=status-]:before {
  content: "";
  position: absolute;
  z-index: 1;
  border-radius: 100%;
  border: 2px solid #fff;
  height: 12px;
  width: 12px;
  right: -2px;
  top: -2px;
}

.status-online:before {
  background-color: #0cbc87;
}

.status-away:before {
  background-color: #f7c32e;
}

.status-offline:before {
  background-color: #d6293e;
}

.avatar.avatar-lg[class*=status-]:before {
  right: 2px;
  top: 2px;
}

.avatar.avatar-xl[class*=status-]:before {
  right: 4px;
  top: 4px;
}

.avatar.avatar-xxl[class*=status-]:before {
  right: 12px;
  top: 12px;
}

.avatar.avatar-xxxl[class*=status-]:before {
  right: 18px;
  top: 18px;
}


.avatar-group-two {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0;
}
.avatar-group-two > li {
  margin-top: 0.8rem;
  position: relative;
}
.avatar-group-two > li:not(:last-child) {
  margin-right: -1.2rem;
  margin-top: 0;
}

.avatar-group-three {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
  padding: 0;
  padding-right: 1.2rem;
  position: relative;
}
.avatar-group-three > li {
  position: relative;
}
.avatar-group-three > li:not(:last-child) {
  margin-right: -1.2rem;
}
.avatar-group-three > li:last-child {
  position: absolute;
  bottom: -30px;
  left: 50%;
  top: 0;
  -webkit-transform: translate(-50%, 50%);
          transform: translate(-50%, 50%);
}

.avatar-group-four {
  display: inline-grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  grid-template-columns: auto auto;
  gap: 0px 0px;
}
.avatar-group-four li {
  margin: 0;
}
.avatar-group-four li:not(:last-child) {
  margin: 0;
}

.avatar-xxs {
  height: 1.5rem;
  width: 1.5rem;
}

.avatar-xs {
  height: 2.1875rem;
  width: 2.1875rem;
}

.avatar-sm {
  height: 2.5rem;
  width: 2.5rem;
}

.avatar-lg {
  height: 4rem;
  width: 4rem;
}

.avatar-xl {
  height: 5.125rem;
  width: 5.125rem;
}

.avatar-xxl {
  height: 5.125rem;
  width: 5.125rem;
}
@media (min-width: 768px) {
  .avatar-xxl {
    width: 8rem;
    height: 8rem;
  }
}

.avatar-xxxl {
  height: 8rem;
  width: 8rem;
}
@media (min-width: 768px) {
  .avatar-xxxl {
    width: 11rem;
    height: 11rem;
  }
}

.avatar-story {
  position: relative;
  padding: 4px;
  z-index: 1;
}
.avatar-story:after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  border: 0px solid #e8f8ff;
  z-index: -1;
}

.avatar-uploader {
  position: relative;
}
.avatar-uploader .avatar-edit {
  position: absolute;
  right: 0;
  z-index: 1;
  top: 0;
}
.avatar-uploader .avatar-edit input {
  display: none;
}
.avatar-uploader .avatar-edit input + label {
  display: inline-block;
  width: 34px;
  height: 34px;
  margin-bottom: 0;
  border-radius: 100%;
  background: #191a1f;
  border: 1px solid transparent;
  -webkit-box-shadow: none;
          box-shadow: none;
  cursor: pointer;
  font-weight: normal;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.avatar-uploader .avatar-edit input + label:after {
  content: "\f303";
  font-family: "Font Awesome 5 Free";
  color: #c5c6cc;
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  text-align: center;
  font-weight: 900;
  -webkit-transform: translate(0, -56%);
          transform: translate(0, -56%);
  margin: 0 auto;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.avatar-uploader .avatar-edit input + label:hover {
  background: #61A2F1;
  border-color: #61A2F1;
}
.avatar-uploader .avatar-edit input + label:hover:after {
  color: #fff;
}
.avatar-uploader img#avatar-preview {
  -o-object-fit: cover;
     object-fit: cover;
}

.tns-outer {
  position: relative;
}

.tns-item {
  -ms-touch-action: pan-y;
      touch-action: pan-y;
  -webkit-user-select: none;
     -moz-user-select: none;
      -ms-user-select: none;
          user-select: none;
}

.tiny-slider .item {
  position: relative;
}

.tns-controls [data-controls] {
  position: absolute;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  width: 30px;
  height: 30px;
  line-height: 30px;
  border-radius: 0.4rem;
  display: block;
  z-index: 9;
  text-align: center;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  background: rgba(15, 111, 236, 0.3);
  color: #fff;
  border: none;
  padding: 0;
  margin: 0 5px;
}
.tns-controls [data-controls]:hover {
  background-color: #61A2F1;
  color: #fff;
}
.tns-controls [data-controls] i {
  line-height: inherit;
}

.tns-controls [data-controls=prev] {
  left: 0;
}

.tns-controls [data-controls=next] {
  right: 0;
}

/* rtl:raw:
.tiny-slider .tns-controls [data-controls]{
  transform: scaleX(-1) translateY(-50%);
}
.tns-visually-hidden {
  right: -10000em !important;
  left: 0 !important;
}
*/
.arrow-dark [data-controls] {
  background: #0f0f10;
  color: #fff;
}

.arrow-white [data-controls] {
  background-color: #191a1f;
  color: #a1a1a8;
}

.arrow-round [data-controls] {
  border-radius: 50%;
}

.arrow-hover {
  overflow: hidden;
}
.arrow-hover [data-controls=prev] {
  left: -60px;
}
.arrow-hover [data-controls=next] {
  right: -60px;
}
.arrow-hover:hover [data-controls=prev] {
  left: 0px;
}
.arrow-hover:hover [data-controls=next] {
  right: 0px;
}

.tns-nav {
  position: absolute;
  top: 100%;
  bottom: 0;
  left: 50%;
  -webkit-transform: translateX(-50%);
          transform: translateX(-50%);
  z-index: 10;
  width: auto;
  text-align: center;
  white-space: nowrap;
}
.tns-nav [data-nav] {
  position: relative;
  display: inline-block;
  margin: 0 4px;
  padding: 0;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  overflow: hidden;
  background-color: transparent;
  -webkit-box-shadow: inset 0 0 0 2px #464950;
          box-shadow: inset 0 0 0 2px #464950;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
  border: 0;
}
.tns-nav .tns-nav-active {
  background-color: #fff;
}

.dots-inside .tns-nav {
  top: auto;
  padding-bottom: 20px;
}

.dots-white .tns-nav [data-nav] {
  -webkit-box-shadow: inset 0 0 0 2px #fff;
          box-shadow: inset 0 0 0 2px #fff;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dots-white .tns-nav [data-nav]:after {
  background-color: #fff;
  -webkit-box-shadow: 0 0 1px #fff;
          box-shadow: 0 0 1px #fff;
}

.dots-dark .tns-nav [data-nav] {
  -webkit-box-shadow: inset 0 0 0 2px #a1a1a8;
          box-shadow: inset 0 0 0 2px #a1a1a8;
  -webkit-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.dots-dark .tns-nav [data-nav]:after {
  background-color: #a1a1a8;
  -webkit-box-shadow: 0 0 1px #a1a1a8;
          box-shadow: 0 0 1px #a1a1a8;
}


html[data-theme=dark] .modeswitch-item .modeswitch-icon {
  -webkit-transform: translateX(18px) rotate(1turn);
          transform: translateX(18px) rotate(1turn);
  background: #0f0f10;
}
html[data-theme=dark] .modeswitch-item .modeswitch-icon:before {
  content: "\f186";
}

.navbar-dark .modeswitch-item {
  background: #464950;
}

.dark-mode-item {
  display: none !important;
}

html[data-theme=dark] .dark-mode-item {
  display: block !important;
}
html[data-theme=dark] .light-mode-item {
  display: none !important;
}

.gslide-description.description-left,
.gslide-description.description-right {
  max-width: 360px;
}

.gdesc-inner {
  width: 100%;
}

.glightbox-container .gslide {
  -webkit-user-select: inherit;
     -moz-user-select: inherit;
      -ms-user-select: inherit;
          user-select: inherit;
}

.glightbox-clean .gslide-desc {
  font-family: "Inter", sans-serif;
}

.glightbox-mobile .glightbox-container .gslide-desc .bg-light {
  background: #0f0f10 !important;
}
.glightbox-mobile .glightbox-container .gslide-desc .form-control {
  border-color: rgba(255, 255, 255, 0.1);
}
.glightbox-mobile .glightbox-container .gslide-desc .text-secondary {
  color: #fff !important;
}
.glightbox-mobile .glightbox-container .gslide-desc .dropdown-menu {
  background: #0f0f10 !important;
}
.glightbox-mobile .glightbox-container .gslide-desc a {
  font-weight: normal;
}
.glightbox-mobile .glightbox-container .gslide-desc .nav.nav-divider .nav-item {
  color: #fff !important;
}

.choices .choices__inner {
  background-color: #191a1f;
  color: #c5c6cc;
  border: 1px solid #30343c;
  line-height: 1.5;
  border-radius: 0.4rem;
  min-height: auto;
  padding: 0.5rem 1rem !important;
  font-size: 0.9375rem;
}
.choices .choices__list--multiple .choices__item {
  background-color: #202227;
  border: 1px solid #202227;
  border-radius: 0.4rem;
  color: #c5c6cc;
}
.choices .choices__list--dropdown {
  background: #191a1f;
  border: 1px solid #30343c;
}
.choices .choices__list--dropdown .choices__item--selectable.is-highlighted {
  background-color: #202227;
}
.choices .choices__list--dropdown .choices__item {
  color: #c5c6cc;
}
.choices .choices__input {
  background: transparent;
  padding: 0;
  color: #c5c6cc;
}

.choices__list--single {
  padding: 0;
}

.choices[data-type*=select-multiple] .choices__button, .choices[data-type*=text] .choices__button {
  border-left: 1px solid #c5c6cc;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath d='M.293.293a1 1 0 011.414 0L8 6.586 14.293.293a1 1 0 111.414 1.414L9.414 8l6.293 6.293a1 1 0 01-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 01-1.414-1.414L6.586 8 .293 1.707a1 1 0 010-1.414z'/%3e%3c/svg%3e");
}

.choices[data-type*=select-one]:after {
  content: "";
  height: 10px;
  width: 10px;
  border: none;
  border-width: 5px;
  position: absolute;
  right: 11.5px;
  top: 50%;
  -webkit-transform: translateY(-50%);
          transform: translateY(-50%);
  margin-top: 0;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='M2 5l6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 16px 12px;
}

.choices[data-type*=select-one].is-open:after {
  margin-top: 0;
}

.choices[data-type*=select-one] .choices__input {
  background: #191a1f;
  border-color: #191a1f;
}

.choices.is-focused .choices__inner {
  border-color: #61A2F1;
}

.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable, .choices[data-type*=select-multiple] .choices__list > .choices__list > .choices__item--selectable {
  padding-right: 0px;
}

.choices[data-type*=select-one] .choices__list > .choices__list > .choices__item--selectable::after, .choices[data-type*=select-multiple] .choices__list > .choices__list > .choices__item--selectable::after {
  display: none;
}

.dropzone {
  height: 120px;
  border-radius: 0.4rem;
  position: relative;
  z-index: 0;
  border: 2px dashed #c5c6cc;
}
.dropzone .dz-preview.dz-image-preview {
  background: transparent;
}

.dropzone-custom {
  position: relative;
}
.dropzone-custom .dz-cover-preview {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: 0;
}
.dropzone-custom .dz-cover-preview .dz-cover-preview-wrap {
  position: absolute;
  height: 100%;
  width: 100%;
  border: 0 !important;
  overflow: hidden;
}
.dropzone-custom .dz-preview {
  min-height: auto;
  margin: 0;
  width: 100%;
}
.dropzone-custom .dz-preview.dz-cover-preview .dz-cover-preview-img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  border-radius: 0.375rem;
}
.dropzone-custom.dropzone-cover .dz-message {
  padding: 6rem 2rem;
}
.dropzone-custom.dropzone-cover.dz-file-processing .dz-message {
  display: none;
}
.dropzone-custom.dropzone-cover.dz-file-processing.dz-file-complete .dz-message {
  display: block;
}
.dropzone-custom.dropzone-cover.dz-max-files-reached .dz-message {
  background-color: rgba(0, 0, 0, 0.5);
  color: #fff;
  opacity: 0;
  -webkit-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;
  border-color: transparent;
}
.dropzone-custom.dropzone-cover .dz-preview.dz-cover-preview .dz-cover-preview-img {
  width: 100%;
  height: 100%;
}
.dropzone-custom .dz-message {
  position: relative;
  padding: 2rem;
  margin: 0;
}
.dropzone-custom.dropzone .dz-preview {
  display: -webkit-inline-box;
  display: -ms-inline-flexbox;
  display: inline-flex;
}

.dropzone-custom.dz-clickable .dz-message, .dropzone.dz-clickable .dz-message * {
  cursor: pointer;
}

.dropzone-custom.dropzone-cover.dz-max-files-reached:focus .dz-message, .dropzone.dropzone-cover.dz-max-files-reached:hover .dz-message {
  opacity: 1;
}

.os-theme-dark > .os-scrollbar > .os-scrollbar-track > .os-scrollbar-handle,
.os-theme-dark > .os-scrollbar:hover > .os-scrollbar-track > .os-scrollbar-handle {
  background: rgba(197, 198, 204, 0.15);
}

:root {
  --token-site-green: #5faa41;
  --token-site-green-light: #6ba271;
  --token-site-green-lighter: #d8edd0;
  --token-site-green-hover: #599e3d;
  --token-site-green-dark: #377e1b;
  --token-site-green-darker: #165038;
  --token-site-green-darkest: #123123;
  --token-site-gray: #444;
  --token-site-gray-accent: #ccc;
  --token-site-gray-base: #444;
  --token-site-gray-bg: #f5f5f5;
  --token-site-gray-lighter: #eee;
  --token-site-gray-light: #ddd;
  --token-site-gray-medium: #888;
  --token-site-gray-mediumdark: #666;
  --token-site-gray-dark: #222;
  --token-site-gray-darker: #222;
  --token-site-gray-darkaccent: #aaa;
  --token-site-green-transparent: #e2f8f9;
  --token-site-lightblue: #c6edef;
  --token-site-darkblue: #2e4057;
  --token-site-orange: #e36039;
  --token-site-orange-hover: #d85932;
  --token-site-cfp-orange: #e07900;
  --token-site-cta-bg: #ebf5e6;
  --token-primary-50: #f5fbf4;
  --token-primary-100: #def6d9;
  --token-primary-200: #bde8b5;
  --token-primary-300: #a6de9b;
  --token-primary-400: #7ec16a;
  --token-primary-500: #5faa41;
  --token-primary-600: #397a4a;
  --token-primary-700: #325343;
  --token-primary-800: #1e3e2e;
  --token-primary-900: #0f2a1a;
  --token-primary-1000: #061609;
  --token-secondary-50: #f1fbfb;
  --token-secondary-100: #e1f3f3;
  --token-secondary-200: #bbe5e4;
  --token-secondary-300: #a1dad8;
  --token-secondary-400: #7cbcb8;
  --token-secondary-500: #5ca39f;
  --token-secondary-600: #417978;
  --token-secondary-700: #205454;
  --token-secondary-800: #1d3d3c;
  --token-secondary-900: #132928;
  --token-secondary-1000: #081615;
  --token-gray-50: #f9f9f9;
  --token-gray-100: #eef0ed;
  --token-gray-200: #dbdeda;
  --token-gray-300: #cdd0cc;
  --token-gray-400: #aeb2ac;
  --token-gray-500: #969a95;
  --token-gray-600: #6e716e;
  --token-gray-700: #4a4d4a;
  --token-gray-800: #363936;
  --token-gray-900: #242624;
  --token-gray-1000: #111311;
  --token-system-danger-50: #fef4f4;
  --token-system-danger-100: #fde6e6;
  --token-system-danger-200: #fdd7d6;
  --token-system-danger-300: #f8b6b2;
  --token-system-danger-600: #c73d2d;
  --token-system-danger-700: #8a2c21;
  --token-system-danger-800: #66241e;
  --token-system-danger-900: #48120a;
  --token-system-warning-50: #fff5ee;
  --token-system-warning-100: #fbe8da;
  --token-system-warning-200: #fbdac0;
  --token-system-warning-300: #f6ba81;
  --token-system-warning-600: #a75d00;
  --token-system-warning-700: #6f4205;
  --token-system-warning-800: #543103;
  --token-system-warning-900: #411a00;
  --token-system-info-50: #f1fbfb;
  --token-system-info-100: #e1f3f3;
  --token-system-info-200: #bbe5e4;
  --token-system-info-300: #a1dad8;
  --token-system-info-600: #417978;
  --token-system-info-700: #205454;
  --token-system-info-800: #1d3d3c;
  --token-system-info-900: #132928;
  --token-system-success-50: #f5fbf4;
  --token-system-success-100: #def6d9;
  --token-system-success-200: #bde8b5;
  --token-system-success-300: #a6de9b;
  --token-system-success-600: #397a4a;
  --token-system-success-700: #325343;
  --token-system-success-800: #1e3e2e;
  --token-system-success-900: #0f2a1a;
  --token-system-decorative-50: #fff7da;
  --token-system-decorative-100: #f8ebb7;
  --token-system-decorative-200: #f8e16c;
  --token-system-decorative-300: #e0c600;
  --token-system-decorative-600: #7d6f08;
  --token-system-decorative-700: #564c0c;
  --token-system-decorative-800: #413909;
  --token-system-decorative-900: #2b2500;
  --token-brand-logo-light: #f9f9f9;
  --token-brand-logo-primary: #5faa41;
  --token-brand-logo-dark: #6e716e;
  --token-bg-lightest: #fff;
  --token-bg-light: #f9f9f9;
  --token-bg-default: #fffcf6;
  --token-bg-dark: #f7f0e6;
  --token-bg-gradient: linear-gradient(180deg, #f7f0e6 60%, #fffcf6 80%);
  --token-bg-darkest: #325343;
  --token-bg-danger: #fde6e6;
  --token-bg-warning: #fbe8da;
  --token-bg-success: #def6d9;
  --token-bg-btnPrimary-default: #a6de9b;
  --token-bg-btnPrimary-hover: #397a4a;
  --token-bg-btnPrimary-pressed: #325343;
  --token-bg-btnPrimary-disabled: #eef0ed;
  --token-bg-btnPrimary-onDark: #f9f9f9;
  --token-bg-btnPrimary-pressed-onDark: #397a4a;
  --token-bg-btnPrimary-disabled-onDark: #dbdeda;
  --token-bg-btnSecondary-default: transparent;
  --token-bg-btnSecondary-hover: #f5fbf4;
  --token-bg-btnSecondary-pressed: #def6d9;
  --token-bg-btnTertiary-default: #a6de9b;
  --token-bg-btnTertiary-hover: #397a4a;
  --token-bg-btnTertiary-pressed: #325343;
  --token-bg-btnTertiary-disabled: #eef0ed;
  --token-border-default: #eef0ed;
  --token-border-strong: #cdd0cc;
  --token-border-onDark: #969a95;
  --token-border-strong-onDark: #eef0ed;
  --token-border-danger: #fdd7d6;
  --token-border-warning: #fbdac0;
  --token-border-success: #bde8b5;
  --token-border-btnSecondary-default: #325343;
  --token-border-btnSecondary-disabled: #dbdeda;
  --token-border-btnSecondary-default-onDark: #f9f9f9;
  --token-border-btnSecondary-hover-onDark: #a6de9b;
  --token-border-btnSecondary-disabled-onDark: #969a95;
  --token-border-btnTertiary-default: transparent;
  --token-text-default: #242624;
  --token-text-subdued: #4a4d4a;
  --token-text-emphasis: #397a4a;
  --token-text-disabled: #aeb2ac;
  --token-text-onDark: #f9f9f9;
  --token-text-emphasis-onDark: #a6de9b;
  --token-text-danger: #8a2c21;
  --token-text-warning: #6f4205;
  --token-text-decorative: #564c0c;
  --token-text-success: #325343;
  --token-text-info: #205454;
  --token-text-link-default: #397a4a;
  --token-text-link-hover: #325343;
  --token-text-link-onDark: #a6de9b;
  --token-text-link-hover-onDark: #5faa41;
  --token-text-btnPrimary-default: #325343;
  --token-text-btnPrimary-disabled: #aeb2ac;
  --token-text-btnSecondary-default: #242624;
  --token-text-btnSecondary-disabled: #aeb2ac;
  --token-text-btnTertiary-default: #242624;
  --token-text-btnTertiary-disabled: #aeb2ac;
  --token-icon-default: #4a4d4a;
  --token-icon-hover: #242624;
  --token-icon-active: #325343;
  --token-icon-disabled: #aeb2ac;
  --token-icon-onDark: #f9f9f9;
  --token-icon-emphasis-onDark: #a6de9b;
  --token-icon-danger: #c73d2d;
  --token-icon-warning: #a75d00;
  --token-icon-decorative: #7d6f08;
  --token-icon-success: #397a4a;
  --token-icon-info: #417978;
  --token-red-50: #fef4f4;
  --token-red-100: #fde6e6;
  --token-red-200: #fdd7d6;
  --token-red-300: #f8b6b2;
  --token-red-400: #f69892;
  --token-red-500: #f06f63;
  --token-red-600: #c73d2d;
  --token-red-700: #8a2c21;
  --token-red-800: #66241e;
  --token-red-900: #48120a;
  --token-red-1000: #280804;
  --token-orange-50: #fff5ee;
  --token-orange-100: #fbe8da;
  --token-orange-200: #fbdac0;
  --token-orange-300: #f6ba81;
  --token-orange-400: #eda12e;
  --token-orange-500: #d78614;
  --token-orange-600: #a75d00;
  --token-orange-700: #6f4205;
  --token-orange-800: #543103;
  --token-orange-900: #411a00;
  --token-orange-1000: #230d01;
  --token-yellow-50: #fff7da;
  --token-yellow-100: #f8ebb7;
  --token-yellow-200: #f8e16c;
  --token-yellow-300: #e0c600;
  --token-yellow-400: #ceb318;
  --token-yellow-500: #ac9917;
  --token-yellow-600: #7d6f08;
  --token-yellow-700: #564c0c;
  --token-yellow-800: #413909;
  --token-yellow-900: #2b2500;
  --token-yellow-1000: #161200;
  --token-green-50: #f5fbf4;
  --token-green-100: #def6d9;
  --token-green-200: #bde8b5;
  --token-green-300: #a6de9b;
  --token-green-400: #7ec16a;
  --token-green-500: #5faa41;
  --token-green-600: #397a4a;
  --token-green-700: #325343;
  --token-green-800: #1e3e2e;
  --token-green-900: #0f2a1a;
  --token-green-1000: #061609;
  --token-blue-50: #f1fbfb;
  --token-blue-100: #e1f3f3;
  --token-blue-200: #bbe5e4;
  --token-blue-300: #a1dad8;
  --token-blue-400: #7cbcb8;
  --token-blue-500: #5ca39f;
  --token-blue-600: #417978;
  --token-blue-700: #205454;
  --token-blue-800: #1d3d3c;
  --token-blue-900: #132928;
  --token-blue-1000: #081615;
  --token-purple-50: #fdf6fc;
  --token-purple-100: #fbeaf8;
  --token-purple-200: #f6d2f0;
  --token-purple-300: #f4c1ec;
  --token-purple-400: #dc92d2;
  --token-purple-500: #ba65b1;
  --token-purple-600: #a54b9c;
  --token-purple-700: #823b7b;
  --token-purple-800: #693064;
  --token-purple-900: #3c1538;
  --token-purple-1000: #1f081e;
  --token-beige-50: #fffcf6;
  --token-beige-100: #f7f0e6;
  --token-beige-200: #f7f0eb;
  --token-white: #fff;
  --token-black: #000;
}

.tw-theme-regain {
  --token-primary-50: #e7f7fe;
  --token-primary-100: #ddedf4;
  --token-primary-200: #d2eff9;
  --token-primary-300: #b2e3ed;
  --token-primary-400: #77ceec;
  --token-primary-500: #1faedf;
  --token-primary-600: #198bb2;
  --token-primary-700: #147393;
  --token-primary-800: #2e4057;
  --token-primary-900: #1c2a3e;
  --token-primary-1000: #040f23;
  --token-secondary-50: #fdf6fc;
  --token-secondary-100: #fbeaf8;
  --token-secondary-200: #f6d2f0;
  --token-secondary-300: #f4c1ec;
  --token-secondary-400: #dc92d2;
  --token-secondary-500: #ba65b1;
  --token-secondary-600: #a54b9c;
  --token-secondary-700: #823b7b;
  --token-secondary-800: #693064;
  --token-secondary-900: #3c1538;
  --token-secondary-1000: #1f081e;
  --token-gray-50: #f5f5f5;
  --token-gray-100: #eee;
  --token-gray-200: #ddd;
  --token-gray-300: #ccc;
  --token-gray-400: #aaa;
  --token-gray-500: #888;
  --token-gray-600: #777;
  --token-gray-700: #666;
  --token-gray-800: #444;
  --token-gray-900: #222;
  --token-gray-1000: #000;
  --token-system-danger-100: #fcefeb;
  --token-system-danger-200: #efdfde;
  --token-system-danger-300: #f8b6b0;
  --token-system-danger-600: #d85932;
  --token-system-danger-700: #d9534f;
  --token-system-danger-800: #af2225;
  --token-system-danger-900: #8a2c21;
  --token-system-warning-50: #fcf8e3;
  --token-system-warning-100: #f2ecbd;
  --token-system-warning-200: #eee491;
  --token-system-warning-300: #d6c922;
  --token-system-warning-600: #af8e55;
  --token-system-warning-700: #8a6d3b;
  --token-system-warning-800: #865d1d;
  --token-system-warning-900: #78562b;
  --token-system-info-50: #e7f7fe;
  --token-system-info-100: #ddedf4;
  --token-system-info-200: #d2eff9;
  --token-system-info-300: #b2e3ed;
  --token-system-info-600: #198bb2;
  --token-system-info-700: #147393;
  --token-system-info-800: #2e4057;
  --token-system-info-900: #1c2a3e;
  --token-system-success-50: #e7f2e2;
  --token-system-success-100: #dff0d8;
  --token-system-success-200: #cbe5c1;
  --token-system-success-300: #b8dbaa;
  --token-system-success-600: #5faa41;
  --token-system-success-700: #377e1b;
  --token-system-success-800: #1d5900;
  --token-system-success-900: #0c2f00;
  --token-system-decorative-50: #fff5ee;
  --token-system-decorative-100: #fbe8da;
  --token-system-decorative-200: #fbdac0;
  --token-system-decorative-300: #f6ba81;
  --token-system-decorative-600: #a75d00;
  --token-system-decorative-700: #6f4205;
  --token-system-decorative-800: #543103;
  --token-system-decorative-900: #411a00;
  --token-brand-logo-light: #f5f5f5;
  --token-brand-logo-primary: #1faedf;
  --token-brand-logo-dark: #777;
  --token-bg-light: #f5f5f5;
  --token-bg-darkest: #198bb2;
  --token-bg-danger: #efdfde;
  --token-bg-warning: #fcf8e3;
  --token-bg-success: #dff0d8;
  --token-bg-btnPrimary-default: #1faedf;
  --token-bg-btnPrimary-hover: #198bb2;
  --token-bg-btnPrimary-pressed: #147393;
  --token-bg-btnPrimary-disabled: #eee;
  --token-bg-btnPrimary-onDark: #e7f7fe;
  --token-bg-btnPrimary-pressed-onDark: #d2eff9;
  --token-bg-btnPrimary-disabled-onDark: #ddd;
  --token-bg-btnSecondary-default: #a54b9c;
  --token-bg-btnSecondary-hover: #823b7b;
  --token-bg-btnSecondary-pressed: #693064;
  --token-bg-btnTertiary-default: transparent;
  --token-bg-btnTertiary-hover: transparent;
  --token-bg-btnTertiary-pressed: transparent;
  --token-bg-btnTertiary-disabled: transparent;
  --token-border-default: #eee;
  --token-border-strong: #ddd;
  --token-border-onDark: #888;
  --token-border-strong-onDark: #eee;
  --token-border-danger: #f8b6b0;
  --token-border-warning: #f2ecbd;
  --token-border-success: #cbe5c1;
  --token-border-btnSecondary-default: transparent;
  --token-border-btnSecondary-disabled: transparent;
  --token-border-btnSecondary-default-onDark: transparent;
  --token-border-btnSecondary-hover-onDark: transparent;
  --token-border-btnSecondary-disabled-onDark: transparent;
  --token-border-btnTertiary-default: #888;
  --token-border-btnTertiary-disabled: #ddd;
  --token-text-default: #444;
  --token-text-subdued: #666;
  --token-text-emphasis: #1faedf;
  --token-text-disabled: #aaa;
  --token-text-onDark: #f5f5f5;
  --token-text-emphasis-onDark: #b2e3ed;
  --token-text-warning: #8a6d3b;
  --token-text-decorative: #6f4205;
  --token-text-success: #377e1b;
  --token-text-info: #147393;
  --token-text-link-default: #a54b9c;
  --token-text-link-hover: #823b7b;
  --token-text-link-onDark: #f4c1ec;
  --token-text-link-hover-onDark: #ba65b1;
  --token-text-btnPrimary-default: #fff;
  --token-text-btnPrimary-disabled: #aaa;
  --token-text-btnPrimary-onDark: #147393;
  --token-text-btnSecondary-default: #fff;
  --token-text-btnSecondary-disabled: #aaa;
  --token-text-btnSecondary-onDark: #147393;
  --token-text-btnTertiary-default: #888;
  --token-text-btnTertiary-disabled: #aaa;
  --token-icon-default: #666;
  --token-icon-hover: #222;
  --token-icon-active: #147393;
  --token-icon-disabled: #aaa;
  --token-icon-onDark: #f5f5f5;
  --token-icon-emphasis-onDark: #b2e3ed;
  --token-icon-danger: #d85932;
  --token-icon-warning: #af8e55;
  --token-icon-decorative: #a75d00;
  --token-icon-success: #5faa41;
  --token-icon-info: #198bb2;
}

.tw-theme-betterhelp_two--logged-out {
  --token-fontFamilies-overpass: Overpass;
  --token-fontWeights-light: Light;
}

.tw-theme-teencounseling {
  --token-primary-50: #f4faf1;
  --token-primary-100: #dff0d8;
  --token-primary-200: #d6e9c6;
  --token-primary-300: #acdca2;
  --token-primary-400: #78c271;
  --token-primary-500: #49ad40;
  --token-primary-600: #5faa41;
  --token-primary-700: #3b8c33;
  --token-primary-800: #3c763d;
  --token-primary-900: #2e6e29;
  --token-primary-1000: #041604;
  --token-secondary-50: #dfecf5;
  --token-secondary-100: #ddedf4;
  --token-secondary-200: #d9e7f0;
  --token-secondary-300: #9bc4e1;
  --token-secondary-400: #4e9ccc;
  --token-secondary-500: #3a86b5;
  --token-secondary-600: #37679c;
  --token-secondary-700: #2d4b75;
  --token-secondary-800: #2e4057;
  --token-secondary-900: #18345a;
  --token-secondary-1000: #0c131d;
  --token-gray-50: #f5f5f5;
  --token-gray-100: #eee;
  --token-gray-200: #ddd;
  --token-gray-300: #ccc;
  --token-gray-400: #aaa;
  --token-gray-500: #888;
  --token-gray-600: #777;
  --token-gray-700: #666;
  --token-gray-800: #444;
  --token-gray-900: #222;
  --token-gray-1000: #000;
  --token-system-danger-50: #fdf8f4;
  --token-system-danger-100: #fcefeb;
  --token-system-danger-200: #f2dede;
  --token-system-danger-300: #ebccd1;
  --token-system-danger-600: #a94442;
  --token-system-danger-700: #af2225;
  --token-system-danger-800: #6e1316;
  --token-system-danger-900: #49080b;
  --token-system-warning-50: #fcf8e3;
  --token-system-warning-100: #faebcc;
  --token-system-warning-200: #e4d57e;
  --token-system-warning-300: #d6c922;
  --token-system-warning-600: #ae7c35;
  --token-system-warning-700: #8a6d3b;
  --token-system-warning-800: #4c3707;
  --token-system-warning-900: #2e240b;
  --token-system-info-50: #dfecf5;
  --token-system-info-100: #ddedf4;
  --token-system-info-200: #d9e7f0;
  --token-system-info-300: #9bc4e1;
  --token-system-info-600: #37679c;
  --token-system-info-700: #2d4b75;
  --token-system-info-800: #2e4057;
  --token-system-info-900: #18345a;
  --token-system-success-50: #f4faf1;
  --token-system-success-100: #dff0d8;
  --token-system-success-200: #d6e9c6;
  --token-system-success-300: #acdca2;
  --token-system-success-600: #5faa41;
  --token-system-success-700: #3b8c33;
  --token-system-success-800: #3c763d;
  --token-system-success-900: #2e6e29;
  --token-system-decorative-50: #fff5ee;
  --token-system-decorative-100: #fbe8da;
  --token-system-decorative-200: #fbdac0;
  --token-system-decorative-300: #f6ba81;
  --token-system-decorative-600: #a75d00;
  --token-system-decorative-700: #6f4205;
  --token-system-decorative-800: #543103;
  --token-system-decorative-900: #411a00;
  --token-brand-logo-light: #f5f5f5;
  --token-brand-logo-primary: #49ad40;
  --token-brand-logo-dark: #777;
  --token-bg-light: #f5f5f5;
  --token-bg-default: #eee;
  --token-bg-dark: #ddd;
  --token-bg-darkest: #3b8c33;
  --token-bg-danger: #f2dede;
  --token-bg-warning: #fcf8e3;
  --token-bg-success: #dff0d8;
  --token-bg-btnPrimary-default: #49ad40;
  --token-bg-btnPrimary-hover: #3b8c33;
  --token-bg-btnPrimary-pressed: #2e6e29;
  --token-bg-btnPrimary-disabled: #eee;
  --token-bg-btnPrimary-onDark: #f5f5f5;
  --token-bg-btnPrimary-pressed-onDark: #5faa41;
  --token-bg-btnPrimary-disabled-onDark: #ddd;
  --token-bg-btnSecondary-default: #de8919;
  --token-bg-btnSecondary-hover: #c77d1a;
  --token-bg-btnSecondary-pressed: #4c3707;
  --token-bg-btnTertiary-default: #3a86b5;
  --token-bg-btnTertiary-hover: #37679c;
  --token-bg-btnTertiary-pressed: #2d4b75;
  --token-bg-btnTertiary-disabled: #eee;
  --token-border-default: #eee;
  --token-border-strong: #ddd;
  --token-border-onDark: #888;
  --token-border-strong-onDark: #eee;
  --token-border-danger: #ebccd1;
  --token-border-warning: #faebcc;
  --token-border-success: #d6e9c6;
  --token-border-btnSecondary-default: transparent;
  --token-border-btnSecondary-disabled: transparent;
  --token-border-btnSecondary-default-onDark: transparent;
  --token-border-btnSecondary-hover-onDark: transparent;
  --token-border-btnSecondary-disabled-onDark: transparent;
  --token-border-btnTertiary-default: #888;
  --token-border-btnTertiary-disabled: #ddd;
  --token-text-default: #444;
  --token-text-subdued: #666;
  --token-text-emphasis: #4e9ccc;
  --token-text-disabled: #aaa;
  --token-text-onDark: #f5f5f5;
  --token-text-emphasis-onDark: #acdca2;
  --token-text-danger: #a94442;
  --token-text-warning: #8a6d3b;
  --token-text-decorative: #6f4205;
  --token-text-success: #3c763d;
  --token-text-info: #2d4b75;
  --token-text-link-default: #49ad40;
  --token-text-link-hover: #3b8c33;
  --token-text-link-onDark: #acdca2;
  --token-text-link-hover-onDark: #dff0d8;
  --token-text-btnPrimary-default: #fff;
  --token-text-btnPrimary-disabled: #aaa;
  --token-text-btnPrimary-onDark: #3b8c33;
  --token-text-btnSecondary-default: #fff;
  --token-text-btnSecondary-disabled: #aaa;
  --token-text-btnSecondary-onDark: #3b8c33;
  --token-text-btnTertiary-default: #fff;
  --token-text-btnTertiary-disabled: #aaa;
  --token-icon-default: #666;
  --token-icon-hover: #222;
  --token-icon-active: #3b8c33;
  --token-icon-disabled: #aaa;
  --token-icon-onDark: #f5f5f5;
  --token-icon-emphasis-onDark: #acdca2;
  --token-icon-danger: #a94442;
  --token-icon-warning: #ae7c35;
  --token-icon-decorative: #a75d00;
  --token-icon-success: #5faa41;
  --token-icon-info: #37679c;
  --token-fontFamilies-overpass: Overpass;
  --token-fontWeights-light: Light;
}

:root {
  --token-mobile-heading-xl-bold-fontFamily: Inter;
  --token-desktop-heading-xl-bold-fontFamily: Inter;
  --token-mobile-heading-xl-bold-fontWeight: 700;
  --token-desktop-heading-xl-bold-fontWeight: 700;
  --token-mobile-heading-xl-bold-lineHeight: 2.5rem;
  --token-desktop-heading-xl-bold-lineHeight: 3.5rem;
  --token-mobile-heading-xl-bold-fontSize: 2rem;
  --token-desktop-heading-xl-bold-fontSize: 3rem;
  --token-mobile-heading-xl-bold-letterSpacing: -.02em;
  --token-desktop-heading-xl-bold-letterSpacing: -.02em;
  --token-mobile-heading-xl-bold-textCase: none;
  --token-desktop-heading-xl-bold-textCase: none;
  --token-mobile-heading-xl-bold-textDecoration: none;
  --token-desktop-heading-xl-bold-textDecoration: none;
  --token-mobile-heading-xl-fontFamily: Inter;
  --token-desktop-heading-xl-fontFamily: Inter;
  --token-mobile-heading-xl-fontWeight: 500;
  --token-desktop-heading-xl-fontWeight: 500;
  --token-mobile-heading-xl-lineHeight: 2.5rem;
  --token-desktop-heading-xl-lineHeight: 3.5rem;
  --token-mobile-heading-xl-fontSize: 2rem;
  --token-desktop-heading-xl-fontSize: 3rem;
  --token-mobile-heading-xl-letterSpacing: -.02em;
  --token-desktop-heading-xl-letterSpacing: -.02em;
  --token-mobile-heading-xl-textCase: none;
  --token-desktop-heading-xl-textCase: none;
  --token-mobile-heading-xl-textDecoration: none;
  --token-desktop-heading-xl-textDecoration: none;
  --token-mobile-heading-lg-bold-fontFamily: Inter;
  --token-desktop-heading-lg-bold-fontFamily: Inter;
  --token-mobile-heading-lg-bold-fontWeight: 700;
  --token-desktop-heading-lg-bold-fontWeight: 700;
  --token-mobile-heading-lg-bold-lineHeight: 2rem;
  --token-desktop-heading-lg-bold-lineHeight: 2.5rem;
  --token-mobile-heading-lg-bold-fontSize: 1.5rem;
  --token-desktop-heading-lg-bold-fontSize: 2rem;
  --token-mobile-heading-lg-bold-letterSpacing: -.01em;
  --token-desktop-heading-lg-bold-letterSpacing: -.02em;
  --token-mobile-heading-lg-bold-textCase: none;
  --token-desktop-heading-lg-bold-textCase: none;
  --token-mobile-heading-lg-bold-textDecoration: none;
  --token-desktop-heading-lg-bold-textDecoration: none;
  --token-mobile-heading-lg-fontFamily: Inter;
  --token-desktop-heading-lg-fontFamily: Inter;
  --token-mobile-heading-lg-fontWeight: 500;
  --token-desktop-heading-lg-fontWeight: 500;
  --token-mobile-heading-lg-lineHeight: 2rem;
  --token-desktop-heading-lg-lineHeight: 2.5rem;
  --token-mobile-heading-lg-fontSize: 1.5rem;
  --token-desktop-heading-lg-fontSize: 2rem;
  --token-mobile-heading-lg-letterSpacing: -.01em;
  --token-desktop-heading-lg-letterSpacing: -.02em;
  --token-mobile-heading-lg-textCase: none;
  --token-desktop-heading-lg-textCase: none;
  --token-mobile-heading-lg-textDecoration: none;
  --token-desktop-heading-lg-textDecoration: none;
  --token-mobile-heading-md-bold-fontFamily: Inter;
  --token-desktop-heading-md-bold-fontFamily: Inter;
  --token-mobile-heading-md-bold-fontWeight: 700;
  --token-desktop-heading-md-bold-fontWeight: 700;
  --token-mobile-heading-md-bold-lineHeight: 2rem;
  --token-desktop-heading-md-bold-lineHeight: 2rem;
  --token-mobile-heading-md-bold-fontSize: 1.25rem;
  --token-desktop-heading-md-bold-fontSize: 1.5rem;
  --token-mobile-heading-md-bold-letterSpacing: -.01em;
  --token-desktop-heading-md-bold-letterSpacing: -.01em;
  --token-mobile-heading-md-bold-textCase: none;
  --token-desktop-heading-md-bold-textCase: none;
  --token-mobile-heading-md-bold-textDecoration: none;
  --token-desktop-heading-md-bold-textDecoration: none;
  --token-mobile-heading-md-fontFamily: Inter;
  --token-desktop-heading-md-fontFamily: Inter;
  --token-mobile-heading-md-fontWeight: 500;
  --token-desktop-heading-md-fontWeight: 500;
  --token-mobile-heading-md-lineHeight: 2rem;
  --token-desktop-heading-md-lineHeight: 2rem;
  --token-mobile-heading-md-fontSize: 1.25rem;
  --token-desktop-heading-md-fontSize: 1.5rem;
  --token-mobile-heading-md-letterSpacing: -.01em;
  --token-desktop-heading-md-letterSpacing: -.01em;
  --token-mobile-heading-md-textCase: none;
  --token-desktop-heading-md-textCase: none;
  --token-mobile-heading-md-textDecoration: none;
  --token-desktop-heading-md-textDecoration: none;
  --token-mobile-heading-sm-bold-fontFamily: Inter;
  --token-desktop-heading-sm-bold-fontFamily: Inter;
  --token-mobile-heading-sm-bold-fontWeight: 600;
  --token-desktop-heading-sm-bold-fontWeight: 700;
  --token-mobile-heading-sm-bold-lineHeight: 1.5rem;
  --token-desktop-heading-sm-bold-lineHeight: 1.5rem;
  --token-mobile-heading-sm-bold-fontSize: 1.125rem;
  --token-desktop-heading-sm-bold-fontSize: 1.25rem;
  --token-mobile-heading-sm-bold-letterSpacing: -.01em;
  --token-desktop-heading-sm-bold-letterSpacing: -.01em;
  --token-mobile-heading-sm-bold-textCase: none;
  --token-desktop-heading-sm-bold-textCase: none;
  --token-mobile-heading-sm-bold-textDecoration: none;
  --token-desktop-heading-sm-bold-textDecoration: none;
  --token-mobile-heading-sm-fontFamily: Inter;
  --token-desktop-heading-sm-fontFamily: Inter;
  --token-mobile-heading-sm-fontWeight: 500;
  --token-desktop-heading-sm-fontWeight: 500;
  --token-mobile-heading-sm-lineHeight: 1.5rem;
  --token-desktop-heading-sm-lineHeight: 1.5rem;
  --token-mobile-heading-sm-fontSize: 1.125rem;
  --token-desktop-heading-sm-fontSize: 1.25rem;
  --token-mobile-heading-sm-letterSpacing: -.01em;
  --token-desktop-heading-sm-letterSpacing: -.01em;
  --token-mobile-heading-sm-textCase: none;
  --token-desktop-heading-sm-textCase: none;
  --token-mobile-heading-sm-textDecoration: none;
  --token-desktop-heading-sm-textDecoration: none;
  --token-mobile-body-lg-bold-fontFamily: Inter;
  --token-desktop-body-lg-bold-fontFamily: var(--token-mobile-body-lg-bold-fontFamily);
  --token-mobile-body-lg-bold-fontWeight: 600;
  --token-desktop-body-lg-bold-fontWeight: var(--token-mobile-body-lg-bold-fontWeight);
  --token-mobile-body-lg-bold-lineHeight: 2rem;
  --token-desktop-body-lg-bold-lineHeight: var(--token-mobile-body-lg-bold-lineHeight);
  --token-mobile-body-lg-bold-fontSize: 1.25rem;
  --token-desktop-body-lg-bold-fontSize: var(--token-mobile-body-lg-bold-fontSize);
  --token-mobile-body-lg-bold-letterSpacing: 0em;
  --token-desktop-body-lg-bold-letterSpacing: var(--token-mobile-body-lg-bold-letterSpacing);
  --token-mobile-body-lg-bold-textCase: none;
  --token-desktop-body-lg-bold-textCase: var(--token-mobile-body-lg-bold-textCase);
  --token-mobile-body-lg-bold-textDecoration: none;
  --token-desktop-body-lg-bold-textDecoration: var(--token-mobile-body-lg-bold-textDecoration);
  --token-mobile-body-lg-fontFamily: Inter;
  --token-desktop-body-lg-fontFamily: var(--token-mobile-body-lg-fontFamily);
  --token-mobile-body-lg-fontWeight: 400;
  --token-desktop-body-lg-fontWeight: var(--token-mobile-body-lg-fontWeight);
  --token-mobile-body-lg-lineHeight: 2rem;
  --token-desktop-body-lg-lineHeight: var(--token-mobile-body-lg-lineHeight);
  --token-mobile-body-lg-fontSize: 1.25rem;
  --token-desktop-body-lg-fontSize: var(--token-mobile-body-lg-fontSize);
  --token-mobile-body-lg-letterSpacing: -.02em;
  --token-desktop-body-lg-letterSpacing: var(--token-mobile-body-lg-letterSpacing);
  --token-mobile-body-lg-textCase: none;
  --token-desktop-body-lg-textCase: var(--token-mobile-body-lg-textCase);
  --token-mobile-body-lg-textDecoration: none;
  --token-desktop-body-lg-textDecoration: var(--token-mobile-body-lg-textDecoration);
  --token-mobile-body-md-bold-fontFamily: Inter;
  --token-desktop-body-md-bold-fontFamily: var(--token-mobile-body-md-bold-fontFamily);
  --token-mobile-body-md-bold-fontWeight: 600;
  --token-desktop-body-md-bold-fontWeight: var(--token-mobile-body-md-bold-fontWeight);
  --token-mobile-body-md-bold-lineHeight: 1.75rem;
  --token-desktop-body-md-bold-lineHeight: var(--token-mobile-body-md-bold-lineHeight);
  --token-mobile-body-md-bold-fontSize: 1rem;
  --token-desktop-body-md-bold-fontSize: var(--token-mobile-body-md-bold-fontSize);
  --token-mobile-body-md-bold-letterSpacing: -.02em;
  --token-desktop-body-md-bold-letterSpacing: var(--token-mobile-body-md-bold-letterSpacing);
  --token-mobile-body-md-bold-textCase: none;
  --token-desktop-body-md-bold-textCase: var(--token-mobile-body-md-bold-textCase);
  --token-mobile-body-md-bold-textDecoration: none;
  --token-desktop-body-md-bold-textDecoration: var(--token-mobile-body-md-bold-textDecoration);
  --token-mobile-body-md-fontFamily: Inter;
  --token-desktop-body-md-fontFamily: var(--token-mobile-body-md-fontFamily);
  --token-mobile-body-md-lineHeight: 1.75rem;
  --token-desktop-body-md-lineHeight: var(--token-mobile-body-md-lineHeight);
  --token-mobile-body-md-fontSize: 1rem;
  --token-desktop-body-md-fontSize: var(--token-mobile-body-md-fontSize);
  --token-mobile-body-md-letterSpacing: -.02em;
  --token-desktop-body-md-letterSpacing: var(--token-mobile-body-md-letterSpacing);
  --token-mobile-body-md-textCase: none;
  --token-desktop-body-md-textCase: var(--token-mobile-body-md-textCase);
  --token-mobile-body-md-textDecoration: none;
  --token-desktop-body-md-textDecoration: var(--token-mobile-body-md-textDecoration);
  --token-mobile-body-md-fontWeight: 400;
  --token-desktop-body-md-fontWeight: var(--token-mobile-body-md-fontWeight);
  --token-mobile-body-sm-bold-fontFamily: Inter;
  --token-desktop-body-sm-bold-fontFamily: var(--token-mobile-body-sm-bold-fontFamily);
  --token-mobile-body-sm-bold-fontWeight: 600;
  --token-desktop-body-sm-bold-fontWeight: var(--token-mobile-body-sm-bold-fontWeight);
  --token-mobile-body-sm-bold-lineHeight: 1.25rem;
  --token-desktop-body-sm-bold-lineHeight: var(--token-mobile-body-sm-bold-lineHeight);
  --token-mobile-body-sm-bold-fontSize: .875rem;
  --token-desktop-body-sm-bold-fontSize: var(--token-mobile-body-sm-bold-fontSize);
  --token-mobile-body-sm-bold-letterSpacing: -.02em;
  --token-desktop-body-sm-bold-letterSpacing: var(--token-mobile-body-sm-bold-letterSpacing);
  --token-mobile-body-sm-bold-textCase: none;
  --token-desktop-body-sm-bold-textCase: var(--token-mobile-body-sm-bold-textCase);
  --token-mobile-body-sm-bold-textDecoration: none;
  --token-desktop-body-sm-bold-textDecoration: var(--token-mobile-body-sm-bold-textDecoration);
  --token-mobile-body-sm-fontFamily: Inter;
  --token-desktop-body-sm-fontFamily: var(--token-mobile-body-sm-fontFamily);
  --token-mobile-body-sm-fontWeight: 400;
  --token-desktop-body-sm-fontWeight: var(--token-mobile-body-sm-fontWeight);
  --token-mobile-body-sm-lineHeight: 1.25rem;
  --token-desktop-body-sm-lineHeight: var(--token-mobile-body-sm-lineHeight);
  --token-mobile-body-sm-fontSize: .875rem;
  --token-desktop-body-sm-fontSize: var(--token-mobile-body-sm-fontSize);
  --token-mobile-body-sm-letterSpacing: -.02em;
  --token-desktop-body-sm-letterSpacing: var(--token-mobile-body-sm-letterSpacing);
  --token-mobile-body-sm-textCase: none;
  --token-desktop-body-sm-textCase: var(--token-mobile-body-sm-textCase);
  --token-mobile-body-sm-textDecoration: none;
  --token-desktop-body-sm-textDecoration: var(--token-mobile-body-sm-textDecoration);
  --token-mobile-label-lg-fontFamily: Inter;
  --token-desktop-label-lg-fontFamily: var(--token-mobile-label-lg-fontFamily);
  --token-mobile-label-lg-fontWeight: 500;
  --token-desktop-label-lg-fontWeight: var(--token-mobile-label-lg-fontWeight);
  --token-mobile-label-lg-lineHeight: 1.5rem;
  --token-desktop-label-lg-lineHeight: var(--token-mobile-label-lg-lineHeight);
  --token-mobile-label-lg-fontSize: 1.25rem;
  --token-desktop-label-lg-fontSize: var(--token-mobile-label-lg-fontSize);
  --token-mobile-label-lg-letterSpacing: -.01em;
  --token-desktop-label-lg-letterSpacing: var(--token-mobile-label-lg-letterSpacing);
  --token-mobile-label-lg-textCase: none;
  --token-desktop-label-lg-textCase: var(--token-mobile-label-lg-textCase);
  --token-mobile-label-lg-textDecoration: none;
  --token-desktop-label-lg-textDecoration: var(--token-mobile-label-lg-textDecoration);
  --token-mobile-label-md-fontFamily: Inter;
  --token-desktop-label-md-fontFamily: var(--token-mobile-label-md-fontFamily);
  --token-mobile-label-md-fontWeight: 500;
  --token-desktop-label-md-fontWeight: var(--token-mobile-label-md-fontWeight);
  --token-mobile-label-md-lineHeight: 1.5rem;
  --token-desktop-label-md-lineHeight: var(--token-mobile-label-md-lineHeight);
  --token-mobile-label-md-fontSize: 1.125rem;
  --token-desktop-label-md-fontSize: var(--token-mobile-label-md-fontSize);
  --token-mobile-label-md-letterSpacing: -.01em;
  --token-desktop-label-md-letterSpacing: var(--token-mobile-label-md-letterSpacing);
  --token-mobile-label-md-textCase: none;
  --token-desktop-label-md-textCase: var(--token-mobile-label-md-textCase);
  --token-mobile-label-md-textDecoration: none;
  --token-desktop-label-md-textDecoration: var(--token-mobile-label-md-textDecoration);
  --token-mobile-label-sm-fontFamily: Inter;
  --token-desktop-label-sm-fontFamily: var(--token-mobile-label-sm-fontFamily);
  --token-mobile-label-sm-fontWeight: 500;
  --token-desktop-label-sm-fontWeight: var(--token-mobile-label-sm-fontWeight);
  --token-mobile-label-sm-lineHeight: 1.5rem;
  --token-desktop-label-sm-lineHeight: var(--token-mobile-label-sm-lineHeight);
  --token-mobile-label-sm-fontSize: 1rem;
  --token-desktop-label-sm-fontSize: var(--token-mobile-label-sm-fontSize);
  --token-mobile-label-sm-letterSpacing: -.01em;
  --token-desktop-label-sm-letterSpacing: var(--token-mobile-label-sm-letterSpacing);
  --token-mobile-label-sm-textCase: none;
  --token-desktop-label-sm-textCase: var(--token-mobile-label-sm-textCase);
  --token-mobile-label-sm-textDecoration: none;
  --token-desktop-label-sm-textDecoration: var(--token-mobile-label-sm-textDecoration);
  --token-mobile-label-xs-fontFamily: Inter;
  --token-desktop-label-xs-fontFamily: var(--token-mobile-label-xs-fontFamily);
  --token-mobile-label-xs-fontWeight: 500;
  --token-desktop-label-xs-fontWeight: var(--token-mobile-label-xs-fontWeight);
  --token-mobile-label-xs-lineHeight: 1rem;
  --token-desktop-label-xs-lineHeight: var(--token-mobile-label-xs-lineHeight);
  --token-mobile-label-xs-fontSize: .75rem;
  --token-desktop-label-xs-fontSize: var(--token-mobile-label-xs-fontSize);
  --token-mobile-label-xs-letterSpacing: 0em;
  --token-desktop-label-xs-letterSpacing: var(--token-mobile-label-xs-letterSpacing);
  --token-mobile-label-xs-textCase: none;
  --token-desktop-label-xs-textCase: var(--token-mobile-label-xs-textCase);
  --token-mobile-label-xs-textDecoration: none;
  --token-desktop-label-xs-textDecoration: var(--token-mobile-label-xs-textDecoration);
  --token-mobile-link-fontFamily: Inter;
  --token-desktop-link-fontFamily: var(--token-mobile-link-fontFamily);
  --token-mobile-link-fontWeight: 600;
  --token-desktop-link-fontWeight: var(--token-mobile-link-fontWeight);
  --token-mobile-link-lineHeight: 1.75rem;
  --token-desktop-link-lineHeight: var(--token-mobile-link-lineHeight);
  --token-mobile-link-fontSize: 1rem;
  --token-desktop-link-fontSize: var(--token-mobile-link-fontSize);
  --token-mobile-link-letterSpacing: -.02em;
  --token-desktop-link-letterSpacing: var(--token-mobile-link-letterSpacing);
  --token-mobile-link-textCase: none;
  --token-desktop-link-textCase: var(--token-mobile-link-textCase);
  --token-mobile-link-textDecoration: underline;
  --token-desktop-link-textDecoration: var(--token-mobile-link-textDecoration);
  --token-mobile-link-sm-fontFamily: Inter;
  --token-desktop-link-sm-fontFamily: var(--token-mobile-link-sm-fontFamily);
  --token-mobile-link-sm-fontWeight: 600;
  --token-desktop-link-sm-fontWeight: var(--token-mobile-link-sm-fontWeight);
  --token-mobile-link-sm-lineHeight: 1.25rem;
  --token-desktop-link-sm-lineHeight: var(--token-mobile-link-sm-lineHeight);
  --token-mobile-link-sm-fontSize: .875rem;
  --token-desktop-link-sm-fontSize: var(--token-mobile-link-sm-fontSize);
  --token-mobile-link-sm-letterSpacing: -.02em;
  --token-desktop-link-sm-letterSpacing: var(--token-mobile-link-sm-letterSpacing);
  --token-mobile-link-sm-textCase: none;
  --token-desktop-link-sm-textCase: var(--token-mobile-link-sm-textCase);
  --token-mobile-link-sm-textDecoration: underline;
  --token-desktop-link-sm-textDecoration: var(--token-mobile-link-sm-textDecoration);
  --token-mobile-overline-fontFamily: Inter;
  --token-desktop-overline-fontFamily: var(--token-mobile-overline-fontFamily);
  --token-mobile-overline-fontWeight: 600;
  --token-desktop-overline-fontWeight: var(--token-mobile-overline-fontWeight);
  --token-mobile-overline-lineHeight: 1rem;
  --token-desktop-overline-lineHeight: var(--token-mobile-overline-lineHeight);
  --token-mobile-overline-fontSize: .875rem;
  --token-desktop-overline-fontSize: var(--token-mobile-overline-fontSize);
  --token-mobile-overline-letterSpacing: .04em;
  --token-desktop-overline-letterSpacing: var(--token-mobile-overline-letterSpacing);
  --token-mobile-overline-textCase: uppercase;
  --token-desktop-overline-textCase: var(--token-mobile-overline-textCase);
  --token-mobile-overline-textDecoration: none;
  --token-desktop-overline-textDecoration: var(--token-mobile-overline-textDecoration);
  --token-mobile-caption-fontFamily: Inter;
  --token-desktop-caption-fontFamily: var(--token-mobile-caption-fontFamily);
  --token-mobile-caption-fontWeight: 500;
  --token-desktop-caption-fontWeight: var(--token-mobile-caption-fontWeight);
  --token-mobile-caption-lineHeight: 1rem;
  --token-desktop-caption-lineHeight: var(--token-mobile-caption-lineHeight);
  --token-mobile-caption-fontSize: .75rem;
  --token-desktop-caption-fontSize: var(--token-mobile-caption-fontSize);
  --token-mobile-caption-letterSpacing: 0em;
  --token-desktop-caption-letterSpacing: var(--token-mobile-caption-letterSpacing);
  --token-mobile-caption-textCase: none;
  --token-desktop-caption-textCase: var(--token-mobile-caption-textCase);
  --token-mobile-caption-textDecoration: none;
  --token-desktop-caption-textDecoration: var(--token-mobile-caption-textDecoration);
}

.tw-theme-betterhelp_two--logged-out {
  --token-mobile-heading-xl-bold-fontFamily: Overpass;
  --token-desktop-heading-xl-bold-fontFamily: Overpass;
  --token-mobile-heading-xl-fontFamily: Overpass;
  --token-desktop-heading-xl-fontFamily: Overpass;
  --token-mobile-heading-xl-fontWeight: 300;
  --token-desktop-heading-xl-fontWeight: 300;
  --token-mobile-heading-lg-bold-fontFamily: Overpass;
  --token-desktop-heading-lg-bold-fontFamily: Overpass;
  --token-mobile-heading-lg-fontFamily: Overpass;
  --token-desktop-heading-lg-fontFamily: Overpass;
  --token-mobile-heading-lg-fontWeight: 300;
  --token-desktop-heading-lg-fontWeight: 300;
  --token-mobile-heading-md-bold-fontFamily: Overpass;
  --token-desktop-heading-md-bold-fontFamily: Overpass;
  --token-mobile-heading-md-fontFamily: Overpass;
  --token-desktop-heading-md-fontFamily: Overpass;
  --token-mobile-heading-md-fontWeight: 300;
  --token-desktop-heading-md-fontWeight: 300;
  --token-mobile-heading-sm-bold-fontFamily: Overpass;
  --token-desktop-heading-sm-bold-fontFamily: Overpass;
  --token-mobile-heading-sm-bold-fontWeight: 700;
  --token-mobile-heading-sm-fontFamily: Overpass;
  --token-desktop-heading-sm-fontFamily: Overpass;
  --token-mobile-heading-sm-fontWeight: 300;
  --token-desktop-heading-sm-fontWeight: 300;
}

*, :before, :after {
  --tw-content: "";
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: $ {defaultRingColor};
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::-webkit-backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: $ {defaultRingColor};
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

::backdrop {
  --tw-border-spacing-x: 0;
  --tw-border-spacing-y: 0;
  --tw-translate-x: 0;
  --tw-translate-y: 0;
  --tw-rotate: 0;
  --tw-skew-x: 0;
  --tw-skew-y: 0;
  --tw-scale-x: 1;
  --tw-scale-y: 1;
  --tw-pan-x: ;
  --tw-pan-y: ;
  --tw-pinch-zoom: ;
  --tw-scroll-snap-strictness: proximity;
  --tw-gradient-from-position: ;
  --tw-gradient-via-position: ;
  --tw-gradient-to-position: ;
  --tw-ordinal: ;
  --tw-slashed-zero: ;
  --tw-numeric-figure: ;
  --tw-numeric-spacing: ;
  --tw-numeric-fraction: ;
  --tw-ring-inset: ;
  --tw-ring-offset-width: 0px;
  --tw-ring-offset-color: #fff;
  --tw-ring-color: $ {defaultRingColor};
  --tw-ring-offset-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-ring-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow: 0 0 rgba(0, 0, 0, 0);
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
  --tw-blur: ;
  --tw-brightness: ;
  --tw-contrast: ;
  --tw-grayscale: ;
  --tw-hue-rotate: ;
  --tw-invert: ;
  --tw-saturate: ;
  --tw-sepia: ;
  --tw-drop-shadow: ;
  --tw-backdrop-blur: ;
  --tw-backdrop-brightness: ;
  --tw-backdrop-contrast: ;
  --tw-backdrop-grayscale: ;
  --tw-backdrop-hue-rotate: ;
  --tw-backdrop-invert: ;
  --tw-backdrop-opacity: ;
  --tw-backdrop-saturate: ;
  --tw-backdrop-sepia: ;
}

.tippy-box[data-theme="tinymce"] {
  --tw-border-opacity: 1;
  border-style: solid;
  border-width: 1px;
  border-color: rgba(220, 220, 221, var(--tw-border-opacity));
  --tw-bg-opacity: 1;
  background-color: rgba(227, 227, 224, var(--tw-bg-opacity));
  color: var(--token-site-gray);
  border-radius: 2px;
  font-size: 12px;
}

[data-theme="tinymce"] .tippy-arrow {
  display: none;
}

.journal-image-small--landscape {
  width: 140px;
}

@media (min-width: 768px) {
  .journal-image-small--landscape {
    width: 200px;
  }
}

.journal-image-medium--landscape {
  width: 224px;
}

@media (min-width: 768px) {
  .journal-image-medium--landscape {
    width: 320px;
  }
}

.journal-image-large--landscape {
  width: 280px;
}

@media (min-width: 768px) {
  .journal-image-large--landscape {
    width: 400px;
  }
}

.journal-image-small--portrait {
  height: 140px;
}

@media (min-width: 768px) {
  .journal-image-small--portrait {
    height: 200px;
  }
}

.journal-image-medium--portrait {
  height: 224px;
}

@media (min-width: 768px) {
  .journal-image-medium--portrait {
    height: 320px;
  }
}

.journal-image-large--portrait {
  height: 280px;
}

@media (min-width: 768px) {
  .journal-image-large--portrait {
    height: 400px;
  }
}

.journal-image-left {
  float: left;
  margin-bottom: 5px;
  margin-right: 10px;
  display: block;
}

.journal-image-center {
  margin: 5px auto;
  display: block;
}

.journal-image-right {
  float: right;
  margin-bottom: 5px;
  margin-left: 10px;
  display: block;
}

.tw-reset-button {
  font-feature-settings: inherit;
  font-variation-settings: inherit;
  font-family: inherit;
  font-size: 100%;
  font-weight: inherit;
  line-height: inherit;
  color: inherit;
  text-transform: none;
  -webkit-appearance: none;
  appearance: none;
  cursor: pointer;
  background-color: rgba(0, 0, 0, 0);
  background-image: none;
  border-width: 0;
  margin: 0;
  padding: 0;
}

.tw-reset-button:disabled {
  cursor: default;
}

.tw-reset-p {
  color: inherit;
  margin: 0;
}

.tw-reset-heading {
  font-size: inherit;
  font-weight: inherit;
  color: inherit;
  margin: 0;
}

.tw-reset-label {
  color: inherit;
  margin: 0;
  font-weight: 400;
  display: block;
}

.tw-reset-border {
  border: 0 solid rgba(0, 0, 0, 0);
}

.tw-reset-ol, .tw-reset-ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.tw-heading-xl {
  font-family: var(--token-mobile-heading-xl-fontFamily);
  font-weight: var(--token-mobile-heading-xl-fontWeight);
  line-height: var(--token-mobile-heading-xl-lineHeight);
  font-size: var(--token-mobile-heading-xl-fontSize);
  letter-spacing: var(--token-mobile-heading-xl-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-heading-xl {
    font-family: var(--token-desktop-heading-xl-fontFamily);
    font-weight: var(--token-desktop-heading-xl-fontWeight);
    line-height: var(--token-desktop-heading-xl-lineHeight);
    font-size: var(--token-desktop-heading-xl-fontSize);
    letter-spacing: var(--token-desktop-heading-xl-letterSpacing);
  }
}

.tw-heading-lg {
  font-family: var(--token-mobile-heading-lg-fontFamily);
  font-weight: var(--token-mobile-heading-lg-fontWeight);
  line-height: var(--token-mobile-heading-lg-lineHeight);
  font-size: var(--token-mobile-heading-lg-fontSize);
  letter-spacing: var(--token-mobile-heading-lg-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-heading-lg {
    font-family: var(--token-desktop-heading-lg-fontFamily);
    font-weight: var(--token-desktop-heading-lg-fontWeight);
    line-height: var(--token-desktop-heading-lg-lineHeight);
    font-size: var(--token-desktop-heading-lg-fontSize);
    letter-spacing: var(--token-desktop-heading-lg-letterSpacing);
  }
}

.tw-heading-md-bold {
  font-family: var(--token-mobile-heading-md-bold-fontFamily);
  font-weight: var(--token-mobile-heading-md-bold-fontWeight);
  line-height: var(--token-mobile-heading-md-bold-lineHeight);
  font-size: var(--token-mobile-heading-md-bold-fontSize);
  letter-spacing: var(--token-mobile-heading-md-bold-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-heading-md-bold {
    font-family: var(--token-desktop-heading-md-bold-fontFamily);
    font-weight: var(--token-desktop-heading-md-bold-fontWeight);
    line-height: var(--token-desktop-heading-md-bold-lineHeight);
    font-size: var(--token-desktop-heading-md-bold-fontSize);
    letter-spacing: var(--token-desktop-heading-md-bold-letterSpacing);
  }
}

.tw-heading-sm-bold {
  font-family: var(--token-mobile-heading-sm-bold-fontFamily);
  font-weight: var(--token-mobile-heading-sm-bold-fontWeight);
  line-height: var(--token-mobile-heading-sm-bold-lineHeight);
  font-size: var(--token-mobile-heading-sm-bold-fontSize);
  letter-spacing: var(--token-mobile-heading-sm-bold-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-heading-sm-bold {
    font-family: var(--token-desktop-heading-sm-bold-fontFamily);
    font-weight: var(--token-desktop-heading-sm-bold-fontWeight);
    line-height: var(--token-desktop-heading-sm-bold-lineHeight);
    font-size: var(--token-desktop-heading-sm-bold-fontSize);
    letter-spacing: var(--token-desktop-heading-sm-bold-letterSpacing);
  }
}

.tw-heading-sm {
  font-family: var(--token-mobile-heading-sm-fontFamily);
  font-weight: var(--token-mobile-heading-sm-fontWeight);
  line-height: var(--token-mobile-heading-sm-lineHeight);
  font-size: var(--token-mobile-heading-sm-fontSize);
  letter-spacing: var(--token-mobile-heading-sm-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-heading-sm {
    font-family: var(--token-desktop-heading-sm-fontFamily);
    font-weight: var(--token-desktop-heading-sm-fontWeight);
    line-height: var(--token-desktop-heading-sm-lineHeight);
    font-size: var(--token-desktop-heading-sm-fontSize);
    letter-spacing: var(--token-desktop-heading-sm-letterSpacing);
  }
}

.tw-body-md-bold {
  font-family: var(--token-mobile-body-md-bold-fontFamily);
  font-weight: var(--token-mobile-body-md-bold-fontWeight);
  line-height: var(--token-mobile-body-md-bold-lineHeight);
  font-size: var(--token-mobile-body-md-bold-fontSize);
  letter-spacing: var(--token-mobile-body-md-bold-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-body-md-bold {
    font-family: var(--token-desktop-body-md-bold-fontFamily);
    font-weight: var(--token-desktop-body-md-bold-fontWeight);
    line-height: var(--token-desktop-body-md-bold-lineHeight);
    font-size: var(--token-desktop-body-md-bold-fontSize);
    letter-spacing: var(--token-desktop-body-md-bold-letterSpacing);
  }
}

.tw-body-sm {
  font-family: var(--token-mobile-body-sm-fontFamily);
  font-weight: var(--token-mobile-body-sm-fontWeight);
  line-height: var(--token-mobile-body-sm-lineHeight);
  font-size: var(--token-mobile-body-sm-fontSize);
  letter-spacing: var(--token-mobile-body-sm-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-body-sm {
    font-family: var(--token-desktop-body-sm-fontFamily);
    font-weight: var(--token-desktop-body-sm-fontWeight);
    line-height: var(--token-desktop-body-sm-lineHeight);
    font-size: var(--token-desktop-body-sm-fontSize);
    letter-spacing: var(--token-desktop-body-sm-letterSpacing);
  }
}

.tw-label-lg {
  font-family: var(--token-mobile-label-lg-fontFamily);
  font-weight: var(--token-mobile-label-lg-fontWeight);
  line-height: var(--token-mobile-label-lg-lineHeight);
  font-size: var(--token-mobile-label-lg-fontSize);
  letter-spacing: var(--token-mobile-label-lg-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-label-lg {
    font-family: var(--token-desktop-label-lg-fontFamily);
    font-weight: var(--token-desktop-label-lg-fontWeight);
    line-height: var(--token-desktop-label-lg-lineHeight);
    font-size: var(--token-desktop-label-lg-fontSize);
    letter-spacing: var(--token-desktop-label-lg-letterSpacing);
  }
}

.\!tw-label-sm {
  font-family: var(--token-mobile-label-sm-fontFamily) !important;
  font-weight: var(--token-mobile-label-sm-fontWeight) !important;
  line-height: var(--token-mobile-label-sm-lineHeight) !important;
  font-size: var(--token-mobile-label-sm-fontSize) !important;
  letter-spacing: var(--token-mobile-label-sm-letterSpacing) !important;
  text-transform: none !important;
  text-decoration: none !important;
}

@media (min-width: 992px) {
  .\!tw-label-sm {
    font-family: var(--token-desktop-label-sm-fontFamily) !important;
    font-weight: var(--token-desktop-label-sm-fontWeight) !important;
    line-height: var(--token-desktop-label-sm-lineHeight) !important;
    font-size: var(--token-desktop-label-sm-fontSize) !important;
    letter-spacing: var(--token-desktop-label-sm-letterSpacing) !important;
  }
}

.tw-link {
  font-family: var(--token-mobile-link-fontFamily);
  font-weight: var(--token-mobile-link-fontWeight);
  line-height: var(--token-mobile-link-lineHeight);
  font-size: var(--token-mobile-link-fontSize);
  letter-spacing: var(--token-mobile-link-letterSpacing);
  text-transform: none;
  text-decoration: underline;
}

@media (min-width: 992px) {
  .tw-link {
    font-family: var(--token-desktop-link-fontFamily);
    font-weight: var(--token-desktop-link-fontWeight);
    line-height: var(--token-desktop-link-lineHeight);
    font-size: var(--token-desktop-link-fontSize);
    letter-spacing: var(--token-desktop-link-letterSpacing);
  }
}

.tw-sr-only {
  width: 1px;
  height: 1px;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
  margin: -1px;
  padding: 0;
  position: absolute;
  overflow: hidden;
}

.tw-pointer-events-none {
  pointer-events: none;
}

.tw-invisible {
  visibility: hidden;
}

.\!tw-static {
  position: static !important;
}

.tw-fixed {
  position: fixed;
}

.\!tw-absolute {
  position: absolute !important;
}

.tw-absolute {
  position: absolute;
}

.\!tw-relative {
  position: relative !important;
}

.tw-relative {
  position: relative;
}

.tw-sticky {
  position: -webkit-sticky;
  position: sticky;
}

.tw-inset-0 {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.\!tw-top-0 {
  top: 0 !important;
}

.\!tw-top-\[-1px\] {
  top: -1px !important;
}

.-tw-right-0 {
  right: 0;
}

.-tw-right-0\.5 {
  right: -.125rem;
}

.-tw-right-\[2px\] {
  right: -2px;
}

.-tw-top-0 {
  top: 0;
}

.-tw-top-0\.5 {
  top: -.125rem;
}

.-tw-top-1 {
  top: -.25rem;
}

.tw--bottom-\[1px\] {
  bottom: -1px;
}

.tw--left-2\/4 {
  left: -50%;
}

.tw--top-2\/4 {
  top: -50%;
}

.tw-bottom-0 {
  bottom: 0;
}

.tw-bottom-24 {
  bottom: 6rem;
}

.tw-bottom-\[2px\] {
  bottom: 2px;
}

.tw-bottom-\[30px\] {
  bottom: 30px;
}

.tw-bottom-\[70px\] {
  bottom: 70px;
}

.tw-bottom-\[95px\] {
  bottom: 95px;
}

.tw-left-0 {
  left: 0;
}

.tw-left-1\/2 {
  left: 50%;
}

.tw-left-\[100px\] {
  left: 100px;
}

.tw-left-\[160px\] {
  left: 160px;
}

.tw-left-\[85px\] {
  left: 85px;
}

.tw-left-\[calc\(50\%_-_80\%\)\] {
  left: -30%;
}

.tw-left-\[calc\(50\%_-_80\%_\/_2\)\] {
  left: 10%;
}

.tw-left-\[calc\(50\%_-_80\%_\/_2_-_500px\)\] {
  left: calc(10% - 500px);
}

.tw-right-0 {
  right: 0;
}

.tw-right-1\/2 {
  right: 50%;
}

.tw-right-2 {
  right: .5rem;
}

.tw-right-6 {
  right: 1.5rem;
}

.tw-right-9 {
  right: 2.25rem;
}

.tw-right-\[--headerPaddingX\] {
  right: var(--headerPaddingX);
}

.tw-right-\[15px\] {
  right: 15px;
}

.tw-right-\[16px\] {
  right: 16px;
}

.tw-right-\[17px\] {
  right: 17px;
}

.tw-right-\[30px\] {
  right: 30px;
}

.tw-right-\[3px\] {
  right: 3px;
}

.tw-right-\[6px\] {
  right: 6px;
}

.tw-top-0 {
  top: 0;
}

.tw-top-2 {
  top: .5rem;
}

.tw-top-2\.5 {
  top: .625rem;
}

.tw-top-\[-80px\] {
  top: -80px;
}

.tw-top-\[11px\] {
  top: 11px;
}

.tw-top-\[12px\] {
  top: 12px;
}

.tw-top-\[14px\] {
  top: 14px;
}

.tw-top-\[163px\] {
  top: 163px;
}

.tw-top-\[16px\] {
  top: 16px;
}

.tw-top-\[1px\] {
  top: 1px;
}

.tw-top-\[30px\] {
  top: 30px;
}

.tw-top-\[3px\] {
  top: 3px;
}

.tw-top-\[42px\] {
  top: 42px;
}

.tw-top-\[52px\] {
  top: 52px;
}

.tw-top-\[6px\] {
  top: 6px;
}

.tw-top-\[78px\] {
  top: 78px;
}

.tw-top-\[calc\(-1_\*_theme\(spacing\.6\)\)\] {
  top: -1.5rem;
}

.tw-top-\[calc\(50\%_-_80\%\)\] {
  top: -30%;
}

.tw-top-\[calc\(50\%_-_80\%_\/_2\)\] {
  top: 10%;
}

.tw-top-\[calc\(50\%_-_80\%_\/_2_\+_200px\)\] {
  top: calc(10% + 200px);
}

.tw-top-auto {
  top: auto;
}

.tw-top-full {
  top: 100%;
}

.\!tw-z-20 {
  z-index: 20 !important;
}

.tw-z-10 {
  z-index: 10;
}

.tw-z-\[1050\] {
  z-index: 1050;
}

.tw-z-\[4051\] {
  z-index: 4051;
}

.tw-z-\[9999\] {
  z-index: 9999;
}

.tw-order-1 {
  order: 1;
}

.tw-order-2 {
  order: 2;
}

.tw-order-last {
  order: 9999;
}

.tw-col-span-2 {
  grid-column: span 2 / span 2;
}

.tw-col-span-4 {
  grid-column: span 4 / span 4;
}

.tw-col-span-5 {
  grid-column: span 5 / span 5;
}

.tw-col-start-1 {
  grid-column-start: 1;
}

.tw-col-start-2 {
  grid-column-start: 2;
}

.tw-col-end-2 {
  grid-column-end: 2;
}

.tw-col-end-3 {
  grid-column-end: 3;
}

.tw-row-start-1 {
  grid-row-start: 1;
}

.tw-row-start-2 {
  grid-row-start: 2;
}

.tw-row-start-3 {
  grid-row-start: 3;
}

.tw-row-end-2 {
  grid-row-end: 2;
}

.tw-row-end-3 {
  grid-row-end: 3;
}

.tw-row-end-4 {
  grid-row-end: 4;
}

.tw-float-right {
  float: right;
}

.tw-float-left {
  float: left;
}

.\!tw-float-none {
  float: none !important;
}

.tw-float-none {
  float: none;
}

.tw-clear-both {
  clear: both;
}

.\!tw-m-0, .\!tw-m-\[0px\] {
  margin: 0 !important;
}

.tw-m-0 {
  margin: 0;
}

.tw-m-2 {
  margin: .5rem;
}

.tw-m-\[0px\] {
  margin: 0;
}

.tw-m-auto {
  margin: auto;
}

.\!tw-mx-0 {
  margin-left: 0 !important;
  margin-right: 0 !important;
}

.\!tw-mx-2 {
  margin-left: .5rem !important;
  margin-right: .5rem !important;
}

.\!tw-mx-auto {
  margin-left: auto !important;
  margin-right: auto !important;
}

.\!tw-my-0 {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.\!tw-my-2 {
  margin-top: .5rem !important;
  margin-bottom: .5rem !important;
}

.\!tw-my-\[1em\] {
  margin-top: 1em !important;
  margin-bottom: 1em !important;
}

.\!tw-my-\[20px\] {
  margin-top: 20px !important;
  margin-bottom: 20px !important;
}

.-tw-mx-6 {
  margin-left: -1.5rem;
  margin-right: -1.5rem;
}

.-tw-mx-\[20px\] {
  margin-left: -20px;
  margin-right: -20px;
}

.tw-mx-0 {
  margin-left: 0;
  margin-right: 0;
}

.tw-mx-1 {
  margin-left: .25rem;
  margin-right: .25rem;
}

.tw-mx-2 {
  margin-left: .5rem;
  margin-right: .5rem;
}

.tw-mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.tw-mx-\[-24px\] {
  margin-left: -24px;
  margin-right: -24px;
}

.tw-mx-\[10px\] {
  margin-left: 10px;
  margin-right: 10px;
}

.tw-mx-\[12px\] {
  margin-left: 12px;
  margin-right: 12px;
}

.tw-mx-\[15px\] {
  margin-left: 15px;
  margin-right: 15px;
}

.tw-mx-\[20px\] {
  margin-left: 20px;
  margin-right: 20px;
}

.tw-mx-\[4px\] {
  margin-left: 4px;
  margin-right: 4px;
}

.tw-mx-\[5px\] {
  margin-left: 5px;
  margin-right: 5px;
}

.tw-mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.tw-mx-px {
  margin-left: 1px;
  margin-right: 1px;
}

.tw-my-0 {
  margin-top: 0;
  margin-bottom: 0;
}

.tw-my-1 {
  margin-top: .25rem;
  margin-bottom: .25rem;
}

.tw-my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.tw-my-2 {
  margin-top: .5rem;
  margin-bottom: .5rem;
}

.tw-my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.tw-my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.tw-my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.tw-my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.tw-my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.tw-my-\[10px\] {
  margin-top: 10px;
  margin-bottom: 10px;
}

.tw-my-\[15px\] {
  margin-top: 15px;
  margin-bottom: 15px;
}

.tw-my-\[16px\] {
  margin-top: 16px;
  margin-bottom: 16px;
}

.tw-my-\[18px\] {
  margin-top: 18px;
  margin-bottom: 18px;
}

.tw-my-\[20px\] {
  margin-top: 20px;
  margin-bottom: 20px;
}

.tw-my-\[25px\] {
  margin-top: 25px;
  margin-bottom: 25px;
}

.tw-my-\[3px\] {
  margin-top: 3px;
  margin-bottom: 3px;
}

.tw-my-\[5px\] {
  margin-top: 5px;
  margin-bottom: 5px;
}

.tw-my-\[8px\] {
  margin-top: 8px;
  margin-bottom: 8px;
}

.tw-my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.\!tw-mb-0 {
  margin-bottom: 0 !important;
}

.\!tw-mb-4 {
  margin-bottom: 1rem !important;
}

.\!tw-mb-5 {
  margin-bottom: 1.25rem !important;
}

.\!tw-mb-8 {
  margin-bottom: 2rem !important;
}

.\!tw-mb-\[-30px\] {
  margin-bottom: -30px !important;
}

.\!tw-mb-\[12px\] {
  margin-bottom: 12px !important;
}

.\!tw-mb-\[14px\] {
  margin-bottom: 14px !important;
}

.\!tw-mb-\[15px\] {
  margin-bottom: 15px !important;
}

.\!tw-mb-\[16px\] {
  margin-bottom: 16px !important;
}

.\!tw-mb-\[20px\] {
  margin-bottom: 20px !important;
}

.\!tw-mb-\[24px\] {
  margin-bottom: 24px !important;
}

.\!tw-mb-\[5px\] {
  margin-bottom: 5px !important;
}

.\!tw-mb-\[8px\] {
  margin-bottom: 8px !important;
}

.\!tw-ml-0 {
  margin-left: 0 !important;
}

.\!tw-mr-0 {
  margin-right: 0 !important;
}

.\!tw-mr-2 {
  margin-right: .5rem !important;
}

.\!tw-mr-\[12px\] {
  margin-right: 12px !important;
}

.\!tw-mr-\[28px\] {
  margin-right: 28px !important;
}

.\!tw-mr-\[30px\] {
  margin-right: 30px !important;
}

.\!tw-mr-\[4px\] {
  margin-right: 4px !important;
}

.\!tw-mr-\[7px\] {
  margin-right: 7px !important;
}

.\!tw-mr-auto {
  margin-right: auto !important;
}

.\!tw-mt-0 {
  margin-top: 0 !important;
}

.\!tw-mt-4 {
  margin-top: 1rem !important;
}

.\!tw-mt-\[15px\] {
  margin-top: 15px !important;
}

.\!tw-mt-\[1em\] {
  margin-top: 1em !important;
}

.\!tw-mt-\[20px\] {
  margin-top: 20px !important;
}

.\!tw-mt-\[32px\] {
  margin-top: 32px !important;
}

.\!tw-mt-\[4px\] {
  margin-top: 4px !important;
}

.\!tw-mt-\[8px\] {
  margin-top: 8px !important;
}

.-tw-mb-\[20px\] {
  margin-bottom: -20px;
}

.-tw-ml-1 {
  margin-left: -.25rem;
}

.-tw-ml-4 {
  margin-left: -1rem;
}

.-tw-ml-\[20px\] {
  margin-left: -20px;
}

.-tw-mt-1 {
  margin-top: -.25rem;
}

.-tw-mt-14 {
  margin-top: -3.5rem;
}

.-tw-mt-4 {
  margin-top: -1rem;
}

.-tw-mt-5 {
  margin-top: -1.25rem;
}

.-tw-mt-\[15px\] {
  margin-top: -15px;
}

.-tw-mt-\[30px\] {
  margin-top: -30px;
}

.tw--mb-\[10px\] {
  margin-bottom: -10px;
}

.tw--mt-3 {
  margin-top: -.75rem;
}

.tw-mb-0 {
  margin-bottom: 0;
}

.tw-mb-1 {
  margin-bottom: .25rem;
}

.tw-mb-1\.5 {
  margin-bottom: .375rem;
}

.tw-mb-10 {
  margin-bottom: 2.5rem;
}

.tw-mb-12 {
  margin-bottom: 3rem;
}

.tw-mb-16 {
  margin-bottom: 4rem;
}

.tw-mb-2 {
  margin-bottom: .5rem;
}

.tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-mb-5 {
  margin-bottom: 1.25rem;
}

.tw-mb-6 {
  margin-bottom: 1.5rem;
}

.tw-mb-8 {
  margin-bottom: 2rem;
}

.tw-mb-9 {
  margin-bottom: 2.25rem;
}

.tw-mb-\[-30px\] {
  margin-bottom: -30px;
}

.tw-mb-\[-40px\] {
  margin-bottom: -40px;
}

.tw-mb-\[100px\] {
  margin-bottom: 100px;
}

.tw-mb-\[10px\] {
  margin-bottom: 10px;
}

.tw-mb-\[12px\] {
  margin-bottom: 12px;
}

.tw-mb-\[15px\] {
  margin-bottom: 15px;
}

.tw-mb-\[16px\] {
  margin-bottom: 16px;
}

.tw-mb-\[18px\] {
  margin-bottom: 18px;
}

.tw-mb-\[20px\] {
  margin-bottom: 20px;
}

.tw-mb-\[22px\] {
  margin-bottom: 22px;
}

.tw-mb-\[24px\] {
  margin-bottom: 24px;
}

.tw-mb-\[25px\] {
  margin-bottom: 25px;
}

.tw-mb-\[30px\] {
  margin-bottom: 30px;
}

.tw-mb-\[31px\] {
  margin-bottom: 31px;
}

.tw-mb-\[32px\] {
  margin-bottom: 32px;
}

.tw-mb-\[34px\] {
  margin-bottom: 34px;
}

.tw-mb-\[38px\] {
  margin-bottom: 38px;
}

.tw-mb-\[40px\] {
  margin-bottom: 40px;
}

.tw-mb-\[4px\] {
  margin-bottom: 4px;
}

.tw-mb-\[50px\] {
  margin-bottom: 50px;
}

.tw-mb-\[5px\] {
  margin-bottom: 5px;
}

.tw-mb-\[6px\] {
  margin-bottom: 6px;
}

.tw-mb-\[8px\] {
  margin-bottom: 8px;
}

.tw-mb-px {
  margin-bottom: 1px;
}

.tw-ml-0 {
  margin-left: 0;
}

.tw-ml-1 {
  margin-left: .25rem;
}

.tw-ml-2 {
  margin-left: .5rem;
}

.tw-ml-2\.5 {
  margin-left: .625rem;
}

.tw-ml-3 {
  margin-left: .75rem;
}

.tw-ml-6 {
  margin-left: 1.5rem;
}

.tw-ml-7 {
  margin-left: 1.75rem;
}

.tw-ml-8 {
  margin-left: 2rem;
}

.tw-ml-\[-2px\] {
  margin-left: -2px;
}

.tw-ml-\[-5px\] {
  margin-left: -5px;
}

.tw-ml-\[0px\] {
  margin-left: 0;
}

.tw-ml-\[10px\] {
  margin-left: 10px;
}

.tw-ml-\[12px\] {
  margin-left: 12px;
}

.tw-ml-\[13px\] {
  margin-left: 13px;
}

.tw-ml-\[16px\] {
  margin-left: 16px;
}

.tw-ml-\[20px\] {
  margin-left: 20px;
}

.tw-ml-\[32px\] {
  margin-left: 32px;
}

.tw-ml-\[3px\] {
  margin-left: 3px;
}

.tw-ml-\[5px\] {
  margin-left: 5px;
}

.tw-ml-\[8px\] {
  margin-left: 8px;
}

.tw-ml-auto {
  margin-left: auto;
}

.tw-mr-1 {
  margin-right: .25rem;
}

.tw-mr-1\.5 {
  margin-right: .375rem;
}

.tw-mr-14 {
  margin-right: 3.5rem;
}

.tw-mr-3 {
  margin-right: .75rem;
}

.tw-mr-4 {
  margin-right: 1rem;
}

.tw-mr-5 {
  margin-right: 1.25rem;
}

.tw-mr-\[10px\] {
  margin-right: 10px;
}

.tw-mr-\[12px\] {
  margin-right: 12px;
}

.tw-mr-\[14px\] {
  margin-right: 14px;
}

.tw-mr-\[15px\] {
  margin-right: 15px;
}

.tw-mr-\[16px\] {
  margin-right: 16px;
}

.tw-mr-\[20px\] {
  margin-right: 20px;
}

.tw-mr-\[4px\] {
  margin-right: 4px;
}

.tw-mr-\[50px\] {
  margin-right: 50px;
}

.tw-mr-\[5px\] {
  margin-right: 5px;
}

.tw-mr-\[6px\] {
  margin-right: 6px;
}

.tw-mr-auto {
  margin-right: auto;
}

.tw-mt-0 {
  margin-top: 0;
}

.tw-mt-1 {
  margin-top: .25rem;
}

.tw-mt-10 {
  margin-top: 2.5rem;
}

.tw-mt-14 {
  margin-top: 3.5rem;
}

.tw-mt-2 {
  margin-top: .5rem;
}

.tw-mt-24 {
  margin-top: 6rem;
}

.tw-mt-3 {
  margin-top: .75rem;
}

.tw-mt-4 {
  margin-top: 1rem;
}

.tw-mt-5 {
  margin-top: 1.25rem;
}

.tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-mt-8 {
  margin-top: 2rem;
}

.tw-mt-\[-10px\] {
  margin-top: -10px;
}

.tw-mt-\[-15px\] {
  margin-top: -15px;
}

.tw-mt-\[-2px\] {
  margin-top: -2px;
}

.tw-mt-\[-5px\] {
  margin-top: -5px;
}

.tw-mt-\[10px\] {
  margin-top: 10px;
}

.tw-mt-\[112px\] {
  margin-top: 112px;
}

.tw-mt-\[14px\] {
  margin-top: 14px;
}

.tw-mt-\[15\%\] {
  margin-top: 15%;
}

.tw-mt-\[15px\] {
  margin-top: 15px;
}

.tw-mt-\[16px\] {
  margin-top: 16px;
}

.tw-mt-\[18px\] {
  margin-top: 18px;
}

.tw-mt-\[20px\] {
  margin-top: 20px;
}

.tw-mt-\[22px\] {
  margin-top: 22px;
}

.tw-mt-\[24px\] {
  margin-top: 24px;
}

.tw-mt-\[25\%\] {
  margin-top: 25%;
}

.tw-mt-\[25px\] {
  margin-top: 25px;
}

.tw-mt-\[26px\] {
  margin-top: 26px;
}

.tw-mt-\[30px\] {
  margin-top: 30px;
}

.tw-mt-\[32px\] {
  margin-top: 32px;
}

.tw-mt-\[36px\] {
  margin-top: 36px;
}

.tw-mt-\[40px\] {
  margin-top: 40px;
}

.tw-mt-\[4px\] {
  margin-top: 4px;
}

.tw-mt-\[50px\] {
  margin-top: 50px;
}

.tw-mt-\[54px\] {
  margin-top: 54px;
}

.tw-mt-\[56px\] {
  margin-top: 56px;
}

.tw-mt-\[5px\] {
  margin-top: 5px;
}

.tw-mt-\[6px\] {
  margin-top: 6px;
}

.tw-mt-\[78px\] {
  margin-top: 78px;
}

.tw-mt-\[80px\] {
  margin-top: 80px;
}

.tw-mt-\[84px\] {
  margin-top: 84px;
}

.tw-mt-\[8px\] {
  margin-top: 8px;
}

.tw-mt-px {
  margin-top: 1px;
}

.tw-line-clamp-2 {
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-line-clamp-3 {
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-line-clamp-4 {
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-line-clamp-\[15\] {
  -webkit-line-clamp: 15;
  -webkit-box-orient: vertical;
  display: -webkit-box;
  overflow: hidden;
}

.tw-block {
  display: block;
}

.tw-inline-block {
  display: inline-block;
}

.tw-inline {
  display: inline;
}

.\!tw-flex {
  display: flex !important;
}

.tw-flex {
  display: flex;
}

.\!tw-inline-flex {
  display: inline-flex !important;
}

.tw-inline-flex {
  display: inline-flex;
}

.tw-grid {
  display: grid;
}

.tw-list-item {
  display: list-item;
}

.\!tw-hidden {
  display: none !important;
}

.tw-hidden {
  display: none;
}

.\!tw-h-\[41px\] {
  height: 41px !important;
}

.\!tw-h-\[45px\] {
  height: 45px !important;
}

.\!tw-h-\[85px\] {
  height: 85px !important;
}

.\!tw-h-auto {
  height: auto !important;
}

.\!tw-h-full {
  height: 100% !important;
}

.tw-h-14 {
  height: 3.5rem;
}

.tw-h-2 {
  height: .5rem;
}

.tw-h-20 {
  height: 5rem;
}

.tw-h-3 {
  height: .75rem;
}

.tw-h-3\.5 {
  height: .875rem;
}

.tw-h-4 {
  height: 1rem;
}

.tw-h-4\/5 {
  height: 80%;
}

.tw-h-5 {
  height: 1.25rem;
}

.tw-h-6 {
  height: 1.5rem;
}

.tw-h-64 {
  height: 16rem;
}

.tw-h-9 {
  height: 2.25rem;
}

.tw-h-\[--navHeight\] {
  height: var(--navHeight);
}

.tw-h-\[10px\] {
  height: 10px;
}

.tw-h-\[120px\] {
  height: 120px;
}

.tw-h-\[14px\] {
  height: 14px;
}

.tw-h-\[16px\] {
  height: 16px;
}

.tw-h-\[176px\] {
  height: 176px;
}

.tw-h-\[18px\] {
  height: 18px;
}

.tw-h-\[198px\] {
  height: 198px;
}

.tw-h-\[1em\] {
  height: 1em;
}

.tw-h-\[200px\] {
  height: 200px;
}

.tw-h-\[20px\] {
  height: 20px;
}

.tw-h-\[213px\] {
  height: 213px;
}

.tw-h-\[22px\] {
  height: 22px;
}

.tw-h-\[24px\] {
  height: 24px;
}

.tw-h-\[26px\] {
  height: 26px;
}

.tw-h-\[28px\] {
  height: 28px;
}

.tw-h-\[290px\] {
  height: 290px;
}

.tw-h-\[30px\] {
  height: 30px;
}

.tw-h-\[32px\] {
  height: 32px;
}

.tw-h-\[34px\] {
  height: 34px;
}

.tw-h-\[35px\] {
  height: 35px;
}

.tw-h-\[36px\] {
  height: 36px;
}

.tw-h-\[39px\] {
  height: 39px;
}

.tw-h-\[400px\] {
  height: 400px;
}

.tw-h-\[40px\] {
  height: 40px;
}

.tw-h-\[41px\] {
  height: 41px;
}

.tw-h-\[45px\] {
  height: 45px;
}

.tw-h-\[46px\] {
  height: 46px;
}

.tw-h-\[48px\] {
  height: 48px;
}

.tw-h-\[56px\] {
  height: 56px;
}

.tw-h-\[60px\] {
  height: 60px;
}

.tw-h-\[64px\] {
  height: 64px;
}

.tw-h-\[65px\] {
  height: 65px;
}

.tw-h-\[75px\] {
  height: 75px;
}

.tw-h-\[76px\] {
  height: 76px;
}

.tw-h-\[80px\] {
  height: 80px;
}

.tw-h-\[8px\] {
  height: 8px;
}

.tw-h-\[calc\(100\%\+2\.5px\)\] {
  height: calc(100% + 2.5px);
}

.tw-h-\[calc\(100vh-var\(--mobileHeaderHeight\)\)\] {
  height: calc(100vh - var(--mobileHeaderHeight));
}

.tw-h-\[calc\(100vh-var\(--mobileHeaderHeight\)-var\(--mobileMenuHeight\)\)\] {
  height: calc(100vh - var(--mobileHeaderHeight)  - var(--mobileMenuHeight));
}

.tw-h-\[calc\(100vh-var\(--navHeight\)\)\] {
  height: calc(100vh - var(--navHeight));
}

.tw-h-\[calc\(80\%_\*_2\)\] {
  height: 160%;
}

.tw-h-auto {
  height: auto;
}

.tw-h-fit {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}

.tw-h-full {
  height: 100%;
}

.tw-h-px {
  height: 1px;
}

.tw-h-screen {
  height: 100vh;
}

.tw-max-h-\[20px\] {
  max-height: 20px;
}

.tw-max-h-\[80px\] {
  max-height: 80px;
}

.\!tw-min-h-\[155px\] {
  min-height: 155px !important;
}

.\!tw-min-h-\[179px\] {
  min-height: 179px !important;
}

.tw-min-h-0 {
  min-height: 0;
}

.tw-min-h-\[24px\] {
  min-height: 24px;
}

.tw-min-h-\[320px\] {
  min-height: 320px;
}

.tw-min-h-\[46px\] {
  min-height: 46px;
}

.tw-min-h-\[70px\] {
  min-height: 70px;
}

.tw-min-h-\[theme\(screens\.sm\)\] {
  min-height: 640px;
}

.tw-min-h-screen {
  min-height: 100vh;
}

.\!tw-w-0 {
  width: 0 !important;
}

.\!tw-w-\[296px\] {
  width: 296px !important;
}

.\!tw-w-\[51vw\] {
  width: 51vw !important;
}

.\!tw-w-\[85px\] {
  width: 85px !important;
}

.\!tw-w-auto {
  width: auto !important;
}

.\!tw-w-fit {
  width: -webkit-fit-content !important;
  width: -moz-fit-content !important;
  width: fit-content !important;
}

.\!tw-w-full {
  width: 100% !important;
}

.tw-w-1\/2 {
  width: 50%;
}

.tw-w-1\/3 {
  width: 33.3333%;
}

.tw-w-11 {
  width: 2.75rem;
}

.tw-w-2 {
  width: .5rem;
}

.tw-w-2\/3 {
  width: 66.6667%;
}

.tw-w-3 {
  width: .75rem;
}

.tw-w-3\.5 {
  width: .875rem;
}

.tw-w-4 {
  width: 1rem;
}

.tw-w-4\/5 {
  width: 80%;
}

.tw-w-5\/6 {
  width: 83.3333%;
}

.tw-w-52 {
  width: 13rem;
}

.tw-w-6 {
  width: 1.5rem;
}

.tw-w-8 {
  width: 2rem;
}

.tw-w-9 {
  width: 2.25rem;
}

.tw-w-\[100\%\] {
  width: 100%;
}

.tw-w-\[104px\] {
  width: 104px;
}

.tw-w-\[10px\] {
  width: 10px;
}

.tw-w-\[120px\] {
  width: 120px;
}

.tw-w-\[124px\] {
  width: 124px;
}

.tw-w-\[135px\] {
  width: 135px;
}

.tw-w-\[137px\] {
  width: 137px;
}

.tw-w-\[141px\] {
  width: 141px;
}

.tw-w-\[148px\] {
  width: 148px;
}

.tw-w-\[14px\] {
  width: 14px;
}

.tw-w-\[150px\] {
  width: 150px;
}

.tw-w-\[153px\] {
  width: 153px;
}

.tw-w-\[165px\] {
  width: 165px;
}

.tw-w-\[16px\] {
  width: 16px;
}

.tw-w-\[176px\] {
  width: 176px;
}

.tw-w-\[17px\] {
  width: 17px;
}

.tw-w-\[180px\] {
  width: 180px;
}

.tw-w-\[18px\] {
  width: 18px;
}

.tw-w-\[190px\] {
  width: 190px;
}

.tw-w-\[1em\] {
  width: 1em;
}

.tw-w-\[200px\] {
  width: 200px;
}

.tw-w-\[24px\] {
  width: 24px;
}

.tw-w-\[267px\] {
  width: 267px;
}

.tw-w-\[26px\] {
  width: 26px;
}

.tw-w-\[280px\] {
  width: 280px;
}

.tw-w-\[28px\] {
  width: 28px;
}

.tw-w-\[30px\] {
  width: 30px;
}

.tw-w-\[32px\] {
  width: 32px;
}

.tw-w-\[330px\] {
  width: 330px;
}

.tw-w-\[349px\] {
  width: 349px;
}

.tw-w-\[350px\] {
  width: 350px;
}

.tw-w-\[360px\] {
  width: 360px;
}

.tw-w-\[36px\] {
  width: 36px;
}

.tw-w-\[38px\] {
  width: 38px;
}

.tw-w-\[40px\] {
  width: 40px;
}

.tw-w-\[44px\] {
  width: 44px;
}

.tw-w-\[48px\] {
  width: 48px;
}

.tw-w-\[52px\] {
  width: 52px;
}

.tw-w-\[56px\] {
  width: 56px;
}

.tw-w-\[60px\] {
  width: 60px;
}

.tw-w-\[64px\] {
  width: 64px;
}

.tw-w-\[75\%\] {
  width: 75%;
}

.tw-w-\[78px\] {
  width: 78px;
}

.tw-w-\[80\%\] {
  width: 80%;
}

.tw-w-\[8px\] {
  width: 8px;
}

.tw-w-\[90px\] {
  width: 90px;
}

.tw-w-\[95\%\] {
  width: 95%;
}

.tw-w-\[96px\] {
  width: 96px;
}

.tw-w-\[calc\(100\%\+2\.5px\)\] {
  width: calc(100% + 2.5px);
}

.tw-w-\[calc\(100\%-100px\)\] {
  width: calc(100% - 100px);
}

.tw-w-\[calc\(100\%-10px\)\] {
  width: calc(100% - 10px);
}

.tw-w-\[calc\(100\%-20px\)\] {
  width: calc(100% - 20px);
}

.tw-w-\[calc\(80\%_\*_2\)\] {
  width: 160%;
}

.tw-w-auto {
  width: auto;
}

.tw-w-fit {
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}

.tw-w-full {
  width: 100%;
}

.tw-w-screen {
  width: 100vw;
}

.\!tw-min-w-0 {
  min-width: 0 !important;
}

.\!tw-min-w-\[140px\] {
  min-width: 140px !important;
}

.tw-min-w-0 {
  min-width: 0;
}

.tw-min-w-\[17px\] {
  min-width: 17px;
}

.tw-min-w-\[30px\] {
  min-width: 30px;
}

.tw-min-w-\[50px\] {
  min-width: 50px;
}

.tw-min-w-\[60px\] {
  min-width: 60px;
}

.tw-min-w-\[70px\] {
  min-width: 70px;
}

.tw-min-w-\[72px\] {
  min-width: 72px;
}

.tw-min-w-\[90px\] {
  min-width: 90px;
}

.\!tw-max-w-full {
  max-width: 100% !important;
}

.tw-max-w-2xl {
  max-width: 42rem;
}

.tw-max-w-5xl {
  max-width: 64rem;
}

.tw-max-w-\[120px\] {
  max-width: 120px;
}

.tw-max-w-\[140px\] {
  max-width: 140px;
}

.tw-max-w-\[147px\] {
  max-width: 147px;
}

.tw-max-w-\[152px\] {
  max-width: 152px;
}

.tw-max-w-\[180px\] {
  max-width: 180px;
}

.tw-max-w-\[20px\] {
  max-width: 20px;
}

.tw-max-w-\[400px\] {
  max-width: 400px;
}

.tw-max-w-\[470px\] {
  max-width: 470px;
}

.tw-max-w-\[48px\] {
  max-width: 48px;
}

.tw-max-w-\[50px\] {
  max-width: 50px;
}

.tw-max-w-\[64px\] {
  max-width: 64px;
}

.tw-max-w-\[768px\] {
  max-width: 768px;
}

.tw-max-w-\[94vw\] {
  max-width: 94vw;
}

.tw-max-w-full {
  max-width: 100%;
}

.tw-max-w-lg {
  max-width: 32rem;
}

.tw-max-w-none {
  max-width: none;
}

.tw-flex-1 {
  flex: 1;
}

.tw-flex-\[1_0_0\%\] {
  flex: 1 0;
}

.tw-flex-auto {
  flex: auto;
}

.tw-flex-none {
  flex: none;
}

.tw-flex-shrink-0, .tw-shrink-0 {
  flex-shrink: 0;
}

.\!tw-flex-grow {
  flex-grow: 1 !important;
}

.tw-flex-grow, .tw-grow {
  flex-grow: 1;
}

.tw-grow-0 {
  flex-grow: 0;
}

.tw-basis-1\/3 {
  flex-basis: 33.3333%;
}

.tw-basis-1\/4 {
  flex-basis: 25%;
}

.tw-table-auto {
  table-layout: auto;
}

.tw-origin-\[calc\(50\%_\+_400px\)\] {
  -webkit-transform-origin: calc(50% + 400px);
  transform-origin: calc(50% + 400px);
}

.tw-origin-\[calc\(50\%_-_200px\)\] {
  -webkit-transform-origin: calc(50% - 200px);
  transform-origin: calc(50% - 200px);
}

.tw-origin-\[calc\(50\%_-_400px\)\] {
  -webkit-transform-origin: calc(50% - 400px);
  transform-origin: calc(50% - 400px);
}

.tw-origin-\[calc\(50\%_-_800px\)_calc\(50\%_\+_200px\)\] {
  -webkit-transform-origin: calc(50% - 800px) calc(50% + 200px);
  transform-origin: calc(50% - 800px) calc(50% + 200px);
}

.tw-origin-\[center_center\], .tw-origin-center {
  -webkit-transform-origin: center;
  transform-origin: center;
}

.-tw-translate-x-1\/2 {
  --tw-translate-x: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-x-1\/2 {
  --tw-translate-x: 50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-translate-y-2 {
  --tw-translate-y: .5rem;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.-tw-rotate-90 {
  --tw-rotate: -90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-rotate-\[15deg\] {
  --tw-rotate: 15deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-transform {
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-animate-\[backgroundTransition_20s_ease-in-out_infinite_alternate\] {
  -webkit-animation: 20s ease-in-out infinite alternate backgroundTransition;
  animation: 20s ease-in-out infinite alternate backgroundTransition;
}

.tw-animate-\[confetti-circle-tr_2s_linear\] {
  -webkit-animation: 2s linear confetti-circle-tr;
  animation: 2s linear confetti-circle-tr;
}

.tw-animate-\[confetti-circle_2s_linear\] {
  -webkit-animation: 2s linear confetti-circle;
  animation: 2s linear confetti-circle;
}

.tw-animate-\[confetti-square_2s_linear\] {
  -webkit-animation: 2s linear confetti-square;
  animation: 2s linear confetti-square;
}

.tw-animate-\[moveHorizontal_40s_ease_infinite\] {
  -webkit-animation: 40s infinite moveHorizontal;
  animation: 40s infinite moveHorizontal;
}

.tw-animate-\[moveInCircle_20s_ease_infinite\] {
  -webkit-animation: 20s infinite moveInCircle;
  animation: 20s infinite moveInCircle;
}

.tw-animate-\[moveInCircle_20s_reverse_infinite\] {
  -webkit-animation: 20s infinite reverse moveInCircle;
  animation: 20s infinite reverse moveInCircle;
}

.tw-animate-\[moveInCircle_40s_linear_infinite\] {
  -webkit-animation: 40s linear infinite moveInCircle;
  animation: 40s linear infinite moveInCircle;
}

.tw-animate-\[moveVertical_30s_ease_infinite\] {
  -webkit-animation: 30s infinite moveVertical;
  animation: 30s infinite moveVertical;
}

.tw-animate-\[puzzle-left_2s_linear\] {
  -webkit-animation: 2s linear puzzle-left;
  animation: 2s linear puzzle-left;
}

.tw-animate-\[puzzle-right_2s_linear\] {
  -webkit-animation: 2s linear puzzle-right;
  animation: 2s linear puzzle-right;
}

.tw-animate-\[rotation_600s_infinite_linear\] {
  -webkit-animation: 600s linear infinite rotation;
  animation: 600s linear infinite rotation;
}

@-webkit-keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes tw-spin {
  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

.tw-animate-spin {
  -webkit-animation: 1s linear infinite tw-spin;
  animation: 1s linear infinite tw-spin;
}

.\!tw-cursor-not-allowed {
  cursor: not-allowed !important;
}

.tw-cursor-default {
  cursor: default;
}

.tw-cursor-not-allowed {
  cursor: not-allowed;
}

.tw-cursor-pointer {
  cursor: pointer;
}

.tw-select-none {
  -webkit-user-select: none;
  user-select: none;
}

.\!tw-resize-none {
  resize: none !important;
}

.tw-resize-none {
  resize: none;
}

.tw-scroll-my-\[65px\] {
  scroll-margin-top: 65px;
  scroll-margin-bottom: 65px;
}

.tw-list-outside {
  list-style-position: outside;
}

.tw-list-\[lower-alpha\] {
  list-style-type: lower-alpha;
}

.tw-list-decimal {
  list-style-type: decimal;
}

.tw-list-disc {
  list-style-type: disc;
}

.tw-list-none {
  list-style-type: none;
}

.tw-appearance-none {
  -webkit-appearance: none;
  appearance: none;
}

.tw-columns-2 {
  columns: 2;
}

.tw-grid-flow-row {
  grid-auto-flow: row;
}

.tw-grid-flow-col {
  grid-auto-flow: column;
}

.tw-grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.tw-grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.tw-grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.tw-grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.tw-grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.tw-grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.tw-grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.tw-grid-cols-\[25px\,1fr\] {
  grid-template-columns: 25px 1fr;
}

.tw-grid-cols-\[auto_1fr\] {
  grid-template-columns: auto 1fr;
}

.tw-flex-row {
  flex-direction: row;
}

.\!tw-flex-col {
  flex-direction: column !important;
}

.tw-flex-col {
  flex-direction: column;
}

.tw-flex-col-reverse {
  flex-direction: column-reverse;
}

.tw-flex-wrap {
  flex-wrap: wrap;
}

.tw-flex-nowrap {
  flex-wrap: nowrap;
}

.tw-content-center {
  align-content: center;
}

.\!tw-items-start {
  align-items: flex-start !important;
}

.tw-items-start {
  align-items: flex-start;
}

.tw-items-end {
  align-items: flex-end;
}

.tw-items-center {
  align-items: center;
}

.tw-items-baseline {
  align-items: baseline;
}

.tw-justify-start {
  justify-content: flex-start;
}

.tw-justify-end {
  justify-content: flex-end;
}

.tw-justify-center {
  justify-content: center;
}

.tw-justify-between {
  justify-content: space-between;
}

.tw-justify-around {
  justify-content: space-around;
}

.\!tw-gap-\[12px\] {
  gap: 12px !important;
}

.tw-gap-1 {
  gap: .25rem;
}

.tw-gap-10 {
  gap: 2.5rem;
}

.tw-gap-2 {
  gap: .5rem;
}

.tw-gap-3 {
  gap: .75rem;
}

.tw-gap-4 {
  gap: 1rem;
}

.tw-gap-6 {
  gap: 1.5rem;
}

.tw-gap-8 {
  gap: 2rem;
}

.tw-gap-\[10px\] {
  gap: 10px;
}

.tw-gap-\[12px\] {
  gap: 12px;
}

.tw-gap-\[15px\] {
  gap: 15px;
}

.tw-gap-\[16px\] {
  gap: 16px;
}

.tw-gap-\[18px\] {
  gap: 18px;
}

.tw-gap-\[20px\] {
  gap: 20px;
}

.tw-gap-\[22px\] {
  gap: 22px;
}

.tw-gap-\[24px\] {
  gap: 24px;
}

.tw-gap-\[25px\] {
  gap: 25px;
}

.tw-gap-\[2px\] {
  gap: 2px;
}

.tw-gap-\[32px\] {
  gap: 32px;
}

.tw-gap-\[3px\] {
  gap: 3px;
}

.tw-gap-\[4px\] {
  gap: 4px;
}

.tw-gap-\[5px\] {
  gap: 5px;
}

.tw-gap-\[7px\] {
  gap: 7px;
}

.tw-gap-\[8px\] {
  gap: 8px;
}

.tw-gap-px {
  gap: 1px;
}

.tw-gap-x-\[10px\] {
  column-gap: 10px;
}

.tw-gap-x-\[24px\] {
  column-gap: 24px;
}

.tw-gap-x-\[32px\] {
  column-gap: 32px;
}

.tw-gap-x-\[4px\] {
  column-gap: 4px;
}

.tw-gap-x-\[5px\] {
  column-gap: 5px;
}

.tw-gap-x-\[72px\] {
  column-gap: 72px;
}

.tw-gap-x-\[8px\] {
  column-gap: 8px;
}

.tw-gap-y-2 {
  row-gap: .5rem;
}

.tw-gap-y-2\.5 {
  row-gap: .625rem;
}

.tw-gap-y-\[15px\] {
  row-gap: 15px;
}

.tw-gap-y-\[16px\] {
  row-gap: 16px;
}

.tw-gap-y-\[26px\] {
  row-gap: 26px;
}

.tw-gap-y-\[5px\] {
  row-gap: 5px;
}

.tw-gap-y-\[8px\] {
  row-gap: 8px;
}

.tw-space-x-\[4px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(4px * var(--tw-space-x-reverse));
  margin-left: calc(4px * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-\[6px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(6px * var(--tw-space-x-reverse));
  margin-left: calc(6px * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-x-\[8px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-x-reverse: 0;
  margin-right: calc(8px * var(--tw-space-x-reverse));
  margin-left: calc(8px * calc(1 - var(--tw-space-x-reverse)));
}

.tw-space-y-8 > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(2rem * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(2rem * var(--tw-space-y-reverse));
}

.tw-space-y-\[24px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(24px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(24px * var(--tw-space-y-reverse));
}

.tw-space-y-\[4px\] > :not([hidden]) ~ :not([hidden]) {
  --tw-space-y-reverse: 0;
  margin-top: calc(4px * calc(1 - var(--tw-space-y-reverse)));
  margin-bottom: calc(4px * var(--tw-space-y-reverse));
}

.tw-divide-y > :not([hidden]) ~ :not([hidden]) {
  --tw-divide-y-reverse: 0;
  border-top-width: calc(1px * calc(1 - var(--tw-divide-y-reverse)));
  border-bottom-width: calc(1px * var(--tw-divide-y-reverse));
}

.tw-divide-gray-300 > :not([hidden]) ~ :not([hidden]) {
  border-color: var(--token-gray-300);
}

.tw-self-auto {
  align-self: auto;
}

.tw-self-end {
  align-self: flex-end;
}

.tw-self-center {
  align-self: center;
}

.tw-self-stretch {
  align-self: stretch;
}

.tw-overflow-auto {
  overflow: auto;
}

.tw-overflow-hidden {
  overflow: hidden;
}

.tw-overflow-visible {
  overflow: visible;
}

.tw-overflow-y-auto {
  overflow-y: auto;
}

.tw-overflow-y-hidden {
  overflow-y: hidden;
}

.tw-overflow-x-scroll {
  overflow-x: scroll;
}

.tw-overflow-y-scroll {
  overflow-y: scroll;
}

.tw-scroll-smooth {
  scroll-behavior: smooth;
}

.tw-truncate {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.tw-text-ellipsis {
  text-overflow: ellipsis;
}

.tw-hyphens-none {
  -webkit-hyphens: none;
  hyphens: none;
}

.\!tw-whitespace-normal {
  white-space: normal !important;
}

.tw-whitespace-nowrap {
  white-space: nowrap;
}

.tw-whitespace-pre-wrap {
  white-space: pre-wrap;
}

.tw-break-words {
  overflow-wrap: break-word;
}

.\!tw-rounded-\[10px\] {
  border-radius: 10px !important;
}

.\!tw-rounded-\[5px\] {
  border-radius: 5px !important;
}

.\!tw-rounded-lg {
  border-radius: .5rem !important;
}

.tw-rounded {
  border-radius: 5px;
}

.tw-rounded-2xl {
  border-radius: 1rem;
}

.tw-rounded-\[10px\] {
  border-radius: 10px;
}

.tw-rounded-\[20px\] {
  border-radius: 20px;
}

.tw-rounded-\[22px\] {
  border-radius: 22px;
}

.tw-rounded-\[24px\] {
  border-radius: 24px;
}

.tw-rounded-\[2px\] {
  border-radius: 2px;
}

.tw-rounded-\[3px\] {
  border-radius: 3px;
}

.tw-rounded-\[4px\] {
  border-radius: 4px;
}

.tw-rounded-\[5px\] {
  border-radius: 5px;
}

.tw-rounded-\[7px\] {
  border-radius: 7px;
}

.tw-rounded-full {
  border-radius: 9999px;
}

.tw-rounded-lg {
  border-radius: .5rem;
}

.tw-rounded-lg\.5 {
  border-radius: 10px;
}

.tw-rounded-md {
  border-radius: .375rem;
}

.tw-rounded-none {
  border-radius: 0;
}

.tw-rounded-sm {
  border-radius: .125rem;
}

.tw-rounded-b-\[10px\] {
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.tw-rounded-b-\[5px\] {
  border-bottom-right-radius: 5px;
  border-bottom-left-radius: 5px;
}

.tw-rounded-l-none {
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.tw-rounded-t-\[10px\] {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}

.tw-rounded-t-xl {
  border-top-left-radius: .75rem;
  border-top-right-radius: .75rem;
}

.tw-rounded-tr-full {
  border-top-right-radius: 9999px;
}

.\!tw-border-0 {
  border-width: 0 !important;
}

.tw-border {
  border-width: 1px;
}

.tw-border-0 {
  border-width: 0;
}

.tw-border-2 {
  border-width: 2px;
}

.tw-border-\[1px\] {
  border-width: 1px;
}

.tw-border-\[2px\] {
  border-width: 2px;
}

.tw-border-x, .tw-border-x-\[1px\] {
  border-left-width: 1px;
  border-right-width: 1px;
}

.tw-border-y {
  border-top-width: 1px;
  border-bottom-width: 1px;
}

.tw-border-y-0 {
  border-top-width: 0;
  border-bottom-width: 0;
}

.\!tw-border-b {
  border-bottom-width: 1px !important;
}

.\!tw-border-t {
  border-top-width: 1px !important;
}

.\!tw-border-t-0 {
  border-top-width: 0 !important;
}

.tw-border-b {
  border-bottom-width: 1px;
}

.tw-border-b-0 {
  border-bottom-width: 0;
}

.tw-border-b-4 {
  border-bottom-width: 4px;
}

.tw-border-l {
  border-left-width: 1px;
}

.tw-border-l-0 {
  border-left-width: 0;
}

.tw-border-l-4 {
  border-left-width: 4px;
}

.tw-border-l-\[5px\] {
  border-left-width: 5px;
}

.tw-border-r {
  border-right-width: 1px;
}

.tw-border-r-0 {
  border-right-width: 0;
}

.tw-border-t {
  border-top-width: 1px;
}

.tw-border-t-0 {
  border-top-width: 0;
}

.tw-border-t-4 {
  border-top-width: 4px;
}

.\!tw-border-solid {
  border-style: solid !important;
}

.tw-border-solid {
  border-style: solid;
}

.\!tw-border-none {
  border-style: none !important;
}

.tw-border-none {
  border-style: none;
}

.\!tw-border-\[\#6E716E\] {
  --tw-border-opacity: 1 !important;
  border-color: rgba(110, 113, 110, var(--tw-border-opacity)) !important;
}

.\!tw-border-gray-200 {
  border-color: var(--token-gray-200) !important;
}

.\!tw-border-gray-700 {
  border-color: var(--token-gray-700) !important;
}

.\!tw-border-site-gray-light {
  border-color: var(--token-site-gray-light) !important;
}

.tw-border-\[\#5CB85C\] {
  --tw-border-opacity: 1;
  border-color: rgba(92, 184, 92, var(--tw-border-opacity));
}

.tw-border-\[\#5FAA41\] {
  --tw-border-opacity: 1;
  border-color: rgba(95, 170, 65, var(--tw-border-opacity));
}

.tw-border-\[\#9ADBE9\] {
  --tw-border-opacity: 1;
  border-color: rgba(154, 219, 233, var(--tw-border-opacity));
}

.tw-border-\[\#A6DE9B\] {
  --tw-border-opacity: 1;
  border-color: rgba(166, 222, 155, var(--tw-border-opacity));
}

.tw-border-\[\#C1DEB4\] {
  --tw-border-opacity: 1;
  border-color: rgba(193, 222, 180, var(--tw-border-opacity));
}

.tw-border-\[\#D9D9D9\] {
  --tw-border-opacity: 1;
  border-color: rgba(217, 217, 217, var(--tw-border-opacity));
}

.tw-border-\[\#DCE2DA\] {
  --tw-border-opacity: 1;
  border-color: rgba(220, 226, 218, var(--tw-border-opacity));
}

.tw-border-\[\#DDDDDD\] {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.tw-border-\[\#DEF6D9\] {
  --tw-border-opacity: 1;
  border-color: rgba(222, 246, 217, var(--tw-border-opacity));
}

.tw-border-\[\#EBCCD1\] {
  --tw-border-opacity: 1;
  border-color: rgba(235, 204, 209, var(--tw-border-opacity));
}

.tw-border-\[\#F7F0E6\] {
  --tw-border-opacity: 1;
  border-color: rgba(247, 240, 230, var(--tw-border-opacity));
}

.tw-border-\[\#FAEBCC\] {
  --tw-border-opacity: 1;
  border-color: rgba(250, 235, 204, var(--tw-border-opacity));
}

.tw-border-\[\#FFDE75\] {
  --tw-border-opacity: 1;
  border-color: rgba(255, 222, 117, var(--tw-border-opacity));
}

.tw-border-\[\#ccc\] {
  --tw-border-opacity: 1;
  border-color: rgba(204, 204, 204, var(--tw-border-opacity));
}

.tw-border-\[\#ddd\] {
  --tw-border-opacity: 1;
  border-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.tw-border-blue-100 {
  border-color: var(--token-blue-100);
}

.tw-border-border-strong {
  border-color: var(--token-border-strong);
}

.tw-border-gray-100 {
  border-color: var(--token-gray-100);
}

.tw-border-gray-200 {
  border-color: var(--token-gray-200);
}

.tw-border-gray-300 {
  border-color: var(--token-gray-300);
}

.tw-border-gray-400 {
  border-color: var(--token-gray-400);
}

.tw-border-gray-500 {
  border-color: var(--token-gray-500);
}

.tw-border-gray-600 {
  border-color: var(--token-gray-600);
}

.tw-border-green-500 {
  border-color: var(--token-green-500);
}

.tw-border-secondary-300 {
  border-color: var(--token-secondary-300);
}

.tw-border-site-gray {
  border-color: var(--token-site-gray);
}

.tw-border-site-gray-accent {
  border-color: var(--token-site-gray-accent);
}

.tw-border-site-gray-dark {
  border-color: var(--token-site-gray-dark);
}

.tw-border-site-gray-darkaccent {
  border-color: var(--token-site-gray-darkaccent);
}

.tw-border-site-gray-light {
  border-color: var(--token-site-gray-light);
}

.tw-border-site-gray-lighter {
  border-color: var(--token-site-gray-lighter);
}

.tw-border-site-gray-medium {
  border-color: var(--token-site-gray-medium);
}

.tw-border-site-green {
  border-color: var(--token-site-green);
}

.tw-border-site-lightblue {
  border-color: var(--token-site-lightblue);
}

.tw-border-text {
  border-color: var(--token-text-default);
}

.tw-border-transparent {
  border-color: rgba(0, 0, 0, 0);
}

.tw-border-white {
  --tw-border-opacity: 1;
  border-color: rgba(255, 255, 255, var(--tw-border-opacity));
}

.tw-border-yellow-200 {
  border-color: var(--token-yellow-200);
}

.\!tw-border-t-\[\#eee\] {
  --tw-border-opacity: 1 !important;
  border-top-color: rgba(238, 238, 238, var(--tw-border-opacity)) !important;
}

.tw-border-b-black {
  --tw-border-opacity: 1;
  border-bottom-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.tw-border-b-gray-300 {
  border-bottom-color: var(--token-gray-300);
}

.tw-border-l-green-500 {
  border-left-color: var(--token-green-500);
}

.tw-border-l-system-decorative-200 {
  border-left-color: var(--token-system-decorative-200);
}

.tw-border-l-system-info-300 {
  border-left-color: var(--token-system-info-300);
}

.tw-border-l-system-success-200 {
  border-left-color: var(--token-system-success-200);
}

.tw-border-t-black {
  --tw-border-opacity: 1;
  border-top-color: rgba(0, 0, 0, var(--tw-border-opacity));
}

.tw-border-t-site-gray-light {
  border-top-color: var(--token-site-gray-light);
}

.\!tw-bg-bg-light {
  background-color: var(--token-bg-light) !important;
}

.\!tw-bg-blue-50 {
  background-color: var(--token-blue-50) !important;
}

.\!tw-bg-gray-100 {
  background-color: var(--token-gray-100) !important;
}

.\!tw-bg-green-300 {
  background-color: var(--token-green-300) !important;
}

.\!tw-bg-site-gray-bg {
  background-color: var(--token-site-gray-bg) !important;
}

.\!tw-bg-white {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.tw-bg-\[\#444\] {
  --tw-bg-opacity: 1;
  background-color: rgba(68, 68, 68, var(--tw-bg-opacity));
}

.tw-bg-\[\#5FAA41\] {
  --tw-bg-opacity: 1;
  background-color: rgba(95, 170, 65, var(--tw-bg-opacity));
}

.tw-bg-\[\#9AC4F3\] {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 196, 243, var(--tw-bg-opacity));
}

.tw-bg-\[\#9ADBE9\] {
  --tw-bg-opacity: 1;
  background-color: rgba(154, 219, 233, var(--tw-bg-opacity));
}

.tw-bg-\[\#A1D0CE\] {
  --tw-bg-opacity: 1;
  background-color: rgba(161, 208, 206, var(--tw-bg-opacity));
}

.tw-bg-\[\#AF2225\] {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 34, 37, var(--tw-bg-opacity));
}

.tw-bg-\[\#C6EDEF\] {
  --tw-bg-opacity: 1;
  background-color: rgba(198, 237, 239, var(--tw-bg-opacity));
}

.tw-bg-\[\#D9D9D9\] {
  --tw-bg-opacity: 1;
  background-color: rgba(217, 217, 217, var(--tw-bg-opacity));
}

.tw-bg-\[\#E1EEDA\] {
  --tw-bg-opacity: 1;
  background-color: rgba(225, 238, 218, var(--tw-bg-opacity));
}

.tw-bg-\[\#E2F8F9\] {
  --tw-bg-opacity: 1;
  background-color: rgba(226, 248, 249, var(--tw-bg-opacity));
}

.tw-bg-\[\#EBF5E6\] {
  --tw-bg-opacity: 1;
  background-color: rgba(235, 245, 230, var(--tw-bg-opacity));
}

.tw-bg-\[\#EFF9ED\] {
  --tw-bg-opacity: 1;
  background-color: rgba(239, 249, 237, var(--tw-bg-opacity));
}

.tw-bg-\[\#F2DEDE\] {
  --tw-bg-opacity: 1;
  background-color: rgba(242, 222, 222, var(--tw-bg-opacity));
}

.tw-bg-\[\#F5F5F5\] {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.tw-bg-\[\#FCF8E3\] {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 248, 227, var(--tw-bg-opacity));
}

.tw-bg-\[\#FFD600\] {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 214, 0, var(--tw-bg-opacity));
}

.tw-bg-\[\#af2225\] {
  --tw-bg-opacity: 1;
  background-color: rgba(175, 34, 37, var(--tw-bg-opacity));
}

.tw-bg-\[\#eeeeee\] {
  --tw-bg-opacity: 1;
  background-color: rgba(238, 238, 238, var(--tw-bg-opacity));
}

.tw-bg-\[\#f5f5f5\] {
  --tw-bg-opacity: 1;
  background-color: rgba(245, 245, 245, var(--tw-bg-opacity));
}

.tw-bg-\[\#fcf8e3\] {
  --tw-bg-opacity: 1;
  background-color: rgba(252, 248, 227, var(--tw-bg-opacity));
}

.tw-bg-\[rgba\(161\,204\,144\,0\.3\)\] {
  background-color: rgba(161, 204, 144, .3);
}

.tw-bg-\[rgba\(255\,222\,117\,0\.3\)\] {
  background-color: rgba(255, 222, 117, .3);
}

.tw-bg-\[rgba\(92\,184\,92\,0\.3\)\] {
  background-color: rgba(92, 184, 92, .3);
}

.tw-bg-bg {
  background-color: var(--token-bg-default);
}

.tw-bg-bg-btnPrimary {
  background-color: var(--token-bg-btnPrimary-default);
}

.tw-bg-bg-danger {
  background-color: var(--token-bg-danger);
}

.tw-bg-bg-dark {
  background-color: var(--token-bg-dark);
}

.tw-bg-bg-darkest {
  background-color: var(--token-bg-darkest);
}

.tw-bg-bg-light {
  background-color: var(--token-bg-light);
}

.tw-bg-bg-lightest {
  background-color: var(--token-bg-lightest);
}

.tw-bg-bg-success {
  background-color: var(--token-bg-success);
}

.tw-bg-gray-100 {
  background-color: var(--token-gray-100);
}

.tw-bg-gray-50 {
  background-color: var(--token-gray-50);
}

.tw-bg-green-200 {
  background-color: var(--token-green-200);
}

.tw-bg-green-50 {
  background-color: var(--token-green-50);
}

.tw-bg-green-500 {
  background-color: var(--token-green-500);
}

.tw-bg-green-700 {
  background-color: var(--token-green-700);
}

.tw-bg-inherit {
  background-color: inherit;
}

.tw-bg-primary-100 {
  background-color: var(--token-primary-100);
}

.tw-bg-primary-500 {
  background-color: var(--token-primary-500);
}

.tw-bg-secondary-100 {
  background-color: var(--token-secondary-100);
}

.tw-bg-secondary-50 {
  background-color: var(--token-secondary-50);
}

.tw-bg-site-cta-bg {
  background-color: var(--token-site-cta-bg);
}

.tw-bg-site-gray {
  background-color: var(--token-site-gray);
}

.tw-bg-site-gray-base {
  background-color: var(--token-site-gray-base);
}

.tw-bg-site-gray-bg {
  background-color: var(--token-site-gray-bg);
}

.tw-bg-site-gray-darkaccent {
  background-color: var(--token-site-gray-darkaccent);
}

.tw-bg-site-gray-light {
  background-color: var(--token-site-gray-light);
}

.tw-bg-site-gray-lighter {
  background-color: var(--token-site-gray-lighter);
}

.tw-bg-site-green {
  background-color: var(--token-site-green);
}

.tw-bg-site-green-transparent {
  background-color: var(--token-site-green-transparent);
}

.tw-bg-site-lightblue {
  background-color: var(--token-site-lightblue);
}

.tw-bg-system-danger-600 {
  background-color: var(--token-system-danger-600);
}

.tw-bg-system-decorative-100 {
  background-color: var(--token-system-decorative-100);
}

.tw-bg-system-info-200 {
  background-color: var(--token-system-info-200);
}

.tw-bg-system-success-100 {
  background-color: var(--token-system-success-100);
}

.tw-bg-system-warning-300 {
  background-color: var(--token-system-warning-300);
}

.tw-bg-transparent {
  background-color: rgba(0, 0, 0, 0);
}

.tw-bg-white {
  --tw-bg-opacity: 1;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
}

.tw-bg-yellow-100 {
  background-color: var(--token-yellow-100);
}

.tw-bg-yellow-50 {
  background-color: var(--token-yellow-50);
}

.tw-bg-gradient-srv2-border {
  background-image: linear-gradient(#f18ddb, #ff84aa, #ff8f74, #faa847, #ff8988, #f48aca, #a89dec, #d586d0, #f171a2, #f56a6a);
}

.tw-bg-gradient-to-b {
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.tw-from-bg {
  --tw-gradient-from: var(--token-bg-default) var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(255, 255, 255, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tw-from-bg-dark {
  --tw-gradient-from: var(--token-bg-dark) var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(255, 255, 255, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.tw-to-bg {
  --tw-gradient-to: var(--token-bg-default) var(--tw-gradient-to-position);
}

.tw-to-bg-dark {
  --tw-gradient-to: var(--token-bg-dark) var(--tw-gradient-to-position);
}

.tw-object-center {
  object-position: center;
}

.\!tw-p-0 {
  padding: 0 !important;
}

.\!tw-p-\[10px\] {
  padding: 10px !important;
}

.\!tw-p-\[16px\] {
  padding: 16px !important;
}

.\!tw-p-\[20px\] {
  padding: 20px !important;
}

.\!tw-p-\[24px\] {
  padding: 24px !important;
}

.\!tw-p-\[7px\] {
  padding: 7px !important;
}

.tw-p-0 {
  padding: 0;
}

.tw-p-10 {
  padding: 2.5rem;
}

.tw-p-2 {
  padding: .5rem;
}

.tw-p-3 {
  padding: .75rem;
}

.tw-p-4 {
  padding: 1rem;
}

.tw-p-5 {
  padding: 1.25rem;
}

.tw-p-6 {
  padding: 1.5rem;
}

.tw-p-8 {
  padding: 2rem;
}

.tw-p-\[1\.6rem\] {
  padding: 1.6rem;
}

.tw-p-\[10px\] {
  padding: 10px;
}

.tw-p-\[11px\] {
  padding: 11px;
}

.tw-p-\[15px\] {
  padding: 15px;
}

.tw-p-\[16px\] {
  padding: 16px;
}

.tw-p-\[20px\] {
  padding: 20px;
}

.tw-p-\[24px\] {
  padding: 24px;
}

.tw-p-\[25px\] {
  padding: 25px;
}

.tw-p-\[30px\] {
  padding: 30px;
}

.tw-p-\[40px\] {
  padding: 40px;
}

.tw-p-\[5px\] {
  padding: 5px;
}

.tw-p-\[8px\] {
  padding: 8px;
}

.tw-p-\[9px\] {
  padding: 9px;
}

.\!tw-px-0 {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!tw-px-4 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.\!tw-px-\[0px\] {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.\!tw-px-\[15px\] {
  padding-left: 15px !important;
  padding-right: 15px !important;
}

.\!tw-px-\[16px\] {
  padding-left: 16px !important;
  padding-right: 16px !important;
}

.\!tw-px-\[20px\] {
  padding-left: 20px !important;
  padding-right: 20px !important;
}

.\!tw-px-\[2px\] {
  padding-left: 2px !important;
  padding-right: 2px !important;
}

.\!tw-py-2 {
  padding-top: .5rem !important;
  padding-bottom: .5rem !important;
}

.\!tw-py-\[11px\] {
  padding-top: 11px !important;
  padding-bottom: 11px !important;
}

.\!tw-py-\[15px\] {
  padding-top: 15px !important;
  padding-bottom: 15px !important;
}

.\!tw-py-\[20px\] {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
}

.\!tw-py-\[40px\] {
  padding-top: 40px !important;
  padding-bottom: 40px !important;
}

.\!tw-py-\[8px\] {
  padding-top: 8px !important;
  padding-bottom: 8px !important;
}

.tw-px-0 {
  padding-left: 0;
  padding-right: 0;
}

.tw-px-1 {
  padding-left: .25rem;
  padding-right: .25rem;
}

.tw-px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.tw-px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.tw-px-2 {
  padding-left: .5rem;
  padding-right: .5rem;
}

.tw-px-3 {
  padding-left: .75rem;
  padding-right: .75rem;
}

.tw-px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.tw-px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.tw-px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.tw-px-\[10px\] {
  padding-left: 10px;
  padding-right: 10px;
}

.tw-px-\[12px\] {
  padding-left: 12px;
  padding-right: 12px;
}

.tw-px-\[14px\] {
  padding-left: 14px;
  padding-right: 14px;
}

.tw-px-\[15px\] {
  padding-left: 15px;
  padding-right: 15px;
}

.tw-px-\[16px\] {
  padding-left: 16px;
  padding-right: 16px;
}

.tw-px-\[20px\] {
  padding-left: 20px;
  padding-right: 20px;
}

.tw-px-\[24px\] {
  padding-left: 24px;
  padding-right: 24px;
}

.tw-px-\[25px\] {
  padding-left: 25px;
  padding-right: 25px;
}

.tw-px-\[2px\] {
  padding-left: 2px;
  padding-right: 2px;
}

.tw-px-\[32px\] {
  padding-left: 32px;
  padding-right: 32px;
}

.tw-px-\[3px\] {
  padding-left: 3px;
  padding-right: 3px;
}

.tw-px-\[4px\] {
  padding-left: 4px;
  padding-right: 4px;
}

.tw-px-\[5px\] {
  padding-left: 5px;
  padding-right: 5px;
}

.tw-px-\[8px\] {
  padding-left: 8px;
  padding-right: 8px;
}

.tw-py-0 {
  padding-top: 0;
  padding-bottom: 0;
}

.tw-py-0\.5 {
  padding-top: .125rem;
  padding-bottom: .125rem;
}

.tw-py-1 {
  padding-top: .25rem;
  padding-bottom: .25rem;
}

.tw-py-1\.5 {
  padding-top: .375rem;
  padding-bottom: .375rem;
}

.tw-py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.tw-py-2 {
  padding-top: .5rem;
  padding-bottom: .5rem;
}

.tw-py-2\.5 {
  padding-top: .625rem;
  padding-bottom: .625rem;
}

.tw-py-3 {
  padding-top: .75rem;
  padding-bottom: .75rem;
}

.tw-py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.tw-py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.tw-py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.tw-py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.tw-py-\[10px\] {
  padding-top: 10px;
  padding-bottom: 10px;
}

.tw-py-\[11px\] {
  padding-top: 11px;
  padding-bottom: 11px;
}

.tw-py-\[12px\] {
  padding-top: 12px;
  padding-bottom: 12px;
}

.tw-py-\[13px\] {
  padding-top: 13px;
  padding-bottom: 13px;
}

.tw-py-\[14px\] {
  padding-top: 14px;
  padding-bottom: 14px;
}

.tw-py-\[15px\] {
  padding-top: 15px;
  padding-bottom: 15px;
}

.tw-py-\[1px\] {
  padding-top: 1px;
  padding-bottom: 1px;
}

.tw-py-\[20px\] {
  padding-top: 20px;
  padding-bottom: 20px;
}

.tw-py-\[24px\] {
  padding-top: 24px;
  padding-bottom: 24px;
}

.tw-py-\[30px\] {
  padding-top: 30px;
  padding-bottom: 30px;
}

.tw-py-\[3px\] {
  padding-top: 3px;
  padding-bottom: 3px;
}

.tw-py-\[4px\] {
  padding-top: 4px;
  padding-bottom: 4px;
}

.tw-py-\[5px\] {
  padding-top: 5px;
  padding-bottom: 5px;
}

.tw-py-\[6px\] {
  padding-top: 6px;
  padding-bottom: 6px;
}

.tw-py-\[8px\] {
  padding-top: 8px;
  padding-bottom: 8px;
}

.\!tw-pb-0 {
  padding-bottom: 0 !important;
}

.\!tw-pb-\[15px\] {
  padding-bottom: 15px !important;
}

.\!tw-pb-\[16px\] {
  padding-bottom: 16px !important;
}

.\!tw-pb-\[2px\] {
  padding-bottom: 2px !important;
}

.\!tw-pb-\[30px\] {
  padding-bottom: 30px !important;
}

.\!tw-pb-\[5px\] {
  padding-bottom: 5px !important;
}

.\!tw-pl-0 {
  padding-left: 0 !important;
}

.\!tw-pl-4 {
  padding-left: 1rem !important;
}

.\!tw-pl-\[10px\] {
  padding-left: 10px !important;
}

.\!tw-pl-\[40px\] {
  padding-left: 40px !important;
}

.\!tw-pr-\[30px\] {
  padding-right: 30px !important;
}

.\!tw-pt-0 {
  padding-top: 0 !important;
}

.\!tw-pt-\[12px\] {
  padding-top: 12px !important;
}

.\!tw-pt-\[14px\] {
  padding-top: 14px !important;
}

.\!tw-pt-\[15px\] {
  padding-top: 15px !important;
}

.tw-pb-0 {
  padding-bottom: 0;
}

.tw-pb-1 {
  padding-bottom: .25rem;
}

.tw-pb-2 {
  padding-bottom: .5rem;
}

.tw-pb-3 {
  padding-bottom: .75rem;
}

.tw-pb-4 {
  padding-bottom: 1rem;
}

.tw-pb-5 {
  padding-bottom: 1.25rem;
}

.tw-pb-6 {
  padding-bottom: 1.5rem;
}

.tw-pb-8 {
  padding-bottom: 2rem;
}

.tw-pb-\[--mobileMenuHeight\] {
  padding-bottom: var(--mobileMenuHeight);
}

.tw-pb-\[100px\] {
  padding-bottom: 100px;
}

.tw-pb-\[104px\] {
  padding-bottom: 104px;
}

.tw-pb-\[10px\] {
  padding-bottom: 10px;
}

.tw-pb-\[16px\] {
  padding-bottom: 16px;
}

.tw-pb-\[18px\] {
  padding-bottom: 18px;
}

.tw-pb-\[1em\] {
  padding-bottom: 1em;
}

.tw-pb-\[20px\] {
  padding-bottom: 20px;
}

.tw-pb-\[24px\] {
  padding-bottom: 24px;
}

.tw-pb-\[25px\] {
  padding-bottom: 25px;
}

.tw-pb-\[35px\] {
  padding-bottom: 35px;
}

.tw-pb-\[3px\] {
  padding-bottom: 3px;
}

.tw-pb-\[40px\] {
  padding-bottom: 40px;
}

.tw-pb-\[50px\] {
  padding-bottom: 50px;
}

.tw-pb-\[5px\] {
  padding-bottom: 5px;
}

.tw-pb-\[9px\] {
  padding-bottom: 9px;
}

.tw-pl-0 {
  padding-left: 0;
}

.tw-pl-12 {
  padding-left: 3rem;
}

.tw-pl-4 {
  padding-left: 1rem;
}

.tw-pl-7 {
  padding-left: 1.75rem;
}

.tw-pl-\[10px\] {
  padding-left: 10px;
}

.tw-pl-\[15px\] {
  padding-left: 15px;
}

.tw-pl-\[19px\] {
  padding-left: 19px;
}

.tw-pl-\[20px\] {
  padding-left: 20px;
}

.tw-pl-\[2px\] {
  padding-left: 2px;
}

.tw-pl-\[30px\] {
  padding-left: 30px;
}

.tw-pl-\[5px\] {
  padding-left: 5px;
}

.tw-pr-2 {
  padding-right: .5rem;
}

.tw-pr-2\.5 {
  padding-right: .625rem;
}

.tw-pr-4 {
  padding-right: 1rem;
}

.tw-pr-\[10px\] {
  padding-right: 10px;
}

.tw-pr-\[15px\] {
  padding-right: 15px;
}

.tw-pr-\[18px\] {
  padding-right: 18px;
}

.tw-pr-\[30px\] {
  padding-right: 30px;
}

.tw-pr-\[5px\] {
  padding-right: 5px;
}

.tw-pr-\[8px\] {
  padding-right: 8px;
}

.tw-pt-0 {
  padding-top: 0;
}

.tw-pt-0\.5 {
  padding-top: .125rem;
}

.tw-pt-10 {
  padding-top: 2.5rem;
}

.tw-pt-3 {
  padding-top: .75rem;
}

.tw-pt-4 {
  padding-top: 1rem;
}

.tw-pt-8 {
  padding-top: 2rem;
}

.tw-pt-\[10px\] {
  padding-top: 10px;
}

.tw-pt-\[11px\] {
  padding-top: 11px;
}

.tw-pt-\[16px\] {
  padding-top: 16px;
}

.tw-pt-\[18px\] {
  padding-top: 18px;
}

.tw-pt-\[20px\] {
  padding-top: 20px;
}

.tw-pt-\[22px\] {
  padding-top: 22px;
}

.tw-pt-\[24px\] {
  padding-top: 24px;
}

.tw-pt-\[2px\] {
  padding-top: 2px;
}

.tw-pt-\[30px\] {
  padding-top: 30px;
}

.tw-pt-\[34px\] {
  padding-top: 34px;
}

.tw-pt-\[44px\] {
  padding-top: 44px;
}

.tw-pt-\[4px\] {
  padding-top: 4px;
}

.\!tw-text-left {
  text-align: left !important;
}

.tw-text-left {
  text-align: left;
}

.tw-text-center {
  text-align: center;
}

.tw-text-right {
  text-align: right;
}

.tw-align-top {
  vertical-align: top;
}

.tw-align-middle {
  vertical-align: middle;
}

.tw-font-\[\'Lato\'\] {
  font-family: Lato;
}

.tw-font-\[\'Overpass\'\] {
  font-family: Overpass;
}

.tw-font-\[Inter\] {
  font-family: Inter;
}

.tw-font-\[Overpass\] {
  font-family: Overpass;
}

.tw-font-sans {
  font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Noto Sans, Ubuntu, Cantarell, Helvetica Neue, Arial, sans-serif, Apple Color Emoji, Segoe UI Emoji, Segoe UI Symbol, Noto Color Emoji;
}

.\!tw-text-\[10px\] {
  font-size: 10px !important;
}

.\!tw-text-\[12px\] {
  font-size: 12px !important;
}

.\!tw-text-\[14px\] {
  font-size: 14px !important;
}

.\!tw-text-\[16px\] {
  font-size: 16px !important;
}

.\!tw-text-\[20px\] {
  font-size: 20px !important;
}

.\!tw-text-\[24px\] {
  font-size: 24px !important;
}

.\!tw-text-base {
  font-size: 1rem !important;
  line-height: 1.5rem !important;
}

.\!tw-text-sm {
  font-size: .875rem !important;
  line-height: 1.25rem !important;
}

.\!tw-text-xl {
  font-size: 1.25rem !important;
  line-height: 1.75rem !important;
}

.tw-text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.tw-text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.tw-text-\[1\.4rem\] {
  font-size: 1.4rem;
}

.tw-text-\[1\.5rem\] {
  font-size: 1.5rem;
}

.tw-text-\[1\.6rem\] {
  font-size: 1.6rem;
}

.tw-text-\[10px\] {
  font-size: 10px;
}

.tw-text-\[11px\] {
  font-size: 11px;
}

.tw-text-\[12px\] {
  font-size: 12px;
}

.tw-text-\[12px\]\/\[16px\] {
  font-size: 12px;
  line-height: 16px;
}

.tw-text-\[12px\]\/\[24px\] {
  font-size: 12px;
  line-height: 24px;
}

.tw-text-\[12px\]\/none {
  font-size: 12px;
  line-height: 1;
}

.tw-text-\[13px\] {
  font-size: 13px;
}

.tw-text-\[14px\] {
  font-size: 14px;
}

.tw-text-\[14px\]\/\[20px\] {
  font-size: 14px;
  line-height: 20px;
}

.tw-text-\[14px\]\/\[22px\] {
  font-size: 14px;
  line-height: 22px;
}

.tw-text-\[14px\]\/\[24px\] {
  font-size: 14px;
  line-height: 24px;
}

.tw-text-\[15px\] {
  font-size: 15px;
}

.tw-text-\[15px\]\/\[24px\] {
  font-size: 15px;
  line-height: 24px;
}

.tw-text-\[16px\] {
  font-size: 16px;
}

.tw-text-\[16px\]\/\[20px\] {
  font-size: 16px;
  line-height: 20px;
}

.tw-text-\[16px\]\/\[28px\] {
  font-size: 16px;
  line-height: 28px;
}

.tw-text-\[18px\] {
  font-size: 18px;
}

.tw-text-\[18px\]\/\[30px\] {
  font-size: 18px;
  line-height: 30px;
}

.tw-text-\[2\.4rem\] {
  font-size: 2.4rem;
}

.tw-text-\[20px\] {
  font-size: 20px;
}

.tw-text-\[21px\] {
  font-size: 21px;
}

.tw-text-\[22px\] {
  font-size: 22px;
}

.tw-text-\[24px\] {
  font-size: 24px;
}

.tw-text-\[24px\]\/\[24px\] {
  font-size: 24px;
  line-height: 24px;
}

.tw-text-\[24px\]\/none {
  font-size: 24px;
  line-height: 1;
}

.tw-text-\[28px\] {
  font-size: 28px;
}

.tw-text-\[2rem\] {
  font-size: 2rem;
}

.tw-text-\[32px\] {
  font-size: 32px;
}

.tw-text-\[34px\] {
  font-size: 34px;
}

.tw-text-\[40px\] {
  font-size: 40px;
}

.tw-text-\[50px\] {
  font-size: 50px;
}

.tw-text-\[64px\] {
  font-size: 64px;
}

.tw-text-\[6px\] {
  font-size: 6px;
}

.tw-text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.tw-text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.tw-text-sm {
  font-size: .875rem;
  line-height: 1.25rem;
}

.tw-text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.tw-text-xs {
  font-size: .75rem;
  line-height: 1rem;
}

.\!tw-font-bold {
  font-weight: 700 !important;
}

.\!tw-font-medium {
  font-weight: 500 !important;
}

.\!tw-font-normal {
  font-weight: 400 !important;
}

.tw-font-\[500\] {
  font-weight: 500;
}

.tw-font-black {
  font-weight: 900;
}

.tw-font-bold {
  font-weight: 700;
}

.tw-font-extrabold {
  font-weight: 800;
}

.tw-font-light {
  font-weight: 300;
}

.tw-font-medium {
  font-weight: 500;
}

.tw-font-normal {
  font-weight: 400;
}

.tw-font-semibold {
  font-weight: 600;
}

.tw-uppercase {
  text-transform: uppercase;
}

.tw-capitalize {
  text-transform: capitalize;
}

.tw-italic {
  font-style: italic;
}

.\!tw-leading-\[14px\] {
  line-height: 14px !important;
}

.\!tw-leading-\[20px\] {
  line-height: 20px !important;
}

.tw-leading-10 {
  line-height: 2.5rem;
}

.tw-leading-4 {
  line-height: 1rem;
}

.tw-leading-5 {
  line-height: 1.25rem;
}

.tw-leading-6 {
  line-height: 1.5rem;
}

.tw-leading-7 {
  line-height: 1.75rem;
}

.tw-leading-8 {
  line-height: 2rem;
}

.tw-leading-\[--navHeight\] {
  line-height: var(--navHeight);
}

.tw-leading-\[1\.3em\] {
  line-height: 1.3em;
}

.tw-leading-\[1\.4\] {
  line-height: 1.4;
}

.tw-leading-\[1\.7\] {
  line-height: 1.7;
}

.tw-leading-\[12px\] {
  line-height: 12px;
}

.tw-leading-\[14px\] {
  line-height: 14px;
}

.tw-leading-\[16px\] {
  line-height: 16px;
}

.tw-leading-\[17px\] {
  line-height: 17px;
}

.tw-leading-\[2\.4rem\] {
  line-height: 2.4rem;
}

.tw-leading-\[20px\] {
  line-height: 20px;
}

.tw-leading-\[22\.4px\] {
  line-height: 22.4px;
}

.tw-leading-\[22px\] {
  line-height: 22px;
}

.tw-leading-\[24px\] {
  line-height: 24px;
}

.tw-leading-\[27px\] {
  line-height: 27px;
}

.tw-leading-\[28px\] {
  line-height: 28px;
}

.tw-leading-\[2rem\] {
  line-height: 2rem;
}

.tw-leading-\[30px\] {
  line-height: 30px;
}

.tw-leading-\[32px\] {
  line-height: 32px;
}

.tw-leading-\[34px\] {
  line-height: 34px;
}

.tw-leading-\[45px\] {
  line-height: 45px;
}

.tw-leading-\[48px\] {
  line-height: 48px;
}

.tw-leading-none {
  line-height: 1;
}

.tw-leading-normal {
  line-height: 1.5;
}

.tw-leading-tight {
  line-height: 1.25;
}

.tw-tracking-\[-0\.01em\] {
  letter-spacing: -.01em;
}

.tw-tracking-\[-0\.02em\] {
  letter-spacing: -.02em;
}

.tw-tracking-\[\.4px\] {
  letter-spacing: .4px;
}

.tw-tracking-tight {
  letter-spacing: -.025em;
}

.tw-tracking-widest {
  letter-spacing: .1em;
}

.\!tw-text-\[\#4A4D4A\] {
  --tw-text-opacity: 1 !important;
  color: rgba(74, 77, 74, var(--tw-text-opacity)) !important;
}

.\!tw-text-gray-600 {
  color: var(--token-gray-600) !important;
}

.\!tw-text-gray-700 {
  color: var(--token-gray-700) !important;
}

.\!tw-text-green-300 {
  color: var(--token-green-300) !important;
}

.\!tw-text-green-700 {
  color: var(--token-green-700) !important;
}

.\!tw-text-inherit {
  color: inherit !important;
}

.\!tw-text-text {
  color: var(--token-text-default) !important;
}

.\!tw-text-text-btnPrimary {
  color: var(--token-text-btnPrimary-default) !important;
}

.\!tw-text-text-subdued {
  color: var(--token-text-subdued) !important;
}

.\!tw-text-yellow-600 {
  color: var(--token-yellow-600) !important;
}

.tw-text-\[\#05BBD3\] {
  --tw-text-opacity: 1;
  color: rgba(5, 187, 211, var(--tw-text-opacity));
}

.tw-text-\[\#242624\] {
  --tw-text-opacity: 1;
  color: rgba(36, 38, 36, var(--tw-text-opacity));
}

.tw-text-\[\#315778\] {
  --tw-text-opacity: 1;
  color: rgba(49, 87, 120, var(--tw-text-opacity));
}

.tw-text-\[\#3C7D4D\] {
  --tw-text-opacity: 1;
  color: rgba(60, 125, 77, var(--tw-text-opacity));
}

.tw-text-\[\#564C0C\] {
  --tw-text-opacity: 1;
  color: rgba(86, 76, 12, var(--tw-text-opacity));
}

.tw-text-\[\#5F5E67\] {
  --tw-text-opacity: 1;
  color: rgba(95, 94, 103, var(--tw-text-opacity));
}

.tw-text-\[\#5FAA41\] {
  --tw-text-opacity: 1;
  color: rgba(95, 170, 65, var(--tw-text-opacity));
}

.tw-text-\[\#666\] {
  --tw-text-opacity: 1;
  color: rgba(102, 102, 102, var(--tw-text-opacity));
}

.tw-text-\[\#76708D\] {
  --tw-text-opacity: 1;
  color: rgba(118, 112, 141, var(--tw-text-opacity));
}

.tw-text-\[\#8A6D3B\] {
  --tw-text-opacity: 1;
  color: rgba(138, 109, 59, var(--tw-text-opacity));
}

.tw-text-\[\#AAA\] {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity));
}

.tw-text-\[\#AF2225\] {
  --tw-text-opacity: 1;
  color: rgba(175, 34, 37, var(--tw-text-opacity));
}

.tw-text-\[\#CCC\] {
  --tw-text-opacity: 1;
  color: rgba(204, 204, 204, var(--tw-text-opacity));
}

.tw-text-\[\#DDA821\] {
  --tw-text-opacity: 1;
  color: rgba(221, 168, 33, var(--tw-text-opacity));
}

.tw-text-\[\#F00\] {
  --tw-text-opacity: 1;
  color: rgba(255, 0, 0, var(--tw-text-opacity));
}

.tw-text-\[\#aaaaaa\] {
  --tw-text-opacity: 1;
  color: rgba(170, 170, 170, var(--tw-text-opacity));
}

.tw-text-\[\#e36039\] {
  --tw-text-opacity: 1;
  color: rgba(227, 96, 57, var(--tw-text-opacity));
}

.tw-text-black {
  --tw-text-opacity: 1;
  color: rgba(0, 0, 0, var(--tw-text-opacity));
}

.tw-text-blue-600 {
  color: var(--token-blue-600);
}

.tw-text-gray-400 {
  color: var(--token-gray-400);
}

.tw-text-gray-50 {
  color: var(--token-gray-50);
}

.tw-text-gray-500 {
  color: var(--token-gray-500);
}

.tw-text-gray-600 {
  color: var(--token-gray-600);
}

.tw-text-gray-700 {
  color: var(--token-gray-700);
}

.tw-text-gray-900 {
  color: var(--token-gray-900);
}

.tw-text-green-500 {
  color: var(--token-green-500);
}

.tw-text-green-600 {
  color: var(--token-green-600);
}

.tw-text-green-700 {
  color: var(--token-green-700);
}

.tw-text-icon-warning {
  color: var(--token-icon-warning);
}

.tw-text-inherit {
  color: inherit;
}

.tw-text-orange-400 {
  color: var(--token-orange-400);
}

.tw-text-primary-100 {
  color: var(--token-primary-100);
}

.tw-text-primary-500 {
  color: var(--token-primary-500);
}

.tw-text-primary-600 {
  color: var(--token-primary-600);
}

.tw-text-red-500 {
  color: var(--token-red-500);
}

.tw-text-red-600 {
  color: var(--token-red-600);
}

.tw-text-red-700 {
  color: var(--token-red-700);
}

.tw-text-site-cfp-orange {
  color: var(--token-site-cfp-orange);
}

.tw-text-site-darkblue {
  color: var(--token-site-darkblue);
}

.tw-text-site-gray {
  color: var(--token-site-gray);
}

.tw-text-site-gray-accent {
  color: var(--token-site-gray-accent);
}

.tw-text-site-gray-base {
  color: var(--token-site-gray-base);
}

.tw-text-site-gray-darkaccent {
  color: var(--token-site-gray-darkaccent);
}

.tw-text-site-gray-medium {
  color: var(--token-site-gray-medium);
}

.tw-text-site-gray-mediumdark {
  color: var(--token-site-gray-mediumdark);
}

.tw-text-site-green {
  color: var(--token-site-green);
}

.tw-text-site-green-dark {
  color: var(--token-site-green-dark);
}

.tw-text-site-green-darker {
  color: var(--token-site-green-darker);
}

.tw-text-system-danger-600 {
  color: var(--token-system-danger-600);
}

.tw-text-system-decorative-600 {
  color: var(--token-system-decorative-600);
}

.tw-text-system-decorative-700 {
  color: var(--token-system-decorative-700);
}

.tw-text-system-info-600 {
  color: var(--token-system-info-600);
}

.tw-text-system-success-600 {
  color: var(--token-system-success-600);
}

.tw-text-text {
  color: var(--token-text-default);
}

.tw-text-text-btnPrimary {
  color: var(--token-text-btnPrimary-default);
}

.tw-text-text-danger {
  color: var(--token-text-danger);
}

.tw-text-text-emphasis-onDark {
  color: var(--token-text-emphasis-onDark);
}

.tw-text-text-info {
  color: var(--token-text-info);
}

.tw-text-text-link {
  color: var(--token-text-link-default);
}

.tw-text-text-onDark {
  color: var(--token-text-onDark);
}

.tw-text-text-subdued {
  color: var(--token-text-subdued);
}

.tw-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.tw-text-yellow-600 {
  color: var(--token-yellow-600);
}

.tw-text-yellow-700 {
  color: var(--token-yellow-700);
}

.tw-antialiased {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.tw-opacity-0 {
  opacity: 0;
}

.tw-opacity-100 {
  opacity: 1;
}

.tw-opacity-20 {
  opacity: .2;
}

.tw-opacity-25 {
  opacity: .25;
}

.tw-opacity-30 {
  opacity: .3;
}

.tw-opacity-40 {
  opacity: .4;
}

.tw-opacity-50 {
  opacity: .5;
}

.tw-opacity-70 {
  opacity: .7;
}

.tw-opacity-75 {
  opacity: .75;
}

.tw-opacity-80 {
  opacity: .8;
}

.tw-opacity-90 {
  opacity: .9;
}

.tw-mix-blend-hard-light {
  mix-blend-mode: hard-light;
}

.\!tw-shadow-none {
  --tw-shadow: 0 0 rgba(0, 0, 0, 0) !important;
  --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow) !important;
}

.tw-shadow {
  --tw-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px -1px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 1px 3px 0 var(--tw-shadow-color), 0 1px 2px -1px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-\[0_0_10px_0_rgba\(0\,0\,0\,0\.06\)\] {
  --tw-shadow: 0 0 10px 0 rgba(0, 0, 0, .06);
  --tw-shadow-colored: 0 0 10px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-\[0_13px_24px_0_rgba\(74\,84\,114\,0\.04\)\,0_6px_12px_0_rgba\(74\,84\,114\,0\.04\)\,0_4px_4px_0_rgba\(74\,84\,114\,0\.04\)\,0_-1px_4px_0_rgba\(74\,84\,114\,0\.08\)\] {
  --tw-shadow: 0 13px 24px 0 rgba(74, 84, 114, .04), 0 6px 12px 0 rgba(74, 84, 114, .04), 0 4px 4px 0 rgba(74, 84, 114, .04), 0 -1px 4px 0 rgba(74, 84, 114, .08);
  --tw-shadow-colored: 0 13px 24px 0 var(--tw-shadow-color), 0 6px 12px 0 var(--tw-shadow-color), 0 4px 4px 0 var(--tw-shadow-color), 0 -1px 4px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-\[0_2px_28px_0px_rgba\(0\,0\,0\,0\.25\)\] {
  --tw-shadow: 0 2px 28px 0px rgba(0, 0, 0, .25);
  --tw-shadow-colored: 0 2px 28px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-\[0px_0px_4px_0px_rgba\(37\,38\,37\,0\.15\)\,0px_8px_12px_0px_rgba\(37\,38\,37\,0\.15\)\] {
  --tw-shadow: 0px 0px 4px 0px rgba(37, 38, 37, .15), 0px 8px 12px 0px rgba(37, 38, 37, .15);
  --tw-shadow-colored: 0px 0px 4px 0px var(--tw-shadow-color), 0px 8px 12px 0px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-card {
  --tw-shadow: 0px 0px 12px rgba(37, 38, 37, .05), 0px 1px 5px rgba(37, 38, 37, .15);
  --tw-shadow-colored: 0px 0px 12px var(--tw-shadow-color), 0px 1px 5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-header {
  --tw-shadow: 0 6px 5px -5px rgba(37, 38, 37, .1);
  --tw-shadow-colored: 0 6px 5px -5px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-lg {
  --tw-shadow: 0 10px 15px -3px rgba(0, 0, 0, .1), 0 4px 6px -4px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 10px 15px -3px var(--tw-shadow-color), 0 4px 6px -4px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-shadow-md {
  --tw-shadow: 0 4px 6px -1px rgba(0, 0, 0, .1), 0 2px 4px -2px rgba(0, 0, 0, .1);
  --tw-shadow-colored: 0 4px 6px -1px var(--tw-shadow-color), 0 2px 4px -2px var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-ring {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(3px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.tw-ring-1 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(1px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.tw-ring-2 {
  --tw-ring-offset-shadow: var(--tw-ring-inset) 0 0 0 var(--tw-ring-offset-width) var(--tw-ring-offset-color);
  --tw-ring-shadow: var(--tw-ring-inset) 0 0 0 calc(2px + var(--tw-ring-offset-width)) var(--tw-ring-color);
  box-shadow: var(--tw-ring-offset-shadow), var(--tw-ring-shadow), var(--tw-shadow, 0 0 rgba(0, 0, 0, 0));
}

.tw-ring-inset {
  --tw-ring-inset: inset;
}

.tw-ring-\[\#008CF6\] {
  --tw-ring-opacity: 1;
  --tw-ring-color: rgba(0, 140, 246, var(--tw-ring-opacity));
}

.tw-ring-border-btnSecondary {
  --tw-ring-color: var(--token-border-btnSecondary-default);
}

.tw-ring-gray-100 {
  --tw-ring-color: var(--token-gray-100);
}

.tw-ring-site-gray-accent {
  --tw-ring-color: var(--token-site-gray-accent);
}

.tw-ring-site-gray-light {
  --tw-ring-color: var(--token-site-gray-light);
}

.tw-ring-site-gray-lighter {
  --tw-ring-color: var(--token-site-gray-lighter);
}

.tw-ring-site-gray-medium {
  --tw-ring-color: var(--token-site-gray-medium);
}

.tw-blur-2xl {
  --tw-blur: blur(40px);
  -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
}

.tw-transition {
  transition-property: color, background-color, border-color, text-decoration-color, fill, stroke, opacity, box-shadow, -webkit-transform, transform, -webkit-filter, filter, -webkit-backdrop-filter, backdrop-filter;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-\[top\] {
  transition-property: top;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-all {
  transition-property: all;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-transition-transform {
  transition-property: -webkit-transform, transform;
  transition-duration: .15s;
  transition-timing-function: cubic-bezier(.4, 0, .2, 1);
}

.tw-duration-0 {
  transition-duration: 0s;
}

.tw-duration-100 {
  transition-duration: .1s;
}

.tw-duration-1000 {
  transition-duration: 1s;
}

.tw-duration-150 {
  transition-duration: .15s;
}

.tw-duration-200 {
  transition-duration: .2s;
}

.tw-duration-300 {
  transition-duration: .3s;
}

.tw-duration-75 {
  transition-duration: 75ms;
}

.tw-ease-in {
  transition-timing-function: cubic-bezier(.4, 0, 1, 1);
}

.tw-underline {
  text-decoration-line: underline;
}

.tw-line-through {
  text-decoration-line: line-through;
}

.\!tw-no-underline {
  text-decoration-line: none !important;
}

.tw-no-underline {
  text-decoration-line: none;
}

.tw-absolutely-centered {
  --tw-translate-x: -50%;
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
  left: 50%;
}

.tw-absolutely-centered-y {
  --tw-translate-y: -50%;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  position: absolute;
  top: 50%;
}

.\[--fa-secondary-color\:var\(--token-orange-300\)\] {
  --fa-secondary-color: var(--token-orange-300);
}

.\[--fa-secondary-color\:var\(--token-primary-300\)\] {
  --fa-secondary-color: var(--token-primary-300);
}

.\[--fa-secondary-opacity\:1\] {
  --fa-secondary-opacity: 1;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-heading-sm-bold {
  font-family: var(--token-mobile-heading-sm-bold-fontFamily);
  font-weight: var(--token-mobile-heading-sm-bold-fontWeight);
  line-height: var(--token-mobile-heading-sm-bold-lineHeight);
  font-size: var(--token-mobile-heading-sm-bold-fontSize);
  letter-spacing: var(--token-mobile-heading-sm-bold-letterSpacing);
  text-transform: none;
  text-decoration: none;
}

@media (min-width: 992px) {
  .tw-group.sticky .group-\[\.sticky\]\:tw-heading-sm-bold {
    font-family: var(--token-desktop-heading-sm-bold-fontFamily);
    font-weight: var(--token-desktop-heading-sm-bold-fontWeight);
    line-height: var(--token-desktop-heading-sm-bold-lineHeight);
    font-size: var(--token-desktop-heading-sm-bold-fontSize);
    letter-spacing: var(--token-desktop-heading-sm-bold-letterSpacing);
  }
}

@media (min-width: 768px) {
  .md\:tw-heading-md {
    font-family: var(--token-mobile-heading-md-fontFamily);
    font-weight: var(--token-mobile-heading-md-fontWeight);
    line-height: var(--token-mobile-heading-md-lineHeight);
    font-size: var(--token-mobile-heading-md-fontSize);
    letter-spacing: var(--token-mobile-heading-md-letterSpacing);
    text-transform: none;
    text-decoration: none;
  }

  @media (min-width: 992px) {
    .md\:tw-heading-md {
      font-family: var(--token-desktop-heading-md-fontFamily);
      font-weight: var(--token-desktop-heading-md-fontWeight);
      line-height: var(--token-desktop-heading-md-lineHeight);
      font-size: var(--token-desktop-heading-md-fontSize);
      letter-spacing: var(--token-desktop-heading-md-letterSpacing);
    }
  }

  .md\:tw-body-lg {
    font-family: var(--token-mobile-body-lg-fontFamily);
    font-weight: var(--token-mobile-body-lg-fontWeight);
    line-height: var(--token-mobile-body-lg-lineHeight);
    font-size: var(--token-mobile-body-lg-fontSize);
    letter-spacing: var(--token-mobile-body-lg-letterSpacing);
    text-transform: none;
    text-decoration: none;
  }

  @media (min-width: 992px) {
    .md\:tw-body-lg {
      font-family: var(--token-desktop-body-lg-fontFamily);
      font-weight: var(--token-desktop-body-lg-fontWeight);
      line-height: var(--token-desktop-body-lg-lineHeight);
      font-size: var(--token-desktop-body-lg-fontSize);
      letter-spacing: var(--token-desktop-body-lg-letterSpacing);
    }
  }
}

@media (min-width: 992px) {
  .tw-group.sticky .lg\:group-\[\.sticky\]\:tw-heading-md-bold {
    font-family: var(--token-mobile-heading-md-bold-fontFamily);
    font-weight: var(--token-mobile-heading-md-bold-fontWeight);
    line-height: var(--token-mobile-heading-md-bold-lineHeight);
    font-size: var(--token-mobile-heading-md-bold-fontSize);
    letter-spacing: var(--token-mobile-heading-md-bold-letterSpacing);
    text-transform: none;
    text-decoration: none;
  }

  @media (min-width: 992px) {
    .tw-group.sticky .lg\:group-\[\.sticky\]\:tw-heading-md-bold {
      font-family: var(--token-desktop-heading-md-bold-fontFamily);
      font-weight: var(--token-desktop-heading-md-bold-fontWeight);
      line-height: var(--token-desktop-heading-md-bold-lineHeight);
      font-size: var(--token-desktop-heading-md-bold-fontSize);
      letter-spacing: var(--token-desktop-heading-md-bold-letterSpacing);
    }
  }
}

.selection\:tw-bg-transparent ::selection {
  background-color: rgba(0, 0, 0, 0);
}

.selection\:tw-bg-transparent::selection {
  background-color: rgba(0, 0, 0, 0);
}

.file\:tw-hidden::-webkit-file-upload-button {
  display: none;
}

.file\:tw-hidden::file-selector-button {
  display: none;
}

.placeholder\:tw-text-site-gray-medium::-webkit-input-placeholder {
  color: var(--token-site-gray-medium);
}

.placeholder\:tw-text-site-gray-medium::placeholder {
  color: var(--token-site-gray-medium);
}

.before\:tw-absolute:before {
  content: var(--tw-content);
  position: absolute;
}

.before\:tw-inset-0:before {
  content: var(--tw-content);
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.before\:tw-left-0:before {
  content: var(--tw-content);
  left: 0;
}

.before\:tw-left-4:before {
  content: var(--tw-content);
  left: 1rem;
}

.before\:tw-right-4:before {
  content: var(--tw-content);
  right: 1rem;
}

.before\:tw-top-0:before {
  content: var(--tw-content);
  top: 0;
}

.before\:tw-top-\[-15px\]:before {
  content: var(--tw-content);
  top: -15px;
}

.before\:tw-mr-\[12px\]:before {
  content: var(--tw-content);
  margin-right: 12px;
}

.before\:tw-block:before {
  content: var(--tw-content);
  display: block;
}

.before\:tw-hidden:before {
  content: var(--tw-content);
  display: none;
}

.before\:tw-h-4:before {
  content: var(--tw-content);
  height: 1rem;
}

.before\:tw-h-\[25px\]:before {
  content: var(--tw-content);
  height: 25px;
}

.before\:tw-translate-y-\[-1\.25em\]:before {
  content: var(--tw-content);
  --tw-translate-y: -1.25em;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:tw-translate-y-\[-1\.5em\]:before {
  content: var(--tw-content);
  --tw-translate-y: -1.5em;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.before\:tw-border-0:before {
  content: var(--tw-content);
  border-width: 0;
}

.before\:tw-border-l:before {
  content: var(--tw-content);
  border-left-width: 1px;
}

.before\:tw-border-solid:before {
  content: var(--tw-content);
  border-style: solid;
}

.before\:tw-border-l-gray-500:before {
  content: var(--tw-content);
  border-left-color: var(--token-gray-500);
}

.before\:tw-bg-site-gray-lighter:before {
  content: var(--tw-content);
  background-color: var(--token-site-gray-lighter);
}

.before\:tw-bg-gradient-to-b:before {
  content: var(--tw-content);
  background-image: linear-gradient(to bottom, var(--tw-gradient-stops));
}

.before\:tw-from-transparent:before {
  content: var(--tw-content);
  --tw-gradient-from: transparent var(--tw-gradient-from-position);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), var(--tw-gradient-to);
}

.before\:tw-via-transparent:before {
  content: var(--tw-content);
  --tw-gradient-to: rgba(0, 0, 0, 0) var(--tw-gradient-to-position);
  --tw-gradient-stops: var(--tw-gradient-from), transparent var(--tw-gradient-via-position), var(--tw-gradient-to);
}

.before\:tw-to-white:before {
  content: var(--tw-content);
  --tw-gradient-to: #fff var(--tw-gradient-to-position);
}

.before\:tw-font-bold:before {
  content: var(--tw-content);
  font-weight: 700;
}

.before\:\!tw-text-white:before {
  content: var(--tw-content);
  --tw-text-opacity: 1 !important;
  color: rgba(255, 255, 255, var(--tw-text-opacity)) !important;
}

.before\:tw-opacity-30:before {
  content: var(--tw-content);
  opacity: .3;
}

.before\:\!tw-content-none:before {
  --tw-content: none !important;
  content: var(--tw-content) !important;
}

.before\:tw-content-\[\'\'\]:before {
  --tw-content: "";
  content: var(--tw-content);
}

.before\:tw-content-\[\'AND\'\]:before {
  --tw-content: "AND";
  content: var(--tw-content);
}

.before\:tw-content-\[\'OR\'\]:before {
  --tw-content: "OR";
  content: var(--tw-content);
}

.after\:tw-mt-\[15px\]:after {
  content: var(--tw-content);
  margin-top: 15px;
}

.after\:tw-mt-\[24px\]:after {
  content: var(--tw-content);
  margin-top: 24px;
}

.after\:tw-mt-\[80px\]:after {
  content: var(--tw-content);
  margin-top: 80px;
}

.after\:tw-block:after {
  content: var(--tw-content);
  display: block;
}

.after\:tw-h-8:after {
  content: var(--tw-content);
  height: 2rem;
}

.after\:tw-w-full:after {
  content: var(--tw-content);
  width: 100%;
}

.after\:tw-scale-x-110:after {
  content: var(--tw-content);
  --tw-scale-x: 1.1;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.after\:tw-bg-bg:after {
  content: var(--tw-content);
  background-color: var(--token-bg-default);
}

.after\:tw-bg-bg-dark:after {
  content: var(--tw-content);
  background-color: var(--token-bg-dark);
}

.after\:\!tw-text-\[\#397A4A\]:after {
  content: var(--tw-content);
  --tw-text-opacity: 1 !important;
  color: rgba(57, 122, 74, var(--tw-text-opacity)) !important;
}

.after\:\!tw-opacity-100:after {
  content: var(--tw-content);
  opacity: 1 !important;
}

.after\:tw-content-\[\'\'\]:after {
  --tw-content: "";
  content: var(--tw-content);
}

.first\:tw-mt-0:first-child {
  margin-top: 0;
}

.first\:tw-mt-1:first-child {
  margin-top: .25rem;
}

.first\:tw-mt-1\.5:first-child {
  margin-top: .375rem;
}

.first\:tw-pt-0:first-child {
  padding-top: 0;
}

.first\:before\:tw-content-\[\'\'\]:first-child:before {
  --tw-content: "";
  content: var(--tw-content);
}

.last\:tw-border-0:last-child {
  border-width: 0;
}

.last\:tw-border-b-0:last-child {
  border-bottom-width: 0;
}

.last\:tw-pb-0:last-child {
  padding-bottom: 0;
}

.checked\:tw-border-transparent:checked {
  border-color: rgba(0, 0, 0, 0);
}

.checked\:tw-bg-primary-300:checked {
  background-color: var(--token-primary-300);
}

.checked\:tw-ring-site-green:checked {
  --tw-ring-color: var(--token-site-green);
}

.checked\:before\:tw-absolute:checked:before {
  content: var(--tw-content);
  position: absolute;
}

.checked\:before\:tw-left-\[5px\]:checked:before {
  content: var(--tw-content);
  left: 5px;
}

.checked\:before\:tw-top-\[-2px\]:checked:before {
  content: var(--tw-content);
  top: -2px;
}

.checked\:before\:tw-h-\[20px\]:checked:before {
  content: var(--tw-content);
  height: 20px;
}

.checked\:before\:tw-w-3:checked:before {
  content: var(--tw-content);
  width: .75rem;
}

.checked\:before\:tw-rotate-45:checked:before {
  content: var(--tw-content);
  --tw-rotate: 45deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.checked\:before\:tw-border-0:checked:before {
  content: var(--tw-content);
  border-width: 0;
}

.checked\:before\:tw-border-b-2:checked:before {
  content: var(--tw-content);
  border-bottom-width: 2px;
}

.checked\:before\:tw-border-r-2:checked:before {
  content: var(--tw-content);
  border-right-width: 2px;
}

.checked\:before\:tw-border-solid:checked:before {
  content: var(--tw-content);
  border-style: solid;
}

.checked\:before\:tw-border-\[\#4C4C4C\]:checked:before {
  content: var(--tw-content);
  --tw-border-opacity: 1;
  border-color: rgba(76, 76, 76, var(--tw-border-opacity));
}

.checked\:before\:tw-bg-site-green:checked:before {
  content: var(--tw-content);
  background-color: var(--token-site-green);
}

.checked\:before\:tw-content-\[\'\'\]:checked:before {
  --tw-content: "";
  content: var(--tw-content);
}

.focus-within\:tw-outline:focus-within {
  outline-style: solid;
}

@media (hover: hover) and (pointer: fine) {
  .hover\:tw-cursor-pointer:hover {
    cursor: pointer;
  }

  .hover\:tw-border:hover {
    border-width: 1px;
  }

  .hover\:\!tw-border-b-0:hover {
    border-bottom-width: 0 !important;
  }

  .hover\:tw-border-site-gray-lighter:hover {
    border-color: var(--token-site-gray-lighter);
  }

  .hover\:tw-border-site-green:hover {
    border-color: var(--token-site-green);
  }

  .hover\:tw-border-text-link:hover {
    border-color: var(--token-text-link-default);
  }

  .hover\:\!tw-bg-green-600:hover {
    background-color: var(--token-green-600) !important;
  }

  .hover\:tw-bg-\[\#599e3d\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(89, 158, 61, var(--tw-bg-opacity));
  }

  .hover\:tw-bg-\[\#9c1d20\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(156, 29, 32, var(--tw-bg-opacity));
  }

  .hover\:tw-bg-\[\#C4DDF8\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(196, 221, 248, var(--tw-bg-opacity));
  }

  .hover\:tw-bg-\[\#ddd\]:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(221, 221, 221, var(--tw-bg-opacity));
  }

  .hover\:tw-bg-bg-btnPrimary-hover:hover {
    background-color: var(--token-bg-btnPrimary-hover);
  }

  .hover\:tw-bg-bg-btnSecondary-hover:hover {
    background-color: var(--token-bg-btnSecondary-hover);
  }

  .hover\:tw-bg-gray-100:hover {
    background-color: var(--token-gray-100);
  }

  .hover\:tw-bg-gray-50:hover {
    background-color: var(--token-gray-50);
  }

  .hover\:tw-bg-site-gray-bg:hover {
    background-color: var(--token-site-gray-bg);
  }

  .hover\:tw-bg-site-gray-light:hover {
    background-color: var(--token-site-gray-light);
  }

  .hover\:tw-bg-site-gray-lighter:hover {
    background-color: var(--token-site-gray-lighter);
  }

  .hover\:tw-bg-system-danger-700:hover {
    background-color: var(--token-system-danger-700);
  }

  .hover\:tw-bg-white:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(255, 255, 255, var(--tw-bg-opacity));
  }

  .hover\:\!tw-text-gray-50:hover {
    color: var(--token-gray-50) !important;
  }

  .hover\:tw-text-gray-50:hover {
    color: var(--token-gray-50);
  }

  .hover\:tw-text-gray-700:hover {
    color: var(--token-gray-700);
  }

  .hover\:tw-text-green-700:hover {
    color: var(--token-green-700);
  }

  .hover\:tw-text-site-gray-base:hover {
    color: var(--token-site-gray-base);
  }

  .hover\:tw-text-site-gray-medium:hover {
    color: var(--token-site-gray-medium);
  }

  .hover\:tw-text-site-gray-mediumdark:hover {
    color: var(--token-site-gray-mediumdark);
  }

  .hover\:tw-text-text:hover {
    color: var(--token-text-default);
  }

  .hover\:tw-text-white:hover {
    --tw-text-opacity: 1;
    color: rgba(255, 255, 255, var(--tw-text-opacity));
  }

  .hover\:tw-underline:hover {
    text-decoration-line: underline;
  }

  .hover\:\!tw-no-underline:hover {
    text-decoration-line: none !important;
  }

  .hover\:tw-no-underline:hover {
    text-decoration-line: none;
  }

  .hover\:before\:tw-opacity-50:hover:before {
    content: var(--tw-content);
    opacity: .5;
  }

  .checked\:hover\:before\:tw-opacity-100:hover:checked:before {
    content: var(--tw-content);
    opacity: 1;
  }
}

.focus\:\!tw-outline-none:focus {
  outline-offset: 2px !important;
  outline: 2px solid rgba(0, 0, 0, 0) !important;
}

.focus\:tw-outline:focus {
  outline-style: solid;
}

.focus\:tw-outline-2:focus {
  outline-width: 2px;
}

.focus\:tw-outline-\[\#206fe4\]:focus {
  outline-color: #206fe4;
}

.focus\:tw-no-underline:focus {
  text-decoration-line: none;
}

.focus-visible\:tw-outline-none:focus-visible {
  outline-offset: 2px;
  outline: 2px solid rgba(0, 0, 0, 0);
}

.active\:tw-bg-green-700:active {
  background-color: var(--token-green-700);
}

.active\:tw-bg-site-gray-lighter:active {
  background-color: var(--token-site-gray-lighter);
}

.active\:tw-no-underline:active {
  text-decoration-line: none;
}

.disabled\:tw-cursor-default:disabled {
  cursor: default;
}

.disabled\:tw-cursor-not-allowed:disabled {
  cursor: not-allowed;
}

.disabled\:tw-opacity-100:disabled {
  opacity: 1;
}

.disabled\:tw-opacity-30:disabled {
  opacity: .3;
}

.disabled\:tw-opacity-60:disabled {
  opacity: .6;
}

.disabled\:tw-opacity-70:disabled {
  opacity: .7;
}

@media (hover: hover) and (pointer: fine) {
  .tw-group:hover .group-hover\:tw-block {
    display: block;
  }

  .tw-group:hover .group-hover\:tw-hidden {
    display: none;
  }

  .tw-group:hover .group-hover\:tw-bg-site-gray-bg {
    background-color: var(--token-site-gray-bg);
  }

  .tw-group:hover .group-hover\:tw-ring-site-gray-darkaccent {
    --tw-ring-color: var(--token-site-gray-darkaccent);
  }

  .tw-group:hover .group-hover\:tw-underline {
    text-decoration-line: underline;
  }
}

.tw-group:active .group-active\:tw-bg-site-gray-lighter {
  background-color: var(--token-site-gray-lighter);
}

.tw-group:active .group-active\:tw-ring-site-gray-medium {
  --tw-ring-color: var(--token-site-gray-medium);
}

.tw-group.sticky .group-\[\.sticky\]\:tw-mt-0 {
  margin-top: 0;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-block {
  display: block;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-hidden {
  display: none;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-bg-bg-lightest {
  background-color: var(--token-bg-lightest);
}

.tw-group.sticky .group-\[\.sticky\]\:tw-pt-2 {
  padding-top: .5rem;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.tw-group.sticky .group-\[\.sticky\]\:tw-shadow-\[0_1px_5px_0_rgba\(37\,38\,37\,0\.15\)\,0_0_12px_0_rgba\(37\,38\,37\,0\.05\)\] {
  --tw-shadow: 0 1px 5px 0 rgba(37, 38, 37, .15), 0 0 12px 0 rgba(37, 38, 37, .05);
  --tw-shadow-colored: 0 1px 5px 0 var(--tw-shadow-color), 0 0 12px 0 var(--tw-shadow-color);
  box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
}

.tw-peer:checked ~ .peer-checked\:tw-bg-primary-500 {
  background-color: var(--token-primary-500);
}

.tw-peer:checked ~ .peer-checked\:tw-text-white {
  --tw-text-opacity: 1;
  color: rgba(255, 255, 255, var(--tw-text-opacity));
}

.aria-disabled\:tw-cursor-default[aria-disabled="true"] {
  cursor: default;
}

.aria-disabled\:tw-opacity-30[aria-disabled="true"] {
  opacity: .3;
}

.tw-group[aria-expanded="true"] .group-aria-expanded\:tw-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-group[aria-expanded="true"] .group-aria-expanded\:tw-rotate-90 {
  --tw-rotate: 90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.tw-peer[aria-expanded="true"] ~ .peer-aria-expanded\:tw-line-clamp-none {
  -webkit-line-clamp: none;
  -webkit-box-orient: horizontal;
  display: block;
  overflow: visible;
}

.tw-group[data-online="true"] .group-data-\[online\=true\]\:tw-block {
  display: block;
}

.tw-group[data-open="false"] .group-data-\[open\=false\]\:tw-hidden, .tw-group[data-open="true"] .group-data-\[open\=true\]\:tw-hidden {
  display: none;
}

.tw-group[data-live-session-on="true"] .group-data-\[live-session-on\=true\]\:tw-text-text-link {
  color: var(--token-text-link-default);
}

.tw-theme-betterhelp .bh\:tw-mt-0 {
  margin-top: 0;
}

.tw-theme-betterhelp .bh\:tw-mt-4 {
  margin-top: 1rem;
}

.tw-theme-betterhelp .bh\:tw-mt-\[16px\] {
  margin-top: 16px;
}

.tw-theme-betterhelp .bh\:tw-hidden {
  display: none;
}

.tw-theme-betterhelp .bh\:tw-text-green-500 {
  color: var(--token-green-500);
}

.tw-theme-betterhelp .bh\:tw-text-site-green {
  color: var(--token-site-green);
}

@media (hover: hover) and (pointer: fine) {
  .tw-theme-betterhelp .hover\:bh\:tw-border-site-green:hover {
    border-color: var(--token-site-green);
  }
}

.tw-theme-betterhelp .tw-group[data-live-session-on="true"] .bh\:group-data-\[live-session-on\=true\]\:tw-text-site-green {
  color: var(--token-site-green);
}

.tw-theme-teencounseling .teencounseling\:tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-theme-teencounseling .teencounseling\:tw-mt-4 {
  margin-top: 1rem;
}

.tw-theme-teencounseling .teencounseling\:tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-theme-teencounseling .teencounseling\:tw-mt-8 {
  margin-top: 2rem;
}

.tw-theme-teencounseling .teencounseling\:tw-inline {
  display: inline;
}

.tw-theme-teencounseling .teencounseling\:tw-hidden {
  display: none;
}

.tw-theme-teencounseling .teencounseling\:tw-text-gray-50 {
  color: var(--token-gray-50);
}

.tw-theme-teencounseling .teencounseling\:tw-text-green-500 {
  color: var(--token-green-500);
}

.tw-theme-teencounseling .teencounseling\:before\:tw-border-l-gray-50:before {
  content: var(--tw-content);
  border-left-color: var(--token-gray-50);
}

.tw-theme-regain .regain\:tw-mb-4 {
  margin-bottom: 1rem;
}

.tw-theme-regain .regain\:tw-mt-0 {
  margin-top: 0;
}

.tw-theme-regain .regain\:tw-mt-4 {
  margin-top: 1rem;
}

.tw-theme-regain .regain\:tw-mt-6 {
  margin-top: 1.5rem;
}

.tw-theme-regain .regain\:tw-mt-8 {
  margin-top: 2rem;
}

.tw-theme-regain .regain\:tw-text-purple-600 {
  color: var(--token-purple-600);
}

.tw-user-counselor .counselor\:tw-inline-block {
  display: inline-block;
}

.tw-user-counselor .counselor\:tw-hidden {
  display: none;
}

@media not all and (min-width: 992px) {
  .max-lg\:tw-text-center {
    text-align: center;
  }

  .tw-user-counselor .counselor\:max-lg\:tw-flex {
    display: flex;
  }
}

@media not all and (min-width: 768px) {
  .max-md\:tw-mx-\[14px\] {
    margin-left: 14px;
    margin-right: 14px;
  }

  .max-md\:\!tw-w-full {
    width: 100% !important;
  }

  .max-md\:\!tw-p-0 {
    padding: 0 !important;
  }
}

@media (min-width: 375px) {
  .\33 xs\:tw-w-\[325px\] {
    width: 325px;
  }

  .\33 xs\:tw-gap-4 {
    gap: 1rem;
  }

  .\33 xs\:tw-text-\[16px\] {
    font-size: 16px;
  }
}

@media (min-width: 390px) {
  .xs\:tw-max-w-\[180px\] {
    max-width: 180px;
  }

  .xs\:tw-px-\[24px\] {
    padding-left: 24px;
    padding-right: 24px;
  }

  .xs\:tw-px-\[26px\] {
    padding-left: 26px;
    padding-right: 26px;
  }

  .xs\:tw-py-\[12px\] {
    padding-top: 12px;
    padding-bottom: 12px;
  }
}

@media (min-width: 640px) {
  .sm\:tw-bottom-auto {
    bottom: auto;
  }

  .sm\:tw-right-6 {
    right: 1.5rem;
  }

  .sm\:tw-top-6 {
    top: 1.5rem;
  }

  .sm\:tw-top-\[77px\] {
    top: 77px;
  }

  .sm\:tw-order-1 {
    order: 1;
  }

  .sm\:tw-order-2 {
    order: 2;
  }

  .sm\:tw-float-right {
    float: right;
  }

  .sm\:\!tw-my-\[25px\] {
    margin-top: 25px !important;
    margin-bottom: 25px !important;
  }

  .sm\:\!tw--mt-2 {
    margin-top: -.5rem !important;
  }

  .sm\:\!tw-mb-0 {
    margin-bottom: 0 !important;
  }

  .sm\:\!tw-mb-\[25px\] {
    margin-bottom: 25px !important;
  }

  .sm\:tw-mb-0 {
    margin-bottom: 0;
  }

  .sm\:tw-mb-4 {
    margin-bottom: 1rem;
  }

  .sm\:tw-mb-\[15px\] {
    margin-bottom: 15px;
  }

  .sm\:tw-ml-\[15px\] {
    margin-left: 15px;
  }

  .sm\:tw-mr-\[15px\] {
    margin-right: 15px;
  }

  .sm\:tw-mt-0 {
    margin-top: 0;
  }

  .sm\:tw-mt-2 {
    margin-top: .5rem;
  }

  .sm\:tw-mt-\[10px\] {
    margin-top: 10px;
  }

  .sm\:tw-block {
    display: block;
  }

  .sm\:tw-inline-block {
    display: inline-block;
  }

  .sm\:tw-inline {
    display: inline;
  }

  .sm\:tw-flex {
    display: flex;
  }

  .sm\:tw-hidden {
    display: none;
  }

  .sm\:tw-h-\[349px\] {
    height: 349px;
  }

  .sm\:tw-h-\[35px\] {
    height: 35px;
  }

  .sm\:tw-h-\[46px\] {
    height: 46px;
  }

  .sm\:tw-w-3\/5 {
    width: 60%;
  }

  .sm\:tw-w-\[420px\] {
    width: 420px;
  }

  .sm\:tw-w-\[500px\] {
    width: 500px;
  }

  .sm\:tw-w-auto {
    width: auto;
  }

  .sm\:tw-w-full {
    width: 100%;
  }

  .sm\:tw-flex-grow {
    flex-grow: 1;
  }

  .sm\:tw-translate-x-0 {
    --tw-translate-x: 0px;
    -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
    transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  }

  .sm\:tw-columns-3 {
    columns: 3;
  }

  .sm\:tw-flex-row {
    flex-direction: row;
  }

  .sm\:tw-flex-col {
    flex-direction: column;
  }

  .sm\:tw-items-center {
    align-items: center;
  }

  .sm\:\!tw-items-stretch {
    align-items: stretch !important;
  }

  .sm\:tw-justify-between {
    justify-content: space-between;
  }

  .sm\:tw-justify-around {
    justify-content: space-around;
  }

  .sm\:tw-gap-0 {
    gap: 0;
  }

  .sm\:tw-gap-\[40px\] {
    gap: 40px;
  }

  .sm\:tw-gap-x-\[33rem\] {
    column-gap: 33rem;
  }

  .sm\:tw-gap-y-0 {
    row-gap: 0;
  }

  .sm\:tw-border-0 {
    border-width: 0;
  }

  .sm\:\!tw-border-t {
    border-top-width: 1px !important;
  }

  .sm\:\!tw-p-\[30px\] {
    padding: 30px !important;
  }

  .sm\:tw-p-6 {
    padding: 1.5rem;
  }

  .sm\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .sm\:tw-px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .sm\:tw-px-\[80px\] {
    padding-left: 80px;
    padding-right: 80px;
  }

  .sm\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .sm\:tw-py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .sm\:\!tw-pt-\[20px\] {
    padding-top: 20px !important;
  }

  .sm\:tw-pl-16 {
    padding-left: 4rem;
  }

  .sm\:tw-pt-4 {
    padding-top: 1rem;
  }

  .sm\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .sm\:tw-text-left {
    text-align: left;
  }

  .sm\:tw-text-right {
    text-align: right;
  }

  .tw-theme-betterhelp .bh\:sm\:tw--mt-7 {
    margin-top: -1.75rem;
  }

  .tw-theme-betterhelp .bh\:sm\:tw-mt-2 {
    margin-top: .5rem;
  }

  .tw-theme-regain .regain\:sm\:tw--mt-7 {
    margin-top: -1.75rem;
  }
}

@media (min-width: 768px) {
  .md\:tw-static {
    position: static;
  }

  .md\:tw-absolute {
    position: absolute;
  }

  .md\:tw-relative {
    position: relative;
  }

  .md\:tw-inset-x-0 {
    left: 0;
    right: 0;
  }

  .md\:tw-bottom-0 {
    bottom: 0;
  }

  .md\:tw-left-0 {
    left: 0;
  }

  .md\:tw-left-\[110px\] {
    left: 110px;
  }

  .md\:tw-left-\[228px\] {
    left: 228px;
  }

  .md\:tw-left-\[36px\] {
    left: 36px;
  }

  .md\:tw-left-auto {
    left: auto;
  }

  .md\:tw-right-0 {
    right: 0;
  }

  .md\:tw-right-1 {
    right: .25rem;
  }

  .md\:tw-right-\[17px\] {
    right: 17px;
  }

  .md\:tw-top-0 {
    top: 0;
  }

  .md\:tw-top-7 {
    top: 1.75rem;
  }

  .md\:tw-top-\[122px\] {
    top: 122px;
  }

  .md\:tw-top-\[220px\] {
    top: 220px;
  }

  .md\:tw-top-\[248px\] {
    top: 248px;
  }

  .md\:tw-top-\[84px\] {
    top: 84px;
  }

  .md\:tw-top-\[calc\(100\%_-_4px\)\] {
    top: calc(100% - 4px);
  }

  .md\:tw-top-auto {
    top: auto;
  }

  .md\:tw-z-10 {
    z-index: 10;
  }

  .md\:tw-order-none {
    order: 0;
  }

  .md\:tw-float-none {
    float: none;
  }

  .md\:-tw-mx-\[30px\] {
    margin-left: -30px;
    margin-right: -30px;
  }

  .md\:tw-mx-0 {
    margin-left: 0;
    margin-right: 0;
  }

  .md\:tw-my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  .md\:\!tw-mb-0 {
    margin-bottom: 0 !important;
  }

  .md\:\!tw-ml-\[15px\] {
    margin-left: 15px !important;
  }

  .md\:\!tw-mt-\[24px\] {
    margin-top: 24px !important;
  }

  .md\:-tw-mt-9 {
    margin-top: -2.25rem;
  }

  .md\:tw--mt-3 {
    margin-top: -.75rem;
  }

  .md\:tw-mb-0 {
    margin-bottom: 0;
  }

  .md\:tw-mb-2 {
    margin-bottom: .5rem;
  }

  .md\:tw-mb-\[16px\] {
    margin-bottom: 16px;
  }

  .md\:tw-mb-\[24px\] {
    margin-bottom: 24px;
  }

  .md\:tw-mb-\[44px\] {
    margin-bottom: 44px;
  }

  .md\:tw-mb-\[8px\] {
    margin-bottom: 8px;
  }

  .md\:tw-ml-10 {
    margin-left: 2.5rem;
  }

  .md\:tw-ml-20 {
    margin-left: 5rem;
  }

  .md\:tw-ml-4 {
    margin-left: 1rem;
  }

  .md\:tw-ml-\[10px\] {
    margin-left: 10px;
  }

  .md\:tw-ml-auto {
    margin-left: auto;
  }

  .md\:tw-mr-1 {
    margin-right: .25rem;
  }

  .md\:tw-mr-1\.5 {
    margin-right: .375rem;
  }

  .md\:tw-mr-2 {
    margin-right: .5rem;
  }

  .md\:tw-mr-2\.5 {
    margin-right: .625rem;
  }

  .md\:tw-mr-4 {
    margin-right: 1rem;
  }

  .md\:tw-mr-6 {
    margin-right: 1.5rem;
  }

  .md\:tw-mr-\[20px\] {
    margin-right: 20px;
  }

  .md\:tw-mt-0 {
    margin-top: 0;
  }

  .md\:tw-mt-1 {
    margin-top: .25rem;
  }

  .md\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .md\:tw-mt-\[-30px\] {
    margin-top: -30px;
  }

  .md\:tw-mt-\[20px\] {
    margin-top: 20px;
  }

  .md\:tw-mt-\[30px\] {
    margin-top: 30px;
  }

  .md\:tw-mt-\[80px\] {
    margin-top: 80px;
  }

  .md\:tw-mt-\[8px\] {
    margin-top: 8px;
  }

  .md\:tw-block {
    display: block;
  }

  .md\:tw-inline-block {
    display: inline-block;
  }

  .md\:tw-inline {
    display: inline;
  }

  .md\:tw-flex {
    display: flex;
  }

  .md\:tw-grid {
    display: grid;
  }

  .md\:\!tw-hidden {
    display: none !important;
  }

  .md\:tw-hidden {
    display: none;
  }

  .md\:\!tw-h-\[176px\] {
    height: 176px !important;
  }

  .md\:tw-h-3 {
    height: .75rem;
  }

  .md\:tw-h-7 {
    height: 1.75rem;
  }

  .md\:tw-h-9 {
    height: 2.25rem;
  }

  .md\:tw-h-\[--navHeight\] {
    height: var(--navHeight);
  }

  .md\:tw-h-\[168px\] {
    height: 168px;
  }

  .md\:tw-h-\[307px\] {
    height: 307px;
  }

  .md\:tw-h-\[38px\] {
    height: 38px;
  }

  .md\:tw-h-\[48px\] {
    height: 48px;
  }

  .md\:tw-h-auto {
    height: auto;
  }

  .md\:tw-h-screen {
    height: 100vh;
  }

  .md\:tw-min-h-\[100px\] {
    min-height: 100px;
  }

  .md\:tw-min-h-\[200px\] {
    min-height: 200px;
  }

  .md\:tw-min-h-\[356px\] {
    min-height: 356px;
  }

  .md\:\!tw-w-\[250px\] {
    width: 250px !important;
  }

  .md\:\!tw-w-auto {
    width: auto !important;
  }

  .md\:tw-w-3 {
    width: .75rem;
  }

  .md\:tw-w-64 {
    width: 16rem;
  }

  .md\:tw-w-7 {
    width: 1.75rem;
  }

  .md\:tw-w-9 {
    width: 2.25rem;
  }

  .md\:tw-w-\[156px\] {
    width: 156px;
  }

  .md\:tw-w-\[168px\] {
    width: 168px;
  }

  .md\:tw-w-\[227px\] {
    width: 227px;
  }

  .md\:tw-w-\[246px\] {
    width: 246px;
  }

  .md\:tw-w-\[389px\] {
    width: 389px;
  }

  .md\:tw-w-\[38px\] {
    width: 38px;
  }

  .md\:tw-w-\[400px\] {
    width: 400px;
  }

  .md\:tw-w-auto {
    width: auto;
  }

  .md\:tw-max-w-\[168px\] {
    max-width: 168px;
  }

  .md\:tw-max-w-\[200px\] {
    max-width: 200px;
  }

  .md\:tw-max-w-\[285px\] {
    max-width: 285px;
  }

  .md\:tw-max-w-\[468px\] {
    max-width: 468px;
  }

  .md\:tw-max-w-\[600px\] {
    max-width: 600px;
  }

  .md\:tw-grow {
    flex-grow: 1;
  }

  .md\:\!tw-basis-\[calc\(33\.333333\%-40px\)\] {
    flex-basis: calc(33.3333% - 40px) !important;
  }

  .md\:tw-basis-1\/2 {
    flex-basis: 50%;
  }

  .md\:tw-grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }

  .md\:tw-grid-cols-\[323px_auto\] {
    grid-template-columns: 323px auto;
  }

  .md\:tw-grid-cols-\[theme\(\'screens\.xs\'\)_1fr\] {
    grid-template-columns: 390px 1fr;
  }

  .md\:tw-grid-cols-none {
    grid-template-columns: none;
  }

  .md\:tw-flex-row {
    flex-direction: row;
  }

  .md\:tw-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md\:tw-flex-col {
    flex-direction: column;
  }

  .md\:tw-items-end {
    align-items: flex-end;
  }

  .md\:tw-items-center {
    align-items: center;
  }

  .md\:tw-justify-normal {
    justify-content: normal;
  }

  .md\:tw-justify-center {
    justify-content: center;
  }

  .md\:tw-justify-between {
    justify-content: space-between;
  }

  .md\:tw-gap-0 {
    gap: 0;
  }

  .md\:tw-gap-\[10px\] {
    gap: 10px;
  }

  .md\:tw-gap-\[12px\] {
    gap: 12px;
  }

  .md\:tw-gap-\[16px\] {
    gap: 16px;
  }

  .md\:tw-gap-\[22px\] {
    gap: 22px;
  }

  .md\:tw-gap-\[24px\] {
    gap: 24px;
  }

  .md\:tw-gap-\[32px\] {
    gap: 32px;
  }

  .md\:tw-space-y-0 > :not([hidden]) ~ :not([hidden]) {
    --tw-space-y-reverse: 0;
    margin-top: calc(0px * calc(1 - var(--tw-space-y-reverse)));
    margin-bottom: calc(0px * var(--tw-space-y-reverse));
  }

  .md\:tw-overflow-hidden {
    overflow: hidden;
  }

  .md\:\!tw-overflow-x-scroll {
    overflow-x: scroll !important;
  }

  .md\:tw-overscroll-none {
    overscroll-behavior: none;
  }

  .md\:tw-rounded-\[10px\] {
    border-radius: 10px;
  }

  .md\:tw-rounded-none {
    border-radius: 0;
  }

  .md\:tw-rounded-xl {
    border-radius: .75rem;
  }

  .md\:tw-rounded-b-\[5px\] {
    border-bottom-right-radius: 5px;
    border-bottom-left-radius: 5px;
  }

  .md\:tw-border {
    border-width: 1px;
  }

  .md\:tw-border-b {
    border-bottom-width: 1px;
  }

  .md\:tw-border-t-0 {
    border-top-width: 0;
  }

  .md\:tw-border-t-4 {
    border-top-width: 4px;
  }

  .md\:tw-border-solid {
    border-style: solid;
  }

  .md\:tw-border-none {
    border-style: none;
  }

  .md\:tw-border-t-primary-300 {
    border-top-color: var(--token-primary-300);
  }

  .md\:tw-bg-transparent {
    background-color: rgba(0, 0, 0, 0);
  }

  .md\:tw-p-0 {
    padding: 0;
  }

  .md\:tw-p-8 {
    padding: 2rem;
  }

  .md\:tw-p-\[20px\] {
    padding: 20px;
  }

  .md\:tw-p-\[36px\] {
    padding: 36px;
  }

  .md\:tw-p-\[40px\] {
    padding: 40px;
  }

  .md\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .md\:tw-px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }

  .md\:tw-px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }

  .md\:tw-px-\[10px\] {
    padding-left: 10px;
    padding-right: 10px;
  }

  .md\:tw-px-\[15px\] {
    padding-left: 15px;
    padding-right: 15px;
  }

  .md\:tw-px-\[16px\] {
    padding-left: 16px;
    padding-right: 16px;
  }

  .md\:tw-px-\[30px\] {
    padding-left: 30px;
    padding-right: 30px;
  }

  .md\:tw-px-\[54px\] {
    padding-left: 54px;
    padding-right: 54px;
  }

  .md\:tw-py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }

  .md\:tw-py-2 {
    padding-top: .5rem;
    padding-bottom: .5rem;
  }

  .md\:tw-py-20 {
    padding-top: 5rem;
    padding-bottom: 5rem;
  }

  .md\:tw-py-\[30px\] {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .md\:\!tw-pb-0 {
    padding-bottom: 0 !important;
  }

  .md\:\!tw-pb-\[12px\] {
    padding-bottom: 12px !important;
  }

  .md\:\!tw-pt-0 {
    padding-top: 0 !important;
  }

  .md\:tw-pb-0 {
    padding-bottom: 0;
  }

  .md\:tw-pb-40 {
    padding-bottom: 10rem;
  }

  .md\:tw-pl-0 {
    padding-left: 0;
  }

  .md\:tw-pl-\[30px\] {
    padding-left: 30px;
  }

  .md\:tw-pt-0 {
    padding-top: 0;
  }

  .md\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .md\:tw-pt-\[7px\] {
    padding-top: 7px;
  }

  .md\:tw-text-left {
    text-align: left;
  }

  .md\:tw-text-5xl {
    font-size: 3rem;
    line-height: 1;
  }

  .md\:tw-text-\[10px\] {
    font-size: 10px;
  }

  .md\:tw-text-\[14px\] {
    font-size: 14px;
  }

  .md\:tw-text-\[16px\] {
    font-size: 16px;
  }

  .md\:tw-text-\[20px\] {
    font-size: 20px;
  }

  .md\:tw-text-\[24px\] {
    font-size: 24px;
  }

  .md\:tw-text-\[28px\] {
    font-size: 28px;
  }

  .md\:tw-text-base {
    font-size: 1rem;
    line-height: 1.5rem;
  }

  .md\:tw-leading-6 {
    line-height: 1.5rem;
  }

  .md\:tw-leading-\[--navHeight\] {
    line-height: var(--navHeight);
  }

  .md\:tw-leading-\[56px\] {
    line-height: 56px;
  }

  .md\:tw-text-gray-50 {
    color: var(--token-gray-50);
  }

  .md\:tw-text-site-gray {
    color: var(--token-site-gray);
  }

  .md\:tw-text-site-green {
    color: var(--token-site-green);
  }

  .md\:tw-shadow-dropdown {
    --tw-shadow: 0px 0px 4px 0px rgba(37, 38, 37, .15), 0px 8px 12px 0px rgba(37, 38, 37, .15);
    --tw-shadow-colored: 0px 0px 4px 0px var(--tw-shadow-color), 0px 8px 12px 0px var(--tw-shadow-color);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .md\:tw-shadow-none {
    --tw-shadow: 0 0 rgba(0, 0, 0, 0);
    --tw-shadow-colored: 0 0 rgba(0, 0, 0, 0);
    box-shadow: var(--tw-ring-offset-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-ring-shadow, 0 0 rgba(0, 0, 0, 0)), var(--tw-shadow);
  }

  .md\:tw-drop-shadow {
    --tw-drop-shadow: drop-shadow(0 1px 2px rgba(0, 0, 0, .1)) drop-shadow(0 1px 1px rgba(0, 0, 0, .06));
    -webkit-filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
    filter: var(--tw-blur) var(--tw-brightness) var(--tw-contrast) var(--tw-grayscale) var(--tw-hue-rotate) var(--tw-invert) var(--tw-saturate) var(--tw-sepia) var(--tw-drop-shadow);
  }

  .md\:before\:tw-left-8:before {
    content: var(--tw-content);
    left: 2rem;
  }

  .md\:before\:tw-right-8:before {
    content: var(--tw-content);
    right: 2rem;
  }

  .after\:md\:tw-h-12:after {
    content: var(--tw-content);
    height: 3rem;
  }

  @media (hover: hover) and (pointer: fine) {
    .md\:hover\:tw-bg-transparent:hover {
      background-color: rgba(0, 0, 0, 0);
    }
  }

  .md\:focus\:tw-top-20:focus {
    top: 5rem;
  }

  .tw-group.sticky .md\:group-\[\.sticky\]\:tw-pt-4 {
    padding-top: 1rem;
  }

  .tw-user-counselor .counselor\:md\:tw-inline-block {
    display: inline-block;
  }

  .tw-user-counselor .counselor\:md\:tw-hidden {
    display: none;
  }

  @media not all and (min-width: 992px) {
    .md\:max-lg\:tw-inline-block {
      display: inline-block;
    }
  }
}

@media (min-width: 992px) {
  .lg\:tw-absolute {
    position: absolute;
  }

  .lg\:tw-left-28 {
    left: 7rem;
  }

  .lg\:tw-left-56 {
    left: 14rem;
  }

  .lg\:tw-left-\[40px\] {
    left: 40px;
  }

  .lg\:tw-right-0 {
    right: 0;
  }

  .lg\:tw-right-\[18px\] {
    right: 18px;
  }

  .lg\:tw-top-0 {
    top: 0;
  }

  .lg\:tw-top-\[124px\] {
    top: 124px;
  }

  .lg\:tw-top-\[252px\] {
    top: 252px;
  }

  .lg\:tw-top-\[85px\] {
    top: 85px;
  }

  .lg\:tw-top-auto {
    top: auto;
  }

  .lg\:tw-my-0 {
    margin-top: 0;
    margin-bottom: 0;
  }

  .lg\:tw-my-10 {
    margin-top: 2.5rem;
    margin-bottom: 2.5rem;
  }

  .lg\:\!tw-mb-\[48px\] {
    margin-bottom: 48px !important;
  }

  .lg\:tw-mb-6 {
    margin-bottom: 1.5rem;
  }

  .lg\:tw-mb-\[18px\] {
    margin-bottom: 18px;
  }

  .lg\:tw-mb-\[40px\] {
    margin-bottom: 40px;
  }

  .lg\:tw-ml-0 {
    margin-left: 0;
  }

  .lg\:tw-mr-14 {
    margin-right: 3.5rem;
  }

  .lg\:tw-mt-0 {
    margin-top: 0;
  }

  .lg\:tw-mt-6 {
    margin-top: 1.5rem;
  }

  .lg\:tw-mt-\[10px\] {
    margin-top: 10px;
  }

  .lg\:tw-mt-\[114px\] {
    margin-top: 114px;
  }

  .lg\:tw-mt-\[20px\] {
    margin-top: 20px;
  }

  .lg\:tw-mt-\[68px\] {
    margin-top: 68px;
  }

  .lg\:tw-line-clamp-\[6\] {
    -webkit-line-clamp: 6;
    -webkit-box-orient: vertical;
    display: -webkit-box;
    overflow: hidden;
  }

  .lg\:tw-block {
    display: block;
  }

  .lg\:tw-inline-block {
    display: inline-block;
  }

  .lg\:tw-inline {
    display: inline;
  }

  .lg\:tw-hidden {
    display: none;
  }

  .lg\:tw-h-10 {
    height: 2.5rem;
  }

  .lg\:tw-h-8 {
    height: 2rem;
  }

  .lg\:tw-h-\[311px\] {
    height: 311px;
  }

  .lg\:tw-h-\[48px\] {
    height: 48px;
  }

  .lg\:tw-h-\[493px\] {
    height: 493px;
  }

  .lg\:tw-h-\[56px\] {
    height: 56px;
  }

  .lg\:\!tw-w-\[676px\] {
    width: 676px !important;
  }

  .lg\:tw-w-1\/2 {
    width: 50%;
  }

  .lg\:tw-w-10 {
    width: 2.5rem;
  }

  .lg\:tw-w-8 {
    width: 2rem;
  }

  .lg\:tw-w-\[230px\] {
    width: 230px;
  }

  .lg\:tw-w-\[235px\] {
    width: 235px;
  }

  .lg\:tw-w-\[249px\] {
    width: 249px;
  }

  .lg\:tw-w-\[353px\] {
    width: 353px;
  }

  .lg\:tw-w-\[395px\] {
    width: 395px;
  }

  .lg\:tw-w-\[48px\] {
    width: 48px;
  }

  .lg\:tw-w-\[714px\] {
    width: 714px;
  }

  .lg\:tw-w-\[800px\] {
    width: 800px;
  }

  .lg\:tw-w-full {
    width: 100%;
  }

  .lg\:tw-max-w-3xl {
    max-width: 48rem;
  }

  .lg\:tw-max-w-\[204px\] {
    max-width: 204px;
  }

  .lg\:tw-max-w-\[87px\] {
    max-width: 87px;
  }

  .lg\:tw-grid-cols-\[517px_1fr\] {
    grid-template-columns: 517px 1fr;
  }

  .lg\:tw-flex-row {
    flex-direction: row;
  }

  .lg\:tw-items-end {
    align-items: flex-end;
  }

  .lg\:tw-items-center {
    align-items: center;
  }

  .lg\:tw-gap-\[180px\] {
    gap: 180px;
  }

  .lg\:tw-gap-\[20px\] {
    gap: 20px;
  }

  .lg\:tw-gap-y-\[4px\] {
    row-gap: 4px;
  }

  .lg\:tw-self-center {
    align-self: center;
  }

  .lg\:\!tw-p-0 {
    padding: 0 !important;
  }

  .lg\:tw-p-\[30px\] {
    padding: 30px;
  }

  .lg\:tw-p-\[40px\] {
    padding: 40px;
  }

  .lg\:\!tw-px-0 {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  .lg\:\!tw-px-\[10px\] {
    padding-left: 10px !important;
    padding-right: 10px !important;
  }

  .lg\:\!tw-px-\[12px\] {
    padding-left: 12px !important;
    padding-right: 12px !important;
  }

  .lg\:tw-px-0 {
    padding-left: 0;
    padding-right: 0;
  }

  .lg\:tw-px-\[120px\] {
    padding-left: 120px;
    padding-right: 120px;
  }

  .lg\:tw-px-\[12px\] {
    padding-left: 12px;
    padding-right: 12px;
  }

  .lg\:tw-py-\[1\.2rem\] {
    padding-top: 1.2rem;
    padding-bottom: 1.2rem;
  }

  .lg\:tw-py-\[40px\] {
    padding-top: 40px;
    padding-bottom: 40px;
  }

  .lg\:\!tw-pl-\[24px\] {
    padding-left: 24px !important;
  }

  .lg\:\!tw-pt-\[26px\] {
    padding-top: 26px !important;
  }

  .lg\:tw-pb-44 {
    padding-bottom: 11rem;
  }

  .lg\:tw-pb-\[36px\] {
    padding-bottom: 36px;
  }

  .lg\:tw-pl-\[40px\] {
    padding-left: 40px;
  }

  .lg\:tw-pt-10 {
    padding-top: 2.5rem;
  }

  .lg\:tw-pt-11 {
    padding-top: 2.75rem;
  }

  .lg\:tw-pt-\[80px\] {
    padding-top: 80px;
  }

  .lg\:tw-text-left {
    text-align: left;
  }

  .lg\:\!tw-text-\[32px\] {
    font-size: 32px !important;
  }

  .lg\:tw-text-\[2\.4rem\] {
    font-size: 2.4rem;
  }

  .lg\:tw-text-\[20px\] {
    font-size: 20px;
  }

  .lg\:tw-text-\[24px\] {
    font-size: 24px;
  }

  .lg\:tw-leading-\[40px\] {
    line-height: 40px;
  }

  .lg\:\!tw-no-underline {
    text-decoration-line: none !important;
  }

  .after\:lg\:tw-h-16:after {
    content: var(--tw-content);
    height: 4rem;
  }

  .tw-group.sticky .lg\:group-\[\.sticky\]\:tw-block {
    display: block;
  }

  .tw-group.sticky .lg\:group-\[\.sticky\]\:tw-bg-bg-darkest {
    background-color: var(--token-bg-darkest);
  }

  .tw-group.sticky .lg\:group-\[\.sticky\]\:tw-pt-6 {
    padding-top: 1.5rem;
  }

  .min-\[992px\]\:tw-col-end-2 {
    grid-column-end: 2;
  }

  .min-\[992px\]\:tw-row-start-1 {
    grid-row-start: 1;
  }

  .min-\[992px\]\:tw-row-end-4 {
    grid-row-end: 4;
  }

  .min-\[992px\]\:tw-w-\[72px\] {
    width: 72px;
  }
}

@media (min-width: 1072px) {
  .min-\[1072px\]\:\!tw-overflow-x-visible {
    overflow-x: visible !important;
  }
}

@media (min-width: 1280px) {
  .xl\:tw-left-\[139px\] {
    left: 139px;
  }

  .xl\:tw-left-\[281px\] {
    left: 281px;
  }

  .xl\:tw-right-1 {
    right: .25rem;
  }

  .xl\:tw-right-1\.5 {
    right: .375rem;
  }

  .xl\:tw-right-\[22px\] {
    right: 22px;
  }

  .xl\:tw-top-9 {
    top: 2.25rem;
  }

  .xl\:tw-top-\[107px\] {
    top: 107px;
  }

  .xl\:tw-top-\[155px\] {
    top: 155px;
  }

  .xl\:tw-top-\[315px\] {
    top: 315px;
  }

  .xl\:tw-h-11 {
    height: 2.75rem;
  }

  .xl\:tw-h-12 {
    height: 3rem;
  }

  .xl\:tw-h-9 {
    height: 2.25rem;
  }

  .xl\:tw-h-\[389px\] {
    height: 389px;
  }

  .xl\:tw-w-11 {
    width: 2.75rem;
  }

  .xl\:tw-w-12 {
    width: 3rem;
  }

  .xl\:tw-w-72 {
    width: 18rem;
  }

  .xl\:tw-w-9 {
    width: 2.25rem;
  }

  .xl\:tw-w-\[312px\] {
    width: 312px;
  }

  .xl\:tw-w-\[494px\] {
    width: 494px;
  }
}

@media (min-width: 1536px) {
  .\32 xl\:tw-flex-row {
    flex-direction: row;
  }
}

.\[\&\.open_\]\:tw-flex.open {
  display: flex;
}

.\[\&\.open_\]\:tw-flex-col.open {
  flex-direction: column;
}

@media (min-width: 768px) {
  .md\:\[\&\.open_\]\:tw-flex-row.open {
    flex-direction: row;
  }
}

.\[\&\:\:-webkit-calendar-picker-indicator\]\:-tw-mt-\[0\.5em\]::-webkit-calendar-picker-indicator {
  margin-top: -.5em;
}

.\[\&\>\.alert\]\:tw-mb-0 > .alert {
  margin-bottom: 0;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-mt-\[25px\] > div:not(:first-child) {
  margin-top: 25px;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-border-x-0 > div:not(:first-child) {
  border-left-width: 0;
  border-right-width: 0;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-border-b-0 > div:not(:first-child) {
  border-bottom-width: 0;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-border-t > div:not(:first-child) {
  border-top-width: 1px;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-border-solid > div:not(:first-child) {
  border-style: solid;
}

.\[\&\>div\:not\(\:first-child\)\]\:tw-border-t-\[\#DDD\] > div:not(:first-child) {
  --tw-border-opacity: 1;
  border-top-color: rgba(221, 221, 221, var(--tw-border-opacity));
}

.\[\&_\#body-container\>\#content-container\]\:tw-pb-0 #body-container > #content-container {
  padding-bottom: 0;
}

.\[\&_\#body-container\>\#content-container\]\:tw-pt-16 #body-container > #content-container {
  padding-top: 4rem;
}

.\[\&_\#upperbar\]\:tw-bg-inherit #upperbar {
  background-color: inherit;
}

.\[\&_\.sticky\]\:tw-fixed .sticky {
  position: fixed;
}

.\[\&_\.sticky\]\:tw-z-10 .sticky {
  z-index: 10;
}

.\[\&_\.sticky\]\:tw-w-full .sticky {
  width: 100%;
}

.\[\&_img\]\:tw-max-w-full img {
  max-width: 100%;
}

.\[\&_td\]\:tw-p-\[15px\] td {
  padding: 15px;
}

.\[\&_th\]\:tw-px-\[15px\] th {
  padding-left: 15px;
  padding-right: 15px;
}

.odd\:\[\&_tr\]\:\!tw-bg-white tr:nth-child(2n+1) {
  --tw-bg-opacity: 1 !important;
  background-color: rgba(255, 255, 255, var(--tw-bg-opacity)) !important;
}

.even\:\[\&_tr\]\:\!tw-bg-gray-50 tr:nth-child(2n) {
  background-color: var(--token-gray-50) !important;
}

.collapse.in.\[\.collapse\.in\&\]\:tw-flex {
  display: flex;
}

.collapsed > .\[\.collapsed\>\&\]\:-tw-rotate-90 {
  --tw-rotate: -90deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.collapsed .\[\.collapsed_\&\]\:tw-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.collapsing.\[\.collapsing\&\]\:tw-flex {
  display: flex;
}

.dropdownBtn.open .\[\.dropdownBtn\.open_\&\]\:tw-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.navLink.open .\[\.navLink\.open_\&\]\:tw-rotate-0 {
  --tw-rotate: 0deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

.open > .\[\.open\>\&\]\:tw-flex {
  display: flex;
}

.open .\[\.open_\&\]\:tw-block {
  display: block;
}

.open .\[\.open_\&\]\:tw-hidden {
  display: none;
}

.open .\[\.open_\&\]\:tw-rotate-180 {
  --tw-rotate: 180deg;
  -webkit-transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
  transform: translate(var(--tw-translate-x), var(--tw-translate-y)) rotate(var(--tw-rotate)) skewX(var(--tw-skew-x)) skewY(var(--tw-skew-y)) scaleX(var(--tw-scale-x)) scaleY(var(--tw-scale-y));
}

@media (min-width: 768px) {
  @media (min-height: 640px) {
    .md\:\[\@media\(min-height\:640px\)\]\:tw-sticky {
      position: -webkit-sticky;
      position: sticky;
    }
  }
}

/*# sourceMappingURL=index.css.map */



.navbar-brand {
    font-weight: 400;
    color: #000!important;	
}
.nav-item {
  font-family: "Poppins", sans-serif;	

}
.fixHeight {
  min-height: 250px!important;
}
.font {
  font-family: "Poppins", sans-serif;	
  font-size: 1.9vh;
}
.font-primary {
  font-family: "Poppins", sans-serif;	
  font-size: 2.9vh;
}

.logo {
  width: 32px;
  height: 32px;
  margin-right:5px
}
.bold {
    font-weight: bold;
}
.thumbnailImg {
    height: 100px;
    width:100px;
  }
  .expand {
    width: 100%;
    height: 100%;
    border-radius: 10px!important;
  }
  .z1000 {
    z-index: 1000!important;
    opacity: 1!important;
  }
  .mt {
    margin-top: 2%;
  }
  .width100 {
    width:100%!important;
  }

  .iconProfile {
    position: absolute;
    right: -20%!important;
    margin-top: 50%;
    width: 40px;
    height: 40px;
    padding-right:15px!important;
  }



  .BigAlert {
    background-color: #61A2F1;
    background-color: radial-gradient(
      50% 50% at 50% 50%,
      rgba(170, 137, 254, 0.1) 100%,
      rgba(77, 4, 103, 0) 100%
    );
    width: 100%;
    display: flex;
    height: 70px;
    justify-content: center;
    align-items: center;

}
.dropdown-item {
  cursor: pointer!important;
}
.cursor {
  cursor: pointer!important;

}
img {
  cursor: pointer!important;
}
.small:hover {
  color:#61A2F1;
}
.waves {
  opacity: 0.8;
  background-image:  repeating-radial-gradient( circle at 0 0, transparent 0, #e5e5f7 10px ), repeating-linear-gradient( #444cf755, #444cf7 );
}

.subscriptionBox  {
  border-radius: 10px;
  border:1px solid #61A2F1;
  cursor: pointer!important;
  min-width:350px;
}
.subscriptionBox:hover {
  background-image: radial-gradient(
      50% 50% at 50% 50%,
      rgba(27, 27, 240, 0.1) 0%,
      rgba(77, 136, 239, 0) 100%
    );
  background-color:#61A2F1 ;
  color: #fff !important;
}

.subscriptionBoxHover  {
  border-radius: 10px;
  min-width:350px;
  border:1px solid #61A2F1;
  cursor: pointer!important;
  color: #fff !important;
  background-color:#61A2F1 ;
}
.subscriptionBoxHover:hover {
  background-image: radial-gradient(
      50% 50% at 50% 50%,
      rgba(27, 27, 240, 0.1) 0%,
      rgba(77, 136, 239, 0) 100%
    );

  color: #fff !important;


}
.botonHover:hover {
  background-color: #00000000!important
}
.nohover:hover {
  color: #fff !important;
}
.primary {
  color: #61A2F1 !important;

}
.underline {
  text-decoration: underline;
}
.priceSub {
  padding-left: 20px;
  padding-right: 30px;
}
.h-30 {
  height:100px
}
.cardDebit {
  padding: 5px;
  border-radius: 8px;
  color: #000;
  background-color: #000;
}
.ml-3 {
  margin-left: 15px
}

.post_img  {
  display: inline-block;
  max-height: calc(100vh - 120px);
  object-fit: contain;
}
.changeCard {
  margin-right: 10px;
}
.react-simple-image-viewer__modal {
  z-index: 2000!important;
}
.badgeDonation {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35em 0.65em;
  font-size: 0.85em;
  font-weight: 500;
  text-align: center;
  border-radius: 0.25rem;
  padding: 8px;
  border:2px solid#61A2F1;
  margin:8px;
  border-radius: 10px!important;
  cursor: pointer;
  height: 25px!important;
  color: #61A2F1;
  text-align: center;
}

.badgeDonation:hover {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.35em 0.65em;
  font-size: 0.85em;
  font-weight: 500;
  color: #fff!important;
  text-align: center;
  border-radius: 0.25rem;
  padding: 8px;
  border:2px solid#61A2F1;
  margin:8px;
  border-radius: 10px!important;
  cursor: pointer;
  height: 25px!important;
  text-align: center;
  background-color: #61A2F1;
  color: #fff;
}
.ml-0 {
  margin-left:0!important;
}
.zmax {
  z-index: 2000px;
}
.z-img {
  z-index: 1500px!important;
  
}
.fw-sm {
  font-size: 14px!important;
}
.delete {
  margin-top: -10px!important;
  margin-left: -10px;
  position:absolute;
  height: 16px;
  width: 16px;

}
.mw-200 {
  max-width: 350px;
}
.padChat {
  margin-top: 200px!important;
  height: 500px!important ;
}
.fadeDiv {
    background-color: rgba(0, 0, 0, 0.431);
}

.tabbable .nav-tabs {
  overflow-x: auto;
  overflow-y:hidden;
  flex-wrap: nowrap;
}
.tabbable .nav-tabs .nav-item {
 white-space: nowrap;
}

/* MOBILE RESPONSIVE  */

.mobNo {
  display:block!important;
}
.onlyMob {
  display: none!important;
}

@media all and (min-width:169px) and (max-width:1268px) {
  .card-footer {
    position: fixed!important;
    bottom: 0!important;
    width: 100%;
  
  }
  .avatar-xxl {
    height: 7.125rem!important;
    width: 7.125rem!important;
  } 
  .row {
    --bs-gutter-x: 0rem!important;
  }
  .h-300px{
    max-height: 150px!important;
   }
  .chat-conversation-content {
    padding-bottom: 70px;
    height: 80%!important;
    max-height: 100px;
  }
 

  .mt {
    margin-top: 0%;
  }
  .padMob {
    margin: 10px
  }
  .mobCenter {
    display: flex!important;
    align-items: center!important;
    justify-content: center!important;
    width: 100%!important;
    margin: 0!important;
    padding: 0!important;
  }
  .mobNo {
    display: none!important;
  }
  .onlyMob {
    display: flex!important;
  }

  .searchCard {
    margin: 8px!important;
    margin-bottom:10px;
  }

  .renderSubscribeCard {
    position: relative;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 0.1em 0 1.3em;
    height: 40px!important;
  }
  
  
}

.menuBottom {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #fff ;
  border-top: 1px solid rgba(0, 0, 0, .05);
  padding: 0.3em 0 1.3em;
  z-index: 3;
  font-size: .875em;
  border-top: 2px solid rgba(161, 161, 168, 0.06);
}

.chatFooter {
  position: fixed;
  width: 100%!important;
  bottom: 10px;
  left: 0;
  right: 0;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  background-color: #fff ;
  border-top: 1px solid rgba(0, 0, 0, .05);
  padding: 0.1em 0 1.3em;
  z-index: 3;
  font-size: .875em;
  border-top: 2px solid rgba(161, 161, 168, 0.06);
}
.searchCard {
  background-position: center left; background-size: cover;
  width: 150px;
  margin-bottom: 10px
}

.scrolling-wrapper{
	overflow-x: auto;
  max-width: 100%!important;
  width: 100%!important;
}


/* // terms  */

[data-custom-class='body'], [data-custom-class='body'] * {
  background: transparent !important;
}
[data-custom-class='title'], [data-custom-class='title'] * {
  font-family: Arial !important;
font-size: 26px !important;
color: #000000 !important;
}
[data-custom-class='subtitle'], [data-custom-class='subtitle'] * {
  font-family: Arial !important;
color: #595959 !important;
font-size: 14px !important;
}
[data-custom-class='heading_1'], [data-custom-class='heading_1'] * {
  font-family: Arial !important;
font-size: 19px !important;
color: #000000 !important;
}
[data-custom-class='heading_2'], [data-custom-class='heading_2'] * {
  font-family: Arial !important;
font-size: 17px !important;
color: #000000 !important;
}
[data-custom-class='body_text'], [data-custom-class='body_text'] * {
  color: #595959 !important;
font-size: 14px !important;
font-family: Arial !important;
}
[data-custom-class='link'], [data-custom-class='link'] * {
  color: #3030F1 !important;
font-size: 14px !important;
font-family: Arial !important;
word-break: break-word !important;
}

#p_wrap {
  white-space: pre-line;
}
.w-300 {
  width: 380px!important;
}
.jumbotron {
  border-radius: 20px;
  height: 300px; /* You must set a specified height */
  background: rgb(213,244,254);
background: linear-gradient(90deg, rgba(213,244,254,0.4) 0%, rgba(144,192,255,1) 30%);
  background-position: center; /* Center the image */
  background-repeat: no-repeat; /* Do not repeat the image */
  display: flex;
  justify-content: center;
  align-items: center;
  background-size: cover; /* Resize the background image to cover the entire container */
}

.opTop {
  opacity: 1!important;
}

.section_padding_130 {
  padding-top: 130px;
  padding-bottom: 130px;
}
.faq_area {
  position: relative;
  z-index: 1;
  background-color: #f5f5ff;
}

.faq-accordian {
  position: relative;
  z-index: 1;
}
.faq-accordian .card {
  position: relative;
  z-index: 1;
  margin-bottom: 1.5rem;
}
.faq-accordian .card:last-child {
  margin-bottom: 0;
}
.faq-accordian .card .card-header {
  background-color: #ffffff;
  padding: 0;
  border-bottom-color: #ebebeb;
}
.faq-accordian .card .card-header h6 {
  cursor: pointer;
  padding: 1.75rem 2rem;
  color: #3f43fd;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -ms-grid-row-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
}
.faq-accordian .card .card-header h6 span {
  font-size: 1.5rem;
}
.faq-accordian .card .card-header h6.collapsed {
  color: #070a57;
}
.faq-accordian .card .card-header h6.collapsed span {
  -webkit-transform: rotate(-180deg);
  transform: rotate(-180deg);
}
.faq-accordian .card .card-body {
  padding: 1.75rem 2rem;
}
.faq-accordian .card .card-body p:last-child {
  margin-bottom: 0;
}
.cardFaq {
  border-radius: 5px;
  border: 1px solid grey;
  border-bottom: 1px;
}
.square {
  object-fit: cover;
  width: 100px;
  height: 100px;
  cursor:pointer;
  border-radius: 5px;
}


@media (max-width:450px) {
  .padChat {
    display: none!important;
  }

}

.card-footer {
  position: relative!important;
}




.img-thumbnail {
  padding: 0.25rem;
  background-color: #fff;
  border: 1px solid #dee2e6;
  border-radius: 0.25rem;
  width: 100px!important;
  height: 100px!important;
  object-fit: cover;
}

.video-thumbnail {
  background-color: #fff;
  border: 1px solid #dee2e6;
  max-width: 300px;

}


/*animation form */

/* ----------------------------------------------
 * Generated by Animista on 2024-2-29 0:28:33
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-left
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}
@keyframes slide-in-blurred-left {
  0% {
    -webkit-transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
            transform: translateX(-1000px) scaleX(2.5) scaleY(0.2);
    -webkit-transform-origin: 100% 50%;
            transform-origin: 100% 50%;
    -webkit-filter: blur(40px);
            filter: blur(40px);
    opacity: 0;
  }
  100% {
    -webkit-transform: translateX(0) scaleY(1) scaleX(1);
            transform: translateX(0) scaleY(1) scaleX(1);
    -webkit-transform-origin: 50% 50%;
            transform-origin: 50% 50%;
    -webkit-filter: blur(0);
            filter: blur(0);
    opacity: 1;
  }
}


.slide-in-blurred-left {
  -webkit-animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
          animation: slide-in-blurred-left 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
}


/* animations  */

#testfade {
  animation: fadein 2s;
  -moz-animation: fadein 2s; /* Firefox */
  -webkit-animation: fadein 2s; /* Safari and Chrome */
  -o-animation: fadein 2s; /* Opera */
}
@keyframes fadein {
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-moz-keyframes fadein { /* Firefox */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-webkit-keyframes fadein { /* Safari and Chrome */
  from {
      opacity:0;
  }
  to {
      opacity:1;
  }
}
@-o-keyframes fadein { /* Opera */
  from {
      opacity:0;
  }
  to {
      opacity: 1;
  }
}


